import styled from 'styled-components';

export const FormContainer = styled.div`
  margin-top: 40px;
  background: #ffffff;
  border: 1px solid #f0f0f0;
  box-sizing: border-box;
  border-radius: 10px;
  width: 40%;
  margin: 70px auto;

  .title {
    font-style: normal;
    font-weight: bold;
    font-size: 16px;
    line-height: 140%;
    color: #30313c;
  }
  .header {
    padding: 18px 30px;
    border-bottom: 1px solid #f0f0f0;
  }

  .profile-image-section {
    display: grid;
    align-items: center;
    grid-template-columns: auto 1fr auto;
    gap: 40px;
    padding: 38px 30px;
    border-bottom: 1px solid #f0f0f0;
  }
  .logo-wrapper {
    display: flex;
    justify-content: center;
  }
`;
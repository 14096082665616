/* eslint-disable camelcase */
import React, { useEffect, useState } from 'react';
import * as yup from 'yup';
import { FieldArray, Formik } from 'formik';
import { Text } from 'components/common/Styles';
import { useSelector, useDispatch } from 'react-redux';
import { Input } from 'components/common';
import { updateEmployeeAppraisalResponse } from 'redux/actions/adminAppraisal';
import { useToggle } from 'hooks';
import WarnModal from 'components/WarnModal';
// import { toast } from 'react-toastify';
import { useParams } from 'react-router-dom';
import Button from 'components/common/Button';
import { toast } from 'react-toastify';


const ApraisalObjectives = ({ formik }) => {
  const { role } = useParams();

  const isHm = role === 'hm';

  const MANAGER_STATUS = ['SUPERVISOR RATED', 'MANAGER RATED'];

  const showManager = MANAGER_STATUS.includes(formik.values.status);

  const currentWeightAppraisal = formik.values.employee_objectives.reduce(
    (ini, acc) => ini + acc.weight,
    0
  );

  const currentWorkforceWeightAppraisal = formik.values.workforce_objectives.reduce(
    (ini, acc) => ini + acc.weight,
    0
  );

  const currentRatingAppraisal = formik.values.employee_objectives.reduce(
    (ini, acc) => ini + acc?.rating?.employee_rating || 0,
    0
  );
  const currentWorkforceRatingAppraisal = formik.values.workforce_objectives.reduce(
    (ini, acc) => ini + acc?.rating?.employee_rating || 0,
    0
  );

  const currentWfgLinemanagerRatingAppraisal = formik.values.workforce_objectives.reduce(
    (ini, acc) => ini + parseInt(acc?.rating?.line_manager_rating || 0, 10),
    0
  );

  const currentLinemanagerRatingAppraisal = formik.values.employee_objectives.reduce(
    (ini, acc) => ini + parseInt(acc?.rating?.line_manager_rating || 0, 10),
    0
  );

  const [, setCanAdd] = useState(currentWeightAppraisal < 100);
  useEffect(() => {
    setCanAdd(currentWeightAppraisal < 100);
    // eslint-disable-next-line
  }, [formik.values.objectives]);

  const sumObjectiveWeights = (objectives) => {
    return objectives.reduce((ini, acc) => {
      return (
        parseInt(acc?.rating?.['employee_rating'], 10) +
        parseInt(acc?.rating?.['line_manager_rating'], 10) +
        ini
      );
    }, 0);
  };

  return (
    <>
      <FieldArray name="objectives">
        {(arrayHelpers) => (
          <>
            <div className=" w-100 ">
              <div className=" w-100">
                <Text>Workforce Objectives</Text>
                <table className="table">
                  <thead>
                    <tr className="">
                      <th scope="col">Objective</th>
                      <th scope="col">Weight</th>
                      <th scope="col">Employee Rating</th>
                      <th scope="col">Employee Comment</th>
                      <th scope="col">Line Manager Rating</th>
                      <th scope="col">Line Manager Comment</th>
                    </tr>
                  </thead>

                  {formik.values.workforce_objectives.map((each, index) => (
                    <tr key={index}>
                      <td className="w-10">
                        <p>{formik.values.workforce_objectives[index].text}</p>
                      </td>
                      <td>
                        <Input
                          type="number"
                          readOnly
                          onChange={arrayHelpers.form.handleChange}
                          name={`workforce_objectives.${index}.weight`}
                          value={formik.values.workforce_objectives[index].weight}
                        />
                      </td>
                      <td>
                        <Input
                          type="number"
                          readOnly
                          onChange={arrayHelpers.form.handleChange}
                          name={`workforce_objectives.${index}.rating.employee_rating`}
                          value={
                            formik.values.workforce_objectives[index]?.rating
                              ?.employee_rating
                          }
                        />
                      </td>
                      <td>
                        <Input
                          type="text"
                          readOnly
                          onChange={arrayHelpers.form.handleChange}
                          name={`workforce_objectives.${index}.rating.employee_comment`}
                          value={
                            formik.values.workforce_objectives[index]?.rating
                              ?.employee_comment
                          }
                        />
                      </td>
                      <td>
                        <Input
                          type="text"
                          onChange={arrayHelpers.form.handleChange}
                          name={`workforce_objectives.${index}.rating.line_manager_rating`}
                          value={
                            formik.values.workforce_objectives[index]?.rating
                              ?.line_manager_rating
                          }
                        />
                      </td>
                      <td>
                        <Input
                          type="text"
                          onChange={arrayHelpers.form.handleChange}
                          name={`workforce_objectives.${index}.rating.line_manager_comment`}
                          value={
                            formik.values.workforce_objectives[index]?.rating
                              ?.line_manager_comment
                          }
                        />
                      </td>
                    </tr>
                  ))}
                </table>

                <Text>Employee Objectives</Text>
                <table className="table">
                  <thead>
                    <tr className="">
                      <th scope="col">Objective</th>
                      <th scope="col">Weight</th>
                      <th scope="col">Employee Rating</th>
                      <th scope="col">Employee Comment</th>
                      <th scope="col">Line Manager Rating</th>
                      <th scope="col">Line Manager Comment</th>
                    </tr>
                  </thead>

                  {formik.values.employee_objectives.map((each, index) => (
                    <tr key={index}>
                      <td className="w-10">
                        <p>{formik.values.employee_objectives[index].text}</p>
                      </td>
                      <td>
                        <Input
                          type="number"
                          readOnly
                          onChange={arrayHelpers.form.handleChange}
                          name={`employee_objectives.${index}.weight`}
                          value={formik.values.employee_objectives[index].weight}
                        />
                      </td>

                      <td>
                        <Input
                          type="number"
                          readOnly
                          onChange={arrayHelpers.form.handleChange}
                          name={`employee_objectives.${index}.rating.employee_rating`}
                          value={
                            formik.values.employee_objectives[index]?.rating
                              ?.employee_rating
                          }
                        />
                      </td>
                      <td>
                        <Input
                          type="text"
                          readOnly
                          onChange={arrayHelpers.form.handleChange}
                          name={`employee_objectives.${index}.rating.employee_comment`}
                          value={
                            formik.values.employee_objectives[index]?.rating
                              ?.employee_comment
                          }
                        />
                      </td>
                      <td>
                        <Input
                          type="text"
                          onChange={arrayHelpers.form.handleChange}
                          name={`employee_objectives.${index}.rating.line_manager_rating`}
                          value={
                            formik.values.employee_objectives[index]?.rating
                              ?.line_manager_rating
                          }
                        />
                      </td>
                      <td>
                        <Input
                          type="text"
                          onChange={arrayHelpers.form.handleChange}
                          name={`employee_objectives.${index}.rating.line_manager_comment`}
                          value={
                            formik.values.employee_objectives[index]?.rating
                              ?.line_manager_comment
                          }
                        />
                      </td>
                    </tr>
                  ))}
                </table>

                <Text weight="bold">
                  TOTAL WORKFORCE OBJECTIVES PERCENTAGE:
                  {currentWorkforceWeightAppraisal} %
                </Text>
                <Text weight="bold">
                  TOTAL WORKFORCE RATING PERCENTAGE:
                  {currentWorkforceRatingAppraisal} %
                </Text>
                <br />
                <Text weight="bold">
                  TOTAL EMPLOYEE OBJECTIVES PERCENTAGE:
                  {currentWeightAppraisal} %
                </Text>
                <Text weight="bold">
                  TOTAL EMPLOYEE RATING PERCENTAGE:
                  {currentRatingAppraisal} %
                </Text>
                <br />
                <Text weight="bold">
                  TOTAL WORKFORCE OBJECTIVES LINEMANGER RATING PERCENTAGE:
                  {currentWfgLinemanagerRatingAppraisal} %
                </Text>

                <Text weight="bold">
                  TOTAL EMPLOYEE OBJECTIVES LINEMANGER RATING PERCENTAGE:
                  {currentLinemanagerRatingAppraisal} %
                </Text>
                <br />
                {isHm && (
                  <>
                    <Text weight="bold">
                      TOTAL LINE MANAGER RATING FOR EMPLOYEE OBJECTIVES:
                      {sumObjectiveWeights(
                        formik.values.employee_objectives.concat(
                          formik.values.workforce_objectives
                        ),
                        'line_manager_rating'
                      ) /
                        formik.values.employee_objectives.concat(
                          formik.values.workforce_objectives
                        ).length}
                      %
                    </Text>

                    <br />
                  </>
                )}
                <br />
                {showManager && formik.values.status !== 'MANAGER RATED' && (
                  <div className="w-50">
                    <Input
                      type="number"
                      max={100}
                      label="Head Manager Rating / 100"
                      onChange={formik.handleChange}
                      name="head_manager_rating"
                      value={formik.values.head_manager_rating}
                    />
                    <Text size="12px" color="red">
                      {formik.errors.head_manager_rating}
                    </Text>
                  </div>
                )}
                {formik.values.status === 'MANAGER RATED' && (
                  <Text weight="normal">
                    TOTAL HEAD MANAGER RATING PERCENTAGE:
                    {formik.values.head_manager_rating} %
                  </Text>
                )}
              </div>
              <br />
              <div className=" w-50" />
            </div>
          </>
        )}
      </FieldArray>
    </>
  );
};

const AppraisalList = ({
  appraisalToEdit,
  employee_kpi_id,
  workforce_kpi_id,
  appraisal_id,
  editData,
  kpi_id,
  employee_id,
  validAppraisal_workforce_id,
  validAppraisal_employee_id,
}) => {
  const MANAGER_STATUS = ['SUPERVISOR RATED', 'MANAGER RATED'];
  const showManager = editData && MANAGER_STATUS.includes(editData?.status);

  const validateFormValue = (existingObjectives) => {
    let countobj = 0;
    let employee_rating_total = 0;
    let line_manager_rating_total = 0;

    for (const objective of existingObjectives) {
      if (
        objective?.rating?.line_manager_rating < 1 ||
        objective?.rating?.line_manager_rating === undefined
      ) {
        toast.error('Please enter a positive integer for each line manager rating field');
        countobj += 1;
        return false;
      }
      if (
        !objective?.rating?.line_manager_comment ||
        objective?.rating?.line_manager_comment === undefined
      ) {
        toast.error('Please enter a value for each line manager comment field');
        countobj += 1;
        return false;
      }
      employee_rating_total += objective?.rating?.employee_rating;
      line_manager_rating_total += objective?.rating?.line_manager_rating;

    }

     if (line_manager_rating_total >  employee_rating_total) {
         countobj = 0;
    //   toast.error('Line manager\'s rating should not be gretaer than employee\'s rating');
     //  return false;
     }

    if (countobj === 0) {
      return true;
    }
  };

  const handleSubmit = async (
    { employee_objectives, workforce_objectives, ...rest },
    { setSubmitting }
  ) => {
    let allobjectives = employee_objectives.concat(workforce_objectives);

    if (validateFormValue(allobjectives)) {
      await dispatch(
        updateEmployeeAppraisalResponse(
          {
            ...rest,
            objectives: allobjectives,
            validAppraisal_employee_id: validAppraisal_employee_id,
            validAppraisal_workforce_id: validAppraisal_workforce_id,
            employee: employee_id,
          },
          showManager ? 'MANAGER RATED' : 'SUPERVISOR RATED'
        )
      );
    }
  };
  const dispatch = useDispatch();
  // const hmos = useSelector((state) => state.hmo.hmos) || [];
  const { toggle: toggleWarning, show: showWarning } = useToggle();
  const employeeId = useSelector((state) => state.employee.singleEmployee.id);
  const [edit] = useState(editData);

  const initialValues = {
    employee_objectives: appraisalToEdit.filter((each) => each.type === 'EMPLOYEE') || [],
    workforce_objectives:
      appraisalToEdit.filter((each) => each.type === 'WORKFORCE') || [],
    line_manager_name: edit?.line_manager_name || '',
    line_manager_email: edit?.line_manager_email || '',
    line_manager_rating: edit?.line_manager_rating || '',
    head_manager_email: edit?.head_manager_email || '',
    head_manager_name: edit?.head_manager_name || '',
    head_manager_rating: edit?.head_manager_rating || null,
    status: edit?.status,
    employee: employeeId,
    category: 'EMPLOYEE',
    employee_kpi_id,
    workforce_kpi_id,
    kpi_id,
    deletedIds: [],
    appraisal_id,
  };

  const validationSchema = showManager
    ? yup.object({
        head_manager_rating: yup
          .number()
          .min(1)
          .max(100, 'please rate over 100')
          .required('rating is required'),
      })
    : yup.object({
        line_manager_rating: yup.number().min(1).max(100, 'please rate over 100'),
      });

  return (
    <Formik
      initialValues={initialValues}
      onSubmit={(...args) => {
        handleSubmit(...args);
        toggleWarning();
      }}
      validationSchema={validationSchema}
    >
      {({
        values,
        errors,
        touched,
        isValid,
        handleChange,
        handleBlur,
        handleSubmit,
        isSubmitting,
        setFieldValue,
        validateForm,
      }) => (
        <>
          <div style={{ minWidth: '1024px' }} className="p-4">
            <div>
              <>
                <ApraisalObjectives
                  formik={{
                    values,
                    errors,
                    touched,
                    isValid,
                    handleChange,
                    handleBlur,
                    handleSubmit,
                    setFieldValue,
                  }}
                />
              </>
            </div>
            <Button
              onClick={async () => {
                const result = await validateForm();
                if (Object.keys(result).length < 1) {
                  toggleWarning();
                }
              }}
              text={`Submit`}
            ></Button>
          </div>
          <WarnModal
            show={showWarning}
            toggle={toggleWarning}
            prompt="You are about to submit your rating, you would not be able to edit it again would you like to proceed ?"
            action={async (...args) => {
              toggleWarning();
              await handleSubmit(...args);
            }}
            actionInProgressText="Loading..."
            title="Warning"
            actionText="Submit"
            onCancel={toggleWarning}
            cancelText="Cancel"
          />
        </>
      )}
    </Formik>
  );
};

export default AppraisalList;

import * as actionTypes from '../actionTypes/adminAppraisal';

const initialState = {
    error: null,
    success: false,
    appraisals: [],

    kpiObjectives: [],
    employees:[],
    singleEmployee:{},
    singleAppraisal: {},
    candidateAllappraisalSuccess:false,
    currentAppraisal: [],
    currentappraisalSuccess:false,


  };
  

  const appraisalReducer = (state = initialState, { type, payload }) => {
    switch (type) {
    case actionTypes.GET_APPRAISAL_SUCCESS:
        return {
            ...state,
            appraisals: payload,
            candidateAllappraisalSuccess: payload?.data?.total > 0 ?true:false
        };
    case actionTypes.GET_CURRENT_APPRAISAL_SUCCESS:
          return {
              ...state,
              currentAppraisal: payload,
              // currentappraisalSuccess: payload?.data ?true:false
          };     
    case actionTypes.GET_SINGLE_APPRAISAL_SUCCESS:
      return {
          ...state,
          singleAppraisal: payload,
      };    
    case actionTypes.GET_KPI_OBJECTIVES_SUCCESS:
      return {
        ...state,
        kpiObjectives: payload,
    };

    case actionTypes.GET_EMPLOYEES_APPRAISAL_LIST_SUCCESS:
      return {
          ...state,
          employees: payload,
      };
    case actionTypes.GET_EMPLOYEES_APPRAISAL_SINGLE_SUCCESS:
        return {
            ...state,
            singleEmployee: payload,
        };  

      default:
        return state;
    }
  };
  export default appraisalReducer;


import styled from 'styled-components';

export const User = styled.div`
  display: flex;
  align-self: center;
  justify-self: flex-end;
  align-items: center;
  /* padding: 20px .5rem 53px; */
`;

export const Avatar = styled.img`height: 30px;
width: 30px;
border-radius: 50%;
object-fit:cover
`;
export const Initials = styled.div`
height: 30px;
width: 30px;
border-radius: 50%;
background: #1A21BA;
display: flex !important;
align-items: center !important;
justify-content: center !important;
align-self: center;
font-style: normal;
font-weight: 500;
font-size: 14px;
line-height: 17px;
color: #FFFFFF !important;
`;

export const UserName = styled.p`
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 18px;
  color: ${({ color }) => color ?? '#ffffff'};
  margin: 0 0 0 10px;
  text-transform: capitalize;
`;

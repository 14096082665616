import React from 'react';
import { NavLink } from 'react-router-dom';
import PropTypes from 'prop-types';
// import { Caret } from './styles';
import { svgs } from 'assets';


/**
 * Custom link component
 *
 * @param {Object} props Component props
 * @param {JSX.Element} leftIcon leftIcon element props
 * @param {String} label link name
 * @returns {JSX.Element} component
 */
const CustomLink = ({
  leftIcon: LEFTICON,
  label,
  path,
  showMenu,
  id,
  subMenus,
  more,
  navClick,
  updateMenuDisplay,
  toggleCaret,
}) => {
  return (
    <React.Fragment>
      <li onClick={updateMenuDisplay}>
        <div>
          {LEFTICON && <LEFTICON />}
          {more ? (
            <p id={id}>{label}</p>
          ) : (
            <NavLink  onClick={navClick} activeClassName="activeLink" to={path}>
              {label}
            </NavLink>
          )}
        </div>

        {more && (
          // <Caret
          //   show={showMenu ? 'show' : 'show'}
          //   stroke="red"
          //   id={id}
          //   onClick={updateMenuDisplay}
          // />
          <>
          {!toggleCaret &&
          <img 
          style={{ cursor: 'pointer', width:'14px'}}
          src={svgs.CHEVRON_DOWN}
           alt="chevronup" 
           onClick={updateMenuDisplay}
           className="clickable"  
           id={id}
           />
        }
          {toggleCaret &&
           <img 
           style={{ cursor: 'pointer', width:'14px'}}
           src={svgs.CHEVRON_UP}
            alt="chevronup" 
            onClick={updateMenuDisplay}
            className="clickable"  
            id={id}
            />
         
         }
          </>
        )}
      </li>
      {showMenu && (
        <li className="sub-menu">
          {subMenus &&
            subMenus.map((item, key) => (
              <NavLink
              onClick={navClick}
                key={key}
                activeClassName="activeLink"
                className="sub-menu-text"
                to={`${path}${item?.path}`}
              >
                {item?.label}
              </NavLink>
            ))}
        </li>
      )}
    </React.Fragment>
  );
};

CustomLink.propTypes = {
  leftIcon: PropTypes.object,
  more: PropTypes.bool,
  label: PropTypes.string.isRequired,
  path: PropTypes.string,
};

export default CustomLink;

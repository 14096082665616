/* eslint-disable import/no-dynamic-require */
/* eslint-disable global-require */
import { ReactComponent as FIRST_AID } from 'assets/svgs/first-aid.svg';
import { ReactComponent as WELFARE_ICON } from 'assets/svgs/welfare-icon.svg';
import { ReactComponent as EMPTY_FILE_CMP } from 'assets/svgs/empty-file.svg';
import { ReactComponent as BELL_ICON } from 'assets/svgs/bell-icon.svg';
import { ReactComponent as ATTACHFILE } from 'assets/svgs/attach-file.svg';
import { ReactComponent as FILE_UPLOAD_ICON } from 'assets/svgs/file-text.svg';
import { ReactComponent as IMAGE_UPLOAD_ICON } from 'assets/svgs/image.svg';
import { ReactComponent as UPLOAD_ICON_CLOUD } from 'assets/svgs/upload-cloud.svg';
import { ReactComponent as INFO_ICON } from 'assets/svgs/info.svg';
import { ReactComponent as CHECK_MARK_ICON } from 'assets/svgs/check-mark.svg';
import { ReactComponent as WHITE_CARET_DOWN } from 'assets/svgs/white-caret-down.svg';
import CONFIRMATION_BTN from 'assets/svgs/confirmation-btn.svg';
import { ReactComponent as CHECK_MARK_ROUNDED } from 'assets/svgs/check-mark-rounded.svg';
import { ReactComponent as LOGO } from './svgs/logo.svg';
import { ReactComponent as HOME } from './svgs/home.svg';
import { ReactComponent as USER } from './svgs/user.svg';
import { ReactComponent as COMPANY } from './svgs/company.svg';
import { ReactComponent as CLIPBOARD } from './svgs/clipboard.svg';
import { ReactComponent as SETTINGS } from './svgs/settings.svg';
import { ReactComponent as FORWARD_CARET } from './svgs/forward-caret.svg';
import { ReactComponent as HORIZONTAL_ELLIPSES } from './svgs/more.svg';
import { ReactComponent as CARET_DOWN } from './svgs/caret-down.svg';
import { ReactComponent as BELL } from './svgs/notif-bell-pl.svg';
import { ReactComponent as MAIL } from './svgs/mail.svg';
import { ReactComponent as PHONE } from './svgs/phone.svg';
import { ReactComponent as LOCATION } from './svgs/map-pin.svg';
import { ReactComponent as COMPANY_CIRCLE } from './svgs/company-circle.svg';
import { ReactComponent as EMPLOYEE_CIRCLE } from './svgs/employee-circle.svg';
import { ReactComponent as OFFERS_CIRCLE } from './svgs/offers-circle.svg';
import { ReactComponent as BACK } from './svgs/back.svg';
import { ReactComponent as EYE } from './svgs/eye.svg';
import { ReactComponent as ONBOARDING_ICON } from './svgs/onboarding.svg';
import { ReactComponent as COLOURED_LOGO } from './svgs/logo-colored.svg';
import { ReactComponent as CLOCK } from './svgs/clock.svg';
import { ReactComponent as CALENDAR } from './svgs/calendar.svg';
import { ReactComponent as LOCATIONMARKER } from './svgs/location-marker.svg';
import { ReactComponent as LEFT_ARROW } from './svgs/left-arrow.svg';
import { ReactComponent as ERROR } from './svgs/error.svg';
import { ReactComponent as SUCCESS_CHECK2 } from './svgs/success-check2.svg';
import { ReactComponent as NOTIF_BELL } from './svgs/bell.svg';
import { ReactComponent as EditIconComponent } from './svgs/edit.svg';
import { ReactComponent as CHEVRON_RIGHT } from './svgs/chevron-right.svg';
import { ReactComponent as TrashIcon } from './svgs/trash.svg';

import WORKFLOW from './svgs/achievement.svg';

import LEFT_ICON from './svgs/left.svg';
import RIGHT_ICON from './svgs/right.svg';
import TEST_ICON from './svgs/pf.jpg';

const SVGPATH = './svgs/';
const DOCPATH = './docs/';
const docs = {
  Companies: require(`${DOCPATH}sample_companies.csv`),
  Departments: require(`${DOCPATH}sample_departments.csv`),
  Branches: require(`${DOCPATH}sample_branches.csv`),
  Roles: require(`${DOCPATH}sample_roles.csv`),
  Units: require(`${DOCPATH}sample_units.csv`),
  Candidates: require(`${DOCPATH}sample_candidates.csv`),
  'Code Upload': require(`${DOCPATH}sample_code_upload.csv`),
};

const svgs = {
  sideBarBg: require('./images/sidebar.svg'),
  logo: require('./images/logo.svg'),
  logo_colored: require('./svgs/logo-colored.svg'),
  dashboard_illustration: require('./svgs/dashboard_illustration.svg'),
  edit_icon: require('./svgs/edit.svg'),
  plus_icon: require('./svgs/plus.svg'),
  checked: require('./svgs/checked.svg'),
  unchecked: require('./svgs/unchecked.svg'),
  photo: require('./svgs/photo.svg'),
  bar: require('./svgs/bar.svg'),
  create_button: require('./svgs/new_button.svg'),
  close_button: require('./svgs/close.svg'),
  add_icon: require('./svgs/plus-icon.svg'),
  bottomletterbar: require('./svgs/bottomletterbar.svg'),
  BACK,
  LOGO,
  HOME,
  PICTURE: require(`${SVGPATH}picture.svg`),
  USER,
  NEW_USER: require(`${SVGPATH}user-holder2.svg`),
  COMPANY,
  CLIPBOARD,
  SETTINGS,
  FORWARD_CARET,
  PROFILE_ICON: require(`${SVGPATH}avatar.svg`),
  PASSWORD_ICON: require(`${SVGPATH}eye.svg`),
  HORIZONTAL_ELLIPSES,
  CARET_DOWN,
  WHITE_CARET_DOWN,
  SELECT_DOWN: require(`${SVGPATH}select-down.svg`),
  BELL,
  MAIL,
  PHONE,
  LOCATION,
  TrashIcon,
  NEXT_DOWN: require(`${SVGPATH}arrowDown.svg`),
  USER_OUTLINED: require(`${SVGPATH}user-outlined.svg`),
  SEARCH_ICON: require(`${SVGPATH}search.svg`),
  EMPTY_COMPANY_STATE: require(`${SVGPATH}empty-company-state.svg`),
  UNCHECKED_ICON: require(`${SVGPATH}unchecked.svg`),
  CHECKED_TOGGLE: require(`${SVGPATH}checked-toggle.svg`),
  UNCHECKED_TOGGLE: require(`${SVGPATH}unchecked-toggle.svg`),
  COMPANY_CIRCLE,
  EMPLOYEE_CIRCLE,
  OFFERS_CIRCLE,
  CHECK_ICON: require(`${SVGPATH}check-circle.svg`),
  DRAG: require(`${SVGPATH}drag.svg`),
  CLIP: require(`${SVGPATH}clip.svg`),
  EYE,
  CANDIDATE: require(`${SVGPATH}candidate.svg`),
  FILTER_ICON: require(`${SVGPATH}filter.svg`),
  ARROW_DOWN: require(`${SVGPATH}down.svg`),
  DOWNLOAD: require(`${SVGPATH}download.svg`),
  DOC: require(`${SVGPATH}doc.svg`),
  ARROW_RIGHT: require(`${SVGPATH}chevron-right.svg`),
  ONBOARDING_ICON,
  WF_LOGO: require(`${SVGPATH}wflogo.svg`),
  LETTER_FOOTER_LEFT: require(`${SVGPATH}letter-footer.svg`),
  LETTER_FOOTER_RIGHT: require(`${SVGPATH}letter-footer-right.svg`),
  WORKFLOW,
  LOADER: require(`${SVGPATH}loader.svg`),
  DELETE_WARN: require(`${SVGPATH}delete-warn.svg`),
  FILE_UPLOAD_ICON,
  IMAGE_UPLOAD_ICON,
  MOTIVATION: require(`${SVGPATH}motivation.svg`),
  TRASH: require(`${SVGPATH}trash.svg`),
  CHECK: require(`${SVGPATH}check.svg`),
  LOGO_COLOURED: require(`${SVGPATH}logo-coloured.svg`),
  EMPTY_FILE: require(`${SVGPATH}empty-file.svg`),
  MORE: require(`${SVGPATH}more.svg`),
  SUCCESS_CHECK: require(`${SVGPATH}success-check.svg`),
  CHEVRON_RIGHT_BOLD: require(`${SVGPATH}chevron-right-bold.svg`),
  COLOURED_LOGO,
  NO_FILE_ICON: require(`${SVGPATH}no-files.svg`),
  UPLOAD_ICON: require(`${SVGPATH}upload-cloud.svg`),
  CHEVRON_UP: require(`${SVGPATH}chevron-up.svg`),
  CHEVRON_DOWNBG: require(`${SVGPATH}CARETdOWNbg.svg`),
  CHEVRON_DOWN: require(`${SVGPATH}chevron-down.svg`),
  PAGINATION_CHEVRON_RIGHT: require(`${SVGPATH}Table-chevron-right.svg`),
  PAGINATION_CHEVRON_LEFT: require(`${SVGPATH}Table-chevron-left.svg`),
  PAGINATION_PER_ROW_ICON: require(`${SVGPATH}per-row-icon.svg`),
  FILETEXT: require(`${SVGPATH}file-text-2.svg`),
  DOWNLOAD_COLORED: require(`${SVGPATH}Download-colored.svg`),
  FILE_ICON: require(`${SVGPATH}file-icon.svg`),
  FILE_ICON_SMALL: require(`${SVGPATH}small-file-icon.svg`),
  UPLOAD_ICON_COLOR: require(`${SVGPATH}upload-cloud-black.svg`),
  UPLOAD_ICON_CLOUD,
  INFO_ICON,
  CHECK_MARK_ICON,
  BACK_BUTTON_WITH_BORDER: require(`${SVGPATH}Back-with-border.svg`),
  CLOCK,
  CALENDAR,
  LOCATIONMARKER,
  NOFILES: require(`${SVGPATH}nofiles.svg`),
  CHEVRON_LEFT: require(`${SVGPATH}chevronLeft.svg`),
  CHEVRON_LEFT_BOLD: require(`${SVGPATH}rightArrow.svg`),
  KITE: require(`${SVGPATH}kite.svg`),
  CONFIRMATION_BTN,
  LEFT_ICON,
  RIGHT_ICON,
  FILTER_BY: require(`${SVGPATH}filterBy.svg`),
  APPROVE: require(`${SVGPATH}approve.svg`),
  PENDING: require(`${SVGPATH}pending.svg`),
  DECLINE: require(`${SVGPATH}decline.svg`),
  EDIT_ICON: require(`${SVGPATH}actions.svg`),
  TRASH_RED: require(`${SVGPATH}trash-red.svg`),
  EMPTY_DETAILS_STATE: require(`${SVGPATH}empty-state-view-details.svg`),
  FIRST_AID,
  APRROVE_ICON_RED: require(`${SVGPATH}Approve-icon.svg`),
  REJECT_ICON: require(`${SVGPATH}reject-icon.svg`),
  DOCUMENT_ICON: require(`${SVGPATH}Document.svg`),
  LABEL_CLOSE: require(`${SVGPATH}x.svg`),
  CONFIRM_EMPLOYEE_SUCCESS: require(`${SVGPATH}confirm-employee-success.svg`),
  WELFARE_ICON,
  EMPTY_FILE_CMP,
  AVATAR: require(`${SVGPATH}mini-avatar.svg`),
  BELL_ICON,
  ATTACHFILE,
  LEFT_ARROW,
  CHEVRON_RIGHT,
  CHECK_GREEN: require(`${SVGPATH}check-green.svg`),
  CANCEL_CIRCLE: require(`${SVGPATH}cancel-circle.svg`),
  INFO_CIRCLE: require(`${SVGPATH}info-circle.svg`),
  ERROR,
  APPROVE_GREEN_CHECK: require(`${SVGPATH}mdi-check.svg`),
  ATTACH_ICON: require(`${SVGPATH}attach.svg`),
  TEST_ICON,
  DISCIPLINARY_RESPONSE: require(`${SVGPATH}disciplinary-response.svg`),
  HAPPY_BIRTHDAY: require(`${SVGPATH}happyBirthday.svg`),
  QUESTION_ICON: require(`${SVGPATH}question-1.svg`),
  SUCCESS_CHECK2,
  CHECK_MARK_ROUNDED,
  NOTIF_BELL,
  MOBILE_VIEWER: require(`${SVGPATH}mobileViewer.svg`),
  EditIconComponent,
};

export { svgs, docs };

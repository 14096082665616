import React from 'react';
import { Row } from 'react-bootstrap';
import { AuthPageContainer, SigninArea, Logo, SigninFormArea } from './styles';
import { svgs } from '../../assets';
import AuthSidebar from '../../components/Auth/Sidebar';
import Button from '../../components/common/Button';

const PasswordSuccess = ({ history }) => {
  const backToLogin = () => {
    history.push('/signin');
  };

  return (
    <AuthPageContainer fluid>
      <Row>
        <AuthSidebar />
        <SigninArea lg={8} md={8}>
          <Logo src={svgs.logo_colored} />
          <SigninFormArea lg={5} md={6} sm={12} className="text-center">
            <h1>Password created succesfully</h1>
            <p>You can log in to access your account</p>
            <Button type="button" text="Proceed to Login" onClick={backToLogin} />
          </SigninFormArea>
        </SigninArea>
      </Row>
    </AuthPageContainer>
  );
};

export default PasswordSuccess;

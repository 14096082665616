export const GET_DISCIPLINARY_START = 'GET_DISCIPLINARY_START';
export const GET_DISCIPLINARY_SUCCESS = 'GET_DISCIPLINARY_SUCCESS';
export const GET_DISCIPLINARY_FAIL = 'GET_DISCIPLINARY_FAIL';

export const SEND_DISCIPLINARY_RESPONSE_START = 'SEND_DISCIPLINARY_RESPONSE_START';
export const SEND_DISCIPLINARY_RESPONSE_SUCCESS = 'SEND_DISCIPLINARY_RESPONSE_SUCCESS';
export const SEND_DISCIPLINARY_RESPONSE_FAIL = 'SEND_DISCIPLINARY_RESPONSE_FAIL';

export const GET_COMMITTEES_START = 'GET_COMMITTEES_START';
export const GET_COMMITTEES_SUCCESS = 'GET_COMMITTEES_SUCCESS';
export const GET_COMMITTEES_FAIL = 'GET_COMMITTEES_FAIL';

export const SEND_COMMITTEES_START = 'SEND_COMMITTEES_START';
export const SEND_COMMITTEES_SUCCESS = 'SEND_COMMITTEES_SUCCESS';
export const SEND_COMMITTEES_FAIL = 'SEND_COMMITTEES_FAIL';

export const GET_DISCIPLINARY_EMPLOYEE_START = 'GET_DISCIPLINARY_EMPLOYEE_START';
export const GET_DISCIPLINARY_EMPLOYEE_SUCCESS = 'GET_DISCIPLINARY_EMPLOYEE_SUCCESS';
export const GET_DISCIPLINARY_EMPLOYEE_FAIL = 'GET_DISCIPLINARY_EMPLOYEE_FAIL';

export const CLOSE_DISCIPLINARY_START = 'CLOSE_DISCIPLINARY_START';
export const CLOSE_DISCIPLINARY_SUCCESS = 'CLOSE_DISCIPLINARY_SUCCESS';
export const CLOSE_DISCIPLINARY_FAIL = 'CLOSE_DISCIPLINARY_FAIL';

export const CREATE_DISCIPLINARY_START = 'CREATE_DISCIPLINARY_START';
export const CREATE_DISCIPLINARY_SUCCESS = 'CREATE_DISCIPLINARY_SUCCESS';
export const CREATE_DISCIPLINARY_FAIL = 'CREATE_DISCIPLINARY_FAIL';

export const DISCIPLINARY_TYPES_START = 'DISCIPLINARY_TYPES_START';
export const DISCIPLINARY_TYPES_SUCCESS = 'DISCIPLINARY_TYPES_SUCCESS';
export const DISCIPLINARY_TYPES_FAIL = 'DISCIPLINARY_TYPES_FAIL';
import styled from 'styled-components';

export const Wrapper = styled.div`
  display: flex;
  align-items: flex-start;
  justify-content: center;
  height: 100%;
  .text_centering {
    display: flex;
    justify-content: center;
    text-transform: uppercase;
    font-size: 15px;
  }
  .text_heading {
    color: #1a21ba;
  }
  .comment_button {
    background: #0747a6;
    border-radius: 4px;
    color: white;
    text-decoration: none;
  }
  .comment_button:disabled {
    background: #79bbe6;
    cursor: not-allowed;
    pointer-events: none;
  }
  .status_container {
    display: flex;
    justify-content: flex-end;
    .status_button {
      background: ${(props) => (props.status === 'ACCEPTED' ? '#15c39a' : 'red;')};
      border-radius: 4px;
      color: white;
      text-decoration: none;
    }
  }
`;
export const ModalTextarea = styled.textarea`
  background: #f4f5f7;
  border: 1px solid #c1c7d0;
  box-sizing: border-box;
  border-radius: 4px;
  width: 100%;
  height: 200px;
  padding: 20px;
  font-family: Inter;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 17px;
  color: #5e6c84;
`;

export const Error = styled.div`
  color: red;
  font-size: 12px;
  margin-bottom: 20px;
`;

export const Text = styled.p`
  margin-top: 20px;
  font-family: Inter;
  font-style: normal;
  font-weight: 500;
  font-size: 20px;
  line-height: 29px;
  display: flex;
  align-items: center;
  color: #091e42;
  transition: 1.5s;
`;

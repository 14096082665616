import {
  GET_DISCIPLINARY_START,
  GET_DISCIPLINARY_SUCCESS,
  GET_DISCIPLINARY_FAIL,
  SEND_DISCIPLINARY_RESPONSE_START,
  SEND_DISCIPLINARY_RESPONSE_SUCCESS,
  SEND_DISCIPLINARY_RESPONSE_FAIL,
  GET_COMMITTEES_START,
  GET_COMMITTEES_SUCCESS,
  GET_COMMITTEES_FAIL,
  SEND_COMMITTEES_START,
SEND_COMMITTEES_SUCCESS,
SEND_COMMITTEES_FAIL,
GET_DISCIPLINARY_EMPLOYEE_START,
GET_DISCIPLINARY_EMPLOYEE_SUCCESS,
GET_DISCIPLINARY_EMPLOYEE_FAIL,
CLOSE_DISCIPLINARY_START,
CLOSE_DISCIPLINARY_SUCCESS,
CLOSE_DISCIPLINARY_FAIL,
CREATE_DISCIPLINARY_START,
CREATE_DISCIPLINARY_SUCCESS,
CREATE_DISCIPLINARY_FAIL,
DISCIPLINARY_TYPES_START,
DISCIPLINARY_TYPES_SUCCESS,
DISCIPLINARY_TYPES_FAIL,
} from '../actionTypes/disciplinary';

const initialState = {
  loading: false,
  data: [],
  employee: [],
  response: [],
  error: null,
  success: false,
  committees: [],
  employeeDetail:{},
  disciplinaryData:[],
  committeeError:null,
  committeeSuccess: false,
};

const getDisciplinaryStart = (state) => ({
  ...state,
  loading: true,
  error: null,
});

const getDisciplinarySuccess = (state, action) => ({
  ...state,
  error: null,
  success: false,
  loading: false,
  employee: action.payload,
});
const getDisciplinaryFail = (state, action) => ({
  ...state,
  error: action.error,
  success: false,
  loading: false,
});

const sendDisciplinaryResponseStart = (state) => ({
  ...state,
  loading: true,
  error: null,
});

const sendDisciplinaryResponseSuccess = (state, action) => ({
  ...state,
  error: null,
  success: true,
  loading: false,
  response: action.payload,
});
const sendDisciplinaryResponseFail = (state, action) => (
  {
  ...state,
  error: action.error,
  success: false,
  loading: false,
  response: null,

});

const getCommitteesStart = (state) => ({
  ...state,
  loading: true,
  error: null,
});

const getCommitteesSuccess = (state, action) => ({
  ...state,
  error: null,
  committeeSuccess: true,
  loading: false,
  committees: action.payload,
});
const getCommitteesFail = (state, action) => ({
  ...state,
  success: false,
  loading: false,
  committeeError: action.error,
});


const sendCommitteesStart = (state) => ({
  ...state,
  loading: true,
  error: null,
});

const sendCommitteesSuccess = (state, action) => ({
  ...state,
  error: null,
  success: true,
  loading: false,
});
const sendCommitteesFail = (state, action) => ({
  ...state,
  error: action.error,
  success: false,
  loading: false,
});

const getDisciplinaryEmployeeStart = (state) => ({
  ...state,
  loading: true,
  error: null,
});

const getDisciplinaryEmployeeSuccess = (state, action) => ({
  ...state,
  error: null,
  success: false,
  loading: false,
  employeeDetail: action.payload,
});
const getDisciplinaryEmployeeFail = (state, action) => ({
  ...state,
  error: action.error,
  success: false,
  loading: false,
});

const closeDisciplinaryStart = (state) => ({
  ...state,
  loading: true,
  error: null,
});

const closeDisciplinarySuccess = (state, action) => ({
  ...state,
  error: null,
  success: true,
  loading: false,
});
const closeDisciplinaryFail = (state, action) => ({
  ...state,
  error: action.error,
  success: false,
  loading: false,
});

const createDisciplinaryStart = (state) => ({
  ...state,
  loading: true,
  error: null,
});

const createDisciplinarySuccess = (state, action) => ({
  ...state,
  error: null,
  success: true,
  loading: false,
});
const createDisciplinaryFail = (state, action) => ({
  ...state,
  error: action.error,
  success: false,
  loading: false,
});



const disciplinaryReducer = (state = initialState, action) => {
  switch (action.type) {
    case GET_DISCIPLINARY_START:
      return getDisciplinaryStart(state);
    case GET_DISCIPLINARY_SUCCESS:
      return getDisciplinarySuccess(state, action);
    case GET_DISCIPLINARY_FAIL:
      return getDisciplinaryFail(state, action);
    case SEND_DISCIPLINARY_RESPONSE_START:
      return sendDisciplinaryResponseStart(state);
    case SEND_DISCIPLINARY_RESPONSE_SUCCESS:
      return sendDisciplinaryResponseSuccess(state, action);
    case SEND_DISCIPLINARY_RESPONSE_FAIL:
      return sendDisciplinaryResponseFail(state, action);
    case GET_COMMITTEES_START:
      return getCommitteesStart(state);
    case GET_COMMITTEES_SUCCESS:
      return getCommitteesSuccess(state, action);
    case GET_COMMITTEES_FAIL:
      return getCommitteesFail(state, action);
    case SEND_COMMITTEES_START:
      return sendCommitteesStart(state);
    case SEND_COMMITTEES_SUCCESS:
      return sendCommitteesSuccess(state, action);
    case SEND_COMMITTEES_FAIL:
      return sendCommitteesFail(state, action);
    case GET_DISCIPLINARY_EMPLOYEE_START:
      return getDisciplinaryEmployeeStart(state);
    case GET_DISCIPLINARY_EMPLOYEE_SUCCESS:
      return getDisciplinaryEmployeeSuccess(state, action);
    case GET_DISCIPLINARY_EMPLOYEE_FAIL:
      return getDisciplinaryEmployeeFail(state, action);  
    case CLOSE_DISCIPLINARY_START:
      return closeDisciplinaryStart(state);
    case CLOSE_DISCIPLINARY_SUCCESS:
      return closeDisciplinarySuccess(state, action);
    case CLOSE_DISCIPLINARY_FAIL:
      return closeDisciplinaryFail(state, action); 
  case CREATE_DISCIPLINARY_START:
    return createDisciplinaryStart(state);
  case CREATE_DISCIPLINARY_SUCCESS:
    return createDisciplinarySuccess(state, action);
  case CREATE_DISCIPLINARY_FAIL:
    return createDisciplinaryFail(state, action);
    case DISCIPLINARY_TYPES_START:
      return {
        ...state,
        loading: false,
        success: false,
      };

    case DISCIPLINARY_TYPES_SUCCESS:
      return {
        ...state,
        loading: false,
        success: false,
        disciplinaryData: action.payload,
      };

    case DISCIPLINARY_TYPES_FAIL:
      return {
        ...state,
        loading: false,
        error: action.payload?.error,
      success: false,
      };  
    
    

    default:
      return {
        ...state,
      };
  }
};
export default disciplinaryReducer;

import { Col } from 'react-bootstrap';
import styled from 'styled-components';
import { svgs } from '../../assets';


export const SideBarBg = styled(Col)`
    background: url(${svgs.sideBarBg});
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
`;
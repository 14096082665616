import styled from "styled-components";

export const Body = styled.div`
    display: grid;
    align-items: center;
    justify-content: center;
    height: 100vh;
    padding: 5px;
    grid-template-columns: minmax(300px, 650px);
    background: #E5E5E5;
    div.content {
        display: grid;
        grid-template-columns: 1fr;
        justify-content: center;
        text-align: center;
        div.inner-content{
            margin-top: 45px;
            background: #fff;
            display: grid;
            justify-content: center;
            align-items: center;
            padding: 50px 120px;

            div.icon {
                margin: 35px;
            }

            div.message {
                color: #333;
                span {
                    font-weight: bold;
                    font-size: 16px;
                }

                p {
                    font-weight: 500;
                    font-size: 14px;
                    margin-top: 10px;
                }
            }

            .divider {
                opacity: 0.1;
                border: 1px solid #666666;
                width: 100%;
                margin: 30px 0;
            }

            div.data-section {
                display: grid;
                justify-content: start;
                div {
                    display: grid;
                    grid-template-columns: auto 1fr;
                    grid-column-gap: 12px;
                    margin: 0 0 20px;
                    span{
                        text-align: left;
                        display: grid;
                        align-items: center;
                        font-weight: 500;
                        font-size: 14px;
                        color: #666666;
                    }
                }
            }

            div.upload-section {
                margin-top: 30px;

                button {
                    margin-top: 20px;
                }
            }
        }
    }
`;
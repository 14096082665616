import axios from 'config/axios';
import { toast } from 'react-toastify';
import {
  ACCEPT_OFFER_REQUEST,
  ACCEPT_OFFER_SUCCESS,
  ACCEPT_OFFER_FAILURE,
  DECLINE_OFFER_REQUEST,
  DECLINE_OFFER_SUCCESS,
  DECLINE_OFFER_FAILURE,
} from '../actionTypes/offer';

const acceptOfferRequest = () => ({
  type: ACCEPT_OFFER_REQUEST,
});

const acceptOfferSuccess = (payload) => ({
  type: ACCEPT_OFFER_SUCCESS,
  payload,
});

const acceptOfferFailure = (error) => ({
  type: ACCEPT_OFFER_FAILURE,
  error,
});

export const acceptOffer = (offerId, payload) => async (dispatch) => {
  try {
    dispatch(acceptOfferRequest());
    const request = await axios.patch(`/candidate/accept_offer/${offerId}/`, payload, {
      headers: { 'Content-Type': 'multipart/form-data' },
    });
    return dispatch(acceptOfferSuccess(request));
  } catch (error) {
    toast.error(error?.response?.data?.message || 'an Error occurred')
    return dispatch(acceptOfferFailure(error));
  }
};

const declineOfferRequest = () => ({
  type: DECLINE_OFFER_REQUEST,
});

const declineOfferSuccess = (payload) => ({
  type: DECLINE_OFFER_SUCCESS,
  payload,
});

const declineOfferFailure = (error) => ({
  type: DECLINE_OFFER_FAILURE,
  error,
});

export const declineOffer = ({ offerId, comment }) => async (dispatch) => {
  try {
    dispatch(declineOfferRequest());
    const request = await axios.patch(`/candidate/decline_offer/${offerId}/`, { comment });
    return dispatch(declineOfferSuccess(request));
  } catch (error) {
    return dispatch(declineOfferFailure(error));
  }
};

export const SEND_EMPLOYEE_COMPLAINT_START = 'SEND_EMPLOYEE_COMPLAINT_START';
export const SEND_EMPLOYEE_COMPLAINT_SUCCESS = 'SEND_EMPLOYEE_COMPLAINT_SUCCESS';
export const SEND_EMPLOYEE_COMPLAINT_FAILURE = 'SEND_EMPLOYEE_COMPLAINT_FAILURE';

export const GET_EMPLOYEE_COMPLAINT_CATEGORIES_START =
  'GET_EMPLOYEE_COMPLAINT_CATEGORIES_START';
export const GET_EMPLOYEE_COMPLAINT_CATEGORIES_SUCCESS =
  'GET_EMPLOYEE_COMPLAINT_CATEGORIES_SUCCESS';
export const GET_EMPLOYEE_COMPLAINT_CATEGORIES_FAILURE =
  'GET_EMPLOYEE_COMPLAINT_CATEGORIES_FAILURE';

export const  GET_EMPLOYEE_COMPLAINT_LIST_START = 'GET_EMPLOYEE_COMPLAINT_LIST_START';
export const  GET_EMPLOYEE_COMPLAINT_LIST_SUCCESS = 'GET_EMPLOYEE_COMPLAINT_LIST_SUCCESS';

export const  GET_EMPLOYEE_EACH_COMPLAINT_START = 'GET_EMPLOYEE_EACH_COMPLAINT_START';
export const  GET_EMPLOYEE_EACH_COMPLAINT_SUCCESS = 'GET_EMPLOYEE_EACH_COMPLAINT_SUCCESS'

export const SEND_EMPLOYEE_COMPLAINT_CATEGORIES_START = 'SEND_EMPLOYEE_COMPLAINT_CATEGORIES_START';
export const  SEND_EMPLOYEE_COMPLAINT_CATEGORIES_SUCCESS = 'SEND_EMPLOYEE_COMPLAINT_CATEGORIES_SUCCESS';

export const UPDATE_EMPLOYEE_COMPLAINT_CATEGORIES_START = 'UPDATE_EMPLOYEE_COMPLAINT_CATEGORIES_START';
export const  UPDATE_EMPLOYEE_COMPLAINT_CATEGORIES_SUCCESS = 'UPDATE_EMPLOYEE_COMPLAINT_CATEGORIES_SUCCESS';

export const GET_SINGLE_COMPLAINT_CATEGORIES_START  = 'GET_SINGLE_COMPLAINT_CATEGORIES_START';
export const GET_SINGLE_COMPLAINT_CATEGORIES_SUCCESS = 'GET_SINGLE_COMPLAINT_CATEGORIES_SUCCES';
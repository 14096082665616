export const CREATE_DOCUMENT_TYPE_REQUEST = 'CREATE_DOCUMENT_TYPE_REQUEST';
export const CREATE_DOCUMENT_TYPE_SUCCESS = 'CREATE_DOCUMENT_TYPE_SUCCESS';
export const CREATE_DOCUMENT_TYPE_FAILURE = 'CREATE_DOCUMENT_TYPE_FAILURE';

export const GET_DOCUMENT_TYPE_REQUEST = 'GET_DOCUMENT_TYPE_REQUEST';
export const GET_DOCUMENT_TYPE_SUCCESS = 'GET_DOCUMENT_TYPE_SUCCESS';
export const GET_DOCUMENT_TYPE_FAILURE = 'GET_DOCUMENT_TYPE_FAILURE';

export const EDIT_DOCUMENT_TYPE_REQUEST = 'EDIT_DOCUMENT_TYPE_REQUEST';
export const EDIT_DOCUMENT_TYPE_SUCCESS = 'EDIT_DOCUMENT_TYPE_SUCCESS';
export const EDIT_DOCUMENT_TYPE_FAILURE = 'EDIT_DOCUMENT_TYPE_FAILURE';

export const DELETE_DOCUMENT_TYPE_REQUEST = 'DELETE_DOCUMENT_TYPE_REQUEST';
export const DELETE_DOCUMENT_TYPE_SUCCESS = 'DELETE_DOCUMENT_TYPE_SUCCESS';
export const DELETE_DOCUMENT_TYPE_FAILURE = 'DELETE_DOCUMENT_TYPE_FAILURE';


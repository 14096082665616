import React from 'react';
import PageContainer from 'components/common/PageContainer';
import { Wrapper, FormContainer } from 'pages/Candidate/Offer/styles';
import { svgs } from 'assets';
import { isAuthenticated } from 'utils/auth';
import { decodeToken, getToken } from 'utils/auth';
import { Link } from 'react-router-dom';

const checkisAuthenticated = isAuthenticated();
const token = getToken();
const user = decodeToken(token);

const Success = () => (
  <PageContainer>
    <FormContainer>
      <div className="logo-wrapper">
        <svgs.COLOURED_LOGO width="200px" />
      </div>
      <Wrapper>
        <div className="content status-wrapper">
          <div className="logo-wrapper">
            <svgs.CHECK_MARK_ROUNDED />
          </div>

          <p className="header">Successful</p>
          {checkisAuthenticated && user.roles[0] === 'EMPLOYEE' && (
            <Link to={`/selfservice/appraisals-list`} className="app-img-responsive">
              Back to appraisal
            </Link>
          )}
        </div>
      </Wrapper>
    </FormContainer>
  </PageContainer>
);

export default Success;

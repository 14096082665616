import { useState, useEffect, useRef,useLayoutEffect } from 'react';
import axios from 'axios';
import { getToken } from 'utils/auth';
import { toast } from 'react-toastify';
import React from 'react'




export function useComponentVisible(initialIsVisible) {
  const [isComponentVisible, setIsComponentVisible] = useState(initialIsVisible);
  const ref = useRef(null);

  const handleClickOutside = (event) => {
    if (ref.current && !ref.current.contains(event.target) && isComponentVisible) {
      setIsComponentVisible(false);
    }
  };

  useEffect(() => {
    document.addEventListener('click', handleClickOutside, true);
    return () => {
      document.removeEventListener('click', handleClickOutside, true);
    };
  });

  return { ref, isComponentVisible, setIsComponentVisible };
}

export function textEllipsis(str, maxLength, { side = 'end', ellipsis = '...' } = {}) {
  if (str.length > maxLength) {
    switch (side) {
      case 'start':
        return ellipsis + str.slice(-(maxLength - ellipsis.length));
      case 'end':
      default:
        return str.slice(0, maxLength - ellipsis.length) + ellipsis;
    }
  }
  return str;
}

export const baseUrl = process.env.REACT_APP_BASE_URL;

export function capitalize(s) {
  if (typeof s !== 'string') return s;
  return s.charAt(0).toUpperCase() + s.slice(1).toLowerCase();
}

export function removeTags(str) {
  if (str === null || str === '') return false;
  else str = str?.toString();
  return str?.replace(/(<([^>]+)>)/gi, '');
}

export function getuserDetail(arr, item, userid) {
  let value;
  let alluser = arr?.results?.filter((user) => user.id === userid)[0];
  if (item === 'firstname') value = alluser?.firstname;
  if (item === 'lastname') value = alluser?.lastname;
  if (item === 'email') value = alluser?.email;

  return value;
}

export function changeStatusColor(item) {
  let status = item.toLowerCase();
  switch (status) {
    case 'uploaded':
      return 'color-pending';
    case 'approved':
      return 'color-approved';
    case 'ongoing':
      return 'color-approved';
    case 'nofile':
      return 'color-nofile';
    case 'declined':
      return 'color-rejected';
    case 'pending':
      return 'color-pending';
    case 'decline':
      return 'color-decline';
    case 'closed':
      return 'color-decline';
    case 'acknowledge':
      return 'color-acknowledge';
    default:
      break;
  }
}


export const downLoadZip = async (data, setExportLoading) => {
  const token = getToken();
  const baseUrl = 'http://localhost:8000/api/v1/';
  let endpoint = `${baseUrl}employee/export_photo_id/`;
  setExportLoading(true)

  try {
    await axios({
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`,
      },

      url: endpoint,
      data: data,
      method: 'POST',
      responseType: 'arraybuffer',
    }).then((response) => {
      let url = window.URL.createObjectURL(
        new Blob([response.data], { type: 'application/zip' })
      );

      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', 'Employees_images.zip');
      document.body.appendChild(link);
      link.click();
      setExportLoading(false)
    });
  } catch (error) {
    console.log('error');
  }
};


export function useWindowSize() {
  const [size, setSize] = useState([0, 0]);

  useLayoutEffect(() => {
    function updateSize() {
      setSize([window.innerWidth, window.innerHeight]);
    }

    window.addEventListener('resize', updateSize);
    updateSize();

    return () => window.removeEventListener('resize', updateSize);
  }, []);

  return size;
}

export const generateError = (pageError) => {
  let  listOfErrors = [];
    if(pageError){
    if (Array.isArray(pageError) && typeof pageError[0]  === 'object') {
        let arry = [];
       for (let i = 0; i < pageError.length; i++){
        for (let propName in pageError[i]) {
          arry.push(capitalize(propName) + ' ' + pageError[i][propName]);
          listOfErrors=[...arry]
        }
      }
      }

      if (Array.isArray(pageError) && typeof pageError[0]  === 'string') {
        listOfErrors = [...pageError];
      }


    if (!Array.isArray(pageError) && typeof pageError[0] !== 'string') {
      let arry = [];
      for (let propName in pageError) {
        arry.push(capitalize(propName) + ' ' + pageError[propName]);
        listOfErrors = [...arry];
      }
    }

    if (typeof pageError === 'string') {
      listOfErrors.push(pageError);
    }

    return listOfErrors.map((item, index) => (
     toast.error(item)
    ));
  };
}


export const variableText =() =>{
 return  [
    { id: 1, name: 'First Name', value: 'firstname' },
    { id: 2, name: 'Middle Name', value: 'middle_name' },
    { id: 3, name: 'Last Name', value: 'lastname' },
    { id: 4, name: 'Address', value: 'address' },
    { id: 5, name: 'Staff Id', value: 'staff_id' },
    { id: 6, name: 'Company', value: 'company' },
    { id: 7, name: 'Branch', value: 'branch' },
    { id: 8, name: 'Job Role', value: 'job_role' },
    { id: 9, name: 'Department', value: 'department' },
    { id: 10, name: 'Date', value: 'date' },
    { id: 11, name: 'End Date', value: 'end_date' },
    { id: 12, name: 'Resumption Date', value: 'resumption_date' },
    { id: 13, name: 'Annual Gross Salary', value: 'gross_salary' },
    { id: 14, name: 'Monthly Net Salary', value: 'net_salary' },
    { id: 15, name: 'Payroll', value: 'payroll' },
    { id: 16, name: 'Signature', value: 'signature' }
  ];
}


export const getObjectives = (data) => {
  let array  = []
  if (data?.total){
 let res = data?.results.forEach(e => {
      if (e.objectives.length > 0 ){
        e.objectives.forEach(obj => {
        array.push({
          kpi:e.id,
          weight:obj.weight,
          text:obj.text,
          id:obj.id
        })
       });
      }

    });
    console.log(res)
    return array
  }
  return []
}


export const formatPrice = (num) => {
  if (num){
  var p = parseInt(num).toFixed(2).split(".");
  return "₦" + p[0].split("").reverse().reduce(function(acc, num, i, orig) {
      return num + (num !== "-" && i && !(i % 3) ? "," : "") + acc;
  }, "") + "." + p[1];
}
return "₦"+ num;

}



  export const generateErrorHtml = (pageError) => {
    let listOfErrors = [];
    if(pageError){
    if (Array.isArray(pageError) && typeof pageError[0]  === 'object') {
        let arry = [];
       for (let i = 0; i < pageError.length; i++){
        for (let propName in pageError[i]) {
          arry.push(capitalize(propName) + ' ' + pageError[i][propName]);
          listOfErrors=[...arry]
        }
      }
      }

      if (Array.isArray(pageError) && typeof pageError[0]  === 'string') {
        listOfErrors = [...pageError];
      }


    if (!Array.isArray(pageError) && typeof pageError[0] !== 'string') {
      let arry = [];
      for (let propName in pageError) {
        arry.push(capitalize(propName) + ' ' + pageError[propName]);
        listOfErrors = [...arry];
      }
    }

    if (typeof pageError === 'string') {
      listOfErrors.push(pageError);
    }

    return listOfErrors.map((item, index) => (
      <p className="col-12" key={index}>
        {item}
      </p>
    ));
  };
}

const CHANGEREQUESTMESSAGE='Change request is pending'

export const errorChangeRequestTag=(placeholder, state,requestList)=>{
  return ( <small style={{color:'red'}}>
  {requestList.includes(placeholder) && state
    ? CHANGEREQUESTMESSAGE
    : ''}
</small>)
}


export const filterSameData=(old_data,new_data,id='')=>{
  let edited_data={};

  for (const propName in old_data) {

    if (old_data[propName] === '' || old_data[propName] === undefined || old_data[propName] === 'Invalid date' || old_data[propName] === null) {
      delete old_data[propName]
    }

    if (new_data[propName] === '' || new_data[propName] === undefined || new_data[propName] === 'Invalid date' || new_data[propName] === null) {
      delete new_data[propName]
    }



      if (old_data[propName]?.toString().toLowerCase() !== new_data[propName]?.toString().toLowerCase() &&  new_data[propName] !== undefined ) {

        if (id){
          edited_data[`${propName}_${id}`]=new_data[propName]
        }else{
          edited_data[propName]=new_data[propName]
        }
      }


  }
  return edited_data



}


  export const validateSameDataPayload=(data,data1)=>{
    let sameData = [];
    for (var propName in data) {
      if (data1[propName] === null  || data1[propName] === undefined ) {
        data1[propName]=''

      }
      if (data[propName] === null || data[propName] === undefined ) {
         data[propName]=''

      }

      if (data1[propName].length > 0 || data[propName].length > 0) {

        if (data[propName]?.toLowerCase() === data1[propName]?.toLowerCase() ) {
            sameData.push(propName);
        }
      }
    }
   return sameData
  }

  const displayList = ['Employee', 'Payroll', 'EmergencyContact'];
  export const removeLastItem = (text, modelName) => {
    if (displayList.includes(modelName)) return text;
    const b = text.split('_');
    b.pop();
    return b.toString();
  };


  export const getObjectList = (data,key=[], name='others') => {

    let list = [];
    let d ={};

    if (data){

      data.forEach((element) => {
      if (key.length > 0){
        d= {
          label: element[key[0]],
          value: element[key[1]],

        };
      }
      if (key.length < 1 ){
        d = {
          label: element,
          value: element,

        };
      }

        list.push(d);
      });

      if (['addothers'].includes(name)){
        list.push({label:"others", value:"others"})
      }

  }
    return list;
  };


  export const getObjectListSelect = (data,key=[]) => {
    let list = [];
    let d ={};

    if (data){

      data.forEach((element) => {
      if (key.length > 0){
        d= {
          label: element[key[0]],
          value: element[key[0]],
          name: element[key[1]],
        };
      }
      if (key.length < 1 ){
        d = {
          label: element,
          value: element,
          name: element,
        };
      }
        list.push(d);
      });
  }

    return list;
  };

export const prepareDropdownData = (data, key = []) => {
}

// searchList list should be in the format [{value: 'xxx', label: 'xxx}...]
// callback is for internal use by react-select. do not modify
export const loadSearchOptions = async (
  inputValue,
  callback,
  searchList,
  handleSearch,
) => {

  const filterList = (text, values) =>
    values?.filter((i) => i.label.toLowerCase().includes(text.toLowerCase()));

  if (!inputValue) {
    setTimeout(() => {
      callback(filterList(inputValue, searchList));
    }, 1000);
  } else {
    const resultSearchList = await handleSearch(inputValue);
    const results = resultSearchList?.map((q) => ({
      value: q.id ?? q.name,
      label: q.name,
    }));
    callback(filterList(inputValue, results));
  }
};

export const  changeNationalityName=(value) => {
  let nationality = {Nigerian: 'Nigeria'}
  if (Object.keys(nationality).includes(value)){
    return nationality[value]
  }
  return value
}

import styled from 'styled-components';

export const SelectedItem = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-radius: 4px;
  border: 1px solid #c1c7d0;
  height: 40px;
  padding: 0 1rem;
  cursor: pointer;
  background: #f4f5f7;
`;

export const SelectedItemInput = styled.input`
  border: none;
  width: 100%;
  outline: none;
  background: none;
  font-size: 1rem;
  height: 100%;
`;

export const SelectArrowDown = styled.img`
  cursor: pointer;
  transform: ${({ showDropDown }) =>
    showDropDown ? 'rotate(180deg)' : 'rotate(360deg)'};
`;

export const DropDown = styled.div`
  display: ${({ showDropDown }) => (showDropDown ? 'block' : 'none')};
  width: 100%;
  height: 200px;
  overflow-y: scroll;
  margin-top: 4px;
  border-radius: 4px;
  padding: 8px 0;
  overflow: scroll;
  background: #fff;
  position: absolute;
  z-index: 20;
  border: 1px solid #f4f5f7;
  box-sizing: border-box;
  box-shadow: 0px 8px 16px rgba(167, 190, 206, 0.25);
  border-radius: 5px;
`;

export const CheckboxItem = styled.div`
  display: flex;
  padding: 5px 16px;
  cursor: pointer;
  :hover {
    background: #f4f5f7;
  }
`;

export const SelectDropDownFooter = styled.div`
  display: flex;
  justify-content: center;
  margin: 1rem 0;
  p:first-child,
  p:last-child {
    margin: 0 1rem;
    cursor: pointer;
  }
  p:nth-child(2) {
    span {
      margin: 0 0.5rem;
    }
  }
`;

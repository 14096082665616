
import * as types from '../actionTypes/jobRole';

const initialState = {
  loading: false,
  data: [],
};

const jobRoleReducer = (state = initialState, action) => {
  switch (action.type) {
      case types.ADD_ROLE_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case types.ADD_ROLE_SUCCESS:
      const newData = [...state?.data, action.payload?.data?.data];
      return {
        ...state,
        error: false,
        loading: false,
        data: newData,
        success: true,
      };
    case types.ADD_ROLE_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.error,
        success: false,
      };

      case types.GET_ROLES_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case types.GET_ROLES_SUCCESS:
      return {
        ...state,
        error: false,
        loading: false,
        data: action.payload?.data,
        success: true,
      };
    case types.GET_ROLES_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.error,
        success: false,
      };

      case types.EDIT_ROLE_REQUEST:
        return {
          ...state,
          loading: true,
        };
      case types.EDIT_ROLE_SUCCESS:
        const result = state?.data?.filter(i => i?.id !== action.payload?.data?.data?.id)
        const newEditData = [...result, action.payload?.data?.data];
        return {
          ...state,
          error: false,
          loading: false,
          data: newEditData,
          success: true,
        };
      case types.EDIT_ROLE_FAILURE:
        return {
          ...state,
          loading: false,
          error: action.error,
          success: false,
        };

        case types.DELETE_ROLE_REQUEST:
        return {
          ...state,
          loading: true,
        };
      case types.DELETE_ROLE_SUCCESS:
        const res = state?.data?.filter(i => i?.id !== action.payload)
        return {
          ...state,
          error: false,
          loading: false,
          data: res,
          success: true,
        };
      case types.DELETE_ROLE_FAILURE:
        return {
          ...state,
          loading: false,
          error: action.error,
          success: false,
        };
    default:
      return {
        ...state,
      };
  }
};

export default jobRoleReducer;

import * as actionTypes from '../actionTypes/hmo';

const initialState = {
  error: null,
  success: false,
  hmos: [],
  singleHmo: {},
  hospitals: [],
  hospital: {},
  companies: [],
  company: {},
  employeeComplaintsList: [],
  classification: [],
  singleClassification: {},
  questions: null,
  specific_questions: null,
  surveys: null,
  hmoemployees: [],
  changeRequestSubscribers: [],
  requests: [],
  dashboardData: {},
  dashboardHospitals: [],
  dashboardCompanies: [],
  dashboardEmployees: [],
  allAnnouncements: [],
  publishSuccess: false,
};

const hmoReducer = (state = initialState, { type, payload }) => {
  switch (type) {
    case actionTypes.GET_QUESTION_START:
      return {
        ...state,
        loading: true,
      };
    case actionTypes.GET_QUESTION_SUCCESS:
      return {
        ...state,
        loading: false,
        questions: payload,
      };
    case actionTypes.GET_QUESTION_FAIL:
      return {
        ...state,
        loading: false,
      };
    case actionTypes.GET_SPECIFIC_QUESTION_START:
      return {
        ...state,
        loading: true,
      };
    case actionTypes.GET_SPECIFIC_QUESTION_SUCCESS:
      return {
        ...state,
        loading: false,
        specific_questions: payload,
      };
    case actionTypes.GET_SPECIFIC_QUESTION_FAIL:
      return {
        ...state,
        loading: false,
      };
      case actionTypes.GET_HMO_SURVEYS_START:
        return {
          ...state,
          loading: true,
        };
      case actionTypes.GET_HMO_SURVEYS_SUCCESS:
        return {
          ...state,
          loading: false,
          surveys: payload,
        };
      case actionTypes.GET_HMO_SURVEYS_FAIL:
        return {
          ...state,
          loading: false,
        };
    case actionTypes.GET_HMO_SUCCESS:
      return {
        ...state,
        hmos: payload,
      };
    case actionTypes.GET_SINGLE_HMO_SUCCESS:
      return {
        ...state,
        singleHmo: payload,
      };
    case actionTypes.GET_HOSPITAL_SUCCESS:
      return {
        ...state,
        hospitals: payload,
      };
    case actionTypes.GET_SINGLE_HOSPITAL_SUCCESS:
      return {
        ...state,
        hospital: payload,
      };
    case actionTypes.GET_COMPANY_SUCCESS:
      return {
        ...state,
        companies: payload,
      };
    case actionTypes.GET_SINGLE_COMPANY_SUCCESS:
      return {
        ...state,
        company: payload,
      };
    case actionTypes.GET_EMPLOYEE_COMPLAINT_LIST_START:
      return {
        ...state,
        error: null,
        loading: false,
      };

    case actionTypes.GET_EMPLOYEE_COMPLAINT_LIST_SUCCESS:
      return {
        ...state,
        error: null,
        loading: false,
        employeeComplaintsList: payload,
      };

    case actionTypes.UPDATE_EMPLOYEE_COMPLAINT_CATEGORIES_START:
      return {
        ...state,
        error: null,
        loading: true,
      };

    case actionTypes.GET_CLASSIFICATION_SUCCESS:
      return {
        ...state,
        error: null,
        loading: false,
        classification: payload,
      };

    case actionTypes.GET_SINGLE_CLASSIFICATION_SUCCESS:
      return {
        ...state,
        error: null,
        loading: false,
        singleClassification: payload,
      };
    case actionTypes.GET_SUBSCRIBERS_SUCCESS:
      return {
        ...state,
        error: null,
        loading: false,
        hmoemployees: payload,
      };
    case actionTypes.GET_CHANGE_REQUEST_SUBSCRIBERS_SUCCESS:
      return {
        ...state,
        error: null,
        loading: false,
        changeRequestSubscribers: payload,
      };

    case actionTypes.GET_HMO_CHANGE_REQUEST_START:
      return {
        ...state,
        error: null,
        loading: true,
      };

    case actionTypes.GET_HMO_CHANGE_REQUEST_SUCCESS:
      return {
        ...state,
        error: null,
        loading: false,
        requests: payload,
      };
    case actionTypes.GET_DASHBOARD_DATA_SUCCESS:
      return {
        ...state,
        error: null,
        loading: false,
        dashboardData: payload,
      };

    case actionTypes.GET_DASHBOARD_HOSPITAL_SUCCESS:
      return {
        ...state,
        error: null,
        loading: false,
        dashboardHospitals: payload,
      };
    case actionTypes.GET_DASHBOARD_COMPANY_SUCCESS:
      return {
        ...state,
        error: null,
        loading: false,
        dashboardCompanies: payload,
      };

    case actionTypes.GET_DASHBOARD_SUBSCRIBER_SUCCESS:
      return {
        ...state,
        error: null,
        loading: false,
        dashboardEmployees: payload,
      };
    case actionTypes.GET_ALL_SUCCESS:
      return {
        ...state,
        error: null,
        loading: false,
        allAnnouncements: payload,
      };

    case actionTypes.GET_ALL_REQUEST:
      return {
        ...state,
        error: null,
        loading: true,
      };
    case actionTypes.PUBLISH_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case actionTypes.PUBLISH_SUCCESS:
      return {
        ...state,
        loading: false,
        publishSuccess: true,
      };

    default:
      return state;
  }
};

export default hmoReducer;

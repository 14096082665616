/* eslint-disable react/jsx-one-expression-per-line */
/* eslint-disable no-unused-vars */
/* eslint-disable no-shadow */
/* eslint-disable camelcase */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable jsx-a11y/no-noninteractive-element-to-interactive-role */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React, { useEffect, useState } from 'react';
import { ActionBar } from 'pages/Settings/styles';
import { ActionBarArea, Wrapper, Text } from 'components/common/Styles';
import Button from 'components/common/Button';
import { svgs } from 'assets';
import { useToggle } from 'hooks';
import { useDispatch, useSelector } from 'react-redux';
import { getEmployeeKpiResponse } from 'redux/actions/adminAppraisal';
import { getSingleEmployee } from 'redux/actions/employee';
import { useParams } from 'react-router-dom/cjs/react-router-dom.min';
import AppraisalList from './AppraisalList';
import { Logo } from './style';

/**
 * Surveys Page
 * @returns {JSX}
 */
function AppraisalReview() {
  const {role, appraisal_id, employee_id, kpi_id } = useParams();


  const isHm = role === 'hm';
  const status = isHm ? 'SUPERVISOR RATED' : 'APPRAISAL SUBMITTED';
  useEffect(() => {
    const fetchSingleEmployee = async () => {
      await dispatch(getSingleEmployee(employee_id));
    };
    fetchSingleEmployee();
    // eslint-disable-next-line
  }, []);
  const appraisals = useSelector((state) => state.appraisal.appraisals);
  const [employee_kpi_id, setEmployeeKpiId] = useState(null);
  const [editData, setEditData] = useState(null);
  const employeeId = useSelector((state) => state.employee.singleEmployee.id);
  const dispatch = useDispatch();

  const fetchInitialData = async () => {
    await dispatch(getEmployeeKpiResponse(employee_id, appraisal_id, kpi_id, status));
  };

  useEffect(() => {
    fetchInitialData();
  }, [employeeId]);

  const hasAppraisal = appraisals.results?.filter((each) => each?.objectives?.length > 0);

  const employee = hasAppraisal?.length > 0 ? hasAppraisal[0]?.employee : null;

  const name = employee?.user
    ? `${employee.user.firstname} ${employee.user.lastname}`
    : '';

  const validAppraisal = appraisals.results?.filter((each) => each?.objectives?.length > 0);
  const employeeKpi = validAppraisal?.find(each => each?.kpi?.category === 'EMPLOYEE')?.kpi;
  const workforceKpi = validAppraisal?.find(each => each?.kpi?.category === 'WORKFORCE')?.kpi;
  const validAppraisal_employee_id = validAppraisal?.find(each => each?.kpi?.category === 'EMPLOYEE')?.id;
  const validAppraisal_workforce_id = validAppraisal?.find(each => each?.kpi?.category === 'WORKFORCE')?.id;
  const validAppraisal_employee_employee_id = validAppraisal?.find(each => each?.kpi?.category === 'EMPLOYEE')?.employee?.id;

  useEffect(() => {
    if (validAppraisal && validAppraisal[0]?.id) {
      setEditData(validAppraisal[0]);
    }
  }, [appraisals]);

  const objectiveSum = appraisals.results
    ?.filter((each) => each.objectives.length > 0)
    .reduce((ini, acc, index) => {
      return ini.concat(
        acc.objectives.map((each) => ({ ...each, type: acc.kpi?.category }))
      );
    }, []);

  return (
    <>
      <ActionBar>
        <ActionBarArea>
          <div className="left-items">
            <Logo src={svgs.logo_colored} />
            <div>Review Appraisal</div>
            <br />
            <div>Name: {name}</div>
            <div>Role: {employee?.job_role?.name}</div>
            <div>Department: {employee?.job_role?.department?.name}</div>
          </div>
          <div className="right-items" />
        </ActionBarArea>
      </ActionBar>
      <Wrapper width="95%" margin="0 auto" padding="30px" className="mt-4">
        {validAppraisal && validAppraisal[0] && editData && (
          <AppraisalList
            appraisalToEdit={objectiveSum}
            appraisal_id={appraisal_id}
            editData={editData}
            employee_kpi_id={employeeKpi?.id}
            workforce_kpi_id={workforceKpi?.id}
            kpi_id={kpi_id}
            validAppraisal_employee_id={validAppraisal_employee_id}
            validAppraisal_workforce_id={validAppraisal_workforce_id}
            employee_id ={validAppraisal_employee_employee_id}

          />
        )}
      </Wrapper>
    </>
  );
}

export default AppraisalReview;

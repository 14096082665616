import styled from 'styled-components';

export const ButtonContainer = styled.button`
  width: ${(props) => (props.auto ? 'auto' : '100%')};
  height: 48px;
  background: ${(props) => (props.type === 'cancel' ? '#fff' : '#1A21BA;')};
  border-radius: 3px;
  border: ${(props) => (props.type === 'cancel' ? '1px solid red' : 'none')};
  cursor: pointer;
  color: ${(props) => (props.type === 'cancel' ? 'red' : '#fff')};
  text-align: center;
  font-size: 1rem;
  padding: ${({ auto }) => (auto ? '12px 35px' : '0')};
  &:focus {
    outline: none;
  }
  &:disabled {
    opacity: 0.2;
  }
`;

export const PrimaryButton = styled.button`
  width: 220px;
  height: 50px;
  background: #ffffff;
  border: 1px solid #d9d9d9;
  box-sizing: border-box;
  box-shadow: 0px 4px 4px rgba(199, 223, 241, 0.25);
  border-radius: 5px;
`;

/* eslint-disable camelcase */
import * as yup from 'yup';
import moment from 'moment';

const phoneNumberRules = /^\+?\d+-?[0-9]+-?[0-9]+$/;
const emailRules = /^[a-zA-Z0-9_.+-]+@[a-zA-Z0-9-]+.[a-zA-Z0-9-.]+$/;
const numberRules = /^[0-9]+$/;

export const userLoginSchema = yup.object().shape({
  email: yup.string().required('Email is required'),
  password: yup.string().required('Password is required'),
});

export const addDepartmentSchema = yup.object({
  name: yup.string().required('name is required.'),
  description: yup.string().max(300, 'should not be more than 300 characters').nullable(),
  code: yup.string().nullable()
});

export const addBranchSchema = yup.object({
  name: yup.string().required('name is required.'),
  description: yup.string().max(300, 'should not be more than 300 characters'),
  code: yup.string().nullable(),
});

export const addLetterSchema = yup.object({
  title: yup.string().required('title is required.'),
  template: yup.string().required('template is required.'),
});

export const addUnitSchema = yup.object({
  name: yup.string().required('name is required.'),
  description: yup
    .string()
    .required('dscription is required')
    .max(300, 'should not be more than 300 characters'),
    code: yup.string().nullable(),
});

export const addCompanySchema = yup.object({
  name: yup.string().required('company name is required.').nullable(),
  email: yup.string().nullable(),
  phone: yup
    .number()
    .nullable()
    .typeError('phone number must be a number'),
  address: yup.string().required('address is required').nullable(),
  country: yup.string().required('country is required').nullable(),
  staff_id_format: yup.string().required('Staff Id format  is required').nullable(),
  code: yup.string().nullable(),
});

export const addLocationSchema = yup.object({
  name: yup.string().required('name is required.'),
  address: yup.string().max(300, 'should not be more than 300 characters').nullable(),
  code: yup.string().nullable(),
});

export const workflowErrorSchema = yup.object({
  title: yup.string().required('name is required.'),
});

export const addCandidateSchema = {
  firstName: yup.string().required('First name is required'),
  lastName: yup.string().required('Last name is required'),
  email: yup
    .string()
    .required('Email is required')
    .email('Please enter a valid email address'),
  company: yup.string().required('Company is required').nullable(),
  salary: yup.number().min(1).required('Salary is required'),
  netSalary: yup.number().min(1).required('Net Salary is required'),
  jobrole: yup.string().required('Jobrole is required').nullable(),
  location: yup.string().required('Branch is required').nullable(),
  department: yup.string().required('Department is required').nullable(),
  gender: yup.string().required('Gender is required'),
};

export const addEmployeeSchema = {
  firstName: yup.string().required('First name is required'),
  lastName: yup.string().required('Last name is required'),
  email: yup
    .string()
    .required('Email is required')
    .email('Please enter a valid email address'),
  company: yup.string().required('Company is required'),
};

export const redeployEmployeeSchema = {
  jobrole: yup.string().required('Job role is required'),
  branch: yup.string().required('Branch is required'),
  effective_date: yup.string().required('Effective date is required'),
};

export const addDocumentTypeSchema = yup.object({
  title: yup.string().required('title is required'),
  description: yup.string().required('description is required'),
});

export const addWorkflowSchema = yup.object({
  title: yup.string().required('workflow title is required'),
  approval_groups: yup.array().of(
    yup.object().shape({
      action: yup.string().required('An appoval action is required'),
      approver_type: yup.string().required('Approver type is required'),
      approver: yup.string().required('Approver  is required'),
    })
  ),
});

export const policySchema = yup.object({
  name: yup.string().required('name is required.'),
  probation_period: yup.number().required('select probation period'),
  reminder: yup.number().required('select reminder').nullable(),
});

export const editEmployeePayRollSchema = {
  // bvn: yup.string().when('bvnSaved', {
  //   is: false,
  //   then: yup.string().required('Bvn is required.').nullable(),
  // }),
  bvn: yup
    .string()
    .matches(numberRules, { message: 'Invalid bvn number Provided!' })
    .required('Bvn number is Required !')
    .nullable()
    .min(11, 'Bvn minimum is 11 characters')
    .max(11, 'Bvn maximum is 11 characters'),

  bank_name: yup.string().required('Bank name is required').nullable(),
  account_name: yup.string().required('Account name is required').nullable(),
  account_number: yup
    .string()
    .matches(numberRules, { message: 'Invalid account number Provided!' })
    .required('Account number is Required !')
    .nullable()
    .min(10, 'Account number minimum is 10 digits')
    .max(10, 'Account number maximum is 10 digits'),
  pfa_number: yup
    .string()
    .nullable()
    .when({
      is: (exists) => !!exists,
      then: (rule) =>
        rule.test('pfa_number', 'pfa number should start with PEN', (value) =>
          value?.startsWith('PEN')
        ),
    }),
};

export const editEmployeeSchema = {
  marital_status: yup.string().required('Marital status required !'),
};

export const editEmployeeMarriageDetailsSchema = {
  marital_status: yup.string().required('Marital status required !'),

  marriage_date: yup.string().when('marital_status', {
    is: (val) => (val && val === 'MARRIED' ? true : false),
    then: yup.string(),
  }),

  spouse_name: yup.string().when('marital_status', {
    is: (val) => (val && val === 'MARRIED' ? true : false),
    then: yup.string(),
  }),

  maiden_name: yup.string().when('marital_status', {
    is: (val) => (val && val === 'MARRIED' ? true : false),
    then: yup.string(),
  }),

  spouse_phone: yup.string().when('marital_status', {
    is: (val) => (val && val === 'MARRIED' ? true : false),
    then: yup
      .string()
      .matches(phoneNumberRules, { message: 'Invalid Phone Number!' })
      .min(9, 'Spouse phone number should not be less than 9 characters')
      .max(14, 'spouse phone number should not be more 14 characters'),
  }),
};

export const editJobInfoSchema = {
  confirmation_status: yup
    .string()
    .required('Confirmation status is required !')
    .nullable(),
  confirmation_effective_date: yup.string().when('confirmation_status', {
    is: (val) => (val && ['CONFIRMED', 'YES'].includes(val) ? true : false),
    then: yup.string().required('confirmation effective date is required!').nullable(),
  }),
};

export const editDetailsSchema = {
  branch: yup.string().required('Branch is required !').nullable(),
  department: yup.string().required('Department is required !').nullable(),
  job_role: yup.string().required('Job role is required !').nullable(),
  // location: yup.string().required('Location is required !').nullable(),
  new_branch: yup.string().when('branch', {
    is: (val) => (val && val === 'others' ? true : false),
    then: yup.string().required('Enter new branch name!').nullable(),
  }),
  new_department: yup.string().when('department', {
    is: (val) => (val && val === 'others' ? true : false),
    then: yup.string().required('Enter new department name!').nullable(),
  }),
  new_jobrole: yup.string().when('job_role', {
    is: (val) => (val && val === 'others' ? true : false),
    then: yup.string().required('Enter new job role name!').nullable(),
  }),
};

export const editSupervisorDetailsSchema = {
  supervisor_name: yup.string().required('supervisor name is required !').nullable(),
  supervisor_email: yup.string().required('supervisor email is required !').nullable(),
  supervisor_phone: yup
    .string()
    .matches(phoneNumberRules, { message: 'Invalid Phone Number!' })
    .required('Spouse phone number required !')
    .min(9, 'Spouse phone number should not be less than 9 characters')
    .max(14, 'spouse phone number should not be more 14 characters'),
};

export const editEmployeeContactDetailsSchema = {
  email: yup
    .string()
    .matches(emailRules, { message: 'Invalid Email Provided!' })
    .required('Login email is Required !'),

  official_email_ID: yup
    .string(),

  phone: yup
    .string()
    .matches(phoneNumberRules, { message: 'Invalid Phone Number!' })
    .required('Phone Number Required !')
    .min(9, 'Phone should not be less than 9 characters')
    .max(14, 'Phone should not be more 14 characters'),
};

export const editEmployeeLocationSchema = {
  current_address: yup.string().required('current address is required.').nullable(),
  residential_city: yup.string().required('residential city is required.').nullable(),
  residential_state: yup.string().required('residential state is required.').nullable(),
  landmark: yup.string().required('landmark state is required.').nullable(),
};

export const editEmployeeOtherInformationsSchema = {
  nationality: yup.string().required('nationality is required.').nullable(),
  ethnicity: yup.string().required('ethnicity is required.').nullable(),
  state_of_origin: yup.string().required('state of origin is required.').nullable(),
  lga_of_origin: yup.string().required('lga of origin is required.').nullable(),
  permanent_address: yup.string().required('permanent address is required.').nullable(),
  religion: yup.string().required('religion is required.').nullable(),
  birth_place: yup.string().required('birth place is required.').nullable(),
};

export const editEmployeeBasicInfoSchema = {
  title: yup.string().required('title is required.').nullable(),
  code: yup.string().required('Staff ID is required.').nullable(),
  lastname: yup.string().required('Lastname is required.').nullable(),
  firstname: yup.string().required('Firstname is required.').nullable(),
  middlename: yup.string().required('Middlename is required.').nullable(),
  // othername: capitalize(employeeDetail?.user_info?.othername) || '',
  gender: yup.string().required('Gender is required.').nullable(),
  dob: yup.string().required('Birth date is required.').nullable(),
};

export const employeeRefereersSchema = {
  title: yup.string().required('title is required.'),
  // firstname: yup.string().required('firstname is required.'),
  // lastname: yup.string().required('lastname is required.'),
  fullname: yup.string().required('Full Name is required.'),
  place_of_work: yup.string().required('Place of work is required.'),
  relationship: yup.string().required('Relationship is required.'),
  occupation: yup.string().required('Occupation is required.'),
  address: yup.string().required('Address is required.'),
  phone: yup
    .string()
    .matches(phoneNumberRules, { message: 'Invalid Phone Number!' })
    .required('Referee phone number required !')
    .min(9, 'Referee phone number should not be less than 9 characters')
    .max(14, 'Referee phone number should not be more 14 characters'),
};

export const employeeGuarantorsSchema = {
  // firstname: yup.string().required('firstname is required.'),
  // lastname: yup.string().required('lastname is required.'),
  fullname: yup.string().required('Full Name is required.'),
  place_of_work: yup.string().required('Place of work is required.'),
  relationship: yup.string().required('Relationship is required.'),
  occupation: yup.string().required('Occupation is required.'),
  address: yup.string().required('Address is required.'),
  phone: yup
    .string()
    .matches(phoneNumberRules, { message: 'Invalid Phone Number!' })
    .required('Guarantor phone number required !')
    .min(9, 'Guarantor phone number should not be less than 9 characters')
    .max(14, 'Guarantor phone number should not be more 14 characters'),
};

export const employeeEducationSchema = {
  institution: yup.string().required('Institution is required.').nullable(),
  start_date: yup.string().required('Start Date is required.').nullable(),
  qualification: yup.string().required('Qualification is required.').nullable(),
  course_of_study: yup.string().required('Course of study is required.').nullable(),
  grade: yup.string().required('Grade is required.').nullable(),
  end_date: yup.string().required('End Date is required.').nullable(),
};

export const employeeCertifcationSchema = {
  exam_body: yup.string().required('Exam Body is required.').nullable(),
  exam_name: yup.string().required('Certification Name is required.').nullable(),
  exam_year: yup.string().required('Exam Year is required.').nullable(),
  grade: yup.string().required('Grade is required.').nullable(),
};

export const employeeNextOfKInSchema = {
  name: yup.string().required('name is required'),
  email: yup.string().required('email is required'),
  address: yup.string().required('Address is required.'),
  city: yup.string().required('Town is required.'),
  state: yup.string().required('State is required.'),
  relationship: yup.string().required('Relationship is required.'),
  phone: yup
    .string()
    .matches(phoneNumberRules, { message: 'Invalid Phone Number!' })
    .required('NextOfKIn phone number required !')
    .min(9, 'NextOfKIn phone number should not be less than 9 characters')
    .max(14, 'NextOfKIn phone number should not be more 14 characters'),
};

export const employeeEmergencySchema = {
  fullname: yup.string().required('fullname is required'),
  email: yup.string().required('email is required'),
  address: yup.string().required('Address is required.'),
  relationship: yup.string().required('Relationship is required.'),
  city: yup.string().required('Town is required.'),
  state: yup.string().required('State is required.'),
  country: yup.string().required('State is required.'),
  phone: yup
    .string()
    .matches(phoneNumberRules, { message: 'Invalid Phone Number!' })
    .required('phone number required !')
    .min(9, 'phone number should not be less than 9 characters')
    .max(14, 'phone number should not be more 14 characters'),
};

export const employeeDependentSchema = {
  dob: yup.string().required('Date of birth is required').nullable(),
  relationship: yup.string().required('Relationship is required.').nullable(),
  city: yup.string().required('Town is required.').nullable(),
  state: yup.string().required('State is required.').nullable(),
  country: yup.string().required('Country is required.').nullable(),
  // phone: yup.string()
  // .matches(phoneNumberRules, { message: "Invalid Phone Number!" })
  // .required("Dependent phone number required !")
  // .min(9, 'Dependent phone number should not be less than 9 characters')
  // .max(14, 'Dependent phone number should not be more 14 characters'),
  gender: yup.string().required('Gender is required').nullable(),
  address: yup.string().required('Address is required').nullable(),
};

export const employeeMedicalsSchema = {
  blood_group: yup.string().required('Blood Group is required').nullable(),
  rhesus_factor: yup.string().required('Rhesus Factor is required.').nullable(),
  genotype: yup.string().required('GenoType is required.').nullable(),
  disability: yup.string().required('Disability is required.').nullable(),
  medical_condition: yup.string().required('Medical condition is required.').nullable(),
  hmo_provider: yup.string().required('Hmo provider is required.').nullable(),
  hmo_policy_number: yup.string().required('Hmo policy number is required').nullable(),
  hmo_plan_type: yup.string().required('Hmo plan type is required').nullable(),
  registered_hospital: yup
    .string()
    .required('Registered hospital is required')
    .nullable(),
};

export const employeeLanguageSchema = {
  name: yup.string().required('Name is required'),
  // date_verified: yup.string().required('Date Verified is required.'),
};

export const createLeterSchema = yup.object({
  letter_name: yup.string().required('letter name is required'),
  category: yup.string().required('Letter category is required'),
  letter_type: yup.string().required('Letter type is required'),
});

export const filterLetterSchema = yup.object({
  category: yup.string().required('Letter category is required'),
});

// export const confirmEmployeeSchema = yup.object({
//   title: yup.string().required('workflow title is required'),
//   approval_groups: yup.array().of(
//     yup.object().shape({
//       action: yup.string().required('An appoval action is required'),
//       approver_type: yup.string().required('Approver type is required'),
//     })
//   ),

export const confirmEmployeeSchemaa = {
  subject: yup.string().required('Subject is required'),
  effective_date: yup.string().required('Effective Date is required'),
};

export const raiseDisciplinarySchema = {
  subject: yup.string().required('Subject is required'),
  type: yup.string().required('The query type is required'),
};

export const approvalRequestSchema = yup.object({
  message: yup.string().required('Message is required'),
});

export const leaveApprovalSchema = yup.object({
  approval_level: yup.string().required('Please select an approval level'),
});

export const approveRequestSchema = yup.object({
  notes: yup.string().required('notes is required'),
  status: yup.string().required('approval status is required'),
});

export const declineRequestSchema = yup.object({
  notes: yup.string().required('notes is required'),
  status: yup.string().required('approval status is required'),
});

const getAdayBeforeToday = () => {
  const today = new Date();
  today.setDate(today.getDate() - 1);
  return today;
};

export const leaveRequestValidationSchema = yup.object({
  start_date: yup
    .date()
    .required('start date is required')
    .min(getAdayBeforeToday(), 'start date must be today or later'),
  end_date: yup
    .string()
    .required('end date is required')
    // eslint-disable-next-line func-names
    .test('end_date', 'end date should be greater than start date', function (value) {
      const { start_date } = this.parent;
      return moment(value).isSameOrAfter(moment(start_date));
    }),
  leave_type: yup.string().required('Leave type is required'),
  status: yup.string().required('Leave status is required'),
  notes: yup.string().required('notes is required'),
  // handover_document: yup.required('document is required'),
  employee: yup.string().required('employee is required'),
  leave_address: yup.string().required('leave address is required'),
  leave_phone_number: yup
    .string()
    .matches(/^[0-9]*$/, {
      message: 'Please enter valid number.',
      excludeEmptyString: false,
    })
    .required('Phone number is required'),
  relief_officer_needed: yup
    .boolean()
    .oneOf([true, false], 'select an option')
    .required('select an option'),
  line_manager1_name: yup.string().required('line manager 1 name is required'),
  line_manager1_email: yup
    .string()
    .required('Email line manage 1 email is required')
    .email('please enter a valid email'),
  line_manager2_name: yup.string('Please enter a valid name').required('required'),
  line_manager2_email: yup
    .string()
    .email('please enter a valid email')
    .required('required'),
});

export const notificationEmail = yup.object({
  email: yup.string().required('email is required').email('please enter a valid email'),
  message: yup.string().required('message is required'),
});
export const notificationEmailBulk = yup.object({
  message: yup.string().required('message is required'),
});

export const notificationSms = yup.object({
  phone: yup.string().required('phone is required'),
  message: yup.string().required('message is required'),
});

export const notificationSmsBulk = yup.object({
  message: yup.string().required('message is required'),
});

export const hollidaySchema = yup.object({
  name: yup.string().required('holliday name is required'),
  start_date: yup
    .date()
    .required('start date is required')
    .min(getAdayBeforeToday(), 'start date must be today or later'),
  end_date: yup
    .string()
    .required('end date is required')
    // eslint-disable-next-line func-names
    .test('end_date', 'end date should be greater than start date', function (value) {
      const { start_date } = this.parent;
      return moment(value).isSameOrAfter(moment(start_date));
    }),
});

export const leaveTypeSchema = yup.object({
  title: yup.string().required('title is required'),
  type: yup.string().required('type is required'),
  // proration: '',
  // splitting: '',
  // handover: '',
  // departments: yup.array().min(1, 'at least a department is required'),
  approvals: yup.string().required('approval type is required'),
  grade: yup.string().required('grade is required'),
  gender: yup.string().required('gender is required'),
  days: yup.number().required('number of days is required'),
});

export const leaveRecallSchema = yup.object({
  reason: yup.string().required('reason is required'),
  resumption_date: yup
    .date()
    .required('resumption date is required')
    .min(getAdayBeforeToday(), 'resumption date must be today or later'),
});

export const filterOnleaveSchema = yup.object({
  leave_type: yup.string().required('leave type is required'),
});

export const filterHistorySchema = yup.object({
  filter_by: yup.string('filter by is required'),
  action_type: yup.string().required('action type is required'),
});
export const selectWelfareTextSchema = {
  comment: yup.string().required('Sms Message is required'),
};

export const sendCommitteeSchema = {
  date: yup.string().required('Meeting date is required'),
  type: yup.string().required('Meeting type is required'),
  notes: yup
    .string()
    .max(225, 'Notes should not be more than 225 characters')
    .required('Notes is required'),
  employee: yup.array().required('employee cannot is required'),
  offline: yup.boolean(),
  location: yup.string().when('offline', {
    is: true,
    then: yup.string().required('Meeting location is required'),
  }),
};
export const acceptOfferSchema = yup.object({
  firstname: yup.string().required('First Name is required.'),
  lastname: yup.string().required('Last Name is required.'),
  address: yup.string().required('Address is required.'),
  // branch: yup.string().required('Branch Name is required.'),
  resumption_date: yup.string().required('Enter resumption date'),
  employment_date: yup.string().required('Enter employment date'),
  bank_name: yup.string(),
  supervisor_name: yup.string().required('Supervisor Name is required'),
  account_number: yup
    .string()
    .test('len', 'Must be exactly 10 characters', (val) =>
      val ? val?.toString().length === 10 : !val
    ),
  pension_manager: yup.string('Pension manger Name is required'),
  // pension_pin: yup
  //   .string()
  //   .matches(/([aA-zZ]{3})([0-9]{12})/, 'Enter a valid pension pin'),
  handbook_confirmation: yup.string().required('required field'),
  tax_id:  yup
  .string()
  .nullable()
  .when({
    is: (exists) => !!exists,
    then: (rule) =>
      rule.test('tax_id', `Tax id should start with N- or enter 'NIL' `, (value) =>
        value?.startsWith('N-') || value?.toUpperCase() ==='NIL'
      ),
  }),
  pension_pin:  yup
  .string()
  .nullable()
  .when({
    is: (exists) => !!exists,
    then: (rule) =>
      rule.test('pension_pin', `Enter a valid pension pin or enter 'NIL' `, (value) =>
        (/([aA-zZ]{3})([0-9]{12})/).test(value) || value?.toUpperCase() ==='NIL'
      ),
  }),
  bvn: yup
  .string()
  .matches(numberRules, { message: 'Invalid bvn number Provided!' })
  .required('Bvn number is Required !')
  .nullable()
  .min(11, 'Bvn minimum is 11 characters')
  .max(11, 'Bvn maximum is 11 characters'),
});

export const candidateSignupSchema = yup.object({
  email: yup.string().required('Email is required').email('please enter a valid email'),
});

export const createNewPasswordSchema = yup.object().shape({
  password: yup.string().required('Password is required'),
  confirmPassword: yup
    .string()
    .required('confirm password is required')
    .when('password', {
      is: (val) => (val && val.length > 0 ? true : false),
      then: yup
        .string()
        .oneOf([yup.ref('password')], 'confirm password must match password'),
    }),
});

export const ExitSchema = yup.object().shape({
  exit_mode: yup.string().required('Mode of Exit is required'),
});

export const CreatePasswordSchema = yup.object().shape({
  password: yup.string().required('password is required'),
  confirm_password: yup
    .string()
    .required('confirm password is required')
    .when('password', {
      is: (val) => (val && val.length > 0 ? true : false),
      then: yup
        .string()
        .oneOf([yup.ref('password')], 'confirm password must match password'),
    }),
});

export const PayrollSchema = yup.object().shape({
  criteria: yup.string().required('field is required'),
  name: yup.string().required('field is required'),
  formula: yup.string().required('field is required'),
  background_colour: yup.string().required('field is required'),
  order: yup.number().required('order is required'),
  company: yup.string().required('field is required'),
});

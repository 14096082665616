import styled from 'styled-components';

export const SettingsPageWrapper = styled.div``;

export const FormContainer = styled.form`
  padding: 30px;
`;
export const CenteringContainer = styled.div`
  padding: ${(props) => (props.padding ? props.padding : '30px')};
  display: flex;
  justify-content: ${(props) => (props.position ? props.position : 'center')};
`;

export const DeleteButton = styled.button`
  background: #ff0033;
  color: #fff;
  font-weight: 600;
  font-size: 14px;
  padding: '5px 10px';
  border-radius: 4px;
  width: '100%';
  box-sizing: border-box;
  box-shadow: 0px 4px 4px rgba(199, 223, 241, 0.25);
  border-radius: 5px;
`;
export const FileContainer = styled.div`
  .download_spreadsheet {
    padding: 40px 10px 0px;
    text-align: center;
    .browse {
      color: #1a21ba;
    }
    p {
      font-family: Inter;
      font-style: normal;
      font-weight: 500;
      font-size: 16px;
      line-height: 19px;
      text-align: center;
      margin-bottom: 40px;
    }
    button {
      border: none;

      outline: none;
    }
    .btn_with_image {
      background: #ffffff;
      border: 1px solid #d9d9d9;
      box-sizing: border-box;
      box-shadow: 0px 4px 4px rgba(199, 223, 241, 0.25);
      border-radius: 5px;
      width: 228px;
      margin: 0 auto;
      margin-bottom: 40px;

      img {
        display: inline-block;
        position: relative;
        left: 10px;
      }
    }
    .react-file-reader {
      margin-top: -30px;
      padding-top: 30px;
      cursor: pointer;
    }

    .react-file-reader input {
      cursor: pointer;
      z-index: 200;
      width: 100% !important;
      height: 100px;
      position: absolute !important;
      left: 0;
    }
  }
`;

export const ActionBar = styled.div`
  display: flex;
  flex-direction: column;
  box-shadow: 0px 1px 2px rgba(199, 223, 241, 0.25);
  background: #ffffff;

  div.action-buttons {
    display: flex;
    align-self: flex-end;
    padding: 20px 50px;
  }

  ::before {
    content: '';
    height: 1px;
    width: 100%;
    background: #f4f7fa;
  }
`;

export const Table = styled.div`
  font-family: Inter;
  background: #ffffff;
  box-shadow: 0px 4px 4px rgba(199, 223, 241, 0.25);
  border-radius: 10px;
  margin: 180px 0 150px;

  .table-body__row,
  header {
    display: grid;
    grid-template-columns: 50px repeat(4, 2fr) 1fr;
    column-gap: 15px;
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 19px;
    padding: 30px 36px;
    color: #333333;
    align-items: center;
    justify-content: center;
    border-top: 1px solid #f2f2f2;
    .username-wrapper {
      display: flex;
      flex-direction: column;
      .email {
        font-family: Inter;
        font-style: normal;
        font-weight: normal;
        font-size: 12px;
        line-height: 15px;
        color: #999999;
        span {
          font-weight: bold;
        }
      }
    }
  }

  .header {
    display: grid;
    grid-template-columns: 50px repeat(4, 2fr) 1fr;
    column-gap: 15px;
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 19px;
    padding: 30px 36px;
    justify-content: center;
    align-items: center;
    p {
      font-style: normal;
      font-weight: 500;
      font-size: 14px;
      line-height: 19px;
      color: #666666;
    }
  }
  .table-divider {
    content: '';
    height: 1px;
    width: 100%;
    background: #f4f7fa;
  }
`;

export const RoleCard = styled.div`
  display: grid;
  grid-template-columns: 1fr auto;
  row-gap: 10px;
  column-gap: 70px;
  background: #ffffff;
  border-radius: 5px;
  padding: 11px 15px;
  box-shadow: 0px 4px 4px rgba(199, 223, 241, 0.25);
  cursor: pointer;
  transition: ease-in 0.9s;
  outline: none;
  border: ${({ selected }) =>
    selected === 'selected'
      ? `
  2px solid #989DFE
  `
      : ''};

  .title {
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 26px;
    color: #333333;
    padding-bottom: 8px;
  }

  .count {
    font-style: normal;
    font-weight: 600;
    font-size: 12px;
    line-height: 26px;
    color: #999999;
  }
`;

export const CardsLayout = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(16em, 1fr));
  gap: 30px;
  border-radius: 5px;
  margin-top: 150px;
`;

export const SearchFilterWrapper = styled.div`
  background: #ffffff;
  box-shadow: 0px 1px 2px rgba(199, 223, 241, 0.25);
  position: fixed;
  left: 250px;
  right: 0;
  z-index: 10;
`;

export const CandidateAction = styled.div`
  margin-top: 0px;
  display: grid;
  grid-template-columns: auto 1fr;
  padding: 15px 50px;
  align-items: center;

  div.left-items {
    margin: 0 15px;
  }

  div.right-items {
    display: grid;
    grid-template-columns: auto auto;
    justify-content: flex-end;
  }
`;

export const INITIATE_ONBOARDING_REQUEST = 'INITIATE_ONBOARDING_REQUEST';
export const INITIATE_ONBOARDING_SUCCESS = 'INITIATE_ONBOARDING_SUCCESS';
export const INITIATE_ONBOARDING_FAILURE = 'INITIATE_ONBOARDING_FAILURE';

export const COMPLETE_ONBOARDING_REQUEST = 'COMPLETE_ONBOARDING_REQUEST';
export const COMPLETE_ONBOARDING_SUCCESS = 'COMPLETE_ONBOARDING_SUCCESS';
export const COMPLETE_ONBOARDING_FAILURE = 'COMPLETE_ONBOARDING_FAILURE';

export const GET_EMPLOYEE_ONBOARDING_REQUEST = 'GET_EMPLOYEE_ONBOARDING_REQUEST';
export const GET_EMPLOYEE_ONBOARDING_RESPONSE = 'GET_EMPLOYEE_ONBOARDING_RESPONSE';
export const GET_EMPLOYEE_ONBOARDING_ERROR = 'GET_EMPLOYEE_ONBOARDING_ERROR';

export const GET_ONBOARDING_SETTINGS_REQUEST = 'GET_ONBOARDING_SETTINGS_REQUEST';
export const GET_ONBOARDING_SETTINGS_SUCCESS = 'GET_ONBOARDING_SETTINGS_SUCCESS';
export const GET_ONBOARDING_SETTINGS_FAILURE = 'GET_ONBOARDING_SETTINGS_FAILURE';

export const CREATE_ONBOARDING_SETTINGS_REQUEST = 'CREATE_ONBOARDING_SETTINGS_REQUEST';
export const CREATE_ONBOARDING_SETTINGS_SUCCESS = 'CREATE_ONBOARDING_SETTINGS_SUCCESS';
export const CREATE_ONBOARDING_SETTINGS_FAILURE = 'CREATE_ONBOARDING_SETTINGS_FAILURE';

export const CREATE_DEPLOYMENT_REQUEST = 'CREATE_DEPLOYMENT_REQUEST';
export const CREATE_DEPLOYMENT_FAILURE = 'CREATE_DEPLOYMENT_FAILURE';
export const CREATE_DEPLOYMENT_SUCCESS = 'CREATE_DEPLOYMENT_SUCCESS';

export const GET_DEPLOYMENT_LETTER_REQUEST = 'GET_DEPLOYMENT_LETTER_REQUEST';
export const GET_DEPLOYMENT_LETTER_SUCCESS = 'GET_DEPLOYMENT_LETTER_SUCCESS';
export const GET_DEPLOYMENT_LETTER_FAILURE = 'GET_DEPLOYMENT_LETTER_FAILURE';

export const EDIT_DEPLOYMENT_LETTER_REQUEST = 'EDIT_DEPLOYMENT_LETTER_REQUEST';
export const EDIT_DEPLOYMENT_LETTER_SUCCESS = 'EDIT_DEPLOYMENT_LETTER_SUCCESS';
export const EDIT_DEPLOYMENT_LETTER_FAILURE = 'EDIT_DEPLOYMENT_LETTER_FAILURE';

export const DELETE_DEPLOYMENT_LETTER_REQUEST = 'DELETE_DEPLOYMENT_LETTER_REQUEST';
export const DELETE_DEPLOYMENT_LETTER_SUCCESS = 'DELETE_DEPLOYMENT_LETTER_SUCCESS';
export const DELETE_DEPLOYMENT_LETTER_FAILURE = 'DELETE_DEPLOYMENT_LETTER_FAILURE';

export const GET_SINGLE_DEPLOYMENT_LETTER_REQUEST =
  'GET_SINGLE_DEPLOYMENT_LETTER_REQUEST';
export const GET_SINGLE_DEPLOYMENT_LETTER_SUCCESS =
  'GET_SINGLE_DEPLOYMENT_LETTER_SUCCESS';
export const GET_SINGLE_DEPLOYMENT_LETTER_FAILURE =
  'GET_SINGLE_DEPLOYMENT_LETTER_FAILURE';

export const SEND_DEPLOYMENT_LETTER_REQUEST = 'SEND_DEPLOYMENT_LETTER_REQUEST';
export const SEND_DEPLOYMENT_LETTER_SUCCESS = 'SEND_DEPLOYMENT_LETTER_SUCCESS';
export const SEND_DEPLOYMENT_LETTER_FAILURE = 'SEND_DEPLOYMENT_LETTER_FAILURE';

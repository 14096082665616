
import * as types from '../actionTypes/workflow';

const initialState = {
  loading: false,
  data: {
    results: []
  },
  singleData: {
    results: []
  },
  deleteSuccess: false,
};

const workflowReducer = (state = initialState, action) => {
  switch (action.type) {
    case types.ADD_WORKFLOW_REQUEST:
      return {
        ...state,
        addloading: true,
      };
    case types.ADD_WORKFLOW_SUCCESS:
      return {
        ...state,
        error: false,
        addloading: false,
        success: true,
      };
    case types.ADD_WORKFLOW_FAILURE:
      return {
        ...state,
        addloading: false,
        error: action.error,
        success: false,
      };

    case types.GET_WORKFLOWS_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case types.GET_WORKFLOWS_SUCCESS:
      return {
        ...state,
        error: false,
        loading: false,
        data: action.payload.data,
        success: true,
      };
    case types.GET_WORKFLOWS_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.error,
        success: false,
      };
    case types.GET_SINGLE_WORKFLOW_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case types.GET_SINGLE_WORKFLOW_SUCCESS:
      return {
        ...state,
        error: false,
        loading: false,
        singleData: action.payload,
        success: true,
      };
    case types.GET_SINGLE_WORKFLOW_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.error,
        success: false,
      };

    case types.EDIT_WORKFLOW_REQUEST:
      return {
        ...state,
        editloading: true,
      };
    case types.EDIT_WORKFLOW_SUCCESS:
      const result = state?.data?.results?.filter(result => result?.id !== action.payload?.data?.id)
      const newEditData = [...result, action.payload?.data];
      return {
        ...state,
        error: false,
        editloading: false,
        data: { results: newEditData },
        success: true,
      };
    case types.EDIT_WORKFLOW_FAILURE:
      return {
        ...state,
        editloading: false,
        error: action.error,
        success: false,
      };

    case types.DELETE_WORKFLOW_REQUEST:
      return {
        ...state,
        deleteLoading: true,
        deleteSuccess: false
      };
    case types.DELETE_WORKFLOW_SUCCESS:
      const results = state?.data?.results?.filter(result => result?.id !== action.payload)
      return {
        ...state,
        error: false,
        deleteLoading: false,
        data: { results },
        success: true,
        deleteSuccess: true,
      };
    case types.DELETE_WORKFLOW_FAILURE:
      return {
        ...state,
        deleteLoading: false,
        error: action.error,
        success: false,
        deleteSuccess: false
      };
    default:
      return {
        ...state,
      };
  }
};

export default workflowReducer;

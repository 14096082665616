import {
  SET_APPROVALS_SUCCESS,
  SET_APPROVALS_REQUEST,
  SET_APPROVALS_FAILURE,
  ADD_APPROVALS_REQUEST,
  ADD_APPROVALS_FAILURE,
  ADD_APPROVALS_SUCCESS,
  SET_BEGIN_EDITING,
  DELETE_APPROVALS_REQUEST,
  DELETE_APPROVALS_SUCCESS,
  DELETE_APPROVALS_FAILURE,
  RESET,
} from 'redux/actionTypes/WorkflowApproval';

const initialState = {
  workflows: {},
  loading: false,
  isEditing: false,
  workflowInEdit: null,
};

const approvalReducer = (state = initialState, { type, payload }) => {
  switch (type) {
    case SET_APPROVALS_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case SET_APPROVALS_SUCCESS:
      return {
        ...state,
        loading: false,
        workflows: { ...payload },
      };
    case SET_APPROVALS_FAILURE:
      return {
        ...state,
        loading: false,
      };
    case ADD_APPROVALS_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case ADD_APPROVALS_SUCCESS:
      return {
        ...state,
        loading: false,
      };
    case ADD_APPROVALS_FAILURE:
      return {
        ...state,
        loading: false,
      };
    case SET_BEGIN_EDITING:
      return {
        ...state,
        isEditing: true,
        workflowInEdit: payload.workflowInEdit,
      };
    case DELETE_APPROVALS_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case DELETE_APPROVALS_SUCCESS:
      return {
        ...state,
        loading: false,
      };
    case DELETE_APPROVALS_FAILURE:
      return {
        ...state,
        loading: false,
      };
    case RESET:
      return {
        ...state,
        isEditing: false,
        workflowInEdit: null,
      };
    default:
      return state;
  }
};

export default approvalReducer;

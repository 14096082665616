import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { declineOffer } from 'redux/actions/offer';
import { useHistory, useParams} from 'react-router-dom';
import jwt from 'jsonwebtoken';
import Button from 'components/common/Button';
import PageContainer from 'components/common/PageContainer';
import { EMPTY_FILE, Wrapper, FormContainer } from 'pages/Candidate/Offer/styles';
import { svgs } from 'assets';
import TextArea from 'components/common/TextArea';
import { useQuery } from 'utils/useQuery';
import { toast } from 'react-toastify';
import {Logo} from 'pages/Auth/styles';



export const Decline = () => {
  const dispatch = useDispatch();
  const [data, setData] = useState({});
  const [comment, setComment] = useState();
  const [loading, setLoading] = useState(false);
  const history = useHistory();
  const {offerId} = useParams();
  const handleOnchange = (e) => {
    setComment(e.target.value);
  };
  const query = useQuery();
  const token = query.get('d');

  useEffect(() => {
    const formdata = jwt.decode(token);
    setData(formdata);
  }, [token]);

  const handleDecline = async () => {
    setLoading(true);
    try {
      const result = await dispatch(declineOffer({ comment, offerId }));
      setLoading(false);
      if (result?.payload.data) {
        history.push('/onboarding/offer-decline');
      }
    } catch (error) {
      setLoading(false);
      toast.error('an error occured');
    }
  };
  return (
    <PageContainer>
      <FormContainer>
        <div className="logo-wrapper">
        <Logo src={svgs.logo_colored} />
        </div>
        <Wrapper>
          <div className="content">
            <div className="logo-wrapper">
              <EMPTY_FILE />
            </div>
            <p className="header">Decline Offer</p>
            <p className="note">
              <br />
              {`Hello, ${data?.firstname}.
Kindly Let us know why you’re declining this offer`}
            </p>

            <div className="file-upload-wrapper">
              <labe>Reason</labe>
              <TextArea name="message" value={comment} onChange={handleOnchange} />
              <Button
                block
                text={loading ? 'Loading...' : 'Confirm'}
                className="button"
                onClick={handleDecline}
              />
            </div>
          </div>
        </Wrapper>
      </FormContainer>
    </PageContainer>
  );
};

export const GET_CANDIDATES_REQUEST = 'GET_CANDIDATES_REQUEST';
export const GET_CANDIDATES_SUCCESS = 'GET_CANDIDATES_SUCCESS';
export const GET_CANDIDATES_FAILURE = 'GET_CANDIDATES_FAILURE';

export const GET_SINGLE_CANDIDATE_REQUEST = 'GET_SINGLE_CANDIDATES_REQUEST';
export const GET_SINGLE_CANDIDATE_SUCCESS = 'GET_SINGLE_CANDIDATES_SUCCESS';
export const GET_SINGLE_CANDIDATE_FAILURE = 'GET_SINGLE_CANDIDATES_FAILURE';

export const BULK_CANDIDATE_CSV_HEADERS_SUCCESS = 'BULK_CANDIDATE_CSV_HEADERS_SUCCESS';
export const BULK_CANDIDATE_CSV_HEADERS_FAIL = 'BULK_CANDIDATE_CSV_HEADERS_FAIL';

export const ADD_CANDIDATES_SUCCESS = 'ADD_CANDIDATES_SUCCESS';
export const ADD_CANDIDATES_FAILURE = 'ADD_CANDIDATES_FAILURE';
export const ADD_CANDIDATES_REQUEST = 'ADD_CANDIDATES';

export const CREATE_APPOINTMENT_REQUEST = 'CREATE_APPOINTMENT_REQUEST';
export const CREATE_APPOINTMENT_SUCCESS = 'CREATE_APPOINTMENT_SUCCESS';
export const CREATE_APPOINTMENT_FAILURE = 'CREATE_APPOINTMENT_FAILURE';

export const UPDATE_CANDIDATES_REQUEST = 'EDIT_CANDIDATES_REQUEST';
export const UPDATE_CANDIDATES_SUCCESS = 'EDIT_CANDIDATES_SUCCESS';
export const UPDATE_CANDIDATES_FAILURE = 'EDIT_CANDIDATES_FAILURE';

export const DELETE_CANDIDATES_REQUEST = 'DELETE_CANDIDATES_REQUEST';
export const DELETE_CANDIDATES_SUCCESS = 'DELETE_CANDIDATES_SUCCESS';
export const DELETE_CANDIDATES_FAILURE = 'DELETE_CANDIDATES_FAILURE';

export const SEARCH_AND_FILTER_CANDIDATE_REQUEST = 'SEARCH_AND_FILTER_CANDIDATE_REQUEST';
export const SEARCH_AND_FILTER_CANDIDATE_SUCCESS = 'SEARCH_AND_FILTER_CANDIDATE_SUCCESS';
export const SEARCH_AND_FILTER_CANDIDATE_FAILURE = 'SEARCH_AND_FILTER_CANDIDATE_FAILURE';

export const GET_DASHBOARD_DATA_REQUEST = 'GET_DASHBOARD_DATA_REQUEST';
export const GET_DASHBOARD_DATA_SUCCESS = 'GET_DASHBOARD_DATA_SUCCESS';
export const GET_DASHBOARD_DATA_FAILURE = 'GET_DASHBOARD_DATA_FAILURE';

export const ADD_BULK_CANDIDATES_SUCCESS = 'ADD_BULK_CANDIDATES_SUCCESS';
export const ADD_BULK_CANDIDATES_FAILURE = 'ADD_BULK_CANDIDATES_FAILURE';
export const ADD_BULK_CANDIDATES_REQUEST = 'ADD_BULK_CANDIDATES';

export const GET_CANDIDATE_DOC_REQUEST = 'GET_CANDIDATE_DOC_REQUEST';
export const GET_CANDIDATE_DOC_RESPONSE = 'GET_CANDIDATE_DOC_RESPONSE';
export const GET_CANDIDATE_DOC_ERROR = 'GET_CANDIDATE_DOC_ERROR';

export const GET_INSTITUTIONS_REQUEST = 'GET_INSTITUTIONS_REQUEST';
export const GET_INSTITUTIONS_SUCCESS = 'GET_INSTITUTIONS_SUCCESS';
export const GET_INSTITUTIONS_FAILURE = 'GET_INSTITUTIONS_FAILURE';

import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import {StepContainer} from './styles';


export const MultiStep = (props) => {
  let { activeStep } = props;
  const { stepNames, stepContent } = props;
  const totalSteps = stepNames.length;

  if (activeStep > totalSteps) {
    activeStep = totalSteps;
  }

  return (
    <>
      <StepContainer style={{ display: 'flex', width: '100%'}}>
        {stepNames.map((stepName, index) => (
          <div
            key={index}
            style={{
              display: 'grid',
              alignItems: 'center',
              gridTemplateColumns: `auto 2fr ${
                index === stepNames.length - 1 ? '' : '1fr'
              }`,
              gap: 10,
              paddingRight: 2,
            }}
          >
            <p
              style={{
                width: 30,
                height: 30,
                borderRadius: '50%',
                backgroundColor: activeStep - 1 === index ? 'blue' : 'grey',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                color: 'white',
              }}
            >
              {index + 1}
            </p>
            <p style={{ fontSize: 14 }}>{stepName}</p>
            {index + 1 < stepNames.length && (
              <div style={{ width: '100%', height: 2, backgroundColor: 'grey' }} />
            )}
          </div>
        ))}
      </StepContainer>
      {stepContent.map(
        (content, index) => activeStep - 1 === index && <Fragment key={index}>{content}</Fragment>,
      )}
    </>
  );
};

MultiStep.propTypes = {
  activeStep: PropTypes.number,
  stepNames: PropTypes.arrayOf(PropTypes.string),
  stepContent: PropTypes.arrayOf(
    PropTypes.oneOfType([PropTypes.element, PropTypes.node]),
  ),
};

MultiStep.defaultProps = {
  activeStep: 1,
  stepNames: [],
  stepContent: [],
};

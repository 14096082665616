import React from 'react';
import AppModal from 'components/AppModal';
import ActionNotification from 'pages/Employee/components/ActionNotification';

const ErrorModal = ({ show, headerText, error, errmessageText=true, handleClose }) => (
  <AppModal
    show={show}
    header={false}
    component={(
      <ActionNotification
        headerText={headerText}
        error={errmessageText}
        headerTextErr=""
        subHeaderText=""
        subHeaderTextErr={error}
      />
    )}
    singleAction
    close={handleClose}
  />
);

export default ErrorModal;

import React, { useEffect, useState } from 'react';
import { svgs } from 'assets';
import PageContainer from 'components/common/PageContainer';
import { MultiStep } from 'components/common/MultiStep';
import jwt from 'jsonwebtoken';
import { useHistory } from 'react-router-dom';
import { Step4 } from './Step4';
import { Step3 } from './Step3';
import { Step2 } from './Step2';
import { Step1 } from './Step1';
import { FormContainer } from '../styles';
import { useDispatch } from 'react-redux';
import { getBanks, getPensionManagers } from 'redux/actions/settings';


const AcceptOffer = () => {
  const [data, setData] = useState({});
  const [activeStep, setActiveStep] = useState(1);
  const stepOne = Step1(activeStep, setActiveStep, setData, data);
  const stepTwo = Step2(activeStep, setActiveStep, setData, data);
  const stepThree = Step3(activeStep, setActiveStep, setData,data);
  const stepFour = Step4(activeStep, setActiveStep, data);
  const dispatch = useDispatch()
  const history = useHistory();
  const token = history.location.search.split('?d=')[1];

  useEffect(() => {
    dispatch(getPensionManagers());
    dispatch(getBanks())

// eslint-disable-next-line
  }, [dispatch])

  useEffect(() => {
    const formdata = jwt.decode(token);
    setData(formdata);
  }, [token]);

  return (
    <PageContainer>
      <FormContainer>
        <div className="logo-wrapper">
          <svgs.COLOURED_LOGO width="200px" height="100px" />
        </div>
        <MultiStep
          activeStep={activeStep}
          stepNames={[
            'Upload Signed Offer Letter',
            'Upload Signed Deployment Letter',
            'Upload Signed Handbook',
            'Staff acknowledgement form',
          ]}
          stepContent={[stepOne, stepTwo, stepThree, stepFour]}
        />
      </FormContainer>
    </PageContainer>
  );
};

export default AcceptOffer;

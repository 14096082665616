export const ADD_OFFER_LETTER_REQUEST = 'ADD_OFFER_LETTER_REQUEST';
export const ADD_OFFER_LETTER_SUCCESS = 'ADD_OFFER_LETTER_SUCCESS';
export const ADD_OFFER_LETTER_FAILURE = 'ADD_OFFER_LETTER_FAILURE';

export const GET_OFFER_LETTER_REQUEST = 'GET_OFFER_LETTER_REQUEST';
export const GET_OFFER_LETTER_SUCCESS = 'GET_OFFER_LETTER_SUCCESS';
export const GET_OFFER_LETTER_FAILURE = 'GET_OFFER_LETTER_FAILURE';

export const EDIT_OFFER_LETTER_REQUEST = 'EDIT_OFFER_LETTER_REQUEST';
export const EDIT_OFFER_LETTER_SUCCESS = 'EDIT_OFFER_LETTER_SUCCESS';
export const EDIT_OFFER_LETTER_FAILURE = 'EDIT_OFFER_LETTER_FAILURE';

export const DELETE_OFFER_LETTER_REQUEST = 'DELETE_OFFER_LETTER_REQUEST';
export const DELETE_OFFER_LETTER_SUCCESS = 'DELETE_OFFER_LETTER_SUCCESS';
export const DELETE_OFFER_LETTER_FAILURE = 'DELETE_OFFER_LETTER_FAILURE';

export const GET_SINGLE_OFFER_LETTER_REQUEST = 'GET_SINGLE_OFFER_LETTER_REQUEST';
export const GET_SINGLE_OFFER_LETTER_SUCCESS = 'GET_SINGLE_OFFER_LETTER_SUCCESS';
export const GET_SINGLE_OFFER_LETTER_FAILURE = 'GET_SINGLE_OFFER_LETTER_FAILURE';

export const SEND_OFFER_LETTER_REQUEST = 'SEND_OFFER_LETTER_REQUEST';
export const SEND_OFFER_LETTER_SUCCESS = 'SEND_OFFER_LETTER_SUCCESS';
export const SEND_OFFER_LETTER_FAILURE = 'SEND_OFFER_LETTER_FAILURE';

export const GET_TEMPLATE_REQUEST = 'GET_TEMPLATE_REQUEST';
export const GET_TEMPLATE_SUCCESS = 'GET_TEMPLATE_SUCCESS';
export const GET_TEMPLATE_FAILURE = 'GET_TEMPLATE_FAILURE';

export const GET_LETTER_CATEGORY_REQUEST = 'GET_LETTER_CATEGORY_REQUEST';
export const GET_LETTER_CATEGORY_SUCCESS = 'GET_LETTER_CATEGORY_SUCCESS';
export const GET_LETTER_CATEGORY_FAIL = 'GET_LETTER_CATEGORY_FAIL';

export const SEND_BULK_LETTER_REQUEST = 'GET_LETTER_CATEGORY_REQUEST';
export const SEND_BULK_LETTER_SUCCESS = 'GET_LETTER_CATEGORY_SUCCESS';
export const SEND_BULK_LETTER_FAIL = 'GET_LETTER_CATEGORY_FAIL';

export const GET_SEARCH_OFFER_LETTER_REQUEST='GET_SEARCH_OFFER_LETTER_REQUEST';
export const GET_SEARCH_OFFER_LETTER_SUCCESS='GET_SEARCH_OFFER_LETTER_SUCCESS';


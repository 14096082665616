export const CREATE_EMPLOYEE_REQUEST = 'CREATE_EMPLOYEE_REQUEST';
export const GET_EMPLOYEE_FAILURE = 'GET_EMPLOYEE_FAILURE';

export const GET_EMPLOYEE_START = 'GET_EMPLOYEE_START';
export const GET_EMPLOYEE_SUCCESS = 'GET_EMPLOYEE_SUCCESS';
export const GET_EMPLOYEE_FAIL = 'GET_EMPLOYEE_FAIL';

export const CREATE_EMPLOYEE_START = 'CREATE_EMPLOYEE_START';
export const CREATE_EMPLOYEE_SUCCESS = 'CREATE_EMPLOYEE_SUCCESS';
export const CREATE_EMPLOYEE_FAIL = 'CREATE_EMPLOYEE_FAIL,';

export const GET_EMPLOYEE_SEARCH_START = 'GET_EMPLOYEE_SEARCH_START';
export const GET_EMPLOYEE_SEARCH_SUCCESS = 'GET_EMPLOYEE_SEARCH_SUCCESS';
export const GET_EMPLOYEE_SEARCH_FAIL = 'GET_EMPLOYEE_SEARCH_FAIL';

export const GET_SINGLE_EMPLOYEE_START = 'GET_SINGLE_EMPLOYEE_START';
export const GET_SINGLE_EMPLOYEE_SUCCESS = 'GET_SINGLE_EMPLOYEE_SUCCESS';
export const GET_SINGLE_EMPLOYEE_FAIL = 'GET_SINGLE_EMPLOYEE_FAIL';

export const GET_SINGLE_EMPLOYEE_ONBOARDING_START = // eslint-disable-line operator-linebreak
  'GET_SINGLE_EMPLOYEE_ONBOARDING_START';
export const GET_SINGLE_EMPLOYEE_ONBOARDING_SUCCESS = // eslint-disable-line operator-linebreak
  'GET_SINGLE_EMPLOYEE_ONBOARDING_SUCCESS';
export const GET_SINGLE_EMPLOYEE_ONBOARDING_FAIL = 'GET_SINGLE_EMPLOYEE_ONBOARDING_FAIL';

export const EDIT_EMPLOYEE_START = 'EDIT_EMPLOYEE_START';
export const EDIT_EMPLOYEE_SUCCESS = 'EDIT_EMPLOYEE_SUCCESS';
export const EDIT_EMPLOYEE_FAIL = 'EDIT_EMPLOYEE_FAIL';

export const UPDATE_EMPLOYEE_START = 'UPDATE_EMPLOYEE_START';
export const UPDATE_EMPLOYEE_SUCCESS = 'UPDATE_EMPLOYEE_SUCCESS';
export const UPDATE_EMPLOYEE_FAIL = 'UPDATE_EMPLOYEE_FAIL';

export const GET_SINGLE_EMPLOYEE_PAYROLL_START = 'GET_SINGLE_EMPLOYEE_PAYROLL_START';
export const GET_SINGLE_EMPLOYEE_PAYROLL_SUCCESS = 'GET_SINGLE_EMPLOYEE_PAYROLL_SUCCESS';
export const GET_SINGLE_EMPLOYEE_PAYROLL_FAIL = 'GET_SINGLE_EMPLOYEE_PAYROLL_FAIL';

export const CREATE_EMPLOYEE_PAYROLL_START = 'CREATE_EMPLOYEE_PAYROLL_START';
export const CREATE_EMPLOYEE_PAYROLL_SUCCESS = 'CREATE_EMPLOYEE_PAYROLL_SUCCESS';
export const CREATE_EMPLOYEE_PAYROLL_FAIL = 'CREATE_EMPLOYEE_PAYROLL_FAIL,';

export const UPDATE_EMPLOYEE_PAYROLL_START = 'UPDATE_EMPLOYEE_PAYROLL_START';
export const UPDATE_EMPLOYEE_PAYROLL_SUCCESS = 'UPDATE_EMPLOYEE_PAYROLL_SUCCESS';
export const UPDATE_EMPLOYEE_PAYROLL_FAIL = 'UPDATE_EMPLOYEE_PAYROLL_FAIL';

export const CREATE_EMPLOYEE_REFEREES_START = 'CREATE_EMPLOYEE_REFEREES_START';
export const CREATE_EMPLOYEE_REFEREES_SUCCESS = 'CREATE_EMPLOYEE_REFEREES_SUCCESS';
export const CREATE_EMPLOYEE_REFEREES_FAIL = 'CREATE_EMPLOYEE_REFEREES_FAIL,';

export const UPDATE_EMPLOYEE_REFEREES_START = 'UPDATE_EMPLOYEE_REFEREES_START';
export const UPDATE_EMPLOYEE_REFEREES_SUCCESS = 'UPDATE_EMPLOYEE_REFEREES_SUCCESS';
export const UPDATE_EMPLOYEE_REFEREES_FAIL = 'UPDATE_EMPLOYEE_REFEREES_FAIL';

export const DELETE_EMPLOYEE_REFEREES_START = 'DELETE_EMPLOYEE_REFEREES_START';
export const DELETE_EMPLOYEE_REFEREES_SUCCESS = 'DELETE_EMPLOYEE_REFEREES_SUCCESS';
export const DELETE_EMPLOYEE_REFEREES_FAIL = 'DELETE_EMPLOYEE_REFEREES_FAIL';

export const CREATE_EMPLOYEE_GUARANTORS_START = 'CREATE_EMPLOYEE_GUARANTORS_START';
export const CREATE_EMPLOYEE_GUARANTORS_SUCCESS = 'CREATE_EMPLOYEE_GUARANTORS_SUCCESS';
export const CREATE_EMPLOYEE_GUARANTORS_FAIL = 'CREATE_EMPLOYEE_GUARANTORS_FAIL,';

export const UPDATE_EMPLOYEE_GUARANTORS_START = 'UPDATE_EMPLOYEE_GUARANTORS_START';
export const UPDATE_EMPLOYEE_GUARANTORS_SUCCESS = 'UPDATE_EMPLOYEE_GUARANTORS_SUCCESS';
export const UPDATE_EMPLOYEE_GUARANTORS_FAIL = 'UPDATE_EMPLOYEE_GUARANTORS_FAIL';

export const DELETE_EMPLOYEE_GUARANTORS_START = 'DELETE_EMPLOYEE_GUARANTORS_START';
export const DELETE_EMPLOYEE_GUARANTORS_SUCCESS = 'DELETE_EMPLOYEE_GUARANTORS_SUCCESS';
export const DELETE_EMPLOYEE_GUARANTORS_FAIL = 'DELETE_EMPLOYEE_GUARANTORS_FAIL';

export const CREATE_EDUCATION_START = 'CREATE_EDUCATION_START';
export const CREATE_EDUCATION_SUCCESS = 'CREATE_EDUCATION_SUCCESS';
export const CREATE_EDUCATION_FAIL = 'CREATE_EDUCATION_FAIL,';

export const UPDATE_EDUCATION_START = 'UPDATE_EDUCATION_START';
export const UPDATE_EDUCATION_SUCCESS = 'UPDATE_EDUCATION_SUCCESS';
export const UPDATE_EDUCATION_FAIL = 'UPDATE_EDUCATION_FAIL';

export const DELETE_EDUCATION_START = 'DELETE_EDUCATION_START';
export const DELETE_EDUCATION_SUCCESS = 'DELETE_EDUCATION_SUCCESS';
export const DELETE_EDUCATION_FAIL = 'DELETE_EDUCATION_FAIL';

export const CREATE_CERTIFICATION_START = 'CREATE_CERTIFICATION_START';
export const CREATE_CERTIFICATION_SUCCESS = 'CREATE_CERTIFICATION_SUCCESS';
export const CREATE_CERTIFICATION_FAIL = 'CREATE_CERTIFICATION_FAIL,';

export const UPDATE_CERTIFICATION_START = 'UPDATE_CERTIFICATION_START';
export const UPDATE_CERTIFICATION_SUCCESS = 'UPDATE_CERTIFICATION_SUCCESS';
export const UPDATE_CERTIFICATION_FAIL = 'UPDATE_CERTIFICATION_FAIL';

export const DELETE_CERTIFICATION_START = 'DELETE_CERTIFICATION_START';
export const DELETE_CERTIFICATION_SUCCESS = 'DELETE_CERTIFICATION_SUCCESS';
export const DELETE_CERTIFICATION_FAIL = 'DELETE_CERTIFICATION_FAIL';

export const CREATE_NEXTOFKINS_START = 'CREATE_NEXTOFKINS_START';
export const CREATE_NEXTOFKINS_SUCCESS = 'CREATE_NEXTOFKINS_SUCCESS';
export const CREATE_NEXTOFKINS_FAIL = 'CREATE_NEXTOFKINS_FAIL,';

export const UPDATE_NEXTOFKINS_START = 'UPDATE_NEXTOFKINS_START';
export const UPDATE_NEXTOFKINS_SUCCESS = 'UPDATE_NEXTOFKINS_SUCCESS';
export const UPDATE_NEXTOFKINS_FAIL = 'UPDATE_NEXTOFKINS_FAIL';

export const CREATE_DEPENDENTS_START = 'CREATE_CERTIFICATION_START';
export const CREATE_DEPENDENTS_SUCCESS = 'CREATE_CERTIFICATION_SUCCESS';
export const CREATE_DEPENDENTS_FAIL = 'CREATE_CERTIFICATION_FAIL,';

export const UPDATE_DEPENDENTS_START = 'UPDATE_DEPENDENTS_START';
export const UPDATE_DEPENDENTS_SUCCESS = 'UPDATE_DEPENDENTS_SUCCESS';
export const UPDATE_DEPENDENTS_FAIL = 'UPDATE_DEPENDENTS_FAIL';

export const DELETE_DEPENDENTS_START = 'DELETE_DEPENDENTS_START';
export const DELETE_DEPENDENTS_SUCCESS = 'DELETE_DEPENDENTS_SUCCESS';
export const DELETE_DEPENDENTS_FAIL = 'DELETE_DEPENDENTS_FAIL';

export const CREATE_MEDICALS_START = 'CREATE_MEDICALS_START';
export const CREATE_MEDICALS_SUCCESS = 'CREATE_MEDICALS_SUCCESS';
export const CREATE_MEDICALS_FAIL = 'CREATE_MEDICALS_FAIL,';

export const UPDATE_MEDICALS_START = 'UPDATE_MEDICALS_START';
export const UPDATE_MEDICALS_SUCCESS = 'UPDATE_MEDICALS_SUCCESS';
export const UPDATE_MEDICALS_FAIL = 'UPDATE_MEDICALS_FAIL';

export const CREATE_LANGUAGES_START = 'CREATE_CERTIFICATION_START';
export const CREATE_LANGUAGES_SUCCESS = 'CREATE_CERTIFICATION_SUCCESS';
export const CREATE_LANGUAGES_FAIL = 'CREATE_CERTIFICATION_FAIL';

export const BULK_EMPLOYEE_CSV_HEADERS_SUCCESS = 'BULK_EMPLOYEE_CSV_HEADERS_SUCCESS';
export const BULK_EMPLOYEE_CSV_HEADERS_FAIL = 'BULK_EMPLOYEE_CSV_HEADERS_FAIL';

export const UPDATE_LANGUAGES_START = 'UPDATE_LANGUAGES_START';
export const UPDATE_LANGUAGES_SUCCESS = 'UPDATE_LANGUAGES_SUCCESS';
export const UPDATE_LANGUAGES_FAIL = 'UPDATE_LANGUAGES_FAIL';

export const DELETE_LANGUAGES_START = 'DELETE_LANGUAGES_START';
export const DELETE_LANGUAGES_SUCCESS = 'DELETE_LANGUAGES_SUCCESS';
export const DELETE_LANGUAGES_FAIL = 'DELETE_LANGUAGES_FAIL';

export const SEND_EMPLOYEE_EMAIL_REQUEST = 'SEND_EMPLOYEE_EMAIL_REQUEST';
export const SEND_EMPLOYEE_EMAIL_SUCCESS = 'SEND_EMPLOYEE_EMAIL_SUCCESS';
export const SEND_EMPLOYEE_EMAIL_FAILURE = 'SEND_EMPLOYEE_EMAIL_FAILURE';

export const GET_EMPLOYEE_REFEREES = 'GET_EMPLOYEE_REFEREES';
export const GET_EMPLOYEE_REFEREES_FAIL = 'GET_EMPLOYEE_REFEREES_FAIL';
export const GET_EMPLOYEE_REFEREES_START = 'GET_EMPLOYEE_REFEREES_START';
export const GET_UPGRADE_LETTER_SUCCESS = 'GET_UPGRADE_LETTER_SUCCESS';
export const GET_UPGRADE_LETTER_FAIL = 'GET_UPGRADE_LETTER_FAIL';

export const GET_EMPLOYEE_EMERGENCY_CONTACTS = 'GET_EMPLOYEE_EMERGENCY_CONTACTS';
export const GET_EMPLOYEE_EMERGENCY_CONTACTS_FAIL = // eslint-disable-line operator-linebreak
  'GET_EMPLOYEE_EMERGENCY_CONTACTS_FAIL';
export const GET_EMPLOYEE_EMERGENCY_CONTACTS_START = // eslint-disable-line operator-linebreak
  'GET_EMPLOYEE_EMERGENCY_CONTACTS_START';

export const CREATE_EMPLOYEE_EMERGENCY_CONTACTS = 'CREATE_EMPLOYEE_EMERGENCY_CONTACTS';
export const CREATE_EMPLOYEE_EMERGENCY_CONTACTS_FAIL = // eslint-disable-line operator-linebreak
  'CREATE_EMPLOYEE_EMERGENCY_CONTACTS_FAIL';
export const CREATE_EMPLOYEE_EMERGENCY_CONTACTS_START = // eslint-disable-line operator-linebreak
  'CREATE_EMPLOYEE_EMERGENCY_CONTACTS_START';

export const UPDATE_EMPLOYEE_EMERGENCY_CONTACTS = 'UPDATE_EMPLOYEE_EMERGENCY_CONTACTS';
export const UPDATE_EMPLOYEE_EMERGENCY_CONTACTS_FAIL = // eslint-disable-line operator-linebreak
  'UPDATE_EMPLOYEE_EMERGENCY_CONTACTS_FAIL';
export const UPDATE_EMPLOYEE_EMERGENCY_CONTACTS_START = // eslint-disable-line operator-linebreak
  'UPDATE_EMPLOYEE_EMERGENCY_CONTACTS_START';


const initialState = {
    loading: false,
    data: [],
    employee: [],
    error: null,
    success: false,
    singleEmployee: [],
    singleEmployeePayroll: [],
    employeeReferees: [],
    createProfileError: null,
    createProfileSuccess: false,
    successTwo: false,
  };


  const employeeProfileReducer = (state = initialState, action) => {
    switch (action.type) {

      default:
        return {
          ...state,
        };
    }
  };
  
  export default employeeProfileReducer;
  
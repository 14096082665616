import axios from 'config/axios';
import {
  GET_USER_START,
  GET_USER_SUCCESS,
  GET_USER_FAIL,
  UPDATE_USER_START,
  UPDATE_USER_SUCCESS,
  UPDATE_USER_FAIL,
} from '../actionTypes/profile';

const getUserStart = (payload) => ({
  type: GET_USER_START,
  payload,
});

const getUserSuccess = (payload) => ({
  type: GET_USER_SUCCESS,
  payload,
});

const getUserFail = (payload) => ({
  type: GET_USER_FAIL,
  payload,
});

const updateUserStart = (payload) => ({
  type: UPDATE_USER_START,
  payload,
});

const updateUserSuccess = (payload) => ({
  type: UPDATE_USER_SUCCESS,
  payload,
});

const updateUserFail = (payload) => ({
  type: UPDATE_USER_FAIL,
  payload,
});

export const getEachUser = (userId) => async (dispatch) => {
  dispatch(getUserStart());
  try {
    const response = await axios.get(`/auth/users/${userId}/`);
    dispatch(getUserSuccess(response.data));
  } catch ({ response }) {
    return dispatch(getUserFail(response?.data));
  }
};

export const updateUser = (userId, data) => async (dispatch) => {
  dispatch(updateUserStart());
  try {
    const response = await axios.patch(`/auth/users/${userId}/`, data);
    dispatch(getEachUser(userId));
    dispatch(updateUserSuccess(response.data));
  } catch ({ response }) {
    return dispatch(updateUserFail(response?.data));
  }
};

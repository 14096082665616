import { combineReducers } from 'redux';
import user from './user';
import companiesReducer from './companies';
import departments from './department';
import locations from './location';
import candidatesReducer from './candidates';
import deploymentLettersReducer from './deployment';
import units from './units';
import jobRoles from './jobRoles';
import workflow from './workflow';
import offerLetter from './offerLetter';
import activity from './activity';
import onboarding from './onboarding';
import documentTypeReducer from './documentType';
import confirmationPolicy from './confirmationPolicy';
import employee from './employee';
import settings from './settings';
import announcements from './announcements';
import workflowApproval from './workflowApproval';
import approvalRequests from './approvalRequests';
import employeeDocuments from './employeeDocuments';
import confirmEmployee from './confirmEmployee';
import leaveManagement from './leaveManagement';
import exitManagement from './exitManagement';
import templates from './templates';
import candidateReducer from './candidate';
import disciplinary from './disciplinary';
import welfare from './welfare';
import profile from './profile';
import leave from './candidate/leaveManagement';
import headcount from './headcount';
import employeeComplaintsReducer from './employeeComplaints';
import employeeProfile from './employeeProfile';
import hmoReducer from './hmo';
import hmoCandidateReducer from './candidate/hmo';
import appraisalReducer from './adminAppraisal'


export default combineReducers({
  companies: companiesReducer,
  candidates: candidatesReducer,
  user,
  departments,
  deploymentLetters: deploymentLettersReducer,
  locations,
  units,
  jobRoles,
  workflow,
  offerLetter,
  activities: activity,
  onboarding,
  documentTypeReducer,
  confirmationPolicy,
  employee,
  settings,
  announcements,
  workflowApproval,
  approvalRequests,
  employeeDocuments,
  confirmEmployee,
  leaveManagement,
  exitManagement,
  templates,
  disciplinary,
  welfare,
  candidateReducer,
  profile,
  leave,
  headcount,
  complaints: employeeComplaintsReducer,
  employeeProfile:employeeProfile,
  hmo: hmoReducer,
  employeeHmo: hmoCandidateReducer,
  appraisal: appraisalReducer
});

import styled from 'styled-components';
import { svgs } from '../../assets';

export const Container = styled.div`
  display: flex;
`;

export const SidebarContentResponsive = styled.div`
font-family: Inter;
width: 250px;
height: 100vh;
display: flex;
flex-direction: column;
background: ${(props) => (props.candidate ? '#fff' : '#1a21ba')};

position: fixed;
padding: 5px;
overflow-y:auto;
overflow-x:scroll;

@media screen and (max-width: 997px) {
    z-index:5;
    }
`;

export const MainContentResponsive = styled.div`
  width: 100%;
  height: auto;
  margin-left: 0px;
  @media screen and (max-width: 1024px) {
  
    }
`;

export const Logo = styled(svgs.LOGO)`
  align-self: center;
`;

export const LogoRed = styled(svgs.COLOURED_LOGO)`
  align-self: center;
  pattern {
    fill: white;
  }
`;

export const MenuResponsive = styled.ul`
  display: flex;
  flex-direction: column;
  list-style-type: none;
  padding: 0;
  margin: 0;

  .sub-menu {
    display: flex;
    flex-direction: column;
    text-decoration: none;
    justify-content: flex-start;
    align-items: flex-start;
    margin-left: 38px;
    transition: 0.3s;
    a {
      padding: 5px;
      transition: ease-in 0.8s;
      margin: 10px 0;
      text-transform: capitalize;
    }
  }
  li {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0 12px;
    transition: ease-in 0.8s;

    div {
      display: flex;
      align-items: center;
      transition: ease-in 0.8s;
    }
    a,
    p {
      padding: 20px;
      text-decoration: none;
      display: block;
      position: relative;
      font-weight: 500;
      font-style: normal;
      font-weight: 500;
      font-size: calc(16px);
      cursor: pointer;
      line-height: 21px;
      color: ${(props) => (props.candidate ? '#333333' : '#7377dc')};
      transition: ease-in 0.8s;
      text-transform: capitalize;
      &:after {
        content: '';
        position: absolute;
        display: block;
        width: 100%;
        height: 4px;
        opacity: 0;
        left: 0;
        transition: 0.3s;
        bottom: -12px;
      }

      &:hover {
        color: ${(props) => (props.candidate ? '#1a21ba' : '#ffffff')};

        &:after {
          opacity: 1;
        }
      }
    }

    .sub-menu-text {
      font-size: calc(14px);
    }
    .activeLink {
      color: ${(props) => (props.candidate ? '#1a21ba' : '#ffffff')};
    }
  }
`;

export const TopMenuResponsive = styled.div`
  padding: 30px 0 0;
`;

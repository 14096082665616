import React from 'react'
import { StyledButton } from '../common/Styles';

const Button = ({
    text,
    block = false,
    image = null,
    float=false,
    size="large",
    ...props
}) => {
    return (
        <StyledButton size={size} block={block} float={float} {...props}>
            {image ? <img src={image} alt="" /> : null } {text}
        </StyledButton>
    )
}

export default Button;
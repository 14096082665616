import * as actions from '../actionTypes/documentType';

const initialState = {
  loading: false,
  data: {},
  error: false,
  addDocumentTypeSuccess: false,
  getDocumentTypeSuccess: false,
  editDocumentTypeSuccess: false,
  deleteDocumentTypeSuccess: false,
};

const documentTypeReducer = (state = initialState, action) => {
  switch (action.type) {
    case actions.GET_DOCUMENT_TYPE_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case actions.GET_DOCUMENT_TYPE_SUCCESS:
      return {
        ...state,
        loading: false,
        data: action.payload,
        getDocumentTypeSuccess: true,
      };

    case actions.GET_DOCUMENT_TYPE_FAILURE:
      return {
        ...state,
        loading: false,
        error: true,
      };

    case actions.CREATE_DOCUMENT_TYPE_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case actions.CREATE_DOCUMENT_TYPE_SUCCESS:
      return {
        ...state,
        loading: false,
        addDocumentTypeSuccess: true,
        data: { ...state.data, data: [action.payload.data, ...state.data.data] },
      };

    case actions.CREATE_DOCUMENT_TYPE_FAILURE:
      return {
        ...state,
        loading: false,
        error: true,
      };

    case actions.EDIT_DOCUMENT_TYPE_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case actions.EDIT_DOCUMENT_TYPE_SUCCESS:
      return {
        ...state,
        loading: false,
        editDocumentTypeSuccess: true,
        data: {
          ...state.data,
          data: state.data.data.map((item) => {
            if (action.payload?.id === item.id) {
              return action.payload;
            }
            return item;
          }),
        },
      };
    case actions.EDIT_DOCUMENT_TYPE_FAILURE:
      return {
        ...state,
        loading: false,
        error: true,
      };

    case actions.DELETE_DOCUMENT_TYPE_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case actions.DELETE_DOCUMENT_TYPE_SUCCESS:
      return {
        ...state,
        loading: false,
        deleteDocumentTypeSuccess: true,
        data: {
          data: state.data?.data.filter((item) => item.id !== action.payload?.id),
        },
      };
    case actions.DELETE_DOCUMENT_TYPE_FAILURE:
      return {
        ...state,
        loading: false,
        error: true,
      };

    case 'DOCUMENT_TYPE_INIT_STATE':
      return {
        ...state,
        addDocumentTypeSuccess: false,
        error: false,
      };

    default:
      return {
        ...state,
      };
  }
};

export default documentTypeReducer;


import { svgs } from 'assets';
import React from 'react';
import { Body } from '../style';

const MeetingScheduled = () => {
    return (
        <Body>
            <div className="content">
                <div>
                    <img src={svgs.LOGO_COLOURED} alt="colored logo" />
                </div>
                <div className="inner-content">
                    <div className="icon">
                        <img src={svgs.CHECK_ICON} alt="check icon"/>
                    </div>
                    <div className="message">
                        <span>
                            Pre-employment Medical Meeting Scheduled
                        </span>
                        <p>
                            some stories about how lorem became the most useful text in the world of development today
                        </p>
                    </div>
                    <hr className="divider"/>

                    <div className="data-section">
                        <div>
                            <span>
                                <svgs.CLOCK /> 
                            </span>
                            <span>
                                2:00pm - 3:00pm
                            </span>
                        </div>
                        <div>
                            <span>
                                <svgs.CALENDAR /> 
                            </span>
                            <span>
                                Monday November 16, 2020
                            </span>
                        </div>
                        <div>
                            <span>
                                <svgs.LOCATIONMARKER /> 
                            </span>
                            <span>
                                Workforce Group, Gbagada
                            </span>
                        </div>
                    </div>
                </div>
            </div>
        </Body>
    )
}

export default MeetingScheduled;
import axios from 'config/axios';
import * as actionDoc from '../actionTypes/employeeDocuments';
import {
  GET_EMPLOYEE_START,
  GET_EMPLOYEE_SUCCESS,
  GET_EMPLOYEE_FAIL,
  CREATE_EMPLOYEE_START,
  CREATE_EMPLOYEE_SUCCESS,
  CREATE_EMPLOYEE_FAIL,
  GET_EMPLOYEE_SEARCH_SUCCESS,
  GET_EMPLOYEE_SEARCH_FAIL,
  GET_SINGLE_EMPLOYEE_FAIL,
  GET_SINGLE_EMPLOYEE_SUCCESS,
  GET_SINGLE_EMPLOYEE_START,
  GET_SINGLE_EMPLOYEE_ONBOARDING_FAIL,
  GET_SINGLE_EMPLOYEE_ONBOARDING_SUCCESS,
  GET_SINGLE_EMPLOYEE_ONBOARDING_START,
  EDIT_EMPLOYEE_START,
  EDIT_EMPLOYEE_SUCCESS,
  EDIT_EMPLOYEE_FAIL,
  UPDATE_EMPLOYEE_START,
  UPDATE_EMPLOYEE_SUCCESS,
  UPDATE_EMPLOYEE_FAIL,
  SEND_EMPLOYEE_EMAIL_REQUEST,
  SEND_EMPLOYEE_EMAIL_SUCCESS,
  SEND_EMPLOYEE_EMAIL_FAILURE,
  GET_EMPLOYEE_REFEREES,
  GET_EMPLOYEE_REFEREES_FAIL,
  GET_EMPLOYEE_REFEREES_START,
  GET_UPGRADE_LETTER_SUCCESS,
  GET_UPGRADE_LETTER_FAIL,
  BULK_EMPLOYEE_CSV_HEADERS_SUCCESS,
  BULK_EMPLOYEE_CSV_HEADERS_FAIL,
} from '../actionTypes/employee';

import { GET_ALL_USERS } from '../actionTypes/user';
import * as actionTypes from '../actionTypes/companies';
import { getCompaniesApi } from 'redux/api/companies';
import {
  GET_CHANGE_REQUEST_START,
  GET_CHANGE_REQUEST_SUCCESS,
  GET_CHANGE_REQUEST_FAIL,
  CHANGE_REQUEST_ALL_START,
  CHANGE_REQUEST_ALL_SUCCESS,
  CHANGE_REQUEST_ALL_FAIL,
  ALL_UPGRADED_LETTERS_START,
  ALL_UPGRADED_LETTERS_SUCCESS,
  ALL_UPGRADED_LETTERS_FAIL,
} from '../actionTypes/employeeDocuments';
import { toast } from 'react-toastify';

//Action creators

const getChangeRequestStart = () => ({
  type: GET_CHANGE_REQUEST_START,
});

const getChangeRequestSuccess = (payload) => ({
  type: GET_CHANGE_REQUEST_SUCCESS,
  payload,
});
const getChangeRequestFail = (error) => ({
  type: GET_CHANGE_REQUEST_FAIL,
  error,
});

export const getCompanies = () => ({
  type: actionTypes.GET_COMPANIES_REQUEST,
});

export const getCompaniesFailure = (error) => ({
  type: actionTypes.GET_COMPANIES_FAILURE,
  error,
});

export const getCompaniesSuccess = (payload) => ({
  type: actionTypes.GET_COMPANIES_SUCCESS,
  payload,
});

const getEmployeeStart = () => ({
  type: GET_EMPLOYEE_START,
});

const getEmployeeSuccess = (payload) => ({
  type: GET_EMPLOYEE_SUCCESS,
  payload,
});
const getEmployeeFail = (error) => ({
  type: GET_EMPLOYEE_FAIL,
  error,
});

const createEmployeeStart = () => ({
  type: CREATE_EMPLOYEE_START,
});

const createEmployeeSuccess = (payload) => ({
  type: CREATE_EMPLOYEE_SUCCESS,
  payload,
});
const createEmployeeFail = (error) => ({
  type: CREATE_EMPLOYEE_FAIL,
  error,
});

const getEmployeeSearchStart = () => ({
  type: 'RESET_STATE',
});
const getEmployeeSearchSuccess = (payload) => ({
  type: GET_EMPLOYEE_SEARCH_SUCCESS,
  payload,
});
const getEmployeeSearchFail = (error) => ({
  type: GET_EMPLOYEE_SEARCH_FAIL,
  error,
});

const getSingleEmployeeStart = () => ({
  type: GET_SINGLE_EMPLOYEE_START,
});
const getSingleEmployeeSuccess = (payload) => ({
  type: GET_SINGLE_EMPLOYEE_SUCCESS,
  payload,
});
const getSingleEmployeeFail = (error) => ({
  type: GET_SINGLE_EMPLOYEE_FAIL,
  error,
});

const getSingleEmployeeOnboardingStart = () => ({
  type: GET_SINGLE_EMPLOYEE_ONBOARDING_START,
});
const getSingleEmployeeOnboardingSuccess = (payload) => ({
  type: GET_SINGLE_EMPLOYEE_ONBOARDING_SUCCESS,
  payload,
});
const getSingleEmployeeOnboardingFail = (error) => ({
  type: GET_SINGLE_EMPLOYEE_ONBOARDING_FAIL,
  error,
});

const editSingleEmployeeStart = (payload) => ({
  type: EDIT_EMPLOYEE_START,
  payload,
});
const editSingleEmployeeSuccess = (payload) => ({
  type: EDIT_EMPLOYEE_SUCCESS,
  payload,
});
const editSingleEmployeeFail = (error) => ({
  type: EDIT_EMPLOYEE_FAIL,
  error,
});

const updateSingleEmployeeStart = (payload) => ({
  type: UPDATE_EMPLOYEE_START,
  payload,
});
const updateSingleEmployeeSuccess = (payload) => ({
  type: UPDATE_EMPLOYEE_SUCCESS,
  payload,
});
const updateSingleEmployeeFail = (error) => ({
  type: UPDATE_EMPLOYEE_FAIL,
  error,
});

const getUsers = (payload) => ({
  type: GET_ALL_USERS,
  payload,
});

const getUsersFail = (payload) => ({
  type: GET_ALL_USERS,
  payload,
});

const getEmployeeRefs = (payload) => ({
  type: GET_EMPLOYEE_REFEREES,
  payload,
});

const getEmployeeRefsFail = (payload) => ({
  type: GET_EMPLOYEE_REFEREES_FAIL,
  payload,
});

const getEmployeeRefereesStart = () => ({
  type: GET_EMPLOYEE_REFEREES_START,
});

const changeRequestStart = () => ({
  type: actionDoc.CHANGE_REQUEST_START,
});

const changeRequestSuccess = (payload) => ({
  type: actionDoc.CHANGE_REQUEST_SUCCESS,
  payload,
});
const changeRequestFail = (error) => ({
  type: actionDoc.CHANGE_REQUEST_FAIL,
  error,
});

export const getChangeRequest = (employeeId) => async (dispatch) => {
  dispatch(getChangeRequestStart());
  try {
    const response = await axios.get(
      `/employee/change_requests/${employeeId}/?remove_model=Subscriber`
    );
    dispatch(getChangeRequestSuccess(response.data));
  } catch ({ response }) {
    if (response?.status) {
      return dispatch(getChangeRequestFail(response?.data.errors));
    }
    return dispatch(getChangeRequestFail('Network Error'));
  }
};

export const changeRequestUpdate =
  (data, employeeId = null) =>
  async (dispatch) => {
    dispatch(changeRequestStart());
    try {
      const response = await axios.patch(`/employee/change_request/`, data);
      if (employeeId) {
        dispatch(getChangeRequest(employeeId));
        dispatch(getSingleEmployee(employeeId));
        dispatch(getChangeRequest(employeeId));
      }

      toast.success('Change request updated successfully');
      dispatch(changeRequestSuccess(response.data));
      dispatch(getAllChangeRequest());
    } catch ({ response }) {
      if (response?.status) {
        dispatch(changeRequestFail(response?.data.errors));
        return toast.error(response?.data.errors || response?.data?.message);
      }
      dispatch(changeRequestFail(response?.data.errors));
      return toast.error('Network Error');
    }
  };

export const getAllCompanies = (paginate, pageSize = 10, page = 1, searchParam = '') => {
  return async (dispatch) => {
    dispatch(getCompanies());
    try {
      const response = await getCompaniesApi(paginate, pageSize, page, searchParam);
      if (response.data) return dispatch(getCompaniesSuccess(response.data));
    } catch (error) {
      return dispatch(getCompaniesFailure(error.response));
    }
  };
};

export const getEmployee =
  (paginate, page = 1, pagesize = 10) =>
  async (dispatch) => {
    dispatch(getEmployeeStart());
    let url = paginate ? paginate : `/employee/?page=${page}&page_size=${pagesize}`;
    try {
      const response = await axios.get(url);
      dispatch(getEmployeeSuccess(response.data));
    } catch ({ response }) {
      if (response?.status) {
        return dispatch(getEmployeeFail(response?.data.errors));
      }
      return dispatch(getEmployeeFail('Network Error'));
    }
  };

export const getEmployeeSearch = (link) => async (dispatch) => {
  dispatch(getEmployeeSearchStart());
  try {
    const response = await axios.get(link);

    dispatch(getEmployeeSearchSuccess(response.data));
  } catch ({ response }) {
    if (response.status) {
      return dispatch(getEmployeeSearchFail(response.data.errors));
    }
    return dispatch(getEmployeeSearchFail('Network Error'));
  }
};

export const createEmployee = (payload) => async (dispatch) => {
  dispatch(createEmployeeStart());
  try {
    const response = await axios.post(`/employee/`, payload);
    dispatch(createEmployeeSuccess(response.data));
    dispatch(getEmployee());
  } catch ({ response }) {
    if (response.status) {
      return dispatch(
        createEmployeeFail(
          response.data.errors ||
            JSON.stringify(response.data.message).replace(/[^a-zA-Z0-9\s]/g, '')
        )
      );
    }
    return dispatch(createEmployeeFail('Network Error'));
  }
};

export const bulkUploadEmployee = (payload, toast) => async (dispatch) => {
  const fileData = new FormData();
  fileData.append('file', payload[0]);
  dispatch(createEmployeeStart());
  try {
    const response = await axios.post('/employee/bulk_upload/', fileData);
    dispatch(createEmployeeSuccess(response.data));
    dispatch(getEmployee());
  } catch ({ response }) {
    if (response?.status) {
      return dispatch(
        createEmployeeFail({ error: response?.data || 'an error occurred' })
      );
    }
    return toast.error('Network Error');
  }
};
export const bulkEmployeeCVSHeaders = () => async (dispatch) => {
  try {
    const response = await axios.get('/employee/bulk_upload_csv_headers/');
    dispatch({
      type: BULK_EMPLOYEE_CSV_HEADERS_SUCCESS,
      payload: response?.data,
    });
  } catch ({ response }) {
    if (response?.status) {
      dispatch({
        type: BULK_EMPLOYEE_CSV_HEADERS_FAIL,
        error: response?.data.errors,
      });
    }
    dispatch({
      type: BULK_EMPLOYEE_CSV_HEADERS_FAIL,
      error: 'Network Error',
    });
  }
};

export const getSingleEmployee = (employeeid) => async (dispatch) => {
  dispatch(getSingleEmployeeStart());
  try {
    const response = await axios.get(`/employee/${employeeid}/`);
    dispatch(getSingleEmployeeSuccess(response.data));
    dispatch(getChangeRequest(employeeid));
  } catch ({ response }) {
    if (response?.status) {
      return dispatch(getSingleEmployeeFail(response?.data?.error));
    }
    return dispatch(getSingleEmployeeFail('Network Error'));
  }
};

export const getSingleEmployeeOnboarding = (employeeid) => async (dispatch) => {
  dispatch(getSingleEmployeeOnboardingStart());
  try {
    const response = await axios.get(
      `/employee/{${employeeid}}/employee_onboarding_details/`
    );
    dispatch(getSingleEmployeeOnboardingSuccess(response.data));
  } catch ({ response }) {
    if (response?.status) {
      return dispatch(getSingleEmployeeOnboardingFail(response.data.error));
    }
    return dispatch(getSingleEmployeeOnboardingFail('Network Error'));
  }
};

export const getEmployeeFilter = (link) => async (dispatch) => {
  dispatch(getEmployeeSearchStart());
  localStorage.setItem('nexturl', link);
  try {
    const response = await axios.get(link);
    dispatch(getEmployeeSuccess(response.data));
  } catch ({ response }) {
    if (response.status) {
      return dispatch(getEmployeeSearchFail(response.data.error));
    }
    return dispatch(getEmployeeSearchFail('Network Error'));
  }
};

export const editSingleEmployee = (id, employeeData) => async (dispatch) => {
  dispatch(editSingleEmployeeStart());
  try {
    const response = await axios.patch(`/employee/${id}/`, employeeData);
    dispatch(editSingleEmployeeSuccess(response.data));
    dispatch(getEmployee());
  } catch ({ response }) {
    if (response.status) {
      return dispatch(editSingleEmployeeFail(response?.data?.error));
    }
    return dispatch(editSingleEmployeeFail('Network Error'));
  }
};

export const updateEmployeeStatus =
  (payload, employee = '') =>
  async (dispatch) => {
    dispatch(updateSingleEmployeeStart());
    try {
      const response = await axios.patch(`/employee/employment_status_update/`, payload);
      dispatch(updateSingleEmployeeSuccess(response.data));
      if (!employee) {
        dispatch(getEmployee());
      }
      dispatch(getSingleEmployee(employee));
    } catch ({ response }) {
      if (response.status) {
        return dispatch(updateSingleEmployeeFail(response.data.errors));
      }
    }
  };
export const updateEmployeeDeclineStatus = (payload) => async (dispatch) => {
  dispatch(updateSingleEmployeeStart());
  try {
    const response = await axios.patch(`employee/employment_decline_update/`, payload);
    dispatch(updateSingleEmployeeSuccess(response.data));
    dispatch(getEmployee());
  } catch ({ response }) {
    if (response.status) {
      return dispatch(updateSingleEmployeeFail(response.data.errors));
    }
    return dispatch(updateSingleEmployeeFail('Network Error'));
  }
};

export const getAllUsers = () => async (dispatch) => {
  try {
    const response = await axios.get(`/auth/users/`);
    dispatch(getUsers(response.data));
  } catch ({ response }) {
    return dispatch(getUsersFail(response?.data));
  }
};

export const sendEmployeeEmail = (data, toast) => async (dispatch) => {
  dispatch({
    type: SEND_EMPLOYEE_EMAIL_REQUEST,
  });
  try {
    const response = await axios.post(`/employee/mail_employee/`, data);
    dispatch({
      type: SEND_EMPLOYEE_EMAIL_SUCCESS,
      payload: response?.data,
    });
    toast.success('Mail sent successfully');
  } catch ({ response }) {
    dispatch({
      type: SEND_EMPLOYEE_EMAIL_FAILURE,
      payload: {
        error: response?.data.errors || response?.data?.message || 'Network Error',
      },
    });
  }
};

export const getEmployeeReferees = (employeeId) => async (dispatch) => {
  try {
    dispatch(getEmployeeRefereesStart());
    const response = await axios.get(`/employee/${employeeId}/referees/`);
    dispatch(getEmployeeRefs(response.data));
  } catch ({ response }) {
    return dispatch(getEmployeeRefsFail());
  }
};

//used by context only
export const getEmployeeRefereesDispatcher = (employeeId) => {
  return axios
    .get(`/employee/${employeeId}/referees/`)
    .then(({ data }) => data)
    .catch((error) => error);
};

export const updateEmployeeRefereeDispatcher = (employeeId, refeereeId, updatedData) => {
  return axios
    .patch(`/employee/${employeeId}/referee/${refeereeId}/`, updatedData)
    .then((response) => {
      return response;
    })
    .catch((err) => {
      return err?.data;
    });
};

export const addEmployeeRefereeDispatcher = (employeeId, refereeData) => {
  return axios
    .post(`/employee/${employeeId}/referees/`, refereeData)
    .then((response) => {
      return response;
    })
    .catch((err) => {
      return err;
    });
};

export const getEmployeeGuarantorsDispatcher = (employeeId) => {
  return axios
    .get(`/employee/${employeeId}/guarantors/`)
    .then(({ data }) => data)
    .catch((err) => err);
};

export const addEmployeeGuarantorsDispatcher = (employeeId, guarantorToAdd) => {
  return axios
    .post(`/employee/${employeeId}/guarantors/`, guarantorToAdd)
    .then((response) => {
      return response;
    })
    .catch((err) => {
      return err;
    });
};

export const updateEmployeeGuarantorDispatcher = (
  employeeId,
  guarantorId,
  updatedData
) => {
  return axios
    .patch(`/employee/${employeeId}/guarantor/${guarantorId}/`, updatedData)
    .then((response) => {
      return response;
    })
    .catch((err) => {
      return err;
    });
};

export const getAllChangeRequest =
  (paginate, pageSize = 10, page = 1, searchParam = '') =>
  async (dispatch) => {
    try {
      dispatch({
        type: CHANGE_REQUEST_ALL_START,
      });
      let url = paginate
        ? paginate
        : `/employee/change_requests/?page=${page}&page_size=${pageSize}&search=${searchParam}`;
      const response = await axios.get(url);
      dispatch({
        type: CHANGE_REQUEST_ALL_SUCCESS,
        payload: response?.data,
      });
    } catch ({ response }) {
      if (response?.status) {
        dispatch({
          type: CHANGE_REQUEST_ALL_FAIL,
          error: response?.data.errors,
        });
      }
      dispatch({
        type: CHANGE_REQUEST_ALL_FAIL,
        error: 'Network Error',
      });
    }
  };
export const getUpgradedLettersCVS = () => async (dispatch) => {
  try {
    dispatch({
      type: ALL_UPGRADED_LETTERS_START,
    });
    const response = await axios.get('/employee/upgrade_letter_csv_headers/');
    dispatch({
      type: ALL_UPGRADED_LETTERS_SUCCESS,
      payload: response?.data,
    });
  } catch ({ response }) {
    if (response?.status) {
      dispatch({
        type: ALL_UPGRADED_LETTERS_FAIL,
        error: response?.data.errors,
      });
    }
    dispatch({
      type: ALL_UPGRADED_LETTERS_FAIL,
      error: 'Network Error',
    });
  }
};

export const getAllUpgradedLettersRequest =
  (paginate, pageSize = 10, page = 1, searchParam = '') =>
  async (dispatch) => {
    try {
      dispatch({
        type: CHANGE_REQUEST_ALL_START,
      });
      let url = paginate
        ? paginate
        : `/employee/upgrade_letters/?page=${page}&page_size=${pageSize}&search=${searchParam}`;
      const response = await axios.get(url);
      dispatch({
        type: GET_UPGRADE_LETTER_SUCCESS,
        payload: response?.data,
      });
    } catch ({ response }) {
      if (response?.status) {
        dispatch({
          type: GET_UPGRADE_LETTER_FAIL,
          error: response?.data.errors,
        });
      }
      dispatch({
        type: GET_UPGRADE_LETTER_FAIL,
        error: 'Network Error',
      });
    }
  };

// export const editOfferLetter = (id, data) => async (dispatch) => {
//   let vettedData = data;
//   const hasUploadedLetter = data?.file?.name;
//   if (hasUploadedLetter) {
//     vettedData = new FormData();
//     Object.entries(data).forEach(([key, value]) => {
//       vettedData.append(`${key}`, value);
//     });
//   } else {
//     const { file, ...rest } = data;
//     vettedData = rest;
//   }
//   try {
//     dispatch(startEditOfferLetter());
//     const response = await editOfferTemplateApi(id, vettedData);
//     return dispatch(successEditOfferLetter(response));
//   } catch (e) {
//     return dispatch(errorEditOfferLetter(e.response));
//   }
// };

export const changeRequestAllCompanyEmployeeUpdate =
  (companyid, data) => async (dispatch) => {
    dispatch(changeRequestStart());
    try {
      await axios.patch(`/employee/change_requests/${companyid}/all/`, data);
      dispatch(getAllChangeRequest());
      toast.success('Change request updated successfully');
    } catch ({ response }) {
      if (response?.status) {
        toast.error(
          response?.data?.message ||
            response?.data.errors ||
            'Failed to fetch leave requests '
        );
      }
      return dispatch(changeRequestFail('Network Error'));
    }
  };

export const ADD_TO_DRAFT_REQUEST = 'ADD_TO_DRAFT_REQUEST';
export const ADD_TO_DRAFT_SUCCESS = 'ADD_TO_DRAFT_SUCCESS';
export const ADD_TO_DRAFT_FAILURE = 'ADD_TO_DRAFT_FAILURE';

export const PUBLISH_REQUEST = 'PUBLISH_REQUEST';
export const PUBLISH_SUCCESS = 'PUBLISH_SUCCESS';
export const PUBLISH_FAILURE = 'PUBLISH_FAILURE';

export const GET_ALL_REQUEST = 'GET_ALL_REQUEST';
export const GET_ALL_SUCCESS = 'GET_ALL_SUCCESS';
export const GET_ALL_FAILURE = 'GET_ALL_FAILURE';

export const SEARCH_AND_FILTER_REQUEST = 'SEARCH_AND_FILTER_REQUEST';
export const SEARCH_AND_FILTER_SUCCESS = 'SEARCH_AND_FILTER_SUCCESS';
export const SEARCH_AND_FILTER_FAILURE = 'SEARCH_AND_FILTER_FAILURE';

export const DELETE_REQUEST = 'DELETE_REQUEST';
export const DELETE_SUCCESS = 'DELETE_SUCCESS';
export const DELETE_FAILURE = 'DELETE_FAILURE';

export const GET_SINGLE_REQUEST = 'GET_SINGLE_REQUEST';
export const GET_SINGLE_SUCCESS = 'GET_SINGLE_SUCCESS';
export const GET_SINGLE_FAILURE = 'GET_SINGLE_FAILURE';

export const UPDATE_DRAFT_REQUEST = 'UPDATE_DRAFT_REQUEST';
export const UPDATE_DRAFT_SUCCESS = 'UPDATE_DRAFT_SUCCESS';
export const UPDATE_DRAFT_FAILURE = 'UPDATE_DRAFT_FAILURE';


import React from 'react';
import { Row } from 'react-bootstrap';
import { useFormik } from 'formik';
import Input from 'components/common/Input';
import Button from 'components/common/Button';
import { useDispatch } from 'react-redux';
import { useToast } from 'context/toastContext';
import { createPassword } from 'redux/actions/user';
import { CreatePasswordSchema } from 'utils/validators';
import { AuthPageContainer, SigninArea, Logo, SigninFormArea } from './styles';
import { svgs } from '../../assets';
import AuthSidebar from '../../components/Auth/Sidebar';
import { useQuery } from '../../utils/useQuery';
import { NavLink } from 'react-router-dom';


const CreatePassword = ({  history }) => {
  const dispatch = useDispatch();


  const toast = useToast();

  const query = useQuery();

  const token = query.get('token');

  const initialValues = {
    password: '',
    confirm_password: '',
  };

  const onSubmit = async (values, { setSubmitting }) => {
    setSubmitting(true);
    const response = await dispatch(createPassword({ password: values.password, token }));
    setSubmitting(false);
    if (!response.error) {
      toast.success('password created successfully, kindly login');
      return history.push('/signin');
    }
    return toast.error(response?.error || 'an error occured');
  };

  const { handleChange, handleSubmit, handleBlur, values, errors, isSubmitting } = useFormik({
    initialValues,
    onSubmit,
    validationSchema: CreatePasswordSchema,
  });
  

  return (
    <AuthPageContainer fluid>
      <Row>
        <AuthSidebar />
        <SigninArea lg={8} md={8}>
          <Logo src={svgs.logo_colored} />
          <SigninFormArea lg={5} md={6} sm={12} className="text-center">
            <h1>Create Your Password</h1>
            <p></p>
            <form onSubmit={handleSubmit}>
              <Input
                placeholder="Password"
                type="password"
                label="Password"
                error={errors?.password}
                name="password"
                onChange={handleChange}
                value={values?.password}
              />
              <Input
                type="password"
                label="Confirm Password"
                placeholder="Confirm password"
                name="confirm_password"
                error={errors?.confirm_password}
                required
                onChange={handleChange}
                value={values?.confirm_password}
                onBlur={handleBlur}
                marginbottom="10px"
              />

              <Button
                type="submit"
                onClick={() => {
                  console.log('clicked');
                  handleSubmit()}}
                text={isSubmitting? 'Loading': "Create Password"}
                disabled={isSubmitting}
                
              />
                <NavLink to="/signin">Back to Login</NavLink>
              
            </form>
          </SigninFormArea>

          {/* <SigninFormArea lg={5} md={6} sm={12} className="text-center">
            <h1>Password Created Successfully</h1>
            <p>You can login to access your account</p>

            <Button
              onClick={() => history.push('/signin')}
              type="button"
              text="Proceed to Login"
            />
          </SigninFormArea> */}
        </SigninArea>
      </Row>
    </AuthPageContainer>
  );
};

export default CreatePassword;

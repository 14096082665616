import styled from 'styled-components';

export const FormBody = styled.div`
  border: 1px solid #f0f0f0;
  border-top: none;
  background: #ffffff;
  border-radius: 0 0 10px 10px;
  padding: 30px;
`;

export const FormHeader = styled.div`
  border: 1px solid #f0f0f0;
  padding: 25px;
  background: white;
  border-radius: 10px 10px 0 0;
`;
export const FormContainer = styled.div`
  display: flex;
  flex-direction: column;
`;

export const GET_EXIT_TYPE_REQUEST = 'GET_EXIT_TYPE_REQUEST';
export const GET_EXIT_TYPE_SUCCESS = 'GET_EXIT_TYPE_SUCCESS';
export const GET_EXIT_TYPE_FAILURE = 'GET_EXIT_TYPE_FAILURE';

export const GET_EXIT_POLICY_REQUEST = 'GET_EXIT_POLICY_REQUEST';
export const GET_EXIT_POLICY_SUCCESS = 'GET_EXIT_POLICY_SUCCESS';
export const GET_EXIT_POLICY_FAILURE = 'GET_EXIT_POLICY_FAILURE';

export const GET_ALL_EXIT_REQUEST = 'GET_ALL_EXIT_REQUEST';
export const GET_ALL_EXIT_SUCCESS = 'GET_ALL_EXIT_SUCCESS';
export const GET_ALL_EXIT_FAILURE = 'GET_ALL_EXIT_FAILURE';

export const EXIT_APPROVAL_REQUEST = 'EXIT_APPROVAL_REQUEST';
export const EXIT_APPROVAL_SUCCESS = 'EXIT_APPROVAL_SUCCESS';
export const EXIT_APPROVAL_FAILURE = 'EXIT_APPROVAL_FAILURE';

export const DELETE_EXIT_REQUEST = 'DELETE_EXIT_REQUEST';
export const DELETE_EXIT_SUCCESS = 'DELETE_EXIT_SUCCESS';
export const DELETE_EXIT_FAILURE = 'DELETE_EXIT_FAILURE';

export const DELETE_EXIT_TYPE_REQUEST = 'DELETE_EXIT_TYPE_REQUEST';
export const DELETE_EXIT_TYPE_SUCCESS = 'DELETE_EXIT_TYPE_SUCCESS';
export const DELETE_EXIT_TYPE_FAILURE = 'DELETE_EXIT_TYPE_FAILURE';

export const ADD_EXIT_TYPE_REQUEST = 'ADD_EXIT_TYPE_REQUEST';
export const ADD_EXIT_TYPE_SUCCESS = 'ADD_EXIT_TYPE_SUCCESS';
export const ADD_EXIT_TYPE_FAILURE = 'ADD_EXIT_TYPE_FAILURE';

export const UPDATE_EXIT_TYPE_REQUEST = 'UPDATE_EXIT_TYPE_REQUEST';
export const UPDATE_EXIT_TYPE_SUCCESS = 'UPDATE_EXIT_TYPE_SUCCESS';
export const UPDATE_EXIT_TYPE_FAILURE = 'UPDATE_EXIT_TYPE_FAILURE';

export const ADD_EXIT_POLICY_REQUEST = 'ADD_EXIT_POLICY_REQUEST';
export const ADD_EXIT_POLICY_SUCCESS = 'ADD_EXIT_POLICY_SUCCESS';
export const ADD_EXIT_POLICY_FAILURE = 'ADD_EXIT_POLICY_FAILURE';

export const DELETE_EXIT_POLICY_REQUEST = 'DELETE_EXIT_POLICY_REQUEST';
export const DELETE_EXIT_POLICY_SUCCESS = 'DELETE_EXIT_POLICY_SUCCESS';
export const DELETE_EXIT_POLICY_FAILURE = 'DELETE_EXIT_POLICY_FAILURE';

export const UPDATE_EXIT_POLICY_REQUEST = 'UPDATE_EXIT_POLICY_REQUEST';
export const UPDATE_EXIT_POLICY_SUCCESS = 'UPDATE_EXIT_POLICY_SUCCESS';
export const UPDATE_EXIT_POLICY_FAILURE = 'UPDATE_EXIT_POLICY_FAILURE';

export const ADD_EXIT_REQUEST = 'ADD_EXIT_REQUEST';
export const ADD_EXIT_SUCCESS = 'ADD_EXIT_SUCCESS';
export const ADD_EXIT_FAILURE = 'ADD_EXIT_FAILURE';

export const GET_SINGLE_EXIT_REQUEST = 'GET_SINGLE_EXIT_REQUEST';
export const GET_SINGLE_EXIT_SUCCESS = 'GET_SINGLE_EXIT_SUCCESS';
export const GET_SINGLE_EXIT_FAILURE = 'GET_SINGLE_EXIT_FAILURE';

export const GET_EXIT_TOTAL_REQUEST = 'GET_EXIT_TOTAL_REQUEST';
export const GET_EXIT_TOTAL_SUCCESS = 'GET_EXIT_TOTAL_SUCCESS';
export const GET_EXIT_TOTAL_FAILURE = 'GET_EXIT_TOTAL_FAILURE';

export const GET_ALL_EMPLOYEE_EXIST_REQUEST = 'GET_ALL_EMPLOYEE_EXIST_REQUEST';
export const GET_ALL_EMPLOYEE_EXIST_SUCCESS = 'GET_ALL_EMPLOYEE_EXIST_SUCCESS';
export const GET_ALL_EMPLOYEE_EXIST_FAILURE = 'GET_ALL_EMPLOYEE_EXIST_FAILURE';

import {
  GET_CONFIRMATION_LETTER_START,
  GET_CONFIRMATION_LETTER_SUCCESS,
  GET_CONFIRMATION_LETTER_FAIL,
  SEND_CONFIRMATION_LETTER_START,
  SEND_CONFIRMATION_LETTER_SUCCESS,
  SEND_CONFIRMATION_LETTER_FAIL,
  GET_REWARD_LETTER_START,
  GET_REWARD_LETTER_SUCCESS,
  GET_REWARD_LETTER_FAIL,
  GET_REDEPLOYMENT_LETTER_START,
  GET_REDEPLOYMENT_LETTER_SUCCESS,
  GET_REDEPLOYMENT_LETTER_FAIL,
  REDEPLOY_EMPLOYEE_START,
  REDEPLOY_EMPLOYEE_SUCCESS,
  REDEPLOY_EMPLOYEE_FAIL,

} from '../actionTypes/confirmEmployee';

const initialState = {
  loading: false,
  data: [],
  letter: [],
  error: null,
  success: false,
  confirmsuccess: false,
  confirmerror:null,
  redeployLetter: [],
  rewardLetter: [],
  file:''
};

const getConfirmationLetterStart = (state) => ({
  ...state,
  loading: true,
  error: null,
});

const getConfirmationLetterSuccess = (state, action) => ({
  ...state,
  error: null,
  success: false,
  loading: false,
  letter: action.payload,
});
const getConfirmationLetterFail = (state, action) => ({
  ...state,
  error: action.error,
  success: false,
  letter: null,
  loading: false,
});


const sendConfirmationLetterStart = (state) => ({
  ...state,
  loading: true,
  error: null,
});

const sendConfirmationLetterSuccess = (state, action) => ({
  ...state,
  error: null,
  success: true,
  loading: false,
});
const sendConfirmationLetterFail = (state, action) => ({
  ...state,
  error: action.error,
  success: false,
  letter: null,
  loading: false,
});


const getRedeploymentLetterStart = (state) => ({
  ...state,
  loading: true,
  error: null,
});

const getRedeploymentLetterSuccess = (state, action) => ({
  ...state,
  error: null,
  success: true,
  loading: false,
  redeployLetter: action.payload,
});
const getRedeploymentLetterFail = (state, action) => ({
  ...state,
  error: action.error,
  success: false,
  redeployLetter: null,
  loading: false,
});

const getRewardLetterStart = (state) => ({
  ...state,
  loading: true,
  error: null,
});

const getRewardLetterSuccess = (state, action) => ({
  ...state,
  error: null,
  success: false,
  loading: false,
  rewardLetter: action.payload,
});
const getRewardLetterFail = (state, action) => ({
  ...state,
  error: action.error,
  success: false,
  rewardLetter: null,
  loading: false,
});
const redeployEmployeeStart = (state) => ({
  ...state,
  loading: true,
  confirmerror: null,
});

const redeployEmployeeSuccess = (state, action) => ({
  ...state,
  confirmerror: null,
  confirmsuccess: true,
  loading: false,
  requests: action.payload,
});
const redeployEmployeeFail = (state, action) => ({
  ...state,
  confirmerror: action.error,
  confirmsuccess: false,
  requests: null,
  loading: false,
});




const confirmEmployeeReducer = (state = initialState, action) => {
  switch (action.type) {
    case GET_CONFIRMATION_LETTER_START:
      return getConfirmationLetterStart(state);
    case GET_CONFIRMATION_LETTER_SUCCESS:
      return getConfirmationLetterSuccess(state, action);
    case GET_CONFIRMATION_LETTER_FAIL:
      return getConfirmationLetterFail(state, action);
    case SEND_CONFIRMATION_LETTER_START:
      return sendConfirmationLetterStart(state);
    case SEND_CONFIRMATION_LETTER_SUCCESS:
      return sendConfirmationLetterSuccess(state, action);
    case SEND_CONFIRMATION_LETTER_FAIL:
      return sendConfirmationLetterFail(state, action);
    case GET_REDEPLOYMENT_LETTER_START:
      return getRedeploymentLetterStart(state);
    case GET_REDEPLOYMENT_LETTER_SUCCESS:
      return getRedeploymentLetterSuccess(state, action);
    case GET_REDEPLOYMENT_LETTER_FAIL:
      return getRedeploymentLetterFail(state, action);
    case GET_REWARD_LETTER_START:
      return getRewardLetterStart(state);
    case GET_REWARD_LETTER_SUCCESS:
      return getRewardLetterSuccess(state, action);
    case GET_REWARD_LETTER_FAIL:
      return getRewardLetterFail(state, action);
    case REDEPLOY_EMPLOYEE_START:
      return redeployEmployeeStart(state);
    case REDEPLOY_EMPLOYEE_SUCCESS:
      return redeployEmployeeSuccess(state, action);
    case REDEPLOY_EMPLOYEE_FAIL:
      return redeployEmployeeFail(state, action);
  

    default:
      return {
        ...state,
      };
  }
};
export default confirmEmployeeReducer;
import styled from 'styled-components';

export const StepContainer = styled.div`
justify-content: center;

  @media screen and (max-width:997px){
    justify-content: unset;
    overflow-x: scroll;
  }
`;

export const GET_COMPANIES_REQUEST = 'GET_COMPANIES_REQUEST';
export const GET_COMPANIES_SUCCESS = 'GET_COMPANIES_SUCCESS';
export const GET_COMPANIES_FAILURE = 'GET_COMPANIES_FAILURE';

export const ADD_COMPANY_SUCCESS = 'ADD_COMPANY_SUCCESS';
export const ADD_COMPANY_FAILURE = 'ADD_COMPANY_FAILURE';
export const ADD_COMPANY_REQUEST = 'ADD_COMPANY';

export const UPDATE_COMPANY_REQUEST = 'EDIT_COMPANY_REQUEST';
export const UPDATE_COMPANY_SUCCESS = 'EDIT_COMPANY_SUCCESS';
export const UPDATE_COMPANY_FAILURE = 'EDIT_COMPANY_FAILURE';

export const DELETE_COMPANY_REQUEST = 'DELETE_COMPANY_REQUEST';
export const DELETE_COMPANY_SUCCESS = 'DELETE_COMPANY_SUCCESS';
export const DELETE_COMPANY_FAILURE = 'DELETE_COMPANY_FAILURE';

export const BULK_ADD_COMPANIES_REQUEST = 'BULK_ADD_COMPANIES_REQUEST';
export const BULK_ADD_COMPANIES_SUCCESS = 'BULK_ADD_COMPANIES_SUCCESS';
export const BULK_ADD_COMPANIES_FAILURE = 'BULK_ADD_COMPAINIES_FAILURE';

export const SEARCH_COMPANIES_REQUEST = 'SEARCH_COMPANIES_REQUEST';
export const SEARCH_COMPANIES_SUCCESS = 'SEARCH_COMPANIES_SUCCESS';
export const SEARCH_COMPANIES_FAILURE = 'SEARCH_COMPAINIES_FAILURE';

export const GET_ALL_COMPANY_DEPARTMENTS_REQUEST = 'GET_ALL_COMPANY_DEPARTMENTS_REQUEST';
export const GET_ALL_COMPANY_DEPARTMENTS_SUCCESS = 'GET_ALL_COMPANY_DEPARTMENTS_SUCCESS';
export const GET_ALL_COMPANY_DEPARTMENTS_FAILURE = 'GET_ALL_COMPANY_DEPARTMENTS_FAILURE';

export const GET_ALL_COMPANY_JOBROLES_REQUEST = 'GET_ALL_COMPANY_JOBROLES_REQUEST';
export const GET_ALL_COMPANY_JOBROLES_SUCCESS = 'GET_ALL_COMPANY_JOBROLES_SUCCESS';
export const GET_ALL_COMPANY_JOBROLES_FAILURE = 'GET_ALL_COMPANY_JOBROLES_FAILURE';

export const GET_ALL_COMPANY_BRANCHES_REQUEST = 'GET_ALL_COMPANY_BRANCHES_REQUEST';
export const GET_ALL_COMPANY_BRANCHES_SUCCESS = 'GET_ALL_COMPANY_BRANCHES_SUCCESS';
export const GET_ALL_COMPANY_BRANCHES_FAILURE = 'GET_ALL_COMPANY_BRANCHES_FAILURE';




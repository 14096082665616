import React from 'react';
import Modal from 'react-bootstrap/Modal';
import { Spinner } from 'react-bootstrap';
import { ModalFooter, ModalButton } from './styles';

const AppModal = ({
  show,
  close,
  className,
  header,
  title,
  component,
  children,
  singleAction,
  prevProps = {},
  nextProps = {},
  previousText,
  actionText,
  singleActionText,
  previousType,
  actionType,
  previous,
  action,
  loading = false,
  spinnerVariant = 'light',
  hideFooter,
  customStyles={},
}) => {
  return (
    <>
      <Modal
        style={{ borderRadius: '10px', ...customStyles }}
        show={show}
        onHide={close}
        animation={false}
        className={className}
      >
        {header && (
          <Modal.Header
            closeButton
            style={{
              boxShadow: '0px 4px 20px rgba(199, 223, 241, 0.25',
            }}
          >
            <b>{title}</b>
          </Modal.Header>
        )}
        {component || children}
        <Modal.Footer style={{ display: hideFooter ? 'none' : 'inital' }}>
          {singleAction ? (
            <ModalButton onClick={close || singleAction}>
              <br />
              {singleActionText || 'Close'}
            </ModalButton>
          ) : (
            <ModalFooter hideFooter={hideFooter}>
              <ModalButton
                {...prevProps}
                type={previousType || 'button'}
                onClick={previous}
              >
                {previousText || 'Cancel'}
              </ModalButton>
              <ModalButton
                {...nextProps}
                type={actionType || 'button'}
                action={'action'}
                onClick={action}
                disabled={loading}
              >
                
                {loading ? (
                  <Spinner animation="border" variant={spinnerVariant} />
                ) : (
                  actionText || 'Save'
                )}
              </ModalButton>
            </ModalFooter>
          )}
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default AppModal;

import {
  LOGIN_USER_START,
  LOGIN_USER_ERROR,
  LOGIN_USER_SUCCESS,
  FORGOT_PASSWORD_START,
  FORGOT_PASSWORD_ERROR,
  FORGOT_PASSWORD_SUCCESS,
  UPDATE_PASSWORD_START,
  UPDATE_PASSWORD_ERROR,
  UPDATE_PASSWORD_SUCCESS,
  GET_ALL_USERS,
  GET_NOTIFICATION_REQUEST,
  GET_NOTIFICATION_SUCCESS,
  GET_NOTIFICATION_FAILURE,
  READ_NOTIFICATION_REQUEST,
  READ_NOTIFICATION_SUCCESS,
  READ_NOTIFICATION_FAILURE,
  TOGGLE_RESPONSIVE_MENUBAR_REQUEST
} from './../actionTypes/user';

const initialState = {
  loading: false,
  data: {},
  loginSuccess: false,
  errorMessage: '',
  error: false,
  forgotPasswordSuccess: false,
  alluser: [],
  notificationData: {},
  mobileMenu : null
};

const user = (state = initialState, action) => {
  switch (action.type) {
    case LOGIN_USER_START:
      return {
        ...state,
        loading: true,
      };
    case LOGIN_USER_SUCCESS:
      return {
        ...state,
        loading: false,
        loginSuccess: true,
        data: action.payload,
      };
    case LOGIN_USER_ERROR:
      return {
        ...state,
        loading: false,
        loginSuccess: false,
        error: true,
      };
    case FORGOT_PASSWORD_START:
      return {
        ...state,
        loading: true,
      };
    case FORGOT_PASSWORD_SUCCESS:
      return {
        ...state,
        loading: false,
        forgotPasswordSuccess: true,
      };
    case FORGOT_PASSWORD_ERROR:
      return {
        ...state,
        loading: false,
        forgotPasswordSuccess: false,
        error: true,
      };
    case UPDATE_PASSWORD_START:
      return {
        ...state,
        loading: true,
      };
    case UPDATE_PASSWORD_SUCCESS:
      return {
        ...state,
        loading: false,
        updatePasswordSuccess: true,
      };
    case UPDATE_PASSWORD_ERROR:
      return {
        ...state,
        loading: false,
        updatePasswordSuccess: false,
        error: true,
      };
    case 'USER_INIT_STATE':
      return {
        ...state,
        forgotPasswordSuccess: false,
        updatePasswordSuccess: false,
        errorMessage: '',
        error: false,
      };

    case GET_ALL_USERS:
      return {
        ...state,
        alluser: action.payload,
      };

    case GET_NOTIFICATION_REQUEST:
      return {
        ...state,
        loading: true,
        success: false,
      };

    case GET_NOTIFICATION_FAILURE:
      return {
        ...state,
        loading: false,
        success: false,
        error: action.payload,
      };

    case GET_NOTIFICATION_SUCCESS:
      return {
        ...state,
        loading: false,
        success: false,
        notificationData: action.payload,
      };

    case READ_NOTIFICATION_REQUEST:
      return {
        ...state,
        loading: true,
      };

    case READ_NOTIFICATION_SUCCESS:
      return {
        ...state,
        loading: false,
        data: action.payload,
      };

    case READ_NOTIFICATION_FAILURE:
      return {
        ...state,
        loading: false,
        error: true,
      };
    case TOGGLE_RESPONSIVE_MENUBAR_REQUEST:
      return {
        ...state,
        mobileMenu:action.payload
      }  

    default:
      return {
        ...state,
      };
  }
};

export default user;

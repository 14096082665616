import React, { useCallback, useEffect, useState } from 'react';
import { Row } from 'react-bootstrap';
import { NavLink } from 'react-router-dom';
import { updatePassword } from 'redux/actions/user';
import { createNewPasswordSchema } from 'utils/validators';
import { useDispatch } from 'react-redux';
import { useFormik } from 'formik';
import { svgs } from '../../assets';
import AuthSidebar from '../../components/Auth/Sidebar';
import Input from '../../components/common/Input';
import Button from '../../components/common/Button';
import { toast } from 'react-toastify';
import { AuthPageContainer, SigninArea, Logo, SigninFormArea } from './styles';
import { verifyUser, forgotPassword } from 'redux/actions/user';



const SetNewPassword = ({ match, history }) => {
  const dispatch = useDispatch();
  const { token } = match.params;
  const [loading, setLoading] = useState(false);
  const [useremail, setEmail] = useState(null);
  const [usedToken, setusedToken] = useState(false);


  const verifyToken = useCallback(async () => {
    setLoading(true);
    try {
       const result = await dispatch(verifyUser({ token }));
      setLoading(false);
      if (result?.payload?.status === 401) {
        toast.error('error');
        return;
      }
      if (result?.payload?.status === 400) {
        toast.error(result?.payload?.data?.errors);
        setusedToken(true)
        return;
      }
      if (result?.payload?.status === 200 || result?.payload?.success) {
        setEmail(result?.payload?.data?.email)
        return;
      }
    } catch (error) {
      setLoading(false);
    }
  }, [dispatch, token]);


  
  useEffect(() => {
    verifyToken();
  }, [verifyToken]);


  // const passToken = token.split(':')[1];
  const changePassword = async (values) => {
    const response = await dispatch(updatePassword(token, values.password));
    if (!response.error) {
      return history.push('/password-success');
    }
    setLoading(false);
    toast.error(response.error);
  };

  const { handleSubmit, values, errors, touched, handleBlur, handleChange } = useFormik({
    initialValues: {
      password: '',
      confirmPassword: '',
    },
    onSubmit: changePassword,
    validationSchema: createNewPasswordSchema,
    enableReinitialize: true,
  });


  const resendEmail = async () => {
    if (!useremail) {
      toast.error('Email is required!');
    } else {
      try {
        const response = await dispatch(forgotPassword(useremail));
        if (response.payload.status === 200) {
          history.push('/mail-sent');
        } else {
          toast.error(response.payload.data.message);
          dispatch({
            type: 'USER_INIT_STATE',
          });
        }
      } catch (e) {
        toast.error(e.response.data.message);

      }
    }
  };

  return (
    <AuthPageContainer fluid>
      <Row>
        <AuthSidebar />
        <SigninArea lg={8} md={8}>
        <Logo src={svgs.logo_colored} />
          <SigninFormArea lg={4} md={6} sm={12}>
            <p className="title">Create a password</p>
            {usedToken &&
              <form>
                  <span className="link-desc">
              Token problems? {' '}
              <NavLink to="/forgot-password"> Click to Get a New Link</NavLink>
              </span>

              </form>
            
            }

            {!usedToken &&
            <form>
         
              <Input
                type="password"
                name="password"
                label="New Password"
                placeholder="Password"
                required
                onChange={handleChange}
                error={touched.password && errors.password}
                onBlur={handleBlur}
                value={values.password}
              />

              <Input
                type="password"
                name="confirmPassword"
                label="Re-enter Password"
                placeholder="Re-enter Password"
                required
                error={touched.confirmPassword && errors.confirmPassword}
                onBlur={handleBlur}
                value={values.confirmPassword}
                onChange={handleChange}
              />
              <div className="button-wrapper">
                <Button
                  type="button"
                  text={loading ? 'Loading...' : 'Continue'}
                  onClick={handleSubmit}
                />
              </div>

<p></p>
              <span className="link-desc">
              Not working?
                {' '}
                <span onClick={resendEmail} style={{
                  cursor:'pointer',
                  marginTop: '15px',
                  color: '#1a21ba',
                  fontWeight: 'bold',
                  textDecoration: 'underline',
                }}> Request a New Link</span>
              </span>

              <span className="link-desc">
                Already Have an account?
                {' '}
                <NavLink to="/signin"> Log in</NavLink>
              </span>
            </form>
}
          </SigninFormArea>
        </SigninArea>
      </Row>
    </AuthPageContainer>
  );
};

export default SetNewPassword;

import React from 'react';
import PageContainer from 'components/common/PageContainer';
import { Wrapper, FormContainer } from 'pages/Candidate/Offer/styles';
import { svgs } from 'assets';

export const Success = () => (
  <PageContainer>
    <FormContainer>
      <div className="logo-wrapper">
      <svgs.COLOURED_LOGO width="200px" height="100px" />
      </div>
      <Wrapper>
        <div className="content status-wrapper">
          <div className="logo-wrapper">
            <svgs.CHECK_MARK_ROUNDED height="70px" />
          </div>
          <p className="header">Letters uploaded successfully</p>
          <p className="note">We’ll review the letters and get back to you via email</p>
        </div>
      </Wrapper>
    </FormContainer>
  </PageContainer>
);

import { Col, Container } from 'react-bootstrap';
import styled from 'styled-components';
import { svgs } from '../../assets';

export const AuthPageContainer = styled(Container)`
  height: 100vh;

  div.row {
    height: 100vh;
  }
`;

export const SigninArea = styled(Col)`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;

  a {
    display: block;
    margin-top: 15px;
    color: #1a21ba;
    font-weight: bold;
    text-decoration: underline;
  }

  span.link-desc {
    display: block;
    font-weight: 500;
    font-size: 16px;
    line-height: 140%;
    a {
      display: inline-block;
    }
  }
`;

export const SigninFormArea = styled(Col)`
  h1 {
    margin-top: 30px;
    font-weight: bold;
  }

  p {
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    margin-top: 20px;
    margin-bottom: 30px;
  }

  .title {
    text-align: center;
    font-size: 30px;
  }
  .link-desc {
    text-align: center;
  }
  .button-wrapper {
    display: flex;
    justify-content: center;
  }
`;

export const Logo = styled.img`
  width: 250px;
`;

export const Eye = styled(svgs.EYE)`
  width: 1rem;
  height: 1rem;
`;

export const ShowPassword = styled.div`
  font-weight: 700;
  font-size: 0.9rem;
  position: relative;
  top: -55px;
  right: -90%;
  cursor: pointer;
  width: 0.5rem;
  height: 0.5rem;
`;

export const ContentWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100%;

  .message-title {
    font-style: normal;
    font-weight: bold;
    font-size: 24px;
    line-height: 29px;
    display: flex;
    align-items: center;
    color: #333333;
    margin-top: 34px;
  }

  .message {
    font-family: Inter;
    font-style: normal;
    font-weight: 500;
    font-size: 18px;
    line-height: 150%;
    text-align: center;
    color: #333333;
    width: 30%;
    margin: 30px 0px;
  }
  .button {
    padding: 18px 91px;
  }
`;

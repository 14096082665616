import React, { useState } from 'react';
import { Row } from 'react-bootstrap';
import {
  AuthPageContainer,
  SigninArea,
  Logo,
  SigninFormArea,

} from './styles';
import { svgs } from '../../assets';
import AuthSidebar from '../../components/Auth/Sidebar';
import { NavLink, Redirect } from 'react-router-dom';
import Input from '../../components/common/Input';
import Button from '../../components/common/Button';

import ErrorMessage from 'components/common/ErrorMessage';

import { loginUser } from 'redux/actions/user';
import { useDispatch } from 'react-redux';
import { isAuthenticated } from 'utils/auth';
import { useQuery} from 'utils/useQuery';

const Signin = ({ history }) => {
  const query = useQuery('pathto');
  const goToPath = query.get('pathto');

  const dispatch = useDispatch();

  const [loading, setLoading] = useState(false);
  const [inputs, setInputs] = useState({
    email: '',
    password: '',
  });

  const [errors, setErrors] = useState('');
  const [loginError, setLoginError] = useState('');

  const inputHandler = (e) => {
    e.persist();
    setInputs((inputs) => ({
      ...inputs,
      [e.target.name]: e.target.value,
    }));
  };

  const login = async (e) => {
    setErrors('');
    setLoginError('');
    if (!inputs.password || !inputs.email) {
      return setLoginError('Email and Password is required!');
    }
    try {
      setLoading(true);
      const response = await dispatch(loginUser(inputs));

      if (response.payload.status === 200) {
        history.push(goToPath || '/home');
      } else {
        setLoginError(response.payload.data.detail);
        dispatch({
          type: 'USER_INIT_STATE',
        });
      }
    } catch (e) {
      setLoginError('Something went wrong. Try again or check connection');
    }
    setLoading(false);
  };

  const checkisAuthenticated = isAuthenticated();
  return (
    <AuthPageContainer fluid>
      {checkisAuthenticated ? (
        <Redirect to={'/home'} />
      ) : (
        
        <Row>
          <AuthSidebar />
          <SigninArea lg={8} md={8}>
            <Logo src={svgs.logo_colored} />
            <SigninFormArea lg={4} md={6} sm={12} className="text-center">
              <h1>Welcome Back</h1>
              <p>Please Login to Continue</p>
              <ErrorMessage message={loginError} position="center" />
              <>
                <Input
                  type="email"
                  label="Email Address"
                  name="email"
                  placeholder="example@example.com"
                  required={true}
                  onChange={inputHandler}
                  value={inputs.email}
                />

                {errors && errors.path === 'email' ? (
                  <ErrorMessage message={errors.message} position="left" />
                ) : (
                  <></>
                )}
                <Input
                  type={'password'}
                  label="Password"
                  name="password"
                  placeholder="Password"
                  required={true}
                  onChange={inputHandler}
                  value={inputs.password}
                />

                {errors && errors.path === 'password' ? (
                  <ErrorMessage message={errors.message} position="left" />
                ) : (
                  <></>
                )}

                <Button
                  text={loading ? 'Logging in...' : 'Continue'}
                  onClick={login}
                  disabled={loading ? true : false}
                />

                <NavLink to="/forgot-password">Forgot Password?</NavLink>
              </>
            </SigninFormArea>
          </SigninArea>
        </Row>
      )}
    </AuthPageContainer>
  );
};

export default Signin;

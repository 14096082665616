export const GET_EMPLOYEE_DOCUMENT_START = 'GET_EMPLOYEE_DOCUMENT_START';
export const GET_EMPLOYEE_DOCUMENT_SUCCESS = 'GET_EMPLOYEE_DOCUMENT_SUCCESS';
export const GET_EMPLOYEE_DOCUMENT_FAIL = 'GET_EMPLOYEE_DOCUMENT_FAIL';

export const UPLOAD_EMPLOYEE_DOCUMENT_START = 'UPLOAD_EMPLOYEE_DOCUMENT_START';
export const UPLOAD_EMPLOYEE_DOCUMENT_SUCCESS = 'UPLOAD_EMPLOYEE_DOCUMENT_SUCCESS';
export const UPLOAD_EMPLOYEE_DOCUMENT_FAIL = 'UPLOAD_EMPLOYEE_DOCUMENT_FAIL';

export const VERIFY_EMPLOYEE_DOCUMENT_START = 'VERIFY_EMPLOYEE_DOCUMENT_START';
export const VERIFY_EMPLOYEE_DOCUMENT_SUCCESS = 'VERIFY_EMPLOYEE_DOCUMENT_SUCCESS';
export const VERIFY_EMPLOYEE_DOCUMENT_FAIL = 'VERIFY_EMPLOYEE_DOCUMENT_FAIL';

export const DELETE_EMPLOYEE_DOCUMENT_START = 'DELETE_EMPLOYEE_DOCUMENT_START';
export const DELETE_EMPLOYEE_DOCUMENT_SUCCESS = 'DELETE_EMPLOYEE_DOCUMENT_SUCCESS';
export const DELETE_EMPLOYEE_DOCUMENT_FAIL = 'DELETE_EMPLOYEE_DOCUMENT_FAIL';

export const CHANGE_REQUEST_START = 'CHANGE_REQUEST_START';
export const CHANGE_REQUEST_SUCCESS = 'CHANGE_REQUEST_SUCCESS';
export const CHANGE_REQUEST_FAIL = 'CHANGE_REQUEST_FAIL';

export const GET_CHANGE_REQUEST_START = 'GET_CHANGE_REQUEST_START';
export const GET_CHANGE_REQUEST_SUCCESS = 'GET_CHANGE_REQUEST_SUCCESS';
export const GET_CHANGE_REQUEST_FAIL = 'GET_CHANGE_REQUEST_FAIL';

export const GET_EMPLOYEE_DOCUMENT_TYPE_LIST_REQUEST =
  'GET_EMPLOYEE_DOCUMENT_TYPE_LIST_REQUEST';
export const GET_EMPLOYEE_DOCUMENT_TYPE_LIST_SUCCESS =
  'GET_EMPLOYEE_DOCUMENT_TYPE_LIST_SUCCESS';
export const GET_EMPLOYEE_DOCUMENT_TYPE_LIST_FAILURE =
  'GET_EMPLOYEE_DOCUMENT_TYPE_LIST_FAILURE';

export const CHANGE_REQUEST_ALL_START = 'CHANGE_REQUEST_ALL_START';
export const GET_UPGRADE_LETTER = 'GET_UPGRADE_LETTER';
export const CHANGE_REQUEST_ALL_SUCCESS = 'CHANGE_REQUEST_ALL_SUCCESS';
export const CHANGE_REQUEST_ALL_FAIL = 'CHANGE_REQUEST_ALL_FAIL';
export const ALL_UPGRADED_LETTERS_START = 'ALL_UPGRADED_LETTERS_START';
export const ALL_UPGRADED_LETTERS_SUCCESS = 'ALL_UPGRADED_LETTERS_SUCCESS';
export const ALL_UPGRADED_LETTERS_FAIL = 'ALL_UPGRADED_LETTERS_FAIL';

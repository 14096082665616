import React from 'react';
import { Text } from 'components/common/Styles';
import { svgs } from 'assets';
import { Container, Modal, ModalHeader, ModalContent, ModalFooter } from './styles';

const SuccessModal = ({
  show,
  toggle,
  title,
  prompt,
  subtext,
  ...props
}) => {
  return !show ? null : (
    <Container>
      <Modal>
        <ModalHeader>
          <Text style={{ width: 'fit-content' }} weight="700" color="#333333">
            {title}
          </Text>
          <div
            className="close-icon"
            role="button"
            onClick={toggle}
            tabIndex="0"
            onKeyPress={toggle}
          >
            <img src={svgs.close_button} alt="close icon" />
          </div>
        </ModalHeader>
        <ModalContent style={{ display: 'flex', alignItems: 'center' }}>
          <img width="64px" height="64px" src={svgs.CHECK_ICON} alt="successful checkmark" />
          <Text weight="700" margin="0px 0px 15px 0px">
            {prompt}
          </Text>
          <Text style={{ margin: '0 auto' }} size="14px" color="#666666">
            {subtext}
          </Text>
        </ModalContent>
        <ModalFooter>
          <button
            style={{ border: 'none' }}
            type="button"
            onClick={toggle}
          >
            Close
          </button>
        </ModalFooter>
      </Modal>
    </Container>
  );
};

export default SuccessModal;

/* eslint-disable function-paren-newline */
/* eslint-disable comma-dangle */
/* eslint-disable implicit-arrow-linebreak */
import { lazy } from 'react';

const PensionSettings = lazy(() => import('pages/Settings/Pension'));
const BankSettings = lazy(() => import('pages/Settings/Banks'));
const SignatureSettings = lazy(() => import('pages/Settings/Signature'));
const Templates = lazy(() => import('pages/Onboarding/Templates'));
const Letters = lazy(() => import('pages/Settings/Letters'));
const SentLetters = lazy(() => import('pages/Settings/Letters/SentLetter'));
const DeploymentLetter = lazy(() => import('pages/Settings/Letters/DeploymentEditor'));
const OfferEditor = lazy(() => import('pages/Settings/Letters/OfferEditor'));
const Details = lazy(() => import('pages/Onboarding/OnboardEmployee/Details'));
const Unit = lazy(() => import('pages/Company/Units'));
const JobRoles = lazy(() => import('pages/Company/JobRoles'));
const OnboardingWorkflow = lazy(() => import('pages/Onboarding/Workflow'));
const SendDeployment = lazy(() => import('pages/Settings/Letters/SendDeployment'));
const WorkflowActivity = lazy(() => import('pages/Onboarding/Workflow/Activities'));
const OnboardingSettings = lazy(() => import('pages/Onboarding/Settings'));
const CandidateOnboarding = lazy(() => import('pages/Candidates/Onboarding'));
const SendOffer = lazy(() => import('pages/Settings/Letters/SendLetter'));
const Workflow = lazy(() => import('pages/Onboarding/Dashboard/Workflow'));
const EmployeeSettings = lazy(() => import('pages/Settings/Employee'));
const ConfirmationSettings = lazy(() => import('pages/Settings/Confirmation'));
const CreatePolicy = lazy(() =>
  import('pages/Settings/Confirmation/ConfirmationPolicy/CreatePolicy')
);
const EditPolicy = lazy(() =>
  import('pages/Settings/Confirmation/ConfirmationPolicy/EditPolicy')
);
const Approval = lazy(() => import('pages/ApprovalWorkflow'));
const CreateApprovalWorkflow = lazy(() =>
  import('pages/ApprovalWorkflow/CreateNewWorkflow')
);
const LeaveAndHolliday = lazy(() => import('pages/LeaveAndHolliday'));
const LeaveDetails = lazy(() => import('pages/LeaveAndHolliday/components/LeaveDetails'));
const LeaveSettings = lazy(() =>
  import('pages/LeaveAndHolliday/components/LeaveSettings')
);
const AddLeaveSetting = lazy(() => import('pages/LeaveAndHolliday/LeaveSettings/Add'));
const Announcement = lazy(() => import('pages/Employee/Announcement/'));
const CreateOrEditAnnouncement = lazy(() =>
  import('pages/Employee/Announcement/CreateOrEdit')
);
const EmployeeWelfare = lazy(() => import('pages/Employee/Welfare/Dashboard'));
const Exit = lazy(() => import('pages/ExitManagement'));
const SendExitLetter = lazy(() =>
  import('pages/ExitManagement/components/SendExitLetter')
);
const SingleRequest = lazy(() => import('pages/ExitManagement/components/SingleRequest'));
const ExitSettings = lazy(() => import('pages/ExitManagement/Settings'));
const Disciplinary = lazy(() => import('pages/Admin/Disciplinary'));
const Company = lazy(() => import('pages/Company'));
const CandidateDetails = lazy(() => import('pages/Onboarding/Candidates/Details'));
const Candidates = lazy(() => import('pages/Onboarding/Candidates/'));
const OnboardEmployee = lazy(() => import('pages/Onboarding/OnboardEmployee/'));
const Dashboard = lazy(() => import('pages/Onboarding/Dashboard'));
const Roles = lazy(() => import('pages/Settings/Roles'));
const Settings = lazy(() => import('pages/Settings'));
const Companies = lazy(() => import('pages/Companies'));
const Profile = lazy(() => import('pages/Employee'));
const EmployeeList = lazy(() => import('pages/Employee/index2'));
const HeadCountAdmin = lazy(() => import('pages/HeadCount'));
const Home = lazy(() => import('pages/Home'));

const PerformanceMangement = lazy(() => import('pages/PerformanceManagement'));
const AdminComplaints = lazy(() => import('pages/Admin/Complaints'));
const ComplaintsCategory = lazy(() => import('pages/Admin/Complaints/Category'));
const ComplaintDetails = lazy(() => import('pages/Admin/Complaints/components/Details'));
const Hmos = lazy(() => import('pages/Admin/Hmo/Hmos'));
const HmosDashboard = lazy(() => import('pages/Admin/Hmo/Dashboard'));
const HmosHome = lazy(() => import('pages/Admin/Hmo'));
const HmosComplaints = lazy(() => import('pages/Admin/Hmo/CompliantType'));
const Appraisal = lazy(() => import('pages/Admin/Appraisal'));
const ChangeRequestList = lazy(() => import('pages/Admin/EmployeeProfileChangeRequest'));
const ImpersonateSettings = lazy(() => import('pages/Admin/Impersonate'));


const adminRoutes = [
  { path: '/employees/request-change', exact: true, component: ChangeRequestList },
  { exact: true, path: '/employees/profile', component: Profile },
  {
    exact: true,
    path: '/employees/manage-employees',
    component: EmployeeList,
  },
  { path: '/employees/:employeeId/', component: EmployeeList },
  { exact: true, path: '/employees/announcement', component: Announcement },
  { exact: true, path: '/companies', component: Companies },
  { exact: true, path: '/companies/payroll', component: Companies },
  { exact: true, path: '/companies/:id', component: Company },
  { exact: true, path: '/tasks', component: Home },
  { path: '/approval', component: Approval },
  { path: '/welfare', component: EmployeeWelfare },
  { exact: true, path: '/disciplinary/management', component: Disciplinary },
  { path: '/disciplinary/:id/', component: Disciplinary },
  { exact: true, path: '/employees/welfare', component: EmployeeWelfare },
  {
    exact: true,
    path: '/approval-workflow/create',
    component: CreateApprovalWorkflow,
  },
  {
    exact: true,
    path: '/approval-workflow/:id',
    component: CreateApprovalWorkflow,
  },

  { exact: true, path: '/settings', component: Settings },
  { exact: true, path: '/settings/manage-admins', component: Settings },
  { exact: true, path: '/settings/roles', component: Roles },
  { path: '/settings/manage-employees/', component: EmployeeSettings },
  { path: '/approval', component: Approval },
  {
    exact: true,
    path: '/settings/manage-employees/job-information',
    component: EmployeeSettings,
  },
  { path: '/leave-management/settings', component: LeaveSettings },
  { path: '/leave-management/newType', component: AddLeaveSetting },
  {
    path: '/leave-management/leave-details/:id',
    component: LeaveDetails,
  },
  { path: '/leave-management', component: LeaveAndHolliday },
  {
    exact: true,
    path: '/approval-workflow/create',
    component: CreateApprovalWorkflow,
  },
  {
    exact: true,
    path: '/approval-workflow/:id',
    component: CreateApprovalWorkflow,
  },

  { exact: true, path: '/onboarding', component: Dashboard },
  { path: '/onboarding/candidates', component: Candidates },
  { path: '/candidates/:id', component: CandidateDetails },
  { path: '/onboarding/employees', component: OnboardEmployee },
  { exact: true, path: '/candidate/send-offer', component: SendOffer },
  {
    exact: true,
    path: '/onboarding/employee/:employeeId/:candidateId',
    component: Details,
  },
  { exact: true, path: '/onboarding/dashboard', component: Dashboard },
  {
    exact: true,
    path: '/onboarding/settings',
    component: OnboardingSettings,
  },
  { exact: true, path: '/onboarding/templates', component: Templates },
  { exact: true, path: '/settings/letters', component: Letters },
  { exact: true, path: '/settings/letters/sent', component: SentLetters },
  {
    exact: true,
    path: '/settings/create/deployment',
    component: DeploymentLetter,
  },
  {
    exact: false,
    path: '/settings/manage-pension',
    component: PensionSettings,
  },
  {
    exact: false,
    path: '/settings/manage-banks',
    component: BankSettings,
  },
  {
    exact: false,
    path: '/settings/impersonate',
    component: ImpersonateSettings,
  },
  {
    exact: false,
    path: '/settings/manage-signature',
    component: SignatureSettings,
  },
  {
    exact: true,
    path: '/settings/edit/deployment/:id',
    component: DeploymentLetter,
  },
  {
    exact: true,
    path: '/candidate/send-deployment',
    component: SendDeployment,
  },
  {
    exact: true,
    path: '/onboarding/workflow',
    component: OnboardingWorkflow,
  },

  {
    exact: true,
    path: '/onboarding/workflow/:id',
    component: WorkflowActivity,
  },

  { exact: true, path: '/department/:id/units', component: Unit },
  {
    exact: true,
    path: '/candidate/onboarding',
    component: CandidateOnboarding,
  },

  { exact: true, path: '/department/:id/roles', component: JobRoles },
  {
    exact: true,
    path: '/settings/create/:category/:title/:type',
    component: OfferEditor,
  },
  {
    exact: true,
    path: '/settings/edit/:category/:title/:type',
    component: OfferEditor,
  },

  { exact: true, path: '/settings/edit/offer/:id', component: OfferEditor },
  { exact: true, path: '/initiate-onboarding', component: Workflow },

  { exact: true, path: '/candidate/send-offer/:send_status', component: SendOffer },

  { path: '/employee-Settings', component: ConfirmationSettings },
  {
    path: '/settings/confirmation-settings',
    component: ConfirmationSettings,
  },
  {
    path: '/confirmation-settings/create-policy',
    component: CreatePolicy,
  },
  {
    path: '/confirmation-settings/edit-policy/:id',
    component: EditPolicy,
  },
  {
    exact: true,
    path: '/employee/announcement/create',
    component: CreateOrEditAnnouncement,
  },
  {
    exact: true,
    path: '/employee/announcement/edit/:id',
    component: CreateOrEditAnnouncement,
  },

  { exact: true, path: '/exit/send-letter', component: SendExitLetter },

  { exact: true, path: '/exit/single-request/:id', component: SingleRequest },

  { path: '/exit/overview', component: Exit },

  { path: '/exit/settings', component: ExitSettings },

  { path: '/headcount', component: HeadCountAdmin },

  { path: '/performance', component: PerformanceMangement },

  { path: '/complaints/dashboard', component: AdminComplaints },
  { path: '/complaints/category', component: ComplaintsCategory },
  { path: '/complaints/details', component: ComplaintDetails },
  { path: '/hmo/dashboard', component: HmosDashboard },
  { path: '/hmo/hmos', component: Hmos },
  { path: '/hmo', component: HmosHome },
  { path: '/hmo/complaints/:type', component: HmosComplaints },
  { path: '/appraisal', component: Appraisal },
];

export default adminRoutes;

/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useRef, useCallback } from 'react';
import { svgs } from 'assets';
import { useClickOutside } from 'hooks/useClickOutside';
import SearchBar from 'components/common/SearchBar';
import Checkbox from 'components/common/Checkbox/Checkbox';
import { Select, FormGroup } from '../Styles';
import {
  SelectedItem,
  SelectedItemInput,
  SelectArrowDown,
  DropDown,
  CheckboxItem,
  SelectDropDownFooter,
} from './select.styles.';

const SelectDropdown = ({ label, required, type, children, error, value, ...props }) => (
  <FormGroup>
    <label>{label}</label>
    <div style={{ position: 'relative' }}>
      <Select name={props.name} value={value || props.value} {...props}>
        {children}
      </Select>
      <img className="select-icon" src={svgs.SELECT_DOWN} alt="select dropdown" />
      {error && <p className="error">{error}</p>}
    </div>
  </FormGroup>
);

export const SelectDropdownWithCheckbox = ({
  label,
  required,
  type,
  children,
  error,
  value,
  placeholder,
  data = [],
  pathToRender = 'name',
  onSelect = () => {},
  selectedItems = [],
  errorMessage,
  searchQuery,
  searchBarPlaceHolder,
  onSearch = () => {},
  currentPage = 0,
  totalNumberOfPages = 0,
  onNextClick = () => {},
  onPreviousClick = () => {},
  showFooter,
  showSearch,
}) => {
  const [showDropDown, setShowDropDown] = useState(false);
  const toggleDropwDown = () => setShowDropDown((prevState) => !prevState);

  const dropDownRef = useRef(null);
  useClickOutside(
    dropDownRef,
    // eslint-disable-next-line
    useCallback(() => setShowDropDown(false), [showDropDown]),
    showDropDown
  );

  const handleSelectItem = (selectedItem) => () => {
    onSelect(selectedItem.id);
  };

  const getTitle = () => {
    if (selectedItems?.length > 1) {
      return 'Multiple Selected';
    }

    if (selectedItems.length === 1) {
      const selectedObject = data.find((item) => item?.id === selectedItems[0]);

      return selectedObject ? selectedObject[pathToRender] : '';
    }
    return placeholder;
  };

  const title = selectedItems?.length > 0 ? getTitle() : '';

  return (
    <FormGroup ref={dropDownRef}>
      <label>{label}</label>
      <SelectedItem onClick={toggleDropwDown}>
        <SelectedItemInput placeholder={placeholder} value={title} disabled />
        <SelectArrowDown
          src={svgs.SELECT_DOWN}
          alt="select dropdown"
          showDropDown={showDropDown}
        />
      </SelectedItem>

      <DropDown showDropDown={showDropDown}>
        {showSearch && (
          <SearchBar
            placeHolderText={searchBarPlaceHolder}
            searchParam={searchQuery}
            setSearchParam={onSearch}
          />
        )}
        {data?.map((item, index) => (
          <CheckboxItem
            key={index}
            onClick={handleSelectItem(item)}
            isChecked={selectedItems?.includes(item.id)}
          >
            <Checkbox isChecked={selectedItems?.includes(item.id)} />
            <p>{item[pathToRender]}</p>
          </CheckboxItem>
        ))}
        {showFooter && (
          <SelectDropDownFooter>
            <p onClick={onNextClick}>{'<'}</p>
            <p>
              <span>{currentPage ?? 0}</span>
              of
              <span>{totalNumberOfPages ?? 0}</span>
            </p>
            <p onClick={onPreviousClick}>{'>'}</p>
          </SelectDropDownFooter>
        )}
      </DropDown>
      <p className="error">{errorMessage}</p>
    </FormGroup>
  );
};

export default SelectDropdown;

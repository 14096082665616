import styled from 'styled-components';
import Modal from 'react-bootstrap/Modal';
import { PRIMARY_COLOR, RED_COLOR } from 'assets/colors';

//*****view-details******************************************

//***************************************************

export const WelfareHeader = styled.div.attrs((props) => ({
  className: props.className,
}))`
   {
    margin-top: 85px;
    background-color: #fff;
    padding: 20px 140px 0 180px;
    box-shadow: 0px 1px 2px rgba(199, 223, 241, 0.25);
    position: fixed;
    left: 250px;
    right: 0;
    z-index: 10;
    top: 0;
  }

  .topnav {
    padding: 12px 16px 0px;
    display: flex;
    align-items: center;
    justify-content: space-around;
  }

  .topnav .nav_links-1 {
    border-bottom: 3px solid transparent;
    font-family: Inter;
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 140%;
    color: #333333;
    text-transform: capitalize;
    cursor: pointer;
    padding-bottom: 7px;
  }
  .topnav a {
    text-decoration: none;
    outline: none;
    position: relative;
    top: -7px;
  }

  .topnav.upcoming a {
    padding: 0 15px;
  }
  .topnav.upcoming {
    justify-content: center;
  }
  .topnav .nav_links-1:hover {
    border-bottom: 3px solid #1a21ba;
  }
  .topnav .nav_links-1.active {
    border-bottom: 3px solid #1a21ba;
    color: #1a21ba;
    box-shadow: 10px 0 0 0 #fff;
    border-right: 2px solid #fff;
    border-left: 2px solid #fff;
  }
  .welfare-notifications {
    display: inline;
    background: #deebff;
    border-radius: 100px;
    text-align: center;
    width: 24px !important;
    height: 22px;
    padding: 3px 6px 5px !important;
    font-family: Inter;
    font-style: normal;
    font-weight: 500;
    font-size: 12px !important;
    line-height: 14px;
    text-transform: capitalize;
    color: #1a21ba;
  }
`;

/*****welfaredata page*******************************

***************************************************/

export const WelfarePageContainer = styled.div.attrs((props) => ({
  className: props.className,
}))`
   {
    margin: 150px 50px 0px;
  }
`;

/*****welfaredata page*******************************

***************************************************/

export const WelfareTemplateContainer = styled.div.attrs((props) => ({
  className: props.className,
}))`
   {
    margin: 160px 0px 0px;
  }
`;

/*****welfareAction page*******************************

***************************************************/

export const WelfareAction = styled.div`
  margin-top: 70px;
  display: grid;
  grid-template-columns: auto 1fr;
  padding: 15px 0px;
  align-items: center;

  div.left-items {
    margin: 0 15px;
  }

  div.right-items {
    display: grid;
    grid-template-columns: auto auto;
    justify-content: flex-end;
  }
`;

/*****card*******************************

***************************************************/

export const CardWrapper = styled.div.attrs((props) => ({
  className: props.className,
}))`
  background: #ffffff;
  box-shadow: 0px 4px 4px rgba(199, 223, 241, 0.25);
  border-radius: 5px;
  width: 350px;
  height: 133px;
  padding: 20px 15px;
  .card-flex {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  .title p {
    font-family: Inter;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 16px;
    text-transform: capitalize;
    color: #333333;
  }

  .title h2 {
    padding-top: 20px;
    font-family: Inter;
    font-style: normal;
    font-weight: 600;
    font-size: 36px;
    line-height: 44px;
    text-transform: capitalize;
  }
  .title-right-side svg {
    position: relative;
    top: -10px;
  }
`;

/*****card*******************************

***************************************************/

export const NotificationRowWrapper = styled.div.attrs((props) => ({
  className: props.className,
}))`
  padding: 10px 25px 10px;
  display: flex;
  justify-content: space-between;
  .notification-img {
    width: 36px;
    height: 36px;
    object-fit: cover;
    border-radius: 50%;
  }
  .name {
    font-family: Inter;
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 16px;
    color: #333333;
    padding-left: 10px;
    display: inline-block;
  }

  .date {
    font-family: Inter;
    font-style: normal;
    font-weight: normal;
    font-size: 12px;
    line-height: 12px;
    color: #999999;
    padding-left: 10px;
  }

  .inner-text {
    font-family: Inter;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 11px;
    color: #999999;
    padding-left: 45px;
  }

  .btn-text {
    background: #dfdfe3;
    border-radius: 3px;
    display: inline-block;
    padding: 7px 10px;
    font-family: Inter;
    font-style: normal;
    font-weight: 500;
    font-size: 12px;
    line-height: 12px;
    color: #333333;
    margin-top: 10px;
  }

  .btn-text-PENDING {
    background: #dfdfe3;
    border-radius: 3px;
    display: inline-block;
    padding: 7px 10px;
    font-family: Inter;
    font-style: normal;
    font-weight: 500;
    font-size: 12px;
    line-height: 12px;
    color: #1a21ba;
    margin-top: 10px;
  }

  .btn-text-APPROVED {
    background: #dfffde;
    border-radius: 3px;
    display: inline-block;
    padding: 7px 10px;
    font-family: Inter;
    font-style: normal;
    font-weight: 500;
    font-size: 12px;
    line-height: 12px;
    color: #15a412;
    margin-top: 10px;
  }

  .inner-btn button {
    background: #ffffff;
    border: 1px solid #d9d9d9;
    box-sizing: border-box;
    box-shadow: 0px 4px 4px rgba(199, 223, 241, 0.25);
    border-radius: 5px;
    font-family: Inter;
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 14px;
    color: #333333;
    padding: 10px 15px;
    margin-top: 20px;
  }
`;

/*NotificationBackground*************************
  
  ***************************************************/
export const NotificationBackground = styled.div.attrs((props) => ({
  className: props.className,
}))`
  background: #ffffff;
  box-shadow: 0px 4px 4px rgba(199, 223, 241, 0.25);
  border-radius: 5px;
  padding-bottom: 100px;
  margin-bottom: 20px;
  .flex-notifications {
    display: flex;
    justify-content: space-between;
    padding: 20px 25px 0px;
  }

  .flex-notifications p {
    font-family: Inter;
    font-style: normal;
    font-weight: bold;
    font-size: 16px;
    line-height: 16px;
    color: #333333;
  }

  .flex-notifications a p {
    font-family: Inter;
    font-style: normal;
    font-weight: 500;
    font-size: 14px !important;
    line-height: 17px;
    letter-spacing: -0.5px;
    color: #1a21ba !important;
  }

  .hr-col121 {
    margin-top: 0rem !important;
    margin-bottom: 0rem !important;
    border-top-color: #f2f2f2 !important;
  }
  .col121 {
    padding-top: 10px;
    // background: #EDEEFE;
  }
  .pad-bottom {
    padding-bottom: 15px;
  }
`;

export const Card = styled.div.attrs((props) => ({
  className: props.className,
}))`
   {
    margin: 100px 0px 0px 40px;
  }
  .card-wrap.col-4 {
    flex: 0 0 30.333333%;
    max-width: 30.333333%;
    width: 350px;
    height: 143px;
    background: #ffffff;
    box-shadow: 0px 4px 4px rgba(199, 223, 241, 0.25);
    border-radius: 5px;
    padding: 10px 20px;
    margin: 10px 15px 15px;
  }

  .flex-items-card {
    display: flex;
    justify-content: space-between;
  }

  .card-items p {
    font-family: Inter;
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 19px;
    text-transform: capitalize;
    color: #333333;
  }

  .card-content p {
    font-family: Inter;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 150%;
    color: #666666;
  }

  .card-wrap .dropdown-menu.show {
    -webkit-transform: translate(-0px, 25px) !important;
    -ms-transform: translate(-0px, 25px) !important;
    transform: translate(-70%, 55px) !important;
  }
  .card-wrap .dropdown-item {
    display: block;
    width: 100%;
    padding: 0.25rem 0.5rem;
    clear: both;
    font-weight: 400;
    color: #212529;
    text-align: inherit;
    background-color: transparent;
    border: 0;
    font-family: Inter;
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 24px;
    color: #333333;
  }

  .center-img {
    display: block;
    margin: 8% auto 2%;
  }

  .card-content.add-icon-div p {
    text-align: center;
    font-family: Inter;
    font-style: normal;
    font-weight: bold;
    font-size: 14px;
    line-height: 150%;
    color: #1a21ba;
  }
`;

export const TemplateCard = styled.div.attrs((props) => ({
  className: props.className,
}))`
  margin: 100px 0px 0px 40px;
  .editor-border {
    background: #ffffff;
    border: 1px solid #1a21ba;
    box-sizing: border-box;
    border-radius: 5px;
    margin-bottom: 40px;
  }

  .editor-border .ql-toolbar.ql-snow {
    border: 0px;
    background: #fff;
    padding-left: 35px;
  }

  .editor-border .ql-container.ql-snow {
    border: 0px solid #fff;
  }

  .editor-border .quill {
    border-radius: 4px;
    border: 0px solid #ccc;
  }
  .editor-border .ql-editor.ql-blank {
    padding: 112px 15px 12px;
  }
  .editor-border .ql-container.ql-snow {
    padding-top: 112px;
  }
  textarea {
    height: 70px;
    margin-top: 30px;
  }

  .preview-col {
    background: #fff;
    margin-top: -30px;
    position: relative;
  }

  .preview-image {
    padding: 40px 20px 20px;
  }

  #preview-bottom {
    position: absolute;
    bottom: 0;
    left: 50%;
    font-family: Inter;
    font-style: normal;
    font-weight: bold;
    font-size: 16px;
    line-height: 19px;
    color: #344563;
  }

  .quil-left-col #save-btn {
    width: 30%;
    height: 48px;
    background: #1a21ba;
    border-radius: 2px;
    border: none;
    cursor: pointer;
    color: #fff;
    text-align: center;
    font-size: 1rem;
    padding: 0;
    margin-left: 70%;
    background: #1a21ba;
    border-radius: 4px;
    font-family: Inter;
    font-style: normal;
    font-weight: bold;
    font-size: 16px;
    line-height: 19px;
  }
`;

export const EditorWrapper = styled.div`
  background-color: #fff;
  height: fit-content;
  border: 1px solid #fff;
  border-radius: 10px;
  width: 100%;
  .rdw-editor-main {
    height: 300px;
  }
  .rdw-editor-wrapper {
    width: 95%;
    margin-left: 10px;
  }
`;

export const VariableBox = styled.div`
  display: flex;
  flex-wrap: wrap;
  background-color: #f5f5f5;
  div {
    background: rgba(21, 164, 18, 0.2);
    width: fit-content;
    padding: 2px 5px 2px 5px;
    border: 1px solid #cceacc;
    margin: 5px;
    cursor: pointer;
    text-align: center;
    font-size: 13px;
    color: #15a412;
    font-weight: bold;
  }
`;

export const VariableBoxWrapper = styled.div`
  background-color: #f5f5f5;
  position: relative;
  top: -3px;
  padding: 10px;
  p {
    padding: 10px;
    color: #999999;
    font-family: Inter;
    font-style: normal;
    font-weight: 500;
    font-size: 12px;
    margin-bottom: 20px;
  }
`;

export const PreviewPage = styled.div`
  background: #fff;
  max-height: 800px;
  overflow: auto;
`;

export const PreviewText = styled.div`
  color: #344563;
  font-family: Inter;
  font-style: normal;
  font-weight: bold;
  font-size: 16px;
  text-align: center;
  margin-bottom: 10px;
`;

//*****addemployeemodal******************************************

//***************************************************

export const SendWelfareTextModal = styled(Modal).attrs((props) => ({
  className: props.className,
}))`
   {
    display: block;
    .custom-search {
      position: relative;
      display: block;
    }
    .modal-dialog {
      max-width: 540px !important;
      margin: 1.75rem auto;
    }
    .chevron_down_21 {
      height: 20px;
      position: absolute;
      right: 10px;
      top: 35px;
    }
    .dropdown-21 {
      position: absolute;
      background-color: #f9f9f9;
      min-width: 100%;
      box-shadow: 0px 4px 8px 0px rgba(0, 0, 0, 0.2);
      padding: 12px 16px;
      z-index: 1;
      top: 70px;
      border: 1px solid #c1c7d0;
      color: #5e6c84;
      -webkit-transition: 0.3s;
      transition: 0.3s;
      background: #f4f5f7;
      display: none;
      height: 150px;
      overflow-y: scroll;
    }
    .dropdown-21 p {
      font-family: Inter;
      font-style: normal;
      font-weight: normal;
      font-size: 12px !important;
      line-height: 15px;
      padding: 4px 0px;
    }

    .labels {
      padding: 4px 8px 6px;
      background: #dfe1e6;
      border-radius: 4px;
      display: inline-block;
      width: auto;
      margin: 3px 5px;
      font-family: Inter;
      font-style: normal;
      font-weight: normal;
      font-size: 14px;
      line-height: 17px;
      color: #5e6c84;
      flex: none;
      order: 0;
      flex-grow: 0;
    }
    .mt-label {
      font-family: Inter;
      font-style: normal;
      font-weight: normal;
      font-size: 14px;
      line-height: 17px;
      text-transform: capitalize;
      color: #091e42;
    }
  }
  .bottom-btn {
    margin: -20px 0px 50px;
    padding: 0px 40px;
  }
  .bottom-btn button {
    height: 36px;
    border: 1px solid #c1c7d0;
    box-sizing: border-box;
    border-radius: 100px;
  }
  .bottom-btn img {
    height: 13px;
    float: right;
  }
  .bottom-btn img {
    height: 13px;
    float: right;
    display: inline-block;
    margin-top: 5px;
  }

  .bottom-btn .btn-text {
    font-family: Inter;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 24px;
    text-align: center;
    color: #1a21ba;
  }

  .list-of-employee {
    padding: 10px;
    box-sizing: border-box;
    border-radius: 4px;
    border: 1px solid #c1c7d0;
    color: #5e6c84;
    -webkit-transition: 0.3s;
    transition: 0.3s;
    background: #f4f5f7;
    position: absolute;
    top: 75px;
    width: 28.6rem;
    z-index: 4;
    height: 200px;
    overflow: scroll;
  }
  .list-of-employee p {
    cursor: pointer;
  }
  .center-img {
    display: block;
    margin: 8% auto 2%;
  }
  .pp-bold {
    font-family: Inter;
    font-style: normal;
    font-weight: bold;
    font-size: 16px;
    line-height: 26px;
    color: #333333;
  }
  .pp-normal {
    font-family: Inter;
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 21px;
    color: #666666;
  }
`;

export const Error = styled.div`
  color: red;
  font-size: 12px;
  margin-bottom: 20px;
`;

export const FormContainer = styled.form`
  padding: 40px;
`;

export const ModalButton = styled.button.attrs((props) => ({
  className: props.className,
}))`
  flex: 1;
  text-align: start;
  text-align: center;
  position: relative;
  top: -10px;
  font-weight: 500 !important;
  font-size: 15px;
  color: ${({ action }) => (action ? PRIMARY_COLOR : RED_COLOR)};
  border-left: ${({ action }) => (action ? '1px solid #F0F0F0' : 'none')};
  cursor: pointer;
  border: none;
  background: #fff;
  padding: 10px 0px;
  border-top: 1px solid #eee;
  border-right: 1px solid #eee;
  &:focus {
    outline: none;
  }

  .btn-2 {
    border-right: 0px solid #eee;
  }
`;

export const SendWelfareTextRequest = styled.div.attrs((props) => ({
  className: props.className,
}))`
  margin-top: 100px;
  position: relative;
  z-index: 4;
  .col-7 {
    flex: 0 0 52.333333%;
    max-width: 52.333333%;
    height: 100vh;
    overflow: scroll;
  }

  .mid-6 .col-lg-3 {
    flex: 0 0 100%;
    max-width: 100%;
    margin: 10px 0px 20px 0px;
  }
  .letter-div-empty {
    width: 100%;
    height: 100%;
    border-radius: 10px;
    padding-top: 100px;
  }

  .letter-div {
    background: #fff;
    width: 450px;
    height: 699px;
    border-radius: 10px;
    border: none;
  }

  .letter-div .quill.confirm-letter-default {
    width: 450px;
    border: 0px solid #fff;
    height: 699px;
  }

  .letter-div .ql-container.ql-snow {
    border: 0px solid #ccc;
    padding: 20px 15px;
  }

  .letter-div .ql-toolbar.ql-snow {
    background: #fff;
  }

  .letter-div .ql-toolbar.ql-snow + .ql-container.ql-snow {
    border-top: 0px;
    background: #fff;
    border: none;
  }

  .pull-right {
    float: right;
    padding: 30px 20px;
  }
  .pull-right.snow {
    float: right;
    padding: 60px 20px;
  }

  .edit-letter,
  .send-letter {
    width: 120px;
    height: 48px;
    background: #ffffff;
    border: 1px solid #d9d9d9;
    box-sizing: border-box;
    box-shadow: 0px 4px 4px rgba(199, 223, 241, 0.25);
    border-radius: 5px;
    font-family: Inter;
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 16px;
    color: #333333;
  }

  .send-letter {
    background: #1a21ba;
    font-family: Inter;
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 19px;
    color: #ffffff;
    flex: none;
    order: 0;
    flex-grow: 0;
    margin: 0px 8px;
  }

  .pull-right button:focus {
    outline: none;
  }

  .confirm-letter-default .ql-container,
  .confirm-letter-default .ql-editor {
    background: #fff;
  }

  .disable-quil {
    height: 699px;
    position: absolute;
    top: 0px;
    border-radius: 4px 4px 0px 0px;
    width: 450px;
    opacity: 0;
    z-index: 4;
  }
`;

/*****************************************
 
****************************************/

export const ActionContainer = styled.div.attrs((props) => ({
  className: props.className,
}))`
  padding: 40px;
  text-align: center;
  .comment-form-content form {
    padding: 0;
  }
  &.action-confirm {
    padding: 10px;
  }

  .comment-form-content textarea {
    font-family: Inter;
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 19px;
    color: #5e6c84;
  }

  .wrap-file-upload {
    background: #f4f5f7;
    border: 1px dashed #c1c7d0;
    box-sizing: border-box;
    border-radius: 4px;
    height: 128px;
  }

  .wrap-file-upload .fileupload_wrapper .fileupload_style > .filesize {
    margin-top: 35px;
  }
  .wrap-file-upload .fileupload_wrapper .upload_icon_img {
    display: inline-block;
    margin: 0 100px;
    position: relative;
    top: 40px;
  }
  .wrap-file-upload .fileupload_wrapper .fileupload_style > .undertext {
    position: relative;
    top: 30px;
    margin-top: 10px;
  }
  .wrap-file-upload .browse {
    color: #1a21ba;
  }

  .wrap-file-upload .file-img {
    position: relative;
    top: 50px;
  }

  .wrap-file-upload .fileupload_wrapper {
    position: relative;
    overflow: visible;
    display: inline-block;
    margin: -20px 0px 0px;
  }

  .wrap-file-upload .fileupload_wrapper .fileupload_style > .wrap_file_name_close {
    background: #deebff;
    padding: 5px 5px;
    border-radius: 4px;
    position: relative;
    top: 60px;
  }
`;

//table row****************************************

//*************************************************/

export const ConfirmContainer = styled.div.attrs((props) => ({
  className: props.className,
}))`
  &.confirm-employee {
    padding: 0px;
  }
  .delete-content {
    &.confirm-employee h6 {
      text-align: left;
    }
    h6 {
      padding-bottom: 30px;
    }

    button {
      border: 1px solid #d9d9d9;
      background: #fff;
      padding: 5px 15px;
      margin: 2px;
      border-radius: 4px;
      box-shadow: 0px 4px 4px rgba(199, 223, 241, 0.25);
      color: #172b4d;
    }
    button:nth-of-type(2) {
      border: 1px solid #d9d9d9;
      background: #fff;
      padding: 5px 15px;
      margin: 2px;
      border-radius: 4px;
      box-shadow: 0px 4px 4px rgba(199, 223, 241, 0.25);
      color: #eb5757;
    }
    .labels {
      padding: 4px 8px 6px;
      background: #dfe1e6;
      border-radius: 4px;
      display: inline-block;
      width: auto;
      margin: 3px 5px;
      font-family: Inter;
      font-style: normal;
      font-weight: normal;
      font-size: 14px;
      line-height: 17px;
      color: #5e6c84;
      flex: none;
      order: 0;
      flex-grow: 0;
    }
    .confirm-employees-h12-1 {
      font-family: Inter;
      font-style: normal;
      font-weight: bold;
      font-size: 16px;
      line-height: 26px;
      display: flex;
      align-items: center;
      text-align: center;
      color: #333333;
      padding: 20px 0px 10px 100px;
    }
    .confirm-employees-h12 {
      font-family: Inter;
      font-style: normal;
      font-weight: 500;
      font-size: 14px;
      line-height: 21px;
      text-align: center;
      color: #666666;
      padding: 10px 0;
    }

    .pp-bold {
      font-family: Inter;
      font-style: normal;
      font-weight: bold;
      font-size: 16px;
      line-height: 26px;
      color: #333333;
    }
    .pp-normal {
      font-family: Inter;
      font-style: normal;
      font-weight: 500;
      font-size: 14px;
      line-height: 21px;
      color: #666666;
    }
  }
`;

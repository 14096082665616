/* eslint-disable no-unreachable */
import axios from 'config/axios';
import { setAuthToken } from 'utils/setAuthToken';
import { loginApi, forgotPasswordApi, getNotificationApi, readNotificationApi} from '../api/user';
import {
  LOGIN_USER_START,
  LOGIN_USER_SUCCESS,
  LOGIN_USER_ERROR,
  FORGOT_PASSWORD_START,
  FORGOT_PASSWORD_SUCCESS,
  FORGOT_PASSWORD_ERROR,
  UPDATE_PASSWORD_START,
  // UPDATE_PASSWORD_SUCCESS,
  // UPDATE_PASSWORD_ERROR,
  VERIFY_USER_REQUEST,
  VERIFY_USER_SUCCESS,
  VERIFY_USER_FAILURE,
  SIGNUP_REQUEST,
  SIGNUP_SUCCESS,
  SIGNUP_FAILURE,
  PASSWORD_RESET_REQUEST,
  PASSWORD_RESET_SUCCESS,
  PASSWORD_RESET_FAILURE,
  GET_NOTIFICATION_REQUEST,
  GET_NOTIFICATION_SUCCESS,
  GET_NOTIFICATION_FAILURE,
  READ_NOTIFICATION_REQUEST,
  READ_NOTIFICATION_SUCCESS,
  READ_NOTIFICATION_FAILURE,
  TOGGLE_RESPONSIVE_MENUBAR_REQUEST,
} from '../actionTypes/user';

import { setToken } from '../../utils/auth';


const startLogin = () => ({
  type: LOGIN_USER_START,
});
const successLogin = (payload) => ({
  type: LOGIN_USER_SUCCESS,
  payload,
});
const errorLogin = (payload) => ({
  type: LOGIN_USER_ERROR,
  payload,
});

const startForgotPassword = (payload) => ({
  type: FORGOT_PASSWORD_START,
  payload,
});
const successForgotPassword = (response) => ({
  type: FORGOT_PASSWORD_SUCCESS,
  payload: response,
});
const errorForgotPassword = (payload) => ({
  type: FORGOT_PASSWORD_ERROR,
  payload,
});

const startUpdatePassword = (payload) => ({
  type: UPDATE_PASSWORD_START,
  payload,
});
// const successUpdatePassword = (response) => ({
//   type: UPDATE_PASSWORD_SUCCESS,
//   payload: response,
// });
// const errorUpdatePassword = (payload) => ({
//   type: UPDATE_PASSWORD_ERROR,
//   payload,
// });

export const loginUser = (data) => async (dispatch) => {
  try {
    dispatch(startLogin());
    const response = await loginApi(data);
    setToken(response.data.access);
    return dispatch(successLogin(response));
  } catch (e) {
    return dispatch(errorLogin(e.response));
  }
};

export const forgotPassword = (email) => async (dispatch) => {
  try {
    dispatch(startForgotPassword());
    const response = await forgotPasswordApi(email);
    return dispatch(successForgotPassword(response));
  } catch (e) {
    return dispatch(errorForgotPassword(e.response));
  }
};

export const updatePassword = (token, password) => async (dispatch) => {
  try {
    dispatch(startUpdatePassword());
    const response = await axios.post('/auth/users/create-password/', { token, password });
    return response;
  } catch (error) {
    return {
      error: error.response?.data?.errors,
    };
  }
};

export const createPassword = (payload) => async (dispatch) => {
  try {
    const response = await axios.post('/auth/users/create-password/', payload);
    return response;
  } catch (error) {
    return {
      error: error.response?.data?.errors,
    };
  }
};

const verifyUserRequest = (payload) => ({
  type: VERIFY_USER_REQUEST,
  payload,
});
const verifyUserSuccess = (response) => ({
  type: VERIFY_USER_SUCCESS,
  payload: response,
});
const verifyUserFailure = (payload) => ({
  type: VERIFY_USER_FAILURE,
  payload,
});

export const verifyUser = (token) => async (dispatch) => {
  try {
    dispatch(verifyUserRequest());
    const response = await axios.post('/auth/users/verify-token/', token);
    return dispatch(verifyUserSuccess(response));
  } catch (e) {
    return dispatch(verifyUserFailure(e.response));
  }
};

const signupRequest = (payload) => ({
  type: SIGNUP_REQUEST,
  payload,
});
const signupSuccess = (response) => ({
  type: SIGNUP_SUCCESS,
  payload: response,
});
const signupFailure = (payload) => ({
  type: SIGNUP_FAILURE,
  payload,
});

export const signup = (payload) => async (dispatch) => {
  try {
    dispatch(signupRequest());
    const response = await axios.post('/auth/users/', payload);
    return dispatch(signupSuccess(response));
  } catch (e) {
    return dispatch(signupFailure(e.response));
  }
};

// Log user out
export const logout = () => {
  localStorage.clear();
  setAuthToken(false);
  window.location.href = '/signin';
};

const resetPasswordRequest = (payload) => ({
  type: PASSWORD_RESET_REQUEST,
  payload,
});
const resetPasswordSuccess = (response) => ({
  type: PASSWORD_RESET_SUCCESS,
  payload: response,
});
const resetPasswordFailure = (payload) => ({
  type: PASSWORD_RESET_FAILURE,
  payload,
});

export const resetPassword = (payload) => async (dispatch) => {
  try {
    dispatch(resetPasswordRequest());
    const response = await axios.post('/auth/users/create-password/', payload);
    return dispatch(resetPasswordSuccess(response));
  } catch (e) {
    return dispatch(resetPasswordFailure(e.response));
  }
};

// Get notification action
export const getNotification = () => {
  return async (dispatch) => {
    dispatch({
      type: GET_NOTIFICATION_REQUEST,
    });

    try {
      const response = await getNotificationApi();
      if (response.data) {
        return dispatch({
          type: GET_NOTIFICATION_SUCCESS,
          payload: response.data,
        });
      }
    } catch (error) {
      return dispatch({
        type: GET_NOTIFICATION_FAILURE,
        payload: error.response,
      });
    }
  };
};

// mark notification as read actions
const readNotificationRequest = () => ({
  type: READ_NOTIFICATION_REQUEST,
});

const readNotificationSuccess = (payload) => ({
  type: READ_NOTIFICATION_SUCCESS,
  payload,
});
const readNotificationFailure = (error) => ({
  type: READ_NOTIFICATION_FAILURE,
  error,
});

export const readNotification = (payload) => async (dispatch) => {
  dispatch(readNotificationRequest());
  try {
    const response = await readNotificationApi(payload);
    dispatch(readNotificationSuccess(response.data));
    dispatch(getNotification());
  } catch ({ response }) {
    if (response.status) {
      return dispatch(
        readNotificationFailure(
          response.data.errors ||
            JSON.stringify(response.data.message).replace(/[^a-zA-Z0-9\s]/g, '')
        )
      );
    }
    return dispatch(readNotificationFailure('Network Error'));
  }
};



export const mobileViewerState = (status) => {
  return async (dispatch) => {
    dispatch({
      type: TOGGLE_RESPONSIVE_MENUBAR_REQUEST,
      payload: status
    });

  };
};

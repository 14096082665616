import styled from 'styled-components';
import { PRIMARY_COLOR, RED_COLOR } from '../../assets/colors';

export const ModalFooter = styled.div`
  display: ${({ hideFooter }) => (hideFooter ? 'none' : 'flex')};
  background-color: #fff;
  width: 100%;
  padding: 10px 10px 0px 10px;
`;

export const ModalButton = styled.button`
  flex: 1;
  outline: none !important;
  border: none;
  background: inherit;
  text-align: start;
  text-align: center;
  position: relative;
  top: -10px;
  font-weight: 500 !important;
  font-size: 15px;
  color: ${({ action }) => (action ? PRIMARY_COLOR : RED_COLOR)};
  border-left: ${({ action }) => (action ? '1px solid #F0F0F0' : 'none')};
  cursor: pointer;
`;

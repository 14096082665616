/* eslint-disable camelcase */
import React, {useState} from 'react';
import AppModal from 'components/AppModal';
import TextArea from 'components/common/TextArea';
import { useFormik } from 'formik';
import { useDispatch } from 'react-redux';
import { useToast } from 'context/toastContext';
import { reviewHeadcountResponse } from 'redux/actions/headcount';

const NoteForm = ({ formik }) => {
  return (
    <form className="mt-4 ml-3 mr-3 mb-4">
      <TextArea
        onChange={formik.handleChange}
        label="Add Reason"
        name="supervisor_comment"
        value={formik?.values?.supervisor_comment}
        error={formik.errors?.supervisor_comment}
      />
      <div className="d-flex align-items-center" />
    </form>
  );
};

/**
 *
 *
 * @return {*}
 */
function DeclineModal({ show, toggle, tokendata, employee_headcount_id, toggleSuccess }) {
  const handleClose = () => {
    toggle();
  };

  const [loading, setLoading] = useState(false);
  const dispatch = useDispatch();
  const toast = useToast();

  const initialValues = {
    supervisor_comment: '',
    supervisor_status: 'DECLINED',
    employee_response_id: employee_headcount_id,
  };

  const handleConfirm = async (values) => {
    setLoading(true);
    const response = await dispatch(reviewHeadcountResponse({ ...values }));
    if (response.success) {
      toggle();
      toggleSuccess();
    }
    if (response.error) {
      toast.error('Error occurred');
    }
    setLoading(false);
  };

  const formik = useFormik({
    initialValues,
    onSubmit: handleConfirm,
  });

  return (
    <AppModal
      show={show}
      header
      title="Give Reason for Declining"
      actionText={loading ? 'Declining' : 'Decline'}
      action={formik.handleSubmit}
      previous={handleClose}
      previousText="Back"
      close={handleClose}
    >
      <NoteForm formik={formik} />
    </AppModal>
  );
}

export default DeclineModal;

import styled from 'styled-components';

export const HomeWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
`;
export const Text = styled.p``;

export const LogoWrapper = styled.div`
  margin-top: 10px;
  text-align: center;
`;

export const Content = styled.div`
  text-align: center;
  min-height: 200px;
  padding: 50px;
  background-color: #ffffff;
  position: relative;
  ${'' /* top: -120px; */}
  width: 550px;
`;

export const ContentTitle = styled.div`
  font-family: Inter;
  font-style: normal;
  font-weight: bold;
  font-size: 15px;
  line-height: 29px;
  text-align: center;
  letter-spacing: -0.02em;
  color: #333333;
  margin: 15px 0px;
`;
export const ContentSubTitle = styled.div`
  font-family: Inter;
  font-style: normal;
  font-size: 15px;
  line-height: 29px;
  text-align: center;
  letter-spacing: -0.02em;
  color: #333333;
  margin-top: 10px;
`;
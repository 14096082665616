import React from 'react';
import PropTypes from 'prop-types';
import { Container } from './styles';

const PageContainer = ({children, marginTop}) => {
  return ( 
    <Container marginTop={marginTop}>
      {children}
    </Container>
   );
};
 
PageContainer.propTypes = {
  children: PropTypes.element.isRequired,
};

PageContainer.defaultProps = {
  marginTop: 0
}

export default PageContainer;
import React, { useState } from 'react';
import Button from 'components/common/Button';
import { EMPTY_FILE, Wrapper } from 'pages/Candidate/Offer/styles';
import FileUpload from 'components/common/FileUpload';
import { svgs } from 'assets';
import { useToast } from 'context/toastContext';


export const Step1 = (activeStep, setActiveStep, setData, data) => {
  const [fileValue, setFileValue] = useState();
  const toast = useToast();

  const handleOnchange = (e) => {
    e.persist();
    setFileValue(e.target.files[0]);
    setData((prev) => ({
      ...prev,
      acknowledgement_letter: e.target.files[0],
    }));
  };

  const handleNextStepwithValidation =()=>{
    if (data?.acknowledgement_letter){
    setActiveStep(activeStep + 1)
    return
    }
    return toast.error('Attachment is required');

  }

  return (
    <Wrapper>
      <div className="content">
        <div className="logo-wrapper">
          <EMPTY_FILE />
        </div>
        <p className="header">Upload your Signed offer letter</p>
        <p className="note">
          Hello,
          {' '}
          <span>{data?.firstname}</span>
          .
          <br />
          {`It's nice to have you on-board. Please provide a signed
    copy of your offer letter to confirm your acceptance.`}
        </p>

        <div className="file-upload-wrapper">
          <FileUpload
            className="upload"
            limit={5}
            acceptedFiles="application/pdf,.docx"
            name="file"
            value={fileValue}
            icon={svgs.UPLOAD_ICON_CLOUD}
            onChange={handleOnchange}
            // error="Please upload a file"
          />
          {/* <Button block text="Upload Offer Letter" className="button" /> */}
        </div>
      </div>
      <div className="footer">
        <Button
          text="Next"
          className="button"
          onClick={() => handleNextStepwithValidation()}
        />
      </div>
    </Wrapper>
  );
};

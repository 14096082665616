import React from 'react';
import { svgs } from 'assets';

/**
 * pagination component
 */
const Pagination = ({ totalPages, currentPage, handleNextPage, handlePrevPage }) => {
  return (
    <div>
      {currentPage > 1 ? (
        <button onClick={handlePrevPage}>
          <img src={svgs.LEFT_ICON} alt="left icon" />
        </button>
      ) : null}
      <button>{currentPage}</button>
      <span>/</span>
      <span>{totalPages}</span>
      {totalPages > currentPage ? (
        <button onClick={handleNextPage}>
          <img src={svgs.RIGHT_ICON} alt="left icon" />
        </button>
      ) : null}
    </div>
  );
};

export default Pagination;

export const GET_HMO_HOSPITAL_SUCCESS = 'GET_HMO_HOSPITAL_SUCCESS';
export const GET_HMO_HOSPITAL_START = 'GET_HMO_HOSPITAL_START';
export const GET_HMO_HOSPITAL_FAIL = 'GET_HMO_HOSPITAL_FAIL';


export const CREATE_HMO_START = 'CREATE_HMO_START';
export const CREATE_HMO_SUCCESS = 'CREATE_HMO_SUCCESS';
export const CREATE_HMO_FAIL= 'CREATE_HMO_FAIL';

export const GET_HMO_SUBSCRIBER_SUCCESS = 'GET_HMO_SUBSCRIBER_SUCCESS';

import styled from 'styled-components';

export const Container = styled.div`
  position: fixed;
  left: 0;
  top: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  background: rgba(23, 43, 77, 0.25);
  z-index: 4;
  width: 100%;
  height: 100vh;
  transition: 0.3s;
`;

export const Modal = styled.div`
  background: white;
  display: flex;
  flex-direction: column;
  height: 580px;
  height: 362px;
 
  border-radius: 8px;
  position: relative;
  font-family: Inter;
`;

export const ModalHeader = styled.div`
  padding: 22px 30px;
  display: flex;
  align-items: center;
  justify-content: space-between;
 box-shadow: 0px 4px 20px rgba(199, 223, 241, 0.25);
  .close-icon {
    cursor: pointer;
  }
`;

export const ModalContent = styled.div`
  padding: 40px 60px;
  display: flex;
  flex-direction: column;
  img{
    margin: 0 auto;
    margin-bottom: 20px;
  }
`;

export const ModalFooter = styled.div`
  display: flex;
  width: 100%;
  position: absolute;
  bottom: 0px;

  button {
    outline: none;
    background: none;
    flex: 1;
    padding: 20px;
    font-size: 18px;
    font-weight: 500;
    line-height: 26px;
    border-bottom: none;
    border-right: none;
    border-top: 1px solid #f0f0f0;
  }

  .delete {
    border-left: 1px solid #f0f0f0;
    color: #1a21ba;
  }

  .cancel {
    border-left: none;
    color: #eb5757;
  }
`;

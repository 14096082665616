import React, { useCallback, useState } from 'react';
import { svgs } from 'assets';
import { useDropzone } from 'react-dropzone';
import { PageWrapper, Label } from './styles';
import { ToastError } from 'components/common/Toast';

const UploadComponent = ({
  value,
  label,
  name,
  onChange,
  handleUpload,
  accept,
  background,
  ...props
}) => {
  const [uploadedFile, setUploadedFile] = useState(null);
  const [errorToastMessage, setErrorToastMessage] = useState('');
  const [errorToastOpen, setErrorToastOpen] = useState(false);

  const onDrop = useCallback(
    async (acceptedFile) => {
      const isValidFileType = accept.includes(acceptedFile[0].type);
      const isValidFileSize = acceptedFile[0].size <= 5000000;
      const isValidFile = isValidFileType && isValidFileSize;

      if (!isValidFile) {
        setErrorToastMessage('Please upload a valid file and a File less than 5MB');
        setErrorToastOpen(true);
      } else {
        setUploadedFile(acceptedFile);
      }
    },
    [accept]
  );

  const { getRootProps, getInputProps, isDragActive } = useDropzone({ onDrop });
  return (
    <>
      <Label>{label}</Label>
      <PageWrapper {...getRootProps()} background>
        <input
          {...getInputProps({
            accept: accept,
          })}
          name={name}
          onChange={onChange}
        />
        <img src={svgs.UPLOAD_ICON} alt="download" />
        {value ? (
          <span className="drop-file">
            {value.name} - {(value.size / 1000000).toFixed(3)}MB
          </span>
        ) : (
          <span className="drop-file">
            Drop file here or{' '}
            <span className="browse-file" {...getRootProps()}>
              browse
            </span>
          </span>
        )}
        <span className="size-limit">Size limit: 5MB (pdf or docs)</span>
      </PageWrapper>
      <ToastError
        message={errorToastMessage}
        isOpen={errorToastOpen}
        close={() => {
          setErrorToastOpen(!errorToastOpen);
        }}
        uploadedFile={uploadedFile}
        isDragActive={isDragActive}
      />
    </>
  );
};
export default UploadComponent;


export const GET_HMO_START = 'GET_HMO_START';
export const GET_HMO_SUCCESS = 'GET_HMO_SUCCESS';
export const GET_HMO_FAIL= 'GET_HMO_FAIL';

export const ADD_QUESTION_START = 'ADD_QUESTION_START';
export const ADD_QUESTION_SUCCESS = 'ADD_QUESTION_SUCCESS';
export const ADD_QUESTION_FAIL= 'ADD_QUESTION_FAIL';


export const INITIATE_SURVEY_START = 'INITIATE_SURVEY_START';
export const INITIATE_SURVEY_SUCCESS = 'INITIATE_SURVEY_SUCCESS';

export const GET_QUESTION_START = 'GET_QUESTION_START';
export const GET_QUESTION_SUCCESS = 'GET_QUESTION_SUCCESS';
export const GET_QUESTION_FAIL= 'GET_QUESTION_FAIL';

export const GET_HMO_SURVEYS_START = 'GET_HMO_SURVEYS_START';
export const GET_HMO_SURVEYS_SUCCESS = 'GET_HMO_SURVEYS_SUCCESS';
export const GET_HMO_SURVEYS_FAIL= 'GET_HMO_SURVEYS_FAIL';

export const GET_SPECIFIC_QUESTION_START = 'GET_SPECIFIC_QUESTION_START';
export const GET_SPECIFIC_QUESTION_SUCCESS = 'GET_SPECIFIC_QUESTION_SUCCESS';
export const GET_SPECIFIC_QUESTION_FAIL= 'GET_SPECIFIC_QUESTION_FAIL';

export const CREATE_HMO_START = 'CREATE_HMO_START';
export const CREATE_HMO_SUCCESS = 'CREATE_HMO_SUCCESS';
export const CREATE_HMO_FAIL= 'CREATE_HMO_FAIL';

export const UPDATE_HMO_START = 'UPDATE_HMO_START';
export const UPDATE_HMO_SUCCESS = 'UPDATE_HMO_SUCCESS';
export const UPDATE_HMO_FAIL= 'UPDATE_HMO_FAIL';

export const GET_SINGLE_HMO_START = 'GET_SINGLE_HMO_START';
export const GET_SINGLE_HMO_SUCCESS = 'GET_SINGLE_HMO_SUCCESS';
export const GET_SINGLE_HMO_FAIL= 'GET_SINGLE_HMO_FAIL';

export const CREATE_HOSPITAL_START = 'CREATE_HOSPITAL_START';
export const CREATE_HOSPITAL_SUCCESS = 'CREATE_HOSPITAL_SUCCESS';
export const CREATE_HOSPITAL_FAIL= 'CREATE_HOSPITAL_FAIL';

export const GET_HOSPITAL_START = 'GET_HOSPITAL_START';
export const GET_HOSPITAL_SUCCESS = 'GET_HOSPITAL_SUCCESS';
export const GET_HOSPITAL_FAIL= 'GET_HOSPITAL_FAIL';

export const GET_SINGLE_HOSPITAL_SUCCESS  = 'GET_SINGLE_HOSPITAL_SUCCESS';

export const CREATE_COMPANY_START = 'CREATE_COMPANY_START';
export const CREATE_COMPANY_SUCCESS = 'CREATE_COMPANY_SUCCESS';
export const CREATE_COMPANY_FAIL= 'CREATE_COMPANY_FAIL';

export const GET_COMPANY_START = 'GET_COMPANY_START';
export const GET_COMPANY_SUCCESS = 'GET_COMPANY_SUCCESS';
export const GET_COMPANY_FAIL= 'GET_COMPANY_FAIL';

export const GET_SINGLE_COMPANY_SUCCESS  = 'GET_SINGLE_COMPANY_SUCCESS';

export const  GET_EMPLOYEE_COMPLAINT_LIST_START = 'GET_EMPLOYEE_COMPLAINT_LIST_START';
export const  GET_EMPLOYEE_COMPLAINT_LIST_SUCCESS = 'GET_EMPLOYEE_COMPLAINT_LIST_SUCCESS';

export const UPDATE_EMPLOYEE_COMPLAINT_CATEGORIES_START = 'UPDATE_EMPLOYEE_COMPLAINT_CATEGORIES_START';

export const CREATE_CLASSIFICATION_START = 'CREATE_CLASSIFICATION_START';
export const CREATE_CLASSIFICATION_SUCCESS = 'CREATE_CLASSIFICATION_SUCCESS';
export const CREATE_CLASSIFICATION_FAIL= 'CREATE_CLASSIFICATION_FAIL'

export const GET_CLASSIFICATION_SUCCESS = 'GET_CLASSIFICATION_SUCCESS';
export const GET_SINGLE_CLASSIFICATION_SUCCESS  = 'GET_SINGLE_CLASSIFICATION_SUCCESS';

export const GET_SUBSCRIBERS_SUCCESS = 'GET_SUBSCRIBERS_SUCCESS';

export const GET_CHANGE_REQUEST_SUBSCRIBERS_SUCCESS = 'GET_CHANGE_REQUEST_SUBSCRIBERS_SUCCESS';

export const GET_HMO_CHANGE_REQUEST_START = 'GET_HMO_CHANGE_REQUEST_START';
export const GET_HMO_CHANGE_REQUEST_SUCCESS = 'GET_HMO_CHANGE_REQUEST_SUCCESS';

export const GET_DASHBOARD_DATA_SUCCESS = 'GET_DASHBOARD_DATA_SUCCESS';

export const GET_DASHBOARD_HOSPITAL_SUCCESS = 'GET_DASHBOARD_HOSPITAL_SUCCESS';
export const GET_DASHBOARD_COMPANY_SUCCESS = 'GET_DASHBOARD_COMPANY_SUCCESS';
export const GET_DASHBOARD_SUBSCRIBER_SUCCESS = 'GET_DASHBOARD_SUBSCRIBER_SUCCESS';

export const GET_ALL_REQUEST = 'GET_ALL_REQUEST';
export const GET_ALL_SUCCESS = 'GET_ALL_SUCCESS';
export const GET_ALL_FAILURE = 'GET_ALL_FAILURE';

export const PUBLISH_REQUEST = 'PUBLISH_REQUEST';
export const PUBLISH_SUCCESS = 'PUBLISH_SUCCESS';
export const PUBLISH_FAILURE = 'PUBLISH_FAILURE';






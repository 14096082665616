export const SET_APPROVALS_REQUEST = 'SET_APPROVALS_REQUEST';
export const SET_APPROVALS_SUCCESS  = 'SET_APPROVALS_SUCCESS';
export const SET_APPROVALS_FAILURE = 'SET_APPROVAL_FAILURE';

export const ADD_APPROVALS_REQUEST = 'ADD_APPROVALS_REQUEST';
export const ADD_APPROVALS_SUCCESS = 'ADD_APPROVALS_SUCCESS';
export const ADD_APPROVALS_FAILURE = 'ADD_APPROVALS_FAILURE';

export const SET_BEGIN_EDITING = 'SET_EDITING';
export const EDIT_APPROVALS_REQUEST = 'EDIT_APPROVALS_REQUEST';
export const EDIT_APPROVALS_SUCCESS = 'EDIT_APPROVALS_SUCCESS';
export const EDIT_APPROVALS_FAILURE = 'EDIT_APPROVALS_FAILURE';


export const DELETE_APPROVALS_REQUEST = 'DELETE_APPROVALS_REQUEST';
export const DELETE_APPROVALS_SUCCESS = 'DELETE_APPROVALS_SUCCESS';
export const DELETE_APPROVALS_FAILURE = 'DELETE_APPROVALS_FAILURE';
export const RESET = 'RESET';

// export const GET_HEADCOUNT = 'GET_HEADCOUNT';
// export const GET_HEADCOUNT_QUESTIONS = 'GET_HEADCOUNT_QUESTIONS';
// export const INITIATE_HEACOUNT = 'INITIATE_HEACOUNT';
// export const GET_EMPLOYEE_HEADCOUNT_RESPONSE = 'GET_EMPLOYEE_HEADCOUNT_RESPONSE';
// export const GET_EMPLOYEE_RESPONSE_HEADCOUNT_SUCCESS = 'GET_EMPLOYEE_RESPONSE_HEADCOUNT_SUCCESS';
// export const GET_EMPLOYEE_MONTHLY_HEADCOUNT_REQUEST = 'GET_EMPLOYEE_MONTHLY_HEADCOUNT_REQUEST';

export const GET_EMPLOYEE_MONTHLY_HEADCOUNT_REQUEST =
  'GET_EMPLOYEE_MONTHLY_HEADCOUNT_REQUEST';
export const GET_EMPLOYEE_MONTHLY_HEADCOUNT_SUCCESS =
  'GET_EMPLOYEE_MONTHLY_HEADCOUNT_SUCCESS';
export const GET_EMPLOYEE_MONTHLY_HEADCOUNT_FAILURE =
  'GET_EMPLOYEE_MONTHLY_HEADCOUNT_FAILURE';
export const GET_HEADCOUNT = 'GET_HEADCOUNT';
export const GET_HEADCOUNT_QUESTIONS = 'GET_HEADCOUNT_QUESTIONS';
export const INITIATE_HEACOUNT = 'INITIATE_HEACOUNT';
export const GET_EMPLOYEE_HEADCOUNT_RESPONSE = 'GET_EMPLOYEE_HEADCOUNT_RESPONSE';
export const GET_EMPLOYEE_RESPONSE_HEADCOUNT_SUCCESS =
  'GET_EMPLOYEE_RESPONSE_HEADCOUNT_SUCCESS';

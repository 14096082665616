import { useEffect } from 'react';

export const useClickOutside = (ref, callback: () => {}, showDropDown) => {
  useEffect(() => {
    const handleClickOutside = (event) => {
      if (ref?.current && !ref?.current.contains(event.target)) {
        callback();
      }
    };

    if (showDropDown) {
      document.addEventListener('mousedown', handleClickOutside);
    }

    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [ref, callback, showDropDown]);
};

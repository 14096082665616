import { Container, Col } from 'react-bootstrap';
import styled, { css } from 'styled-components';

export const IntroContainer = styled(Container)`
  background: #ffffff;
  box-shadow: 0px 4px 4px rgba(199, 223, 241, 0.25);
  border-radius: 10px;
  margin-bottom: 30px;
`;

export const IntroTopSection = styled(Col)`
  h4 {
    font-weight: bold;
    margin: 20px 0 0 20px;
  }
  p {
    font-size: 14px;
    text-align: left;
    margin: 0 0 0 20px;
    margin-top: 10px;
  }
  .name {
    text-transform: capitalize;
  }
`;

export const ButtonSection = styled.div`
  margin-top: 50px;
`;

export const CandidateContainer = styled(Container).attrs((props) => ({
  className: props.className,
}))`
  background: #fff;
  border-radius: 10px;
  margin-top: 30px;
  overflow: hidden;
  padding: 0;
  &.r-overflow {
    overflow: unset !important;
  }

  .table-responsive.r-overflow {
    overflow: unset !important;
  }
`;

export const EmptyContainer = styled.div`
  display: grid;
  min-height: 500px;
  align-items: center;
  justify-content: center;
`;

export const CandidateTopBar = styled(Container).attrs((props) => ({
  className: props.className,
}))`
  padding: 20px;
  display: grid;
  grid-template-columns: 40px 40px 1fr auto;
  align-items: center;
  justify-content: start;
  div:first-child {
    display: flex;
    align-items: center;
    justify-content: center;
  }
`;

export const CandidateHeader = styled(Container).attrs((props) => ({
  className: props.className,
}))`
  padding: 20px;
  display: grid;
  grid-template-columns: 95px repeat(5, 1fr) 40px;
  align-items: center;
  justify-content: start;
  font-size: 14px;
  font-weight: 500;
  color: #666666;
  grid-gap: 15px;
`;

export const TableRow = styled(Container).attrs((props) => ({
  className: props.className,
}))`
  padding: 20px;
  display: grid;
  grid-gap: 15px;
  grid-template-columns: 40px 40px repeat(7, 1fr) 40px;
  align-items: center;
  justify-content: start;
  border-top: 1px solid #f2f2f2;
  font-size: 14px;
  color: #666666;
  &.candidateTable {
    grid-template-columns: 40px 40px repeat(5, 1fr) 40px;
  }
  .more-icon {
    cursor: pointer;
  }
  .dropdown-menu {
    opacity: 1 !important;
    pointer-events: auto !important;
    border-radius: 5px;
    left: -190px !important;
    min-width: 240px !important;
    ul {
      a,
      div {
        text-decoration: none;
        color: #333;
        font-size: 14px;
        font-weight: 500;
        display: block;
        padding: 10px 20px;
        &:hover {
          background: #fafafa;
        }
      }
    }
  }
  div.dropdown {
    position: relative;
  }
  div:first-child {
    display: grid;
    justify-content: center;
    .candidate-name {
      font-weight: 500;
      font-size: 16px;
      color: #333;
    }
    .candidate-email {
      font-weight: normal;
      font-size: 12px;
      color: #999;
    }
  }
`;

export const ActionBarTab = styled.div`
  display: grid !important;
  grid-template-columns: 1fr auto !important;
  background: #fff;
  border-top: 1px solid #eee;
  padding: 0px 50px !important;
  align-items: center;
  div {
    display: flex;
    justify-content: flex-start;
    padding: 0px;
    span {
      font-size: 14px;
      display: inline-block;
      cursor: pointer;
      color: #333;
      padding: 35px 0 15px;
      margin-right: 30px;
      font-weight: 500;
      &:hover {
        color: #1a21ba;
        border-bottom: 4px solid #1a21ba;
      }
    }
    .active {
      color: #1a21ba;
      border-bottom: 4px solid #1a21ba;
    }
    button {
      padding: 10px 15px;
      height: auto;
      border-radius: 4px;
    }
  }
`;

export const TabContainer = styled.div``;

export const OfferContainer = styled.div``;

export const DeploymentContainer = styled.div`
  display: flex;
  flex-wrap: no-wrap;
  div {
    flex: 1 1 auto;
  }
`;

export const AddTemplateButton = styled(Col)`
  img {
    width: 100%;
  }
`;

export const AddButtonCard = styled.div`
  position: relative;
  border-radius: 10px;
  background: #fff;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  padding: 50px;
  min-width: 320px;
  height: 220px;
  margin: 10px 10px;
  img {
    align-self: center;
    position: absolute;
  }
  p {
    margin-top: 10rem;
    color: #1a21ba;
    font-weight: 700;
    font-family: Inter;
  }
  &:hover {
    cursor: pointer;
  }
`;

export const FormContainer = styled.form`
  padding: 40px;
`;

export const DashboardWrapper = styled.div`
  margin-top: ${({ marginTop }) => (marginTop ? marginTop : '70px')};
`;

export const DashboardWrapperOnboarding = styled.div`
  margin-top: ${({ marginTop }) => (marginTop ? marginTop : '70px')};
`;

export const Error = styled.div`
  color: red;
  font-size: 12px;
  margin-bottom: 20px;
`;

export const EditorWrapper = styled.div`
  background-color: #fff;
  height: fit-content;
  border: 1px solid #fff;
  border-radius: 10px;
  width: 100%;
  .rdw-editor-main {
    height: 300px;
  }
  .rdw-editor-wrapper {
    width: 95%;
    margin-left: 10px;
  }
  p {
    text-align: left !important;
  }
`;

export const VariableBox = styled.div`
  display: flex;
  flex-wrap: wrap;
  background-color: #f5f5f5;
  div {
    background: rgba(21, 164, 18, 0.2);
    width: fit-content;
    padding: 2px 5px 2px 5px;
    border: 1px solid #cceacc;
    margin: 5px;
    cursor: pointer;
    text-align: center;
    font-size: 13px;
    color: #15a412;
    font-weight: bold;
  }
`;

export const VariableBoxWrapper = styled.div`
  background-color: #f5f5f5;
  position: relative;
  top: -3px;
  padding: 10px;
  p {
    padding: 10px;
    color: #999999;
    font-family: Inter;
    font-style: normal;
    font-weight: 500;
    font-size: 12px;
    margin-bottom: 20px;
  }
`;

export const CandidateAction = styled.div`
  display: grid;
  grid-template-columns: auto 1fr;
  padding: 15px 50px;
  align-items: center;

  div.left-items {
    margin: 0 15px;
  }

  div.right-items {
    display: grid;
    grid-template-columns: auto auto;
    justify-content: flex-end;
  }
`;

export const DetailsContainer = styled(Col)`
  background: #fff;
  padding: 30px;
  box-shadow: 0px 4px 4px rgba(199, 223, 241, 0.25);
  border-radius: 5px;
  display: grid;
  grid-row-gap: 20px;
`;

export const AvatarSection = styled.div`
  display: grid;
  grid-template-columns: auto 1fr;
  grid-gap: 15px;
  align-items: center;
  h5 {
    color: #333;
    font-weight: 500;
    font-size: 16px;
    margin: 0 0 5px 0;
  }
  p {
    font-weight: 500;
    font-size: 12px;
    color: #666;
  }
`;

const LabelWarning = css`
  background: #ffecd6;
  color: #e88612;
`;

const LabelSuccess = css`
  background: #d6ffdf;
  color: #10920d;
`;

export const ProgressSection = styled.div`
  display: grid;
  grid-template-columns: 1fr auto;
  border-radius: 4px;
  font-size: 12px;
  font-weight: 500;
  padding: 15px;
  ${({ type }) => (type === 'warning' ? LabelWarning : LabelSuccess)}
`;

export const AccordionSection = styled.div`
  margin-top: 30px;

  div.accordionContext {
    display: grid;
    grid-template-columns: 1fr auto;
    align-items: center;
    cursor: pointer;
  }

  p {
    margin-bottom: 10px;
    font-size: 12px;
    color: #666;
  }

  .card {
    margin-bottom: 20px;
    padding: 0px;
    border: 1px solid #f0f0f0 !important;
    box-sizing: border-box;
    border-radius: 5px !important;

    span.title {
      font-weight: 500;
      font-size: 16px;
      color: #333;
    }

    span.dot {
      display: inline-block;
      width: 3px;
      height: 3px;
      background: #999;
      border-radius: 5px;
      margin: 0 10px;
      position: relative;
      top: -3px;
    }

    .card-header {
      background: none;
      border: none;
    }

    .card-body {
      padding-top: 0;
    }

    div.details {
      font-size: 14px;
      color: #666;
    }

    div.resources {
      font-weight: bold;
      font-size: 12px;
      color: #666;
      margin-top: 25px;
    }

    div.actions {
      margin-top: 40px;
      display: grid;
      grid-template-columns: auto auto;
      justify-content: end;
      grid-column-gap: 15px;

      button.link {
        background: #fff;
        border: 1px solid transparent;
        padding: 10px;
        border-radius: 5px;
        transition: 0.3s;
        font-weight: bold;
        font-size: 14px;
        &:hover {
          border: 1px solid #d9d9d9;
          box-shadow: 0px 4px 4px rgba(199, 223, 241, 0.25);
          box-sizing: border-box;
        }

        &:active {
          outline: none;
          border: none;
        }
      }
      button:disabled {
        cursor: not-allowed;
      }

      button.accept {
        color: #15a412;
      }

      button.cancel {
        color: #eb5757;
      }
    }
  }
`;

export const DocumentLinks = styled(Col)`
  div.inner {
    background: #fff;
    border-radius: 10px;
  }

  div.title {
    background: #ffffff;
    box-shadow: 0px 4px 20px rgba(199, 223, 241, 0.25);
    border-radius: 10px 10px 0 0;
    padding: 15px 20px;
    font-weight: 500;
    font-size: 16px;
    color: #333;
  }

  div.content {
    padding: 20px;
  }
`;

export const DocumentItem = styled.div`
  display: grid;
  grid-template-columns: repeat(4, auto) 1fr;
  align-items: center;
  align-content: center;
  padding: 10px;
  border: 1px solid rgba(64, 137, 150, 0.2);
  border-radius: 5px;
  grid-column-gap: 8px;
  cursor: pointer;
  margin: 10px 0;

  &:hover {
    background: #fafafa;
  }
  &:hover > div.link {
    text-decoration: underline;
  }
  div {
    display: grid;
    align-items: center;
  }

  div.link {
    font-size: 12px;
    line-height: 24px;
    color: #1a21ba;
    cursor: pointer;
  }

  span.dot {
    display: inline-block;
    width: 3px;
    height: 3px;
    background: #999;
    border-radius: 5px;
    position: relative;
  }

  div.status {
    font-weight: 500;
    font-size: 12px;
    color: #666;
  }

  div.arrow {
    display: grid;
    justify-content: end;
  }
`;

export const PageWrapper = styled.div`
  display: flex;
  flex-wrap: nowrap;
  .mmm {
    padding-left: 20px;
  }
  button {
    float: right;
    width: 130px;
  }
`;

export const PreviewPage = styled.div`
  background: #fff;
  max-height: 800px;
  overflow: auto;
`;

export const TemplateGroupWrapper = styled.div`
  margin: auto;
`;

export const PreviewText = styled.div`
  color: #344563;
  font-family: Inter;
  font-style: normal;
  font-weight: bold;
  font-size: 16px;
  text-align: center;
  margin-bottom: 10px;
`;

export const EmptyPreviewPage = styled.div`
  width: 100%;
  text-align: center;
  padding: 40% 0;
  font-family: Inter;
  background: #f1f1f1;
  color: #999999;
`;

export const TemplateListWrapper = styled.div`
  max-height: 700px;
  overflow-x: hidden;
  overflow-y: auto;
  width: fit-content;
  padding: 0 30px;
`;

export const LetterActionBar = styled.div`
  width: 100%;
  height: 70px;
  background: #fff;
  display: flex;
  justify-content: space-between;
  align-items: center;
  div {
    margin-left: 25px;
  }
  button {
    width: 200px;
    margin-right: 40px;
    border-radius: 5px;
    margin-top: 10px;
  }
`;

export const EditBar = styled.div`
  width: 100%;
  height: 30px;
  background: #fff;
  text-align: center;
  a {
    color: #344563;
    font-family: Inter;
    font-style: normal;
    font-weight: bold;
    font-size: 16px;
    line-height: 19px;
  }
`;

export const FieldWrapper = styled.div`
  margin-bottom: 20px;
  input {
    display: block;
    width: 100%;
    background: #f4f5f7;
    border: 1px solid #c1c7d0;
    border-radius: 4px;
    padding: 5px;
  }
  p {
    font-size: 14px;
    line-height: 17px;
    margin-top: 15px;
    margin-bottom: 5px;
  }
`;

export const SendOfferContainer = styled.div`
  padding: 20px;
  .ql-toolbar {
    background: #f4f5f7;
  }
`;

export const EmailContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  div {
    width: fit-content;
    font-family: Inter;
    font-size: 14px;
    line-height: 17px;
    text-transform: lowercase;
    color: #5e6c84;
    background: #dfe1e6;
    border-radius: 4px;
    padding: 5px;
    margin-right: 10px;
    margin-bottom: 10px;
  }
`;

export const FieldTitle = styled.p`
  font-family: Inter;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 17px;
  text-transform: capitalize;
  color: #091e42;
`;

export const ScrollLoadMore = styled.div`
  text-align: center;
  margin: 30px;
  color: #333333;
`;

export const ScrollLoadMoreText = styled.span`
  text-align: center;
  font-size: 15px;
  font-weight: 650;
  border: 1px solid #1a21ba;
  padding: 15px;
  width: fit-content;
  cursor: pointer;
  color: #333333;
`;

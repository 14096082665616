export const GET_COMPANY_REQUEST = 'GET_COMPANY_REQUEST';
export const GET_COMPANY_SUCCESS = 'GET_COMPANY_SUCCESS';
export const GET_COMPANY_FAILURE = 'GET_COMPANY_FAILURE';

export const GET_PAYROLL_REQUEST = 'GET_PAYROLL_REQUEST';
export const GET_PAYROLL_SUCCESS = 'GET_PAYROLL_SUCCESS';
export const GET_PAYROLL_FAILURE = 'GET_PAYROLL_FAILURE';

export const GET_PAYROLL_COMPANY_REQUEST = 'GET_PAYROLL_COMPANY_REQUEST';
export const GET_PAYROLL_COMPANY_SUCCESS = 'GET_PAYROLL_COMPANY_SUCCESS';
export const GET_PAYROLL_COMPANY_FAILURE = 'GET_PAYROLL_COMPANY_FAILURE';
export const GET_VALIDATED_PAYROLL_SUCCESS = 'GET_VALIDATED_PAYROLL_SUCCESS';

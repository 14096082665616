import React, { useState, useEffect } from 'react';
import { svgs } from 'assets';
import {
    PaginationContainer,
    PageSelector,
    ArrrowDown,
} from 'components/common/Styles';
import { paginatorRowGenerator } from 'utils/paginationRowGenerator';
import Pagination from 'components/Pagination';

/**
 * just another toggle hook
 *
 * @return { show, toggle }
 */
export const useToggle = (state=false) => {

    const [show, setShow] = useState(state);

    const toggle = () => {
        setShow(prev => !prev)
    }
    return {
        show,
        toggle
    }
}

export const usePagination = (requestData) => {
    const pageOptions = paginatorRowGenerator(requestData?.total);
    const [offset, setOffset] = useState(1);
    const [perPage, setPerPage] = useState(10);
    const [current, setCurrent] = useState([]);
    const totalPage = Math.ceil(requestData?.total / perPage);
    const handleNextPage = () => {
        const newOffset = offset + 1;
        setOffset(newOffset);
    };

    const make2dArray = (array, size) => {
        const newArr = [];
        while (array?.length) newArr.push(array.splice(0, size));
        return newArr;
    };

    const copyOfResults = requestData?.results?.slice(0);

    useEffect(() => {
        const paginated = make2dArray(copyOfResults, perPage);
        setCurrent(paginated);

        // eslint-disable-next-line
    }, [perPage]);

    const handlePrevPage = () => {
        const newOffset = offset - 1;
        setOffset(newOffset);
    };

    const handlePageSelector = (e) => {
        setPerPage(e.target.value);
        setOffset(1);
    };

    const component = () => (
        <PaginationContainer>
            <Pagination
                currentPage={offset}
                totalPages={totalPage}
                handleNextPage={handleNextPage}
                handlePrevPage={handlePrevPage}
            />
            <div>
                <span>Rows Per Page &nbsp;</span>
                <span>
                    <PageSelector value={perPage} onChange={handlePageSelector}>
                        {pageOptions.map((pageNum, index) => (
                            <option key={index} value={pageNum}>
                                {pageNum}
                            </option>
                        ))}
                    </PageSelector>
                    <ArrrowDown src={svgs.ARROW_DOWN} alt="arrow down" />
                </span>
            </div>
        </PaginationContainer>
    )
    return {
        component,
        current,
    }
};



/**
 *
 * @param {*} pages
 * @param {Object} config additional optional configuration values
 * @param {Boolean} config.scrollToRef The reference to scroll to after every navigation. Pass `false` or simply do not pass to disable the automatic scrolling.
 * @returns
 */
export default function useStepperForm(pages) {
  const [currentPosition, setCurrentPosition] = useState(0);

  const isFirstPage = currentPosition === 0;
  const isFinalPage = currentPosition === Object.keys(pages).length - 1;

  const navigateTo = (position) => {
    setCurrentPosition(position);
  };

  return {
    pageName: Object.keys(pages)[currentPosition],
    CurrentPage: pages[Object.keys(pages)[currentPosition]],
    next: () => {
      if (!isFinalPage) navigateTo(currentPosition + 1);
    },
    back: () => {
      if (!isFirstPage) navigateTo(currentPosition - 1);
    },
    goTo: (position) => navigateTo(position),
    isFirstPage,
    isFinalPage,
    pageNames: Object.keys(pages),
    position: currentPosition,
  };
}

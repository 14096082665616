import React from 'react';
import { NavLink } from 'react-router-dom';
import PropTypes from 'prop-types';
import { Caret } from './styles';

/**
 * Custom link component
 *
 * @param {Object} props Component props
 * @param {JSX.Element} leftIcon leftIcon element props
 * @param {String} label link name
 * @returns {JSX.Element} component
 */
const CustomLink = ({
  leftIcon: LEFTICON,
  label,
  path,
  updateMenuDisplay,
  showMenu,
  id,
  subMenus,
  more,
}) => {
  return (
    <React.Fragment>
      <li onClick={updateMenuDisplay}>
        <div>
          {LEFTICON && <LEFTICON />}
          {more ? (
            <p id={id}>{label}</p>
          ) : (
            <NavLink activeClassName="activeLink" to={path}>
              {label}
            </NavLink>
          )}
        </div>

        {more && (
          <Caret
            show={showMenu ? 'show' : 'hide'}
            stroke="red"
            id={id}
            onClick={updateMenuDisplay}
          />
        )}
      </li>
      {showMenu && (
        <li className="sub-menu">
          {subMenus &&
            subMenus.map((item, key) => (
              <NavLink
                key={key}
                activeClassName="activeLink"
                className="sub-menu-text"
                to={`${path}${item?.path}`}
              >
                {item?.label}
              </NavLink>
            ))}
        </li>
      )}
    </React.Fragment>
  );
};

CustomLink.propTypes = {
  leftIcon: PropTypes.object,
  more: PropTypes.bool,
  label: PropTypes.string.isRequired,
  path: PropTypes.string,
};

export default CustomLink;

export const GET_COMPANIES_WITHOUT_POLICY_REQUEST = 'GET_COMPANIES_WITHOUT_POLICY_REQUEST';
export const GET_COMPANIES_WITHOUT_POLICY_SUCCESS = 'GET_COMPANIES_WITHOUT_POLICY_SUCCESS';
export const GET_COMPANIES_WITHOUT_POLICY_FAILURE = 'GET_COMPANIES_WITHOUT_POLICY_FAILURE';

export const CREATE_POLICY_REQUEST = 'CREATE_POLICY_REQUEST';
export const CREATE_POLICY_SUCCESS = 'CREATE_POLICY_SUCCESS';
export const CREATE_POLICY_FAILURE = 'CREATE_POLICY_FAILURE';

export const GET_POLICIES_REQUEST = 'GET_POLICIES_REQUEST';
export const GET_POLICIES_SUCCESS = 'GET_POLICIES_SUCCESS';
export const GET_POLICIES_FAILURE = 'GET_POLICIES_FAILURE';

export const EDIT_POLICY_REQUEST = 'EDIT_POLICY_REQUEST';
export const EDIT_POLICY_SUCCESS = 'EDIT_POLICY_SUCCESS';
export const EDIT_POLICY_FAILURE = 'EDIT_POLICY_FAILURE';

export const DELETE_POLICY_REQUEST = 'DELETE_POLICY_REQUEST';
export const DELETE_POLICY_SUCCESS = 'DELETE_POLICY_SUCCESS';
export const DELETE_POLICY_FAILURE = 'DELETE_POLICY_FAILURE';
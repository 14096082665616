import React from 'react';
import { Row } from 'react-bootstrap';
import { AuthPageContainer, SigninArea, Logo, SigninFormArea } from './styles';
import { svgs } from '../../assets';
import AuthSidebar from '../../components/Auth/Sidebar';
import { NavLink } from 'react-router-dom';
import Button from '../../components/common/Button';

const MailSent = ({ history }) => {
  const backToForgotPassword = () => {
    history.push('/forgot-password');
  };

  return (
    <AuthPageContainer fluid>
      <Row>
        <AuthSidebar />
        <SigninArea lg={8} md={8}>
          <Logo src={svgs.logo_colored} />
          <SigninFormArea lg={5} md={6} sm={12} className="text-center
          ">
            <h1>Reset Password</h1>
            <p>We sent a reset link to your registered email address. Check your mailbox</p>

            <Button type="button" text="Resend Link" onClick={backToForgotPassword} />
            <NavLink to="/signin">Back to Login</NavLink>
          </SigninFormArea>
        </SigninArea>
      </Row>
    </AuthPageContainer>
  );
};

export default MailSent;

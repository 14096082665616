export const GET_WELFARE_START = 'GET_WELFARE_START';
export const GET_WELFARE_SUCCESS = 'GET_WELFARE_SUCCESS';
export const GET_WELFARE_FAIL = 'GET_WELFARE_FAIL';
export const GET_WELFARE_EVENTS_SUCCESS = 'GET_WELFARE_EVENTS_SUCCESS';

export const SEND_WELFARE_MSG_START = 'SEND_WELFARE_MSG_START';
export const SEND_WELFARE_MSG_SUCCESS = 'SEND_WELFARE_MSG_SUCCESS';
export const SEND_WELFARE_MSG_FAIL = 'SEND_WELFARE_MSG_FAIL';

export const ADD_WELFARE_LETTER_REQUEST = ' ADD_WELFARE_LETTER_REQUEST';
export const ADD_WELFARE_LETTER_SUCCESS = ' ADD_WELFARE_LETTER_SUCCESS';
export const ADD_WELFARE_LETTER_FAIL = ' ADD_WELFARE_LETTER_FAIL';

export const EDIT_WELFARE_LETTER_REQUEST = ' EDIT_WELFARE_LETTER_REQUEST';
export const EDIT_WELFARE_LETTER_SUCCESS = ' EDIT_WELFARE_LETTER_SUCCESS';
export const EDIT_WELFARE_LETTER_FAIL = ' EDIT_WELFARE_LETTER_FAIL';

export const GET_LETTER_START = ' GET_LETTER_START';
export const GET_LETTER_SUCCESS = ' GET_LETTER_SUCCESS';
export const GET_LETTER_FAIL = ' GET_LETTER_FAIL';

export const DELETE_WELFARE_LETTER_START = ' DELETE_WELFARE_LETTER_START';
export const DELETE_WELFARE_LETTER_SUCCESS = ' DELETE_WELFARE_LETTER_SUCCESS';
export const DELETE_WELFARE_LETTER_FAIL = ' DELETE_WELFARE_LETTER_FAIL';

export const GET_WELFARE_TYPE_START = ' GET_WELFARE_TYPE_START';
export const GET_WELFARE_TYPE_SUCCESS = ' GET_WELFARE_TYPE_SUCCESS';
export const GET_WELFARE_TYPE_FAIL = ' GET_WELFARE_TYPE_FAIL';

export const DELETE_EMPLOYEE_WELFARE_START = ' DELETE_EMPLOYEE_WELFARE_START';
export const DELETE_EMPLOYEE_WELFARE_SUCCESS = ' DELETE_EMPLOYEE_WELFARE_SUCCESS';
export const DELETE_EMPLOYEE_WELFARE_FAIL = ' DELETE_EMPLOYEE_WELFARE_FAIL';

export const GET_EMPLOYEE_WELFARE_HISTORY_START = 'GET_EMPLOYEE_WELFARE_HISTORY_START';
export const GET_EMPLOYEE_WELFARE_HISTORY_SUCCESS = 'GET_EMPLOYEE_WELFARE_HISTORY_SUCCESS';
export const GET_EMPLOYEE_WELFARE_HISTORY_FAIL = 'GET_EMPLOYEE_WELFARE_HISTORY_FAIL';

export const SEND_EMPLOYEE_WELFARE_MSG_START = 'SEND_EMPLOYEE_WELFARE_MSG_START';
export const SEND_EMPLOYEE_WELFARE_MSG_SUCCESS = 'SEND_EMPLOYEE_WELFARE_MSG_SUCCESS';
export const SEND_EMPLOYEE_WELFARE_MSG_FAIL = 'SEND_EMPLOYEE_WELFARE_MSG_FAIL';

export const GET_WELFARE_TYPES_LIST_SUCCESS = 'GET_WELFARE_TYPES_LIST_SUCCESS';
export const WELFARE_DASHBOARD_DATA_SUCCESS = 'WELFARE_DASHBOARD_DATA_SUCCESS';



export const ADD_UNIT_REQUEST = 'ADD_UNIT_REQUEST';
export const ADD_UNIT_SUCCESS = 'ADD_UNIT_SUCCESS';
export const ADD_UNIT_FAILURE = 'ADD_UNIT_FAILURE';

export const GET_UNITS_REQUEST = 'GET_UNITS_REQUEST';
export const GET_UNITS_SUCCESS = 'GET_UNITS_SUCCESS';
export const GET_UNITS_FAILURE = 'GET_UNITS_FAILURE';

export const EDIT_UNIT_REQUEST = 'EDIT_UNIT_REQUEST';
export const EDIT_UNIT_SUCCESS = 'EDIT_UNIT_SUCCESS';
export const EDIT_UNIT_FAILURE = 'EDIT_UNIT_FAILURE';

export const DELETE_UNIT_REQUEST = 'DELETE_UNIT_REQUEST';
export const DELETE_UNIT_SUCCESS = 'DELETE_UNIT_SUCCESS';
export const DELETE_UNIT_FAILURE = 'DELETE_UNIT_FAILURE';
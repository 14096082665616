import * as actions from '../actionTypes/confirmationPolicy';

const initialState = {
  loading: false,
  data: [],
  policies: [],
};

const confirmationPolicyReducer = (state = initialState, action) => {
  switch (action.type) {
    case actions.GET_COMPANIES_WITHOUT_POLICY_REQUEST:
      return {
        ...state,
        loading: true,
        success: false,
      };
    case actions.GET_COMPANIES_WITHOUT_POLICY_SUCCESS:
      return {
        ...state,
        loading: false,
        success: true,
        data: action.payload?.data,
      };
    case actions.GET_COMPANIES_WITHOUT_POLICY_FAILURE:
      return {
        ...state,
        loading: false,
        success: false,
        error: action.payload,
      };

    case actions.CREATE_POLICY_REQUEST:
      return {
        ...state,
        loading: true,
        success: false,
      };
    case actions.CREATE_POLICY_SUCCESS:
      return {
        ...state,
        loading: false,
        success: true,
      };
    case actions.CREATE_POLICY_FAILURE:
      return {
        ...state,
        loading: false,
        success: false,
      };

    case actions.GET_POLICIES_REQUEST:
      return {
        ...state,
        loading: true,
        success: false,
      };
    case actions.GET_POLICIES_SUCCESS:
      return {
        ...state,
        loading: false,
        success: true,
        policies: action.payload?.data,
      };
    case actions.GET_POLICIES_FAILURE:
      return {
        ...state,
        loading: false,
        success: false,
        error: action.payload,
      };

    case actions.EDIT_POLICY_REQUEST:
      return {
        ...state,
        loading: true,
        success: false,
      };
    case actions.EDIT_POLICY_SUCCESS:
      return {
        ...state,
        loading: false,
        success: true,
      };
    case actions.EDIT_POLICY_FAILURE:
      return {
        ...state,
        loading: false,
        success: false,
        error: action.payload,
      };
      case actions.DELETE_POLICY_REQUEST:
      return {
        ...state,
        loading: true,
        success: false,
      };
    case actions.DELETE_POLICY_SUCCESS:
      return {
        ...state,
        loading: false,
        success: true,
      };
    case actions.DELETE_POLICY_FAILURE:
      return {
        ...state,
        loading: false,
        success: false,
        error: action.payload,
      };
    default:
      return {
        ...state,
      };
  }
};

export default confirmationPolicyReducer;

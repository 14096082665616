import axios from 'config/axios';
import {
  INITIATE_ONBOARDING_REQUEST,
  INITIATE_ONBOARDING_SUCCESS,
  // INITIATE_ONBOARDING_FAILURE,
  COMPLETE_ONBOARDING_REQUEST,
  COMPLETE_ONBOARDING_SUCCESS,
  COMPLETE_ONBOARDING_FAILURE,
  GET_EMPLOYEE_ONBOARDING_REQUEST,
  GET_EMPLOYEE_ONBOARDING_RESPONSE,
  GET_ONBOARDING_SETTINGS_REQUEST,
  CREATE_ONBOARDING_SETTINGS_REQUEST,
  CREATE_ONBOARDING_SETTINGS_SUCCESS,
  GET_ONBOARDING_SETTINGS_SUCCESS,

} from '../actionTypes/onboarding';

const initiateOnboardingRequest = () => ({
  type: INITIATE_ONBOARDING_REQUEST,
});

const initiateOnboardingSuccess = (payload) => ({
  type: INITIATE_ONBOARDING_SUCCESS,
  payload,
});

// const initiateOnboardingFailure = (error) => ({
//   type: INITIATE_ONBOARDING_FAILURE,
//   error,
// });

export const initiateOnboarding = ({ values }, toast) => async (dispatch) => {
  try {
    dispatch(initiateOnboardingRequest());
    const request = await axios.post('/employee/initiate_onboarding/', values);
    return dispatch(initiateOnboardingSuccess(request));
  } catch (error) {
    toast.error('An Error Occurred While Iniitiating Onboarding');
    return { error: error?.response?.data } || 'error';
  }
};

const completeOnboardingRequest = () => ({
  type: COMPLETE_ONBOARDING_REQUEST,
});

const completeOnboardingSuccess = (payload) => ({
  type: COMPLETE_ONBOARDING_SUCCESS,
  payload,
});

const completeOnboardingFailure = (error) => ({
  type: COMPLETE_ONBOARDING_FAILURE,
  error,
});

export const completeOnboarding = (values, toast) => async (dispatch) => {
  try {
    dispatch(completeOnboardingRequest());
    const request = await axios.patch('/employee/complete_onboarding/', {requesters: values.candidates});
    return dispatch(completeOnboardingSuccess(request));
  } catch (error) {
    toast.error(error.response.data.message || 'An error occured');
    dispatch(completeOnboardingFailure(error));
    return { error: true };
  }
};

export const sendNotification = (toast, notificationData, url = '/candidate/send_notification/') => async (dispatch) => {
  try {
    await axios.post(url, notificationData);
    toast.success('Email Notification Sent Successfully');
  } catch (error) {
    const { response: data } = error;
    toast.error(data?.message || data?.errors || 'Failed To Send Email Notification');
  }
};

export const respondToWaiver = (status, candidate, toast) => async (dispatch) => {
  try {
    await axios.patch('/employee/approve_waiver/', { status, candidate });
    return true;
  } catch (error) {
    toast.error(error?.response?.data?.message || error?.response?.errors || `Failed To ${ status } Waiver`);
  }
}

export const sendWaiverRequest = (toast, notificationData, url = '/employee​/request_waiver​/', requesters = []) => async (dispatch) => {
  try {
    const appUrl = window.location.origin;
    const waiverData = {
      body: notificationData.message +
      `<div style="text-align: left;">
      <a href="${appUrl}/onboarding/accept/waiver/{url_data}" style="text-decoration: none;  color: white; ">
        <span style="
        height: 22px;
        background: #195E7F;
        border-radius: 5px; margin-right: 15px;padding: 10px; cursor: pointer;
        width: 200px; text-align: center; display: inline-block;">
        Accept Waiver</span>
      </a>
      <a href="${appUrl}/onboarding/decline/waiver/{url_data}" style="text-decoration: none;  color: #195E7F; ">
        <span style="
        height: 22px;
        background: #fff;
        border: 1px solid #195E7F;
        border-radius: 5px; padding: 10px; cursor: pointer;
        width: 200px; text-align: center; display: inline-block;
        position: relative; top: -50px;
        "> Decline Waiver
        </span>
      </a>
    </div>`,
      manager: notificationData.email[0],
      requesters,
    };
    await axios.post('/employee/request_waiver/', waiverData);
    toast.success('Waiver Request Sent Successfully');
  } catch (error) {
    const { response: data } = error;
    toast.error(data?.message || data?.errors || 'Failed To Send Waiver Request');
  }
};

export const sendSmsNotification = (toast, notificationData, url = '/candidate/send_sms_notification/') => async (dispatch) => {
  try {
    await axios.post(url, notificationData);
    toast.success('SMS Notification Sent Successfully');
  } catch (error) {
    const { response: data } = error;
    toast.error(data?.message || data?.errors || 'Failed To Send SMS Notification');
  }
};

export const getOnboardingEmployee = (url = null) => async (dispatch) => {
  dispatch({
    type: GET_EMPLOYEE_ONBOARDING_REQUEST,
  });
  const response = await axios.get(url || '/employee/employee_onboarding/');
  dispatch({
    type: GET_EMPLOYEE_ONBOARDING_RESPONSE,
    payload: { response: response?.data },
  });
};

export const getSettings = (toast) => async (dispatch) => {
  try {
    dispatch({
      type: GET_ONBOARDING_SETTINGS_REQUEST,
    });
    const response = await axios.get('/onboarding-workflow/workflow_settings/');
    dispatch({
      type: GET_ONBOARDING_SETTINGS_SUCCESS,
      payload: { response: response?.data?.data },
    });
  } catch (error) {
    toast.error('unable to get workflow settings');
  }
};

export const createSettings = (toast, data) => async (dispatch) => {
  try {
    dispatch({
      type: CREATE_ONBOARDING_SETTINGS_REQUEST,
    });
    const response = await axios.post('/onboarding-workflow/workflow_settings/', data);
    dispatch({
      type: CREATE_ONBOARDING_SETTINGS_SUCCESS,
      payload: { response: response?.data },
    });
    dispatch(getSettings());
    toast.success('Created Successfully');
  } catch (error) {
    toast.error('unable to create workflow settings');
  }
};

export const applyWorkflowSetting = (toast, workflow, setting) => async (dispatch) => {
  try {
    await axios.patch(`/approval/${setting.approval_workflow}/`, {
      title: workflow.name,
      workflow: workflow.id,
    });
    toast.success('approval have been assigned to selected workflow successfully');
  } catch (error) {
    toast.error('failed to assign approval to selected workflow');
  }
};

export const removeWorkflowSetting = (toast, workflow, setting) => async (dispatch) => {
  try {
    await axios.patch(`/approval/${setting.approval_workflow}/`, {
      title: workflow.name,
      workflow: null,
    });
    toast.success('approval have been removed from selected workflow successfully');
  } catch (error) {
    toast.error('failed to assign approval to selected workflow');
  }
};

export const editSettings = (toast, id, data) => async (dispatch) => {
  try {
    dispatch({
      type: CREATE_ONBOARDING_SETTINGS_REQUEST,
    });
    const response = await axios.post('/onboarding-workflow/workflow_settings/', data);
    dispatch({
      type: CREATE_ONBOARDING_SETTINGS_SUCCESS,
      payload: { response: response?.data },
    });
  } catch (error) {
    toast.error('unable to edit workflow settings');
  }
};

import * as types from '../actionTypes/employeeComplaints';

const initialState = {
  loading: false,
  data: [],
  error: null,
  success: false,
  categories: [],
  complaintSuccess: false,
  complaintFailure: false,
  employeeComplaintsList:[],
  employeeEachComplaint:[],
  singleCategory:{}
};

const sendEmployeeComplaintsStart = (state) => ({
  ...state,
  error: null,
  loading: true,
});

const sendEmployeeComplaintsSuccess = (state) => ({
  ...state,
  error: null,
  success: true,
  loading: false,
  complaintSuccess: true,
  complaintFailure: false,
});

const sendEmployeeComplaintsFailure = (state, action) => ({
  ...state,
  error: action.error,
  success: false,
  loading: false,
  complaintSuccess: false,
  complaintFailure: true,
});

const getEmployeesComplaintsCategoriesStart = (state) => ({
  ...state,
  error: null,
  loading: false,
});


const getEmployeesComplaintsCategoriesFailure = (state, action) => ({
  ...state,
  error: action.error,
  success: false,
  loading: false,
});




const employeeComplaintsReducer = (state = initialState, action) => {
  switch (action.type) {
    case types.SEND_EMPLOYEE_COMPLAINT_START:
      return sendEmployeeComplaintsStart(state);
    case types.SEND_EMPLOYEE_COMPLAINT_SUCCESS:
      return sendEmployeeComplaintsSuccess(state, action);
    case types.SEND_EMPLOYEE_COMPLAINT_FAILURE:
      return sendEmployeeComplaintsFailure(state, action);
    case types.GET_EMPLOYEE_COMPLAINT_CATEGORIES_START:
      return getEmployeesComplaintsCategoriesStart(state);
    case types.GET_EMPLOYEE_COMPLAINT_CATEGORIES_FAILURE:
      return getEmployeesComplaintsCategoriesFailure(state, action);
      case types.GET_EMPLOYEE_COMPLAINT_CATEGORIES_SUCCESS:
        return {
          ...state,
        error: null,
        loading: false,
        categories: action.payload
        }; 
    case types.GET_EMPLOYEE_COMPLAINT_LIST_START:
      return {
        ...state,
      error: null,
      loading: false,
      }; 
      
    case types.GET_EMPLOYEE_COMPLAINT_LIST_SUCCESS:
      return {
        ...state,
        error: null,
        loading: false,
        employeeComplaintsList: action.payload,
      };   
    case types.GET_EMPLOYEE_EACH_COMPLAINT_START:
      return {
        ...state,
      error: null,
      loading: false,
      }; 
      
    case types.GET_EMPLOYEE_EACH_COMPLAINT_SUCCESS:
      return {
        ...state,
        error: null,
        loading: false,
        employeeEachComplaint: action.payload,
      };  

    case types.SEND_EMPLOYEE_COMPLAINT_CATEGORIES_START:
      return {
        ...state,
      error: null,
      loading: true,
      }; 
      
    case types.SEND_EMPLOYEE_COMPLAINT_CATEGORIES_SUCCESS:
      return {
        ...state,
        error: null,
        loading: false,
        
      }; 
      
    case types.UPDATE_EMPLOYEE_COMPLAINT_CATEGORIES_START:
      return {
        ...state,
      error: null,
      loading: true,
      }; 
      
    case types.UPDATE_EMPLOYEE_COMPLAINT_CATEGORIES_SUCCESS:
      return {
        ...state,
        error: null,
        loading: false,
        
      }; 
      
      case types.GET_SINGLE_COMPLAINT_CATEGORIES_START:
        return {
          ...state,
        error: null,
        loading: true,
        }; 
        
      case types.GET_SINGLE_COMPLAINT_CATEGORIES_SUCCESS:
        return {
          ...state,
          error: null,
          loading: false,
          singleCategory:action.payload
        };  
    default:
      return {
        ...state,
      };
  }
};

export default employeeComplaintsReducer;







import React from 'react';
import PageContainer from '../../components/common/PageContainer';

const NotFound = () => {
  return (
    <PageContainer>
      <>
        <div className="app">
          <p> 404. Not Found</p>
        </div>
      </>
    </PageContainer>
  );
};

export default NotFound;

import React, { useState } from 'react';
import { Row } from 'react-bootstrap';
import { AuthPageContainer, SigninArea, Logo, SigninFormArea } from './styles';
import { svgs } from '../../assets';
import AuthSidebar from '../../components/Auth/Sidebar';
import { NavLink } from 'react-router-dom';
import Input from '../../components/common/Input';
import Button from '../../components/common/Button';

import ErrorMessage from 'components/common/ErrorMessage';
import { forgotPassword } from 'redux/actions/user';
import { useDispatch } from 'react-redux';

const ForgotPassword = ({ history }) => {
  const dispatch = useDispatch();
  const [input, setInputs] = useState({
    email: '',
  });
  const [inputError, setInputError] = useState('');
  const [actionError, setActionError] = useState('');

  const inputHandler = (e) => {
    e.persist();
    setInputs((inputs) => ({
      ...inputs,
      [e.target.name]: e.target.value.trim(),
    }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setActionError('');
    setInputError('');
    if (!input.email) {
      setInputError('Email is required!');
    } else {
      try {
        const response = await dispatch(forgotPassword(input.email));
        if (response.payload.status === 200) {
          history.push('/mail-sent');
        } else {
          setActionError(response.payload.data.message);
          dispatch({
            type: 'USER_INIT_STATE',
          });
        }
      } catch (e) {
        setActionError(e.response.data.message);
      }
    }
  };

  return (
    <AuthPageContainer fluid>
      <Row>
        <AuthSidebar />
        <SigninArea lg={8} md={8}>
          <Logo src={svgs.logo_colored} />
          <SigninFormArea lg={5} md={6} sm={12} className="text-center">
            <h1>Reset Password</h1>
            <p>
              Enter the email address linked to your account and you will get a reset link
            </p>
            <br />
            {actionError && <ErrorMessage message={actionError} position="center" />}
            <form onSubmit={handleSubmit}>
              <Input
                type="email"
                name="email"
                onChange={inputHandler}
                label="Email Address"
                placeholder="email@example.com"
                required={true}
              />
              {inputError && <ErrorMessage message={inputError} position="left" />}
              <Button type="submit" text="Continue" />
              <NavLink to="/signin">Back to Login</NavLink>
            </form>
          </SigninFormArea>
        </SigninArea>
      </Row>
    </AuthPageContainer>
  );
};

export default ForgotPassword;

/* eslint-disable jsx-a11y/no-noninteractive-element-to-interactive-role */
import React, { useCallback, useEffect, useState } from 'react';
import jwt from 'jsonwebtoken';
import PageContainer from 'components/common/PageContainer';
import { svgs } from 'assets';
import { useDispatch } from 'react-redux';
import { forgotPassword, verifyUser } from 'redux/actions/user';
import { ToastError, ToastSuccess } from 'components/common/Toast';
import { useHistory } from 'react-router-dom';
import { ContentWrapper } from 'pages/Auth/styles';
import { FormContainer, Wrapper } from './styles';

const CandidateSignup = () => {
  const dispatch = useDispatch();
  const [data, setData] = useState({});
  const [status, setStatus] = useState(null);
  const history = useHistory();
  const [toastOpen, setToastOpen] = useState(false);
  const [toastErrorOpen, setToastErrorOpen] = useState(false);
  const [toastSuccessMessage, setToastSuccessMessage] = useState('');
  const [toastErrorMessage, setToastErrorMessage] = useState('');
  const [verifyLoading, setVerifyLoading] = useState(false);
  const token = history.location.search.split('?d=')[1];

  useEffect(() => {
    const formdata = jwt.decode(token);
    setData(formdata);
  }, [token]);

  const userEmail = data?.email;
  const handleFormSubmit = useCallback(async () => {
    if (userEmail) {
      try {
        const result = await dispatch(forgotPassword(userEmail));
        if (result?.payload?.status === 200) {
          setToastSuccessMessage(result?.payload?.data?.message);
          setToastOpen(true);
          history.push('/mail-sent');
        }

        if (result?.payload?.status === 400) {
          setToastErrorMessage(result?.payload?.data?.errors);
          setToastErrorOpen(true);
        }
      } catch (error) {
        setToastErrorMessage('Something went wrong, Try again!');
        setToastErrorOpen(true);
      }
    }
  }, [userEmail, dispatch, history]);

  const verifyToken = useCallback(async () => {
    setVerifyLoading(true);
    try {
      const result = await dispatch(verifyUser({ token }));
      setVerifyLoading(false);
      if (result?.payload?.status === 401) {
        setStatus('error');
        return;
      }
      if (result?.payload?.status === 200 || result?.payload?.success) {
        setStatus('success');
        handleFormSubmit();
        return;
      }
    } catch (error) {
      setVerifyLoading(false);
    }
  }, [dispatch, handleFormSubmit, token]);

  useEffect(() => {
    verifyToken();
  }, [verifyToken]);

  if (verifyLoading) {
    return (
      <PageContainer>
        <ContentWrapper>
          <img src={svgs.LOADER} alt="loader" />
        </ContentWrapper>
      </PageContainer>
    );
  }

  return (
    <PageContainer>
      {status === 'error' && (
        <FormContainer>
          <div className="logo-wrapper">
            <svgs.COLOURED_LOGO />
          </div>
          <Wrapper>
            <div className="content status-wrapper">
              <div className="logo-wrapper">
                <img src={svgs.DELETE_WARN} alt="delete-warn" />
              </div>
              <p className="header">Error</p>
              <p className="note">Token is invalid</p>
            </div>
          </Wrapper>
        </FormContainer>
      )}
      <ToastSuccess
        message={toastSuccessMessage}
        isOpen={toastOpen}
        close={() => {
          setToastOpen(!toastOpen);
        }}
      />
      <ToastError
        message={toastErrorMessage}
        isOpen={toastErrorOpen}
        close={() => {
          setToastErrorOpen(!toastErrorOpen);
        }}
      />
    </PageContainer>
  );
};

export default CandidateSignup;

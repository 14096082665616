import axios from 'config/axios';

export const loginApi = async (data) => {
  const request = await axios.post('/auth/login/', data);
  return request;
};

export const forgotPasswordApi = async (data) => {
  const request = await axios.post('/auth/users/reset-password/', { email: data });
  return request;
};

export const updatePasswordApi =  (token, password) => {
  return  ''
};

export const getNotificationApi = async () => {
const request =  await axios.get('auth/users/notification/');
return request;
}

export const readNotificationApi = async (data) => {
  const request = await axios.post('/auth/users/read_notification/', {id: data});
  return request;
};

import React, { useState } from 'react';
import Button from 'components/common/Button';
import { useHistory } from 'react-router-dom';
import { Wrapper } from 'pages/Candidate/Offer/styles';
import { Input } from 'components/common';
import { FormContainer} from 'pages/Onboarding/styles';
import Checkbox from 'components/common/Checkbox/Checkbox';
import { useFormik } from 'formik';
import { acceptOfferSchema } from 'utils/validators';
import { useDispatch } from 'react-redux';
import { acceptOffer } from 'redux/actions/offer';
import { useSelector } from 'react-redux';
import Select from 'components/common/SelectDropdown';



export const Step4 = (activeStep, setActiveStep, data) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const [isChecked, setIsChecked] = useState(false);
  const { pensionManagers, banks } = useSelector((state) => state.settings);
  const [loading, setLoading] = useState(false);
  const toggleCheck = () =>
    setIsChecked((prev) => {
      setFieldValue('handbook_confirmation', !prev ? 'True' : '');
      return !prev;
    });
  const offerId = history.location.pathname.split('/')[4];

  const handleFormSubmit = async (values, { errors }) => {
    const formData = new FormData();
    formData.append('handbook_confirmation', isChecked ? 'True' : 'False');
    formData.append('firstname', values?.firstname);
    formData.append('lastname', values?.lastname);
    formData.append('address', values?.address);
    // formData.append('branch', values?.branch);
    formData.append('resumption_date', values?.resumption_date);
    formData.append('employment_date', values?.employment_date);
    formData.append('tax_id', values?.tax_id);
    formData.append('bvn', values?.bvn);

    if (values?.bank)
       formData.append('bank_name', values?.bank);
    if (values?.account_number)  
       formData.set('account_number', values?.account_number); 
    if (values?.pension_manager)   
       formData.set('pension_manager', values?.pension_manager);
    if (values?.pension_pin)  
       formData.set('pension_pin', values?.pension_pin);  

    formData.append('supervisor_name', values?.supervisor_name);
    formData.append('acknowledgement_letter', data?.acknowledgement_letter);
    formData.append('deployment_letter', data?.deployment_letter);
    formData.append('handbook', data?.handbook);

    setLoading(true);
    try {
      const result = await dispatch(acceptOffer(offerId, formData));
      setLoading(false);
      if (result?.payload.data) {
        history.push('/onboarding/offer-status');
      }
    } catch (error) {
      setLoading(false);
    }
  };
 const rearrangeDate=(date_applied)=>{
  if (date_applied){
  let [mm, dd, yyyy] = date_applied?.split("/");
  return `${yyyy}-${mm}-${dd}`;
  }
  return date_applied
 }
  

  const {
    handleSubmit,
    values,
    errors,
    touched,
    handleBlur,
    handleChange,
    setFieldValue,
  } = useFormik({
    initialValues: {
      firstname: data?.firstname || '',
      lastname: data?.lastname || '',
      address: '',
      // branch: data?.branch || '',
      // branch_name: data?.branch_name || '',
      resumption_date: rearrangeDate(data?.resumption_date) || '',
      employment_date: '',
      bank_name: '',
      supervisor_name: '',
      account_number: '',
      pension_manager: '',
      pension_pin: '',
      handbook_confirmation: '',
      bvn:'',
      tax_id:'',
    },
    onSubmit: handleFormSubmit,
    validationSchema: acceptOfferSchema,
    validateOnChange: true,
    enableReinitialize: true,
  });
  return (
    <Wrapper>

      <div className="content">
        <FormContainer style={{ padding: 10, width: '90%' }}>
          <div className="form-grid">
            <Input
              label="First Name"
              name="firstname"
              onChange={handleChange}
              // required
              disabled
              error={touched.firstname && errors.firstname}
              onBlur={handleBlur}
              value={values.firstname}
            />
            <Input
              label="Last Name"
              name="lastname"
              onChange={handleChange}
              // required
              disabled
              error={touched.lastname && errors.lastname}
              onBlur={handleBlur}
              value={values.lastname}
            />
          </div>
          <Input
            label="Residential Address"
            name="address"
            required
            onChange={handleChange}
            error={touched.address && errors.address}
            onBlur={handleBlur}
            value={values.address}
          />


         
          <div className="form-grid">
            <Input
              label="Date of employment"
              name="employment_date"
              onChange={handleChange}
              type="date"
              required
              error={touched.employment_date && errors.employment_date}
              onBlur={handleBlur}
              value={values.employment_date}
            />
            <Input
              label="Date of Resumption"
              name="resumption_date"
              onChange={handleChange}
              type="date"
              required
              error={touched.resumption_date && errors.resumption_date}
              onBlur={handleBlur}
              value={values.resumption_date}
            />
          </div>

          <div className="form-grid">
            <Input
              label="BVN"
              name="bvn"
              onChange={handleChange}
              required
              error={touched.bvn && errors.bvn}
              onBlur={handleBlur}
              value={values.bvn}
            />
            <Input
              label="TAX ID"
              name="tax_id"
              onChange={handleChange}
              // required
              error={touched.tax_id && errors.tax_id}
              onBlur={handleBlur}
              value={values.tax_id}
            />
          </div>
          <div className="form-grid">
            <Select
              name="pension_manager"
              error={errors?.pension_manager}
              label="Pension Manager"
              onChange={(e) => setFieldValue('pension_manager', e.target.value)}
              placeholder="Pension Manager"
            >
              <option value={''}>{'Select Pension Manager'}</option>
              {(pensionManagers?.results || []).map((each) => (
                <option value={each.id} key={each.id}>
                  {' '}
                  {each.name}
                </option>
              ))}
            </Select>
   
            <Input
              label="Pension Pin"
              name="pension_pin"
              onChange={handleChange}
              error={touched.pension_pin && errors.pension_pin}
              onBlur={handleBlur}
              value={values.pension_pin}
            />
          </div>
          <div className="form-grid">
            <Input
              label="Salary Account Number"
              name="account_number"
              onChange={handleChange}
              error={touched.account_number && errors.account_number}
              onBlur={handleBlur}
              value={values.account_number}
            />
            <Select
              name="bank"
              error={errors?.bank}
              label="Bank"
              onChange={(e) => setFieldValue('bank', e.target.value)}
              placeholder="Banks"
            >
              <option value={''}>{'Select Bank'}</option>
              {(banks?.results || [] ).map((each) => (
                <option value={each.id} key={each.id}>
                  {' '}
                  {each.name}
                </option>
              ))}
            </Select>
          </div>
          <div className="form-grid">
            <Input
              label="Name of supervisor"
              name="supervisor_name"
              onChange={handleChange}
              required
              error={touched.supervisor_name && errors.supervisor_name}
              onBlur={handleBlur}
              value={values.supervisor_name}
            />
          </div>
          <div
            style={{
              display: 'grid',
              gridTemplateColumns: 'auto 1fr',
              gap: 10,
            }}
          >
            <Checkbox isChecked={isChecked} onChange={toggleCheck} />

            <p>
              <span style={{ color: 'red' }}>*</span>
              This is to confirm that I, received a copy of Workforce Group Staff
              Handbook. I have read and understood the content. I clearly understand that
              I am bound by the Terms and conditions of Employment as set out in the staff
              handbook and as they may be amended from time to time, and I agree that such
              Conditions and terms of employment shall form an agreement between the
              company and myself.
            </p>
            <p style={{ color: 'red', fontSize: '12px' }}>
              {touched.handbook_confirmation && errors.handbook_confirmation}
            </p>
          </div>
        </FormContainer>
      </div>
      <div className="footer">
        <Button
          text="Previous"
          bordered
          className="button-default"
          company
          onClick={() => setActiveStep(activeStep - 1)}
        />
        <Button
          text={loading ? 'loading...' : 'Submit'}
          className="button"
          onClick={handleSubmit}
        />
      </div>
    </Wrapper>
  );
};

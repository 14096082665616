import React from 'react';
import PageContainer from 'components/common/PageContainer';
import { Wrapper, FormContainer } from 'pages/Candidate/Offer/styles';
import { svgs } from 'assets';
import {Logo} from 'pages/Auth/styles';

export const DeclineSuccess = () => (
  <PageContainer>
    <FormContainer>
      <div className="logo-wrapper">
      <Logo src={svgs.logo_colored} />
      </div>
      <Wrapper>
        <div className="content status-wrapper">
          <div className="logo-wrapper">
            {/* <img src={svgs.DELETE_WARN} alt="delete-warn" /> */}
            <svgs.CHECK_MARK_ROUNDED height="70px" />
          </div>
          <p className="header">Offer declined</p>
          <p className="note">Thank you for your time</p>
        </div>
      </Wrapper>
    </FormContainer>
  </PageContainer>
);

import styled from 'styled-components';

export const InputContainer = styled.div`
  display: grid;
  grid-template-columns: minmax(100px, 100%);
  justify-content: start;
`;

export const InputWithIcon = styled.div`
  display: grid;
  border: 1px solid #d9d9d9;
  height: 50px;
  background: ${({ isNested }) => (isNested ? '#F8F8F8' : '#fff')};
  align-items: center;
  padding: 0 17px;
  justify-content: start;
  grid-column-gap: 10px;
  grid-template-columns: auto 1fr;
  border-radius: 4px;
  font-family: 'Inter';
  font-size: 14px;
  line-height: 17px;
  input {
    border: none;

    color: #5e6c84;
    background: ${({ isNested }) => (isNested ? '#F8F8F8' : '#fff')};
    &::placeholder {
      color: #5e6c84;
      text-transform: capitalize;
    }
    &:hover,
    &:focus {
      border: none;
      outline-width: 0;
    }
  }
`;

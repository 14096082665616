import React from 'react';
import { svgs } from '../../../assets';
import { CheckboxContainer } from './styles';

const Checkbox = ({ name, value, isChecked, label, onChange, strong }) => {
  return (
    <CheckboxContainer onClick={onChange}>
      <input
        type="checkbox"
        name={name}
        value={value}
        defaultChecked={isChecked}
        onChange={onChange}
      />
      {isChecked ? <img src={svgs.checked} width="20px" height="20px" alt="" /> : <img width="20px" height="20px" src={svgs.unchecked} alt="" />}
      <span className="check-label"> {strong ? (<span className='bold-label'>{label}</span>):label}</span>
    </CheckboxContainer>
  );
};

Checkbox.defaultProps = {
  isChecked: false,
  label: null,
};

export default Checkbox;

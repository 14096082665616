/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable camelcase */
import React, { useEffect, useState } from 'react';
import { svgs } from 'assets';
import PageContainer from 'components/common/PageContainer';
import jwt from 'jsonwebtoken';
import { useDispatch } from 'react-redux';
import { useParams } from 'react-router-dom';
import { respondToWaiver } from 'redux/actions/onboarding';
import { useToast } from 'context/toastContext';
import { FormContainer, Wrapper } from '../styles';

const AcceptWaiver = () => {
  const toast = useToast();
  const { token } = useParams();
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);
  const [success, setSuccess] = useState(false);

  const acceptWaiver = async () => {
    setLoading(true);
    const { candidate_id } = jwt.decode(token);
    const result = await dispatch(respondToWaiver('ACCEPTED', candidate_id, toast));
    setSuccess(() => result);
    setLoading(false);
  };

  useEffect(() => {
    acceptWaiver();
  }, [token]);

  return (
    <PageContainer>
      <FormContainer>
        <div className="logo-wrapper">
          <svgs.COLOURED_LOGO />
        </div>
        <Wrapper>
          <div className="content status-wrapper">
            {loading
              ? (
                <>
                  <p className="header">Loading...</p>
                </>
              )
              : success ? (
                <>
                  <div className="logo-wrapper">
                    <svgs.CHECK_MARK_ROUNDED />
                  </div>
                  <p className="header">Employee waiver request approved successfully</p>
                </>
              ) : (<> </>)}
          </div>
        </Wrapper>
      </FormContainer>
    </PageContainer>
  );
};

export default AcceptWaiver;

import axios from 'config/axios';
import * as actionTypes from '../actionTypes/headcount';
import { toast } from 'react-toastify';
import { downloadCsv } from 'utils/downloadCsv';

const getEmployeeMonthlyHeadcountRequest = () => ({
  type: actionTypes.GET_EMPLOYEE_MONTHLY_HEADCOUNT_REQUEST,
});

const getEmployeeMonthlyHeadcountSuccess = (payload) => ({
  type: actionTypes.GET_EMPLOYEE_MONTHLY_HEADCOUNT_SUCCESS,
  payload,
});

const employeeResponseHeadCountSuccess = (payload) => ({
  type: actionTypes.GET_EMPLOYEE_RESPONSE_HEADCOUNT_SUCCESS,
  payload,
});

export const reviewHeadcountResponse = (values) => async (dispatch) => {
  try {
    await axios.post('/headcount/supervisor_response/', values);
    return { success: true };
  } catch (error) {
    return { error: error.response };
  }
};

export const getHeadCount = (year, month, companyName) => async (dispatch) => {
  try {
    const { data } = await axios.get(`/headcount/?year=${year}`);
    dispatch({
      type: actionTypes.GET_HEADCOUNT,
      payload: data,
    });
    return { success: true };
  } catch (error) {
    return { error: error.response };
  }
};

export const intiateHeadcount = (year, month, companyName) => async (dispatch) => {
  try {
    const { data } = await axios.get('/headcount/');

    dispatch({
      type: actionTypes.GET_HEADCOUNT_QUESTIONS,
      payload: data,
    });
    return { success: true };
  } catch (error) {
    return { error: error.response };
  }
};

export const getHeadCountQuestions =
  (page = 1, searchParams = '') =>
  async (dispatch) => {
    try {
      const { data } = await axios.get(
        `/headcount/questions?page=${page}&search=${searchParams}`
      );
      dispatch({
        type: actionTypes.GET_HEADCOUNT_QUESTIONS,
        payload: data,
      });
      return { success: true };
    } catch (error) {
      return { error: error.response };
    }
  };

export const initiateCompanyHeadcount = (headcountData) => async (dispatch) => {
  try {
    await axios.post('/headcount/', headcountData);
    return;
  } catch ({ response }) {
    return response?.data;
  }
};

export const getEmployeeHeadcountResponse =
  (
    month,
    year = new Date().getFullYear(),
    page = 1,
    page_size = 12,
    search = '',
    status
  ) =>
  async (dispatch) => {
    // const employeeStatus = status.toUpperCase();

    let supervisorStatus;
    let employeeStatus;
    if (status === 'approved') {
      supervisorStatus = 'APPROVED';
      employeeStatus = 'SUBMITTED';
    }

    if (status === 'submitted') {
      supervisorStatus = 'PENDING';
      employeeStatus = 'SUBMITTED';
    }

    if (status === 'declined') {
      supervisorStatus = 'DECLINED';
      employeeStatus = 'SUBMITTED';
    }

    if (status === 'pending') {
      supervisorStatus = 'PENDING';
      employeeStatus = 'PENDING';
    }

    try {
      // /headcount/employee_response/?headcount__month=January&headcount__year=2021 // for whatever strange reason, there's a double on "headcount__" we need to speak to backend
      const { data } = await axios.get(
        `/headcount/employee_response/?headcount__month=${month}&headcount__year=${year}&page=${page}&page_size=${page_size}&search=${search}&employee_status=${employeeStatus}&supervisor_status=${supervisorStatus}`
      );


      dispatch({
        type: actionTypes.GET_EMPLOYEE_HEADCOUNT_RESPONSE,
        payload: data,
      });
      return;
    } catch (error) {
      return { error: error.response };
    }
  };

export const addHeadCountQuestion = (question) => async (dispatch) => {
  try {
    await axios.post('/headcount/questions/', question);
    return;
  } catch ({ response }) {
    return response?.data;
  }
};

export const updatedHeadcountQuestions =
  (questionId, questionDetails) => async (dispatch) => {
    try {
      await axios.patch(`/headcount/question/${questionId}/`, questionDetails);
    } catch ({ response }) {
      return response?.data;
    }
  };

export const deleteHeadCountQuestion =
  (questionId, questionDetails) => async (dispatch) => {
    try {
      await axios.delete(`/headcount/question/${questionId}/`, questionDetails);
    } catch ({ response }) {
      return response?.data;
    }
  };

export const getEmployeeHeadCount =
  (values, month, year, employee_id, toast) => async (dispatch) => {
    dispatch(getEmployeeMonthlyHeadcountRequest());
    try {
      const response = await axios.get(
        `/headcount/?company__name=${values.name}&month=${month}&year=${year}`
      );
      dispatch(getEmployeeMonthlyHeadcountSuccess(response.data));
      dispatch(employeeResponseHeadCount(values, month, year, employee_id, toast));
    } catch ({ response }) {
      if (response?.status) {
        toast.error(response?.data.errors || response?.data);
      }
      toast.error('Network Error');
    }
  };

export const sendEmployeeHeadCount = (values, detail, toast) => async (dispatch) => {
  try {
    await axios.post(`/headcount/employee_response/`, values);
    dispatch(
      getEmployeeHeadCount(detail, detail.month, detail.year, detail.employee, toast)
    );
    toast.success('Response has been submitted successfully');
    dispatch(
      employeeResponseHeadCount(detail, detail.month, detail.year, detail.employee, toast)
    );
  } catch ({ response }) {
    if (response?.status) {
      toast.error(response?.data.errors || response?.data);
    }
    toast.error('Network Error');
  }
};

export const employeeResponseHeadCount =
  (values, month, year, employee_id, toast) => async (dispatch) => {
    try {
      const response = await axios.get(
        `/headcount/employee_response/?employee__company__name=${values.name}&headcount__month=${month}&headcount__year=${year}&employee__id=${employee_id}`
      );
      dispatch(employeeResponseHeadCountSuccess(response.data));
    } catch ({ response }) {
      if (response?.status) {
        toast.error(response?.data.errors || response?.data);
      }
      toast.error('Network Error');
    }
  };

export const downloadEmployeeHeadCountReport = async (year, month, fileType = 'csv') => {
  try {
    const { data } = await axios.post('/headcount/download_report/', {
      month,
      year,
      file_type: fileType,
    });
    const fileName = `Headcount Report for ${month} ${year}.csv`;
    downloadCsv(data, fileName);
  } catch ({ response }) {
    if (response?.status) {
      toast.error(response?.data.errors || response?.data);
    }
    toast.error('Network Error');
  }
};

import styled from 'styled-components';

export const Wrapper = styled.div`
  .content-wrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    background: #f4f5f7;
    border: 1px dashed #c1c7d0;
    padding: 22px 5px;
    margin-bottom: 22px;
    border-radius: 4px;
    cursor: pointer;
  }
  .error {
    border: 1px red dotted;
  }

  p {
    font-family: Inter;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 17px;
    color: #5e6c84;
    padding: 11px 0 10px;
  }

  span {
    color: #1a21ba;
    font-weight: 600;
  }

  .drop-input {
    width: 100%;
    outline: none;
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .error-text {
    color: red;
    margin-top: -10px;
  }
`;

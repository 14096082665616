export const downloadCsv = async (data, fileName) => {
  const blob = await new Blob([data], { type: 'text/csv' });
  const url = await window.URL.createObjectURL(blob);
  const a = document.createElement('a');
  a.setAttribute('hidden', '');
  a.setAttribute('href', url);
  a.setAttribute('download', fileName);
  a.setAttribute('style', 'text-decoration:none !important; color:inherit !important;');
  document.body.appendChild(a);
  a.click();
  document.body.removeChild(a);
};

import * as actionTypes from '../../actionTypes/candidate/hmo';

const initialState = {
  error: null,
  success: false,
  loading: false,
  hmos: [],

};

const hmoCandidateReducer = (state = initialState, { type, payload }) => {
  switch (type) {
    case actionTypes.GET_HMO_HOSPITAL_START:
        return {
          ...state,
          loading: true,
        };

    case actionTypes.GET_HMO_HOSPITAL_SUCCESS:
      return {
        ...state,
        loading:false,
        hmos: payload,
       
      };
    case actionTypes.GET_HMO_HOSPITAL_FAIL:
        return {
          ...state,
          loading: false,
        };
 
      

    default:
      return state;
  }
};

export default hmoCandidateReducer;

import React, { useEffect , useRef } from 'react';

import { DropdownContainer } from './styles';

const DropDownMenu = ({ children, display, toggle, renderMenu, id, right, ...props }) => {
  const ref = useRef();
  useEffect(() => {
    const handleClickOutside = (event) => {
      if (ref.current && !ref.current.contains(event.target)) {
        toggle();
      }
    };
    if (display) {
      document.addEventListener('mousedown', handleClickOutside);
    }
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [display, ref, toggle]);
  return (
    <DropdownContainer {...props} right={right} >
      {children}
      <div ref={ref} className={`dropdown-content ${display ? 'show' : ''}`}>
        {renderMenu(id)}
      </div>
    </DropdownContainer>
  );
};

export default DropDownMenu;

import React, { useState, createContext } from 'react';
import ErrorModal from 'components/common/ErrorModal';

const ModalContext = createContext();

const ModalProvider = ({ children }) => {
  const [showErrorModal, setShowErrorModal] = useState(false);
  const [errorModalMsg, setErrorModalMessage] = useState('An Error Occurred');
  let outerAfter = () => {};

  const error = (value, before=()=>{}, after=()=>{}) => {
    before();
    outerAfter = after;
    setErrorModalMessage(value);
    setShowErrorModal(true);
  };

  const onClose = () => {
    setShowErrorModal(false);
    setErrorModalMessage('An Error Occurred');
    outerAfter();
  };
  return (
    <ModalContext.Provider
      value={{
        showErrorModal,
        errorModalMsg,
        error,
      }}
    >
      {showErrorModal && (
        <ErrorModal
          handleClose={onClose}
          error={errorModalMsg}
          headerText="Error"
          show={showErrorModal}
        />
      )}
      {children}
    </ModalContext.Provider>
  );
};

const useModal = () => {
  const context = React.useContext(ModalContext);
  return context;
};

export { ModalProvider, useModal };

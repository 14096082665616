import React from 'react';

import PropTypes from 'prop-types';
import { User, UserName } from './styles';
import ProfilePicture from '../ProfilePicture';


/**
 * user component
 * 
 * @param {Object} props Component props
 * @param {String} name user name
 * @param {String} color user name
 * @param {String} image user picture
 * @returns {JSX.Element} component
 */
const UserTag = ({image, name, color}) => {
  return (
    <User>
      <ProfilePicture image={image} name={name} />
      <UserName color={color}>{name}</UserName>
    </User>
  );
};


UserTag.propTypes = {
  name: PropTypes.string.isRequired,
  color: PropTypes.string,
  image: PropTypes.string,
};

UserTag.defaultProps = {
  color: '#333333',
  image: '',
};

export default UserTag;

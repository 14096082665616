import { svgs } from 'assets';
import styled from 'styled-components';

export const DropdownContainer = styled.div.attrs((props) => ({
  className: props.className,
}))`
  position: relative;
  top: ${({ view }) => (view === 'nav' ? '8px' : '0px')};
  display: flex;
  align-items: center;
  width: fit-content;

  .dropdown-content {
    display: none;
    position: absolute;
    background-color: #f1f1f1;
    min-width: ${(props) => (props.largeWidth ? 'max-content' : '160px')};
    overflow: auto;
    z-index: 30;
    outline: none;
    background: #ffffff;
    box-shadow: 2px 2px 2px 2px rgba(199, 223, 241, 0.25);
    border-radius: 5px;
    right: ${({ right }) => right ?? '0px'};
    top: ${({ top }) => top ?? '20px'};
  }

  .dropdown-content a,
  p {
    color: black;
    padding: 12px 16px;
    text-decoration: none;
    display: block;
    cursor: pointer;
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 24px;
    color: #333333;
    text-transform: capitalize;
  }
  .dropdown-content a,
  p:hover{
    background: #F4F5F7;
  }
  .delete {
    color: #eb5757;
  }

  .dropdown a:hover {
    background-color: #ddd;
  }

  .show {
    display: block;
  }
  .clickable {
    cursor: pointer;
  }

  &.table-background {
    padding: 10px;
    margin: 0 10px;
    border-radius: 4px;
    border: none;
    width: auto;
    box-shadow: none;
    color: #fff;
    background: #1a21ba;
    -webkit-transition: 0.3s;
    transition: 0.3s;
  }
  &.table-background .dropdown-content {
    top: 50px;
  }
  &.table-background .dropdown-content.show p {
    display: inline-block;
    white-space: nowrap;
    padding: 6px 16px 6px;
  }
`;

export const Ellipses = styled(svgs.HORIZONTAL_ELLIPSES)`
  cursor: pointer;
  visibility: ${(props) => (props.hidden ? 'hidden' : null)}
`;

export const Caret = styled(svgs.WHITE_CARET_DOWN)`
  cursor: pointer;
`;

import React from 'react';

import { svgs } from 'assets';
import {
  ActionContainer,
  MessageHeader,
  MessageSubtitle,
} from 'pages/Employee/components/style';

const ActionNotification = ({
  headerText,
  subHeaderText,
  headerTextErr,
  subHeaderTextErr,
  error,
}) => {
  return (
    <>
      <ActionContainer>
        {!error && (
          <>
            <img src={svgs.CHECK_ICON} alt="check_icon" />
            <br />
            <MessageHeader>{headerText}</MessageHeader>
            <MessageSubtitle>{subHeaderText}</MessageSubtitle>
          </>
        )}
        {error && (
          <>
            <span style={{ color: '#f00', fontSize: '22px' }}>Error!</span>
            <br />
            <MessageHeader>{headerTextErr}</MessageHeader>
            <MessageSubtitle>{subHeaderTextErr}</MessageSubtitle>
          </>
        )}
      </ActionContainer>
    </>
  );
};

export default ActionNotification;

import React from 'react';
import { FormContainer, FormHeader, FormBody } from './styles';
import PropTypes from 'prop-types';

const OnboardingForm = ({ children, title, ...props }) => {
  return (
    <FormContainer {...props}>
      <FormHeader>{title}</FormHeader>
      <FormBody>{children}</FormBody>
    </FormContainer>
  );
};

OnboardingForm.propTypes = {
  title: PropTypes.string.isRequired,
  children: PropTypes.element.isRequired,
};

export default OnboardingForm;

import React from 'react';
// import * as Sentry from '@sentry/react';
import { Provider } from 'react-redux';
import 'react-toastify/dist/ReactToastify.css';
import './app.scss';
import { sentryConfig } from 'config/sentry';
import { ToastProvider } from 'context/toastContext';
import { ModalProvider } from 'context/ModalContext';
import { checkAuth } from 'utils/auth';
import store from '../redux/store';
import Routes from '../routes';

sentryConfig();
checkAuth(store);

const App = () => (
  // <Sentry.ErrorBoundary fallback="An error has occured">
  <ToastProvider autoClose={3000}>
    <ModalProvider>
      <Provider store={store}>
        <Routes />
      </Provider>
    </ModalProvider>
  </ToastProvider>
  // </Sentry.ErrorBoundary>
);
export default App;

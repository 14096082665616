import React from 'react';
import { Avatar, Initials } from '../UserTag/styles';

const ProfilePicture = ({ image, name }) => {
  const initials = name.split(' ').map((item) => item.charAt(0).toUpperCase()).slice(0,2);
  return (
    <React.Fragment>
      {image ? <Avatar src={image} /> : <Initials>{initials}</Initials>}
    </React.Fragment>
  );
};

export default ProfilePicture;

import { lazy } from 'react';

const EmployeeExit = lazy(() => import('pages/Candidate/Self-Service/EmployeeExit'));
const EmployeeDisciplinary = lazy(() =>
  import('pages/Candidate/Self-Service/EmployeeDisciplinary')
);
const DisciplinaryDetails = lazy(() =>
  import('pages/Candidate/Self-Service/EmployeeDisciplinary/DisciplinaryDetails')
);
const HeadCount = lazy(() => import('pages/Candidate/Self-Service/HeadCount'));
const TakeHeadCountPageOne = lazy(() =>
  import('pages/Candidate/Self-Service/HeadCount/TakeHeadCount/PageOne/index')
);
const TakeHeadCountPageTwo = lazy(() =>
  import('pages/Candidate/Self-Service/HeadCount/TakeHeadCount/PageTwo')
);
const EmployeeComplaints = lazy(() =>
  import('pages/Candidate/Self-Service/EmployeeComplaints')
);
const EmployeeLeave = lazy(() => import('pages/Candidate/Self-Service/EmployeeLeave'));
const EmployeeActivities = lazy(() => import('pages/Candidate/Self-Service/Activities'));
// const CandidateProfile = lazy(() => import('pages/Candidate/Profile'));
const Welfare = lazy(() => import('pages/Candidate/Welfare'));
const SummaryProfile = lazy(() => import('pages/Candidate/Profile/Summary'));
const ProfileData = lazy(() => import('pages/Candidate/Profile/ProfileData'));
const Documents = lazy(() => import('pages/Candidate/Profile/Documents'));
const Hmo = lazy(() => import('pages/Candidate/Profile/Hmo'));
const HmoSurveys = lazy(() => import('pages/Candidate/Self-Service/Hmo'));

const Appraisals = lazy(() => import('pages/Candidate/Self-Service/Appraisals'));
const AppraisalScore = lazy(() => import('pages/Candidate/Self-Service/AppraisalsScore'));
const ChangeRequest = lazy(() => import('pages/Candidate/Profile/ChangeRequest'));

const AllAppraisalsList = lazy(() => import('pages/Candidate/Self-Service//Appraisals/AppraisalList/AllAppraisals'));
const ViewAppraisalsKpi = lazy(() => import('pages/Candidate/Self-Service//Appraisals/AppraisalList/AllAppraisals/ViewKpi'));
const ViewAppraisalScore = lazy(() => import('pages/Candidate/Self-Service//Appraisals/AppraisalList/AllAppraisals/ViewAppraisalScore'));


const candidateRoutes = [
  { exact: true, path: '/selfservice/exit', component: EmployeeExit },
  {
    exact: true,
    path: '/selfservice/disciplinary',
    component: EmployeeDisciplinary,
  },
  {
    exact: true,
    path: '/selfservice/disciplinary/:id',
    component: DisciplinaryDetails,
  },
  {
    exact: true,
    path: '/selfservice/headcount',
    component: HeadCount,
  },
  {
    exact: true,
    path: '/selfservice/headcount/begin',
    component: TakeHeadCountPageTwo,
  },
  {
    exact: true,
    path: '/selfservice/headcount/:month',
    component: TakeHeadCountPageOne,
  },
  {
    exact: true,
    path: '/selfservice/complaints',
    component: EmployeeComplaints,
  },
  {
    exact: true,
    path: '/selfservice/activities',
    component: EmployeeActivities,
  },
  {
    exact: true,
    path: '/selfservice/hmo-surveys',
    component: HmoSurveys,
  },
  {
    exact: true,
    path: '/selfservice/score/:appraisal_id/:employee_id/:kpi_id',
    component: AppraisalScore,
  },
  
  {
    exact: true,
    path: '/selfservice/appraisals/:appraisal_id/:employee_id/:kpi_id',
    component: Appraisals,
  },

  {
    exact: true,
    path: '/selfservice/appraisals-list/kpi/:appraisal_id',
    component:ViewAppraisalsKpi,
  },
  {
    exact: true,
    path: '/selfservice/appraisals-list/review/:appraisal_id',
    component:ViewAppraisalScore,
  },
 
  {
    exact: true,
    path: '/selfservice/appraisals-list',
    component: AllAppraisalsList,
  },

  { exact: true, path: '/selfservice/leave', component: EmployeeLeave },
  { exact: true, path: '/candidate/welfare', component: Welfare },
  {  path: '/candidate/profile/summary', component: SummaryProfile },
  {  path: '/candidate/profile/profile-data/:name', component: ProfileData },
  {  path: '/candidate/profile/documents', component: Documents },
  {  path: '/candidate/profile/hmo', component: Hmo },
  {  path: '/candidate/profile/change-requests', component: ChangeRequest },
];

export default candidateRoutes;

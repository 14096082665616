export const ADD_WORKFLOW_REQUEST = 'ADD_WORKFLOW_REQUEST';
export const ADD_WORKFLOW_SUCCESS = 'ADD_WORKFLOW_SUCCESS';
export const ADD_WORKFLOW_FAILURE = 'ADD_WORKFLOW_FAILURE';

export const GET_WORKFLOWS_REQUEST = 'GET_WORKFLOWS_REQUEST';
export const GET_WORKFLOWS_SUCCESS = 'GET_WORKFLOWS_SUCCESS';
export const GET_WORKFLOWS_FAILURE = 'GET_WORKFLOWS_FAILURE';

export const GET_SINGLE_WORKFLOW_REQUEST = 'GET_SINGLE_WORKFLOW_REQUEST';
export const GET_SINGLE_WORKFLOW_SUCCESS = 'GET_SINGLE_WORKFLOW_SUCCESS';
export const GET_SINGLE_WORKFLOW_FAILURE = 'GET_SINGLE_WORKFLOW_FAILURE';

export const EDIT_WORKFLOW_REQUEST = 'EDIT_WORKFLOW_REQUEST';
export const EDIT_WORKFLOW_SUCCESS = 'EDIT_WORKFLOW_SUCCESS';
export const EDIT_WORKFLOW_FAILURE = 'EDIT_WORKFLOW_FAILURE';

export const DELETE_WORKFLOW_REQUEST = 'DELETE_WORKFLOW_REQUEST';
export const DELETE_WORKFLOW_SUCCESS = 'DELETE_WORKFLOW_SUCCESS';
export const DELETE_WORKFLOW_FAILURE = 'DELETE_WORKFLOW_FAILURE';

export const APPROVE_ACTIVITY_REQUEST = 'APPROVE_ACTIVITY_REQUEST';
export const APPROVE_ACTIVITY_SUCCESS = 'APPROVE_ACTIVITY_SUCCESS';
export const APPROVE_ACTIVITY_FAILURE = 'APPROVE_ACTIVITY_FAILURE';

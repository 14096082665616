import {
  GET_EMPLOYEE_START,
  GET_EMPLOYEE_SUCCESS,
  GET_EMPLOYEE_FAIL,
  CREATE_EMPLOYEE_START,
  CREATE_EMPLOYEE_SUCCESS,
  CREATE_EMPLOYEE_FAIL,
  GET_EMPLOYEE_SEARCH_START,
  GET_EMPLOYEE_SEARCH_SUCCESS,
  GET_EMPLOYEE_SEARCH_FAIL,
  GET_SINGLE_EMPLOYEE_FAIL,
  GET_SINGLE_EMPLOYEE_SUCCESS,
  GET_SINGLE_EMPLOYEE_START,
  GET_SINGLE_EMPLOYEE_ONBOARDING_FAIL,
  GET_SINGLE_EMPLOYEE_ONBOARDING_SUCCESS,
  GET_SINGLE_EMPLOYEE_ONBOARDING_START,
  EDIT_EMPLOYEE_START,
  EDIT_EMPLOYEE_SUCCESS,
  EDIT_EMPLOYEE_FAIL,
  UPDATE_EMPLOYEE_START,
  UPDATE_EMPLOYEE_SUCCESS,
  UPDATE_EMPLOYEE_FAIL,
  GET_SINGLE_EMPLOYEE_PAYROLL_FAIL,
  GET_SINGLE_EMPLOYEE_PAYROLL_SUCCESS,
  GET_SINGLE_EMPLOYEE_PAYROLL_START,
  CREATE_EMPLOYEE_PAYROLL_START,
  CREATE_EMPLOYEE_PAYROLL_SUCCESS,
  CREATE_EMPLOYEE_PAYROLL_FAIL,
  UPDATE_EMPLOYEE_PAYROLL_START,
  UPDATE_EMPLOYEE_PAYROLL_SUCCESS,
  UPDATE_EMPLOYEE_PAYROLL_FAIL,
  CREATE_EMPLOYEE_REFEREES_START,
  CREATE_EMPLOYEE_REFEREES_SUCCESS,
  CREATE_EMPLOYEE_REFEREES_FAIL,
  UPDATE_EMPLOYEE_REFEREES_START,
  UPDATE_EMPLOYEE_REFEREES_SUCCESS,
  UPDATE_EMPLOYEE_REFEREES_FAIL,
  DELETE_EMPLOYEE_REFEREES_START,
  DELETE_EMPLOYEE_REFEREES_SUCCESS,
  DELETE_EMPLOYEE_REFEREES_FAIL,
  CREATE_EMPLOYEE_GUARANTORS_START,
  CREATE_EMPLOYEE_GUARANTORS_SUCCESS,
  CREATE_EMPLOYEE_GUARANTORS_FAIL,
  UPDATE_EMPLOYEE_GUARANTORS_START,
  UPDATE_EMPLOYEE_GUARANTORS_SUCCESS,
  UPDATE_EMPLOYEE_GUARANTORS_FAIL,
  DELETE_EMPLOYEE_GUARANTORS_START,
  DELETE_EMPLOYEE_GUARANTORS_SUCCESS,
  DELETE_EMPLOYEE_GUARANTORS_FAIL,
  CREATE_EDUCATION_START,
  CREATE_EDUCATION_SUCCESS,
  CREATE_EDUCATION_FAIL,
  UPDATE_EDUCATION_START,
  UPDATE_EDUCATION_SUCCESS,
  UPDATE_EDUCATION_FAIL,
  DELETE_EDUCATION_START,
  DELETE_EDUCATION_SUCCESS,
  DELETE_EDUCATION_FAIL,
  CREATE_CERTIFICATION_START,
  CREATE_CERTIFICATION_SUCCESS,
  CREATE_CERTIFICATION_FAIL,
  UPDATE_CERTIFICATION_START,
  UPDATE_CERTIFICATION_SUCCESS,
  UPDATE_CERTIFICATION_FAIL,
  DELETE_CERTIFICATION_START,
  DELETE_CERTIFICATION_SUCCESS,
  DELETE_CERTIFICATION_FAIL,
  CREATE_NEXTOFKINS_START,
  CREATE_NEXTOFKINS_SUCCESS,
  CREATE_NEXTOFKINS_FAIL,
  UPDATE_NEXTOFKINS_START,
  UPDATE_NEXTOFKINS_SUCCESS,
  UPDATE_NEXTOFKINS_FAIL,
  CREATE_DEPENDENTS_START,
  CREATE_DEPENDENTS_SUCCESS,
  CREATE_DEPENDENTS_FAIL,
  UPDATE_DEPENDENTS_START,
  UPDATE_DEPENDENTS_SUCCESS,
  UPDATE_DEPENDENTS_FAIL,
  DELETE_DEPENDENTS_START,
  DELETE_DEPENDENTS_SUCCESS,
  DELETE_DEPENDENTS_FAIL,
  CREATE_MEDICALS_START,
  CREATE_MEDICALS_SUCCESS,
  CREATE_MEDICALS_FAIL,
  UPDATE_MEDICALS_START,
  UPDATE_MEDICALS_SUCCESS,
  UPDATE_MEDICALS_FAIL,
  CREATE_LANGUAGES_START,
  CREATE_LANGUAGES_SUCCESS,
  CREATE_LANGUAGES_FAIL,
  UPDATE_LANGUAGES_START,
  UPDATE_LANGUAGES_SUCCESS,
  UPDATE_LANGUAGES_FAIL,
  DELETE_LANGUAGES_START,
  DELETE_LANGUAGES_SUCCESS,
  DELETE_LANGUAGES_FAIL,
  SEND_EMPLOYEE_EMAIL_REQUEST,
  SEND_EMPLOYEE_EMAIL_SUCCESS,
  SEND_EMPLOYEE_EMAIL_FAILURE,
  GET_EMPLOYEE_REFEREES_FAIL,
  GET_EMPLOYEE_REFEREES,
  GET_EMPLOYEE_REFEREES_START,
  GET_UPGRADE_LETTER_SUCCESS,
  GET_UPGRADE_LETTER_FAIL,
  BULK_EMPLOYEE_CSV_HEADERS_SUCCESS,
  BULK_EMPLOYEE_CSV_HEADERS_FAIL,
} from '../actionTypes/employee';

import * as actions from '../actionTypes/employeeDocuments';

const initialState = {
  loading: false,
  data: [],
  employee: [],
  error: null,
  success: false,
  singleEmployee: [],
  singleEmployeePayroll: [],
  employeeReferees: [],
  createProfileError: null,
  createProfileSuccess: false,
  successTwo: false,
  employeeLoading: false,
  employeeSearchData: [],
  employeeChangeRequestData: [],
  employeeUpgradeLetterData: [],
  upgradedLettersCVSData: [],
  bulkCVSData: [],
};

const createEmployeeStart = (state) => ({
  ...state,
  loading: true,
  error: null,
  success: false,
});
const createEmployeeSuccess = (state, action) => ({
  ...state,
  error: null,
  success: true,
  loading: false,
  employee: action.payload,
});
const createEmployeeFail = (state, action) => {
  return {
    ...state,
    error: action.error,
    success: false,
    loading: false,
  };
};

const getSingleEmployeeStart = (state) => ({
  ...state,
  loading: true,
  error: null,
});

const getSingleEmployeeSuccess = (state, action) => ({
  ...state,
  error: null,
  success: false,
  loading: false,
  singleEmployee: action.payload,
});
const getSingleEmployeeFail = (state, action) => ({
  ...state,
  error: action.error,
  success: false,
  employee: null,
  loading: false,
});

const getSingleEmployeeOnboardingStart = (state) => ({
  ...state,
  loading: true,
  error: null,
});

const getSingleEmployeeOnboardingSuccess = (state, action) => ({
  ...state,
  error: null,
  success: false,
  loading: false,
  singleEmployeeOnboarding: action.payload,
});
const getSingleEmployeeOnboardingFail = (state, action) => ({
  ...state,
  error: action.error,
  success: false,
  employee: null,
  loading: false,
});

const editSingleEmployeeStart = (state) => ({
  ...state,
  error: null,
  success: false,
  loading: false,
});

const editSingleEmployeeSuccess = (state, action) => ({
  ...state,
  error: null,
  success: true,
  loading: false,
});

const editSingleEmployeeFail = (state, action) => ({
  ...state,
  error: action.error,
  success: false,
  loading: false,
});

const updateSingleEmployeeStart = (state) => ({
  ...state,
  error: null,
  success: false,
  loading: false,
});
const updateSingleEmployeeSuccess = (state) => ({
  ...state,
  error: null,
  success: true,
  loading: false,
});

const updateSingleEmployeeFail = (state, action) => ({
  ...state,
  error: action.error,
  success: false,
  loading: false,
});

const createEmployeePayrollStart = (state) => ({
  ...state,
  loading: true,
  error: null,
  createProfileSuccess: false,
});

const createEmployeePayrollSuccess = (state) => ({
  ...state,
  error: null,
  createProfileSuccess: true,
  loading: false,
});
const createEmployeePayrollFail = (state, action) => ({
  ...state,
  createProfileError: action.error,
  success: false,
  loading: false,
});
const getSingleEmployeePayrollStart = (state) => ({
  ...state,
  loading: true,
  payrollError: null,
});

const getSingleEmployeePayrollSuccess = (state, action) => ({
  ...state,
  error: null,
  success: false,
  loading: false,
  singleEmployeePayroll: action.payload,
});
const getSingleEmployeePayrollFail = (state, action) => ({
  ...state,
  error: null,
  success: false,
  employee: null,
  loading: false,
});

const updateSingleEmployeePayrollStart = (state) => ({
  ...state,
  error: null,
  loading: false,
});
const updateSingleEmployeePayrollSuccess = (state) => ({
  ...state,
  error: null,
  success: true,
  loading: false,
});

const updateSingleEmployeePayrollFail = (state, action) => ({
  ...state,
  error: action.error,
  success: false,
  loading: false,
});

const createEmployeeRefereesStart = (state) => ({
  ...state,
  error: null,
  success: false,
  loading: false,
});

const createEmployeeRefereesSuccess = (state) => ({
  ...state,
  error: null,
  success: true,
  loading: false,
});
const createEmployeeRefereesFail = (state, action) => ({
  ...state,
  error: action.error,
  success: false,
  loading: false,
});

const updateSingleEmployeeRefereesStart = (state) => ({
  ...state,
  error: null,
  success: false,
  loading: false,
});
const updateSingleEmployeeRefereesSuccess = (state) => ({
  ...state,
  error: null,
  success: true,
  loading: false,
});

const updateSingleEmployeeRefereesFail = (state, action) => ({
  ...state,
  error: action.error,
  success: false,
  loading: false,
});

const deleteEmployeeRefereesStart = (state) => ({
  ...state,
  error: null,
  success: false,
  loading: false,
});
const deleteEmployeeRefereesSuccess = (state) => ({
  ...state,
  error: null,
  success: true,
  loading: false,
});

const deleteEmployeeRefereesFail = (state, action) => ({
  ...state,
  error: action.error,
  success: false,
  loading: false,
});

const createEmployeeGuarantorStart = (state) => ({
  ...state,
  error: null,
  success: false,
  loading: false,
});
const createEmployeeGuarantorSuccess = (state) => ({
  ...state,
  error: null,
  success: true,
  loading: false,
});
const createEmployeeGuarantorFail = (state, action) => ({
  ...state,
  error: action.error,
  success: false,
  loading: false,
});

const updateSingleEmployeeGuarantorStart = (state) => ({
  ...state,
  error: null,
  success: false,
  loading: false,
});
const updateSingleEmployeeGuarantorSuccess = (state) => ({
  ...state,
  error: null,
  success: true,
  loading: false,
});

const updateSingleEmployeeGuarantorFail = (state, action) => ({
  ...state,
  error: action.error,
  success: false,
  loading: false,
});

const deleteEmployeeGuarantorStart = (state) => ({
  ...state,
  error: null,
  success: false,
  loading: false,
});
const deleteEmployeeGuarantorSuccess = (state) => ({
  ...state,
  error: null,
  success: true,
  loading: false,
});

const deleteEmployeeGuarantorFail = (state, action) => ({
  ...state,
  error: action.error,
  success: false,
  loading: false,
});

const createEducationStart = (state) => ({
  ...state,
  error: null,
  createProfileSuccess: false,
  loading: false,
});
const createEducationSuccess = (state) => ({
  ...state,
  error: null,
  createProfileSuccess: true,
  loading: false,
});
const createEducationFail = (state, action) => ({
  ...state,
  error: action.error,
  createProfileSuccess: false,
  loading: false,
});

const updateEducationStart = (state) => ({
  ...state,
  error: null,
  success: false,
  loading: false,
});
const updateEducationSuccess = (state) => ({
  ...state,
  error: null,
  success: true,
  loading: false,
});

const updateEducationFail = (state, action) => ({
  ...state,
  error: action.error,
  success: false,
  loading: false,
});

const deleteEducationStart = (state) => ({
  ...state,
  error: null,
  createProfileSuccess: false,
  loading: false,
});
const deleteEducationSuccess = (state) => ({
  ...state,
  error: null,
  createProfileSuccess: true,
  loading: false,
});

const deleteEducationFail = (state, action) => ({
  ...state,
  error: action.error,
  createProfileSuccess: false,
  loading: false,
});

const createCertificationStart = (state) => ({
  ...state,
  error: null,
  success: false,
  loading: false,
});
const createCertificationSuccess = (state) => ({
  ...state,
  error: null,
  createProfileSuccess: true,
  loading: false,
});
const createCertificationFail = (state, action) => ({
  ...state,
  error: action.error,
  success: false,
  loading: false,
});

const updateCertificationStart = (state) => ({
  ...state,
  error: null,
  success: false,
  loading: false,
});
const updateCertificationSuccess = (state) => ({
  ...state,
  error: null,
  success: true,
  loading: false,
});

const updateCertificationFail = (state, action) => ({
  ...state,
  error: action.error,
  success: false,
  loading: false,
});

const deleteCertificationStart = (state) => ({
  ...state,
  error: null,
  success: false,
  loading: false,
});
const deleteCertificationSuccess = (state) => ({
  ...state,
  error: null,
  success: true,
  loading: false,
});

const deleteCertificationFail = (state, action) => ({
  ...state,
  error: action.error,
  success: false,
  loading: false,
});

const createNextofKinsStart = (state) => ({
  ...state,
  error: null,
  createProfileSuccess: false,
  loading: false,
});
const createNextofKinsSuccess = (state) => ({
  ...state,
  error: null,
  createProfileSuccess: true,
  loading: false,
});
const createNextofKinsFail = (state, action) => ({
  ...state,
  error: action.error,
  createProfileSuccess: false,
  loading: false,
});

const updateNextofKinsStart = (state) => ({
  ...state,
  error: null,
  success: false,
  loading: false,
});
const updateNextofKinsSuccess = (state) => ({
  ...state,
  error: null,
  success: true,
  loading: false,
});

const updateNextofKinsFail = (state, action) => ({
  ...state,
  error: action.error,
  success: false,
  loading: false,
});
const createDependentsStart = (state) => ({
  ...state,
  error: null,
  createProfileSuccess: false,
  loading: false,
});

const createDependentsSuccess = (state) => ({
  ...state,
  error: null,
  createProfileSuccess: true,
  loading: false,
});

const createDependentsFail = (state, action) => ({
  ...state,
  error: action.error,
  createProfileSuccess: false,
  loading: false,
});

const updateDependentsStart = (state) => ({
  ...state,
  error: null,
  success: false,
  loading: false,
});

const updateDependentsSuccess = (state) => ({
  ...state,
  error: null,
  success: true,
  loading: false,
});

const updateDependentsFail = (state, action) => ({
  ...state,
  error: action.error,
  success: false,
  loading: false,
});

const deleteDependentsStart = (state) => ({
  ...state,
  error: null,
  createProfileSuccess: false,
  loading: false,
});

const deleteDependentsSuccess = (state) => ({
  ...state,
  error: null,
  createProfileSuccess: true,
  loading: false,
});
const deleteDependentsFail = (state, action) => ({
  ...state,
  error: action.error,
  createProfileSuccess: false,
  loading: false,
});

const createMedicalsStart = (state) => ({
  ...state,
  error: null,
  success: false,
  loading: false,
});

const createMedicalsSuccess = (state) => ({
  ...state,
  error: null,
  createProfileSuccess: true,
  loading: false,
});

const createMedicalsFail = (state, action) => ({
  ...state,
  error: action.error,
  createProfileSuccess: false,
  loading: false,
});

const updateMedicalsStart = (state) => ({
  ...state,
  error: null,
  success: false,
  loading: false,
});

const updateMedicalsSuccess = (state) => ({
  ...state,
  error: null,
  success: true,
  loading: false,
});

const updateMedicalsFail = (state, action) => ({
  ...state,
  error: action.error,
  success: false,
  loading: false,
});

const createLanguagesStart = (state) => ({
  ...state,
  error: null,
  createProfileSuccess: false,
  loading: false,
});

const createLanguagesSuccess = (state) => ({
  ...state,
  error: null,
  createProfileSuccess: true,
  loading: false,
});

const createLanguagesFail = (state, action) => ({
  ...state,
  error: action.error,
  success: false,
  loading: false,
});

const updateLanguagesStart = (state) => ({
  ...state,
  error: null,
  success: false,
  loading: false,
});

const updateLanguagesSuccess = (state) => ({
  ...state,
  error: null,
  success: true,
  loading: false,
});

const updateLanguagesFail = (state, action) => ({
  ...state,
  error: action.error,
  success: false,
  loading: false,
});

const deleteLanguagesStart = (state) => ({
  ...state,
  error: null,
  createProfileSuccess: false,
  loading: false,
});

const deleteLanguagesSuccess = (state) => ({
  ...state,
  error: null,
  createProfileSuccess: true,
  loading: false,
});
const deleteLanguagesFail = (state, action) => ({
  ...state,
  error: action.error,
  createProfileSuccess: false,
  loading: false,
});

const getEmployeeRefereeStart = (state) => ({
  ...state,
  error: null,
  success: false,
  loading: false,
});

const getEmployeeRefereesSucess = (state) => ({
  ...state,
  error: null,
  success: true,
  loading: false,
});

const getEmployeeRefereeFailure = (state, action) => ({
  ...state,
  error: action.error,
  success: false,
  loading: false,
});

const employeeReducer = (state = initialState, action) => {
  switch (action.type) {
    case 'RESET_STATE':
      return initialState;

    case GET_EMPLOYEE_START:
      return {
        ...state,
        loading: true,
        error: null,
        employeeLoading: true,
        employee: [],
      };

    case GET_EMPLOYEE_SUCCESS:
      return {
        ...state,
        error: null,
        success: false,
        loading: false,
        employeeLoading: false,
        employee: action.payload,
      };

    case GET_EMPLOYEE_FAIL:
      return {
        ...state,
        error: action.error,
        success: false,
        loading: false,
        employeeLoading: false,
      };

    case GET_EMPLOYEE_SEARCH_START:
      return {
        ...state,
        loading: true,
        error: null,
        employeeSearchData: [],
      };

    case GET_EMPLOYEE_SEARCH_SUCCESS:
      return {
        ...state,
        error: null,
        success: false,
        loading: false,
        employeeSearchData: action.payload,
      };

    case GET_EMPLOYEE_SEARCH_FAIL:
      return {
        ...state,
        loading: false,
        error: action.error,
      };

    case CREATE_EMPLOYEE_START:
      return createEmployeeStart(state);
    case CREATE_EMPLOYEE_SUCCESS:
      return createEmployeeSuccess(state, action);
    case CREATE_EMPLOYEE_FAIL:
      return createEmployeeFail(state, action);

    case GET_SINGLE_EMPLOYEE_START:
      return getSingleEmployeeStart(state);
    case GET_SINGLE_EMPLOYEE_SUCCESS:
      return getSingleEmployeeSuccess(state, action);

    case GET_SINGLE_EMPLOYEE_FAIL:
      return getSingleEmployeeFail(state, action);

    case GET_SINGLE_EMPLOYEE_ONBOARDING_START:
      return getSingleEmployeeOnboardingStart(state);
    case GET_SINGLE_EMPLOYEE_ONBOARDING_SUCCESS:
      return getSingleEmployeeOnboardingSuccess(state, action);
    case GET_SINGLE_EMPLOYEE_ONBOARDING_FAIL:
      return getSingleEmployeeOnboardingFail(state, action);

    case EDIT_EMPLOYEE_START:
      return editSingleEmployeeStart(state);
    case EDIT_EMPLOYEE_SUCCESS:
      return editSingleEmployeeSuccess(state, action);
    case EDIT_EMPLOYEE_FAIL:
      return editSingleEmployeeFail(state, action);

    case UPDATE_EMPLOYEE_START:
      return updateSingleEmployeeStart(state);
    case UPDATE_EMPLOYEE_SUCCESS:
      return updateSingleEmployeeSuccess(state, action);
    case UPDATE_EMPLOYEE_FAIL:
      return updateSingleEmployeeFail(state, action);

    case CREATE_EMPLOYEE_PAYROLL_START:
      return createEmployeePayrollStart(state);
    case CREATE_EMPLOYEE_PAYROLL_SUCCESS:
      return createEmployeePayrollSuccess(state, action);
    case CREATE_EMPLOYEE_PAYROLL_FAIL:
      return createEmployeePayrollFail(state, action);

    case GET_SINGLE_EMPLOYEE_PAYROLL_START:
      return getSingleEmployeePayrollStart(state);
    case GET_SINGLE_EMPLOYEE_PAYROLL_SUCCESS:
      return getSingleEmployeePayrollSuccess(state, action);
    case GET_SINGLE_EMPLOYEE_PAYROLL_FAIL:
      return getSingleEmployeePayrollFail(state, action);

    case UPDATE_EMPLOYEE_PAYROLL_START:
      return updateSingleEmployeePayrollStart(state);
    case UPDATE_EMPLOYEE_PAYROLL_SUCCESS:
      return updateSingleEmployeePayrollSuccess(state);
    case UPDATE_EMPLOYEE_PAYROLL_FAIL:
      return updateSingleEmployeePayrollFail(state, action);

    case CREATE_EMPLOYEE_REFEREES_START:
      return createEmployeeRefereesStart(state);
    case CREATE_EMPLOYEE_REFEREES_SUCCESS:
      return createEmployeeRefereesSuccess(state, action);
    case CREATE_EMPLOYEE_REFEREES_FAIL:
      return createEmployeeRefereesFail(state, action);

    case UPDATE_EMPLOYEE_REFEREES_START:
      return updateSingleEmployeeRefereesStart(state, action);
    case UPDATE_EMPLOYEE_REFEREES_SUCCESS:
      return updateSingleEmployeeRefereesSuccess(state, action);
    case UPDATE_EMPLOYEE_REFEREES_FAIL:
      return updateSingleEmployeeRefereesFail(state, action);

    case DELETE_EMPLOYEE_REFEREES_START:
      return deleteEmployeeRefereesStart(state);
    case DELETE_EMPLOYEE_REFEREES_SUCCESS:
      return deleteEmployeeRefereesSuccess(state, action);
    case DELETE_EMPLOYEE_REFEREES_FAIL:
      return deleteEmployeeRefereesFail(state, action);

    case CREATE_EMPLOYEE_GUARANTORS_START:
      return createEmployeeGuarantorStart(state);
    case CREATE_EMPLOYEE_GUARANTORS_SUCCESS:
      return createEmployeeGuarantorSuccess(state, action);
    case CREATE_EMPLOYEE_GUARANTORS_FAIL:
      return createEmployeeGuarantorFail(state, action);

    case UPDATE_EMPLOYEE_GUARANTORS_START:
      return updateSingleEmployeeGuarantorStart(state, action);
    case UPDATE_EMPLOYEE_GUARANTORS_SUCCESS:
      return updateSingleEmployeeGuarantorSuccess(state, action);
    case UPDATE_EMPLOYEE_GUARANTORS_FAIL:
      return updateSingleEmployeeGuarantorFail(state, action);

    case DELETE_EMPLOYEE_GUARANTORS_START:
      return deleteEmployeeGuarantorStart(state);
    case DELETE_EMPLOYEE_GUARANTORS_SUCCESS:
      return deleteEmployeeGuarantorSuccess(state, action);
    case DELETE_EMPLOYEE_GUARANTORS_FAIL:
      return deleteEmployeeGuarantorFail(state, action);

    case CREATE_EDUCATION_START:
      return createEducationStart(state);
    case CREATE_EDUCATION_SUCCESS:
      return createEducationSuccess(state, action);
    case CREATE_EDUCATION_FAIL:
      return createEducationFail(state, action);

    case UPDATE_EDUCATION_START:
      return updateEducationStart(state, action);
    case UPDATE_EDUCATION_SUCCESS:
      return updateEducationSuccess(state, action);
    case UPDATE_EDUCATION_FAIL:
      return updateEducationFail(state, action);

    case DELETE_EDUCATION_START:
      return deleteEducationStart(state);
    case DELETE_EDUCATION_SUCCESS:
      return deleteEducationSuccess(state, action);
    case DELETE_EDUCATION_FAIL:
      return deleteEducationFail(state, action);

    case CREATE_CERTIFICATION_START:
      return createCertificationStart(state);
    case CREATE_CERTIFICATION_SUCCESS:
      return createCertificationSuccess(state, action);
    case CREATE_CERTIFICATION_FAIL:
      return createCertificationFail(state, action);

    case UPDATE_CERTIFICATION_START:
      return updateCertificationStart(state, action);
    case UPDATE_CERTIFICATION_SUCCESS:
      return updateCertificationSuccess(state, action);
    case UPDATE_CERTIFICATION_FAIL:
      return updateCertificationFail(state, action);

    case DELETE_CERTIFICATION_START:
      return deleteCertificationStart(state);
    case DELETE_CERTIFICATION_SUCCESS:
      return deleteCertificationSuccess(state, action);
    case DELETE_CERTIFICATION_FAIL:
      return deleteCertificationFail(state, action);

    case CREATE_NEXTOFKINS_START:
      return createNextofKinsStart(state);
    case CREATE_NEXTOFKINS_SUCCESS:
      return createNextofKinsSuccess(state, action);
    case CREATE_NEXTOFKINS_FAIL:
      return createNextofKinsFail(state, action);

    case UPDATE_NEXTOFKINS_START:
      return updateNextofKinsStart(state, action);
    case UPDATE_NEXTOFKINS_SUCCESS:
      return updateNextofKinsSuccess(state, action);
    case UPDATE_NEXTOFKINS_FAIL:
      return updateNextofKinsFail(state, action);

    case CREATE_DEPENDENTS_START:
      return createDependentsStart(state);
    case CREATE_DEPENDENTS_SUCCESS:
      return createDependentsSuccess(state, action);
    case CREATE_DEPENDENTS_FAIL:
      return createDependentsFail(state, action);

    case UPDATE_DEPENDENTS_START:
      return updateDependentsStart(state, action);
    case UPDATE_DEPENDENTS_SUCCESS:
      return updateDependentsSuccess(state, action);
    case UPDATE_DEPENDENTS_FAIL:
      return updateDependentsFail(state, action);

    case DELETE_DEPENDENTS_START:
      return deleteDependentsStart(state);
    case DELETE_DEPENDENTS_SUCCESS:
      return deleteDependentsSuccess(state, action);
    case DELETE_DEPENDENTS_FAIL:
      return deleteDependentsFail(state, action);

    case CREATE_MEDICALS_START:
      return createMedicalsStart(state);
    case CREATE_MEDICALS_SUCCESS:
      return createMedicalsSuccess(state, action);
    case CREATE_MEDICALS_FAIL:
      return createMedicalsFail(state, action);

    case UPDATE_MEDICALS_START:
      return updateMedicalsStart(state, action);
    case UPDATE_MEDICALS_SUCCESS:
      return updateMedicalsSuccess(state, action);
    case UPDATE_MEDICALS_FAIL:
      return updateMedicalsFail(state, action);

    case CREATE_LANGUAGES_START:
      return createLanguagesStart(state);
    case CREATE_LANGUAGES_SUCCESS:
      return createLanguagesSuccess(state, action);
    case CREATE_LANGUAGES_FAIL:
      return createLanguagesFail(state, action);

    case UPDATE_LANGUAGES_START:
      return updateLanguagesStart(state, action);
    case UPDATE_LANGUAGES_SUCCESS:
      return updateLanguagesSuccess(state, action);
    case UPDATE_LANGUAGES_FAIL:
      return updateLanguagesFail(state, action);

    case DELETE_LANGUAGES_START:
      return deleteLanguagesStart(state);
    case DELETE_LANGUAGES_SUCCESS:
      return deleteLanguagesSuccess(state, action);
    case DELETE_LANGUAGES_FAIL:
      return deleteLanguagesFail(state, action);
    case SEND_EMPLOYEE_EMAIL_REQUEST:
      return {
        ...state,
        loading: true,
        success: false,
      };

    case SEND_EMPLOYEE_EMAIL_SUCCESS:
      return {
        ...state,
        loading: false,
        success: false,
      };

    case SEND_EMPLOYEE_EMAIL_FAILURE:
      return {
        ...state,
        loading: false,
        confirmerror: action.payload?.error,
        success: false,
      };
    case GET_EMPLOYEE_REFEREES_START:
      return getEmployeeRefereeStart(state);
    case GET_EMPLOYEE_REFEREES:
      return getEmployeeRefereesSucess(state);
    case GET_EMPLOYEE_REFEREES_FAIL:
      return getEmployeeRefereeFailure(state, action);

    case actions.CHANGE_REQUEST_ALL_START:
      return {
        ...state,
        loading: true,
        error: null,
        employeeChangeRequestData: [],
      };

    case GET_UPGRADE_LETTER_SUCCESS:
      return {
        ...state,
        loading: false,
        error: null,
        employeeUpgradeLetterData: action.payload,
      };
    case GET_UPGRADE_LETTER_FAIL:
      return {
        ...state,
        loading: false,
        error: action.error,
      };

    case BULK_EMPLOYEE_CSV_HEADERS_SUCCESS:
      return {
        ...state,
        loading: false,
        error: null,
        bulkCVSData: action.payload,
      };

    case BULK_EMPLOYEE_CSV_HEADERS_FAIL:
      return {
        ...state,
        loading: false,
        error: action.error,
      };

    case actions.CHANGE_REQUEST_ALL_SUCCESS:
      return {
        ...state,
        error: null,
        success: false,
        loading: false,
        employeeChangeRequestData: action.payload,
      };

    case actions.CHANGE_REQUEST_ALL_FAIL:
      return {
        ...state,
        loading: false,
        error: action.error,
      };
    case actions.ALL_UPGRADED_LETTERS_START:
      return {
        ...state,
        loading: true,
        error: null,
        upgradedLettersCVSData: [],
      };
    case actions.ALL_UPGRADED_LETTERS_SUCCESS:
      return {
        ...state,
        error: null,
        success: false,
        loading: false,
        upgradedLettersCVSData: action.payload,
      };
    case actions.ALL_UPGRADED_LETTERS_FAIL:
      return {
        ...state,
        loading: false,
        error: action.error,
      };

    default:
      return {
        ...state,
      };
  }
};

export default employeeReducer;

import React, { useState } from 'react';
import { Text } from 'components/common/Styles';
import { svgs } from 'assets';
import { Container, Modal, ModalHeader, ModalContent, ModalFooter } from './styles';

const WarnModal = ({
  children,
  show,
  toggle,
  title,
  child,
  prompt,
  subText,
  action,
  actionInProgressText,
  actionText,
  onCancel,
  cancelText,
  ...props
}) => {
  const [actionInProgress, setactionInProgress] = useState(false);

  return !show ? null : (
    <Container>
      <Modal>
        <ModalHeader>
          <Text style={{ width: 'fit-content', minWidth: 400 }} weight="700" color="#333333">
            {title}
          </Text>
          <div
            className="close-icon"
            role="button"
            onClick={toggle}
            tabIndex="0"
            onKeyPress={toggle}
          >
            <img src={svgs.close_button} alt="close icon" />
          </div>
        </ModalHeader>
        <ModalContent>
          {/* <img width="64px" height="64px" src={svgs.DELETE_WARN} alt="delete warning" /> */}
          <Text style={{ textAlign: "center", marginTop: "60px" }} weight="700" margin="0px 0px 15px 0px">
            {prompt}
          </Text>
          <Text style={{ margin: '0 auto' }} size="14px" color="#666666">
            {subText}
          </Text>
        </ModalContent>
        <ModalFooter>
          <button
            type="button"
            className="cancel"
            onClick={onCancel}
          >
            {cancelText}
          </button>
          <button
            type="button"
            onClick={async () => {
              setactionInProgress(true);
              await action();
              setactionInProgress(false);
              toggle();
            }}
          >
            {actionInProgress ? actionInProgressText : actionText}
          </button>

        </ModalFooter>
      </Modal>
    </Container>
  );
};

export default WarnModal;

import * as actions from '../actionTypes/deploymentLetter';

const initialState = {
  loading: false,
  data: {},
  addDeploymentSuccess: false,
  getDeploymentSuccess: false,
  getSingleDeploymentSuccess: false,
  editDeploymentSuccess: false,
  deleteDeploymentSuccess: false,
  sendDeploymentLetterSuccess: false,
  errorMessage: '',
  error: false,
  templates: [],
};

const deploymentLettersReducer = (state = initialState, action) => {
  switch (action.type) {
    case actions.CREATE_DEPLOYMENT_REQUEST:
      return {
        ...state,
        loading: true,
      };

    case actions.CREATE_DEPLOYMENT_SUCCESS:
      return {
        ...state,
        loading: false,
        addDeploymentSuccess: true,
      };

    case actions.CREATE_DEPLOYMENT_FAILURE:
      return {
        ...state,
        loading: false,
        error: true,
      };

    case actions.GET_DEPLOYMENT_LETTER_REQUEST:
      return {
        ...state,
        loading: true,
      };

    case actions.GET_DEPLOYMENT_LETTER_SUCCESS:
      return {
        ...state,
        loading: false,
        getDeploymentSuccess: true,
        templates: action.payload,
      };
    case actions.GET_DEPLOYMENT_LETTER_FAILURE:
      return {
        ...state,
        loading: false,
        error: true,
      };
    case actions.GET_SINGLE_DEPLOYMENT_LETTER_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case actions.GET_SINGLE_DEPLOYMENT_LETTER_SUCCESS:
      return {
        ...state,
        loading: false,
        getSingleDeploymentSuccess: true,
        singleTemplate: action.payload,
      };
    case actions.GET_SINGLE_DEPLOYMENT_LETTER_FAILURE:
      return {
        ...state,
        loading: false,
        getSingleDeploymentSuccess: false,
        error: true,
      };
    case actions.EDIT_DEPLOYMENT_LETTER_REQUEST:
      return {
        ...state,
        loading: true,
      };

    case actions.EDIT_DEPLOYMENT_LETTER_SUCCESS:
      return {
        ...state,
        loading: false,
        editDeploymentSuccess: true,
      };

    case actions.EDIT_DEPLOYMENT_LETTER_FAILURE:
      return {
        ...state,
        loading: false,
        error: true,
      };

    case actions.DELETE_DEPLOYMENT_LETTER_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case actions.DELETE_DEPLOYMENT_LETTER_SUCCESS:
      return {
        ...state,
        loading: false,
        deleteDeploymentSuccess: true,
      };

    case actions.DELETE_DEPLOYMENT_LETTER_FAILURE:
      return {
        ...state,
        loading: false,
        error: true,
      };

    case actions.SEND_DEPLOYMENT_LETTER_REQUEST:
      return {
        ...state,
        loading: true,
      };

    case actions.SEND_DEPLOYMENT_LETTER_SUCCESS:
      return {
        ...state,
        loading: false,
        sendDeploymentLetterSuccess: true,
      };
    case actions.SEND_DEPLOYMENT_LETTER_FAILURE:
      return {
        ...state,
        loading: false,
        error: true,
        errorObject: action.error?.data?.errors,
      };
    case 'DEPLOYMENT_TEMPLATE_INIT_STATE':
      return {
        ...state,
        addDeploymentSuccess: false,
        getDeploymentSuccess: false,
        editDeploymentSuccess: false,
        deleteDeploymentSuccess: false,
        getSingleDeploymentSuccess: false,
        errorMessage: '',
        error: false,
      };

    default:
      return {
        ...state,
      };
  }
};

export default deploymentLettersReducer;

import axios from 'config/axios';

export const getCompaniesApi = async (paginate = '', pageSize = 20, page = 1, searchParam) => {
  const url = paginate || `/company/?page=${page}&page_size=${pageSize}&search=${searchParam}`;
  const request = await axios.get(url);
  return request;
};

export const getCompaniesAllApi = async (paginate='', pageSize=100, page=1, searchParam) => {
  let url = `/company/all`;

  const request = await axios.get(url);
  return request;
};

export const addCompanyApi = async (payload) => {
  const request = await axios.post('/company/', payload);
  return request;
};

export const bulkUploadCompanyApi = async (payload) => {
  const request = await axios.post('/company/bulk_upload/', payload);
  return request;
};

export const editCompanyApi = async (id, payload) => {
  const request = await axios.patch(`/company/${id}/`, payload);
  return request;
};

export const deleteCompanyApi = async (id) => {
  const request = await axios.delete(`/company/${id}/`);
  return request;
};


export const getCompaniesByIdApi = async (id) => {
  const request = await axios.get(`/company/${id}/`);
  return request;
};

import { svgs } from 'assets';
import React, { useState } from 'react';
import { FormGroup } from '../Styles';

const Input = ({
  label,
  placeholder,
  required,
  type = 'text',
  onChange,
  error,
  icon,
  extraLabel,
  ...props
}) => {
  const [inputType, setInputType] = useState(type);
  const handleVisibility = () => {
    if (inputType === 'password') {
      return setInputType('text');
    }
    return setInputType('password');
  };
  return (
    <FormGroup marginbottom={props.marginBottom} required={required}>
      <label>{label} {' '}
      {extraLabel}
      </label>
      <div className="input-container">
        {icon && <img src={icon} alt="search icon" />}
        <input
          type={inputType}
          placeholder={placeholder}
          required={required}
          onChange={onChange}
          {...props}
        />
        {type === 'password' && (
          <svgs.EYE className="eye-icon" onClick={handleVisibility} />
        )}
      </div>
      {error ? <p className="error">{error}</p> : ''}
    </FormGroup>
  );
};

export default Input;

import axios from 'config/axios';

export const addAdminApi = async (payload) => {
  const request = await axios.post('/auth/users/', payload);
  return request;
};

export const getAdminApi = async (paginate, page, pagesize) => {
  const url = paginate || `/auth/users/?roles=ADMIN&page=${page}&page_size=${pagesize}`;
  const request = await axios.get(url);
  return request;
};

export const getSingleAdminApi = async (id) => {
  const request = await axios.get(`/auth/users/${id}`);
  return request;
};

export const getAdminSearchApi = async (search) => {
  const request = await axios.get(`/auth/users/?search=${search}&roles=ADMIN`);
  return request;
};

export const editAdminApi = async (user, payload) => {
  const request = await axios.patch(`/auth/users/${user}/`, payload);
  return request;
};

export const deleteAdminApi = async (user) => {
  const request = await axios.delete(`/auth/users/${user}/`);
  return request;
};

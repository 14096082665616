import { svgs } from 'assets';
import styled from 'styled-components';

export const Wrapper = styled.div`
  width: 70%;
  margin: auto;
  .content {
    display: flex;
    flex-direction: column;
    /* justify-content: center; */
    align-items: center;
    background: #ffffff;
    box-shadow: 0px 4px 4px rgba(199, 223, 241, 0.25);
    border-radius: 5px;

    padding: 20px;
    margin-top: 40px;

    .note {
      font-family: Inter;
      font-style: normal;
      font-weight: 500;
      font-size: 16px;
      line-height: 140%;
      text-align: center;
      color: #333333;
      width: 75%;
      span {
        text-transform: capitalize;
      }
    }

    .upload {
      width: 100%;
    }

    .button {
      font-family: Inter;
      font-style: normal;
      font-weight: 500;
      font-size: 14px;
      line-height: 26px;
      text-align: center;
      color: #ffffff;
      margin: 0;
    }
  }

  .status-wrapper {
    padding-bottom: 100px;
  }

  .header {
    font-family: Inter;
    font-style: normal;
    font-weight: bold;
    font-size: 24px;
    line-height: 29px;
    text-align: center;
    letter-spacing: -0.02em;
    color: #333333;
    padding: 30px 0;
  }

  .footer {
    display: flex;
    justify-content: flex-end;
    margin-top: 30px;

    .button,
    .button-default {
      padding: 10px 40px;
    }
    .button-default {
      background: #ffffff;
      opacity: 0.5;
      border: 1px solid #d9d9d9;
      box-sizing: border-box;
      border-radius: 5px;
    }
  }
  .logo-wrapper {
    display: flex;
    justify-content: center;
  }

  .file-upload-wrapper {
    width: 65%;
    margin-bottom: 40px;
  }
`;

export const EMPTY_FILE = styled(svgs.EMPTY_FILE_CMP)`
  width: 102px;
  height: 102px;
  margin-top: 40px;
`;

export const FormContainer = styled.div`
  width: 60%;
  margin: auto;
  padding-bottom: 30px;

  .logo-wrapper {
    display: flex;
    justify-content: center;
  }
  .form-grid {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 10px;
  }
`;

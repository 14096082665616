/* eslint-disable camelcase */
import React from 'react';
import { svgs } from 'assets';
import PageContainer from 'components/common/PageContainer';
import { useParams } from 'react-router-dom';
import Button from 'components/Button';
import SuccessModal from 'components/SuccessModal';
import { useToggle } from 'hooks';
import DeclineModal from './DeclineModal';
import { FormContainer } from './styles';

/**
 *
 * @returns
 *
 */
export default function DeclineHeadcount() {
  const { employee_headcount_id } = useParams();
  const { toggle, show } = useToggle(true);
  const { toggle: toggleSuccess, show: showSuccess } = useToggle();

  return (
    <PageContainer>
      <FormContainer>
        <div className="logo-wrapper">
          <svgs.COLOURED_LOGO />
        </div>
        <Button className="mt-2" onClick={toggle}> Decline </Button>
        <DeclineModal employee_headcount_id={employee_headcount_id} toggle={toggle} show={show} tokenData={ employee_headcount_id } toggleSuccess={toggleSuccess} />
        <SuccessModal
          show={showSuccess}
          toggle={toggleSuccess}
          title=""
          prompt="Employee Declined"
          subtext="Employee has been successfully Declined"
        />
      </FormContainer>
    </PageContainer>
  );
}

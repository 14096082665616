import styled from 'styled-components';

export const Container = styled.div`
  margin-top: 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
  .footer {
    display: flex;
    justify-content: flex-end;
    margin: 30px 0;
    width: 40%;

    .button,
    .button-default {
      padding: 10px 40px;
    }
    .button-default {
      background: #ffffff;
      opacity: 0.5;
      border: 1px solid #d9d9d9;
      box-sizing: border-box;
      border-radius: 5px;
    }
  }
`;

export const FormContainer = styled.div`
  margin-top: 40px;
  background: #ffffff;
  border: 1px solid #f0f0f0;
  box-sizing: border-box;
  border-radius: 10px;
  width: 40%;
  margin: 70px auto;

  .title {
    font-style: normal;
    font-weight: bold;
    font-size: 16px;
    line-height: 140%;
    color: #30313c;
  }
  .header {
    padding: 18px 30px;
    border-bottom: 1px solid #f0f0f0;
  }

  .profile-image-section {
    display: grid;
    align-items: center;
    grid-template-columns: auto 1fr auto;
    gap: 40px;
    padding: 38px 30px;
    border-bottom: 1px solid #f0f0f0;
  }
  .logo-wrapper {
    display: flex;
    justify-content: center;
  }
`;

export const ProfileText = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  span:first-child {
    font-weight: 500;
    font-size: 16px;
    padding-bottom: 10px;
  }

  span:not(:first-child) {
    font-size: 14px;
    color: #999;
  }
`;

export const Form = styled.div`
  padding: 30px;
  .form-grid {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 30px;
  }
`;

export const Wrapper = styled.div`
  width: 70%;
  margin: auto;
  .content {
    display: flex;
    flex-direction: column;
    /* justify-content: center; */
    align-items: center;
    background: #ffffff;
    box-shadow: 0px 4px 4px rgba(199, 223, 241, 0.25);
    border-radius: 5px;

    padding: 20px;
    margin-top: 40px;

    .note {
      font-family: Inter;
      font-style: normal;
      font-weight: 500;
      font-size: 16px;
      line-height: 140%;
      text-align: center;
      color: #333333;
      width: 75%;
      span {
        text-transform: capitalize;
      }
    }

    .upload {
      width: 100%;
    }

    .button {
      font-family: Inter;
      font-style: normal;
      font-weight: 500;
      font-size: 14px;
      line-height: 26px;
      text-align: center;
      color: #ffffff;
      margin: 0;
    }
  }

  .status-wrapper {
    padding-bottom: 100px;
  }

  .header {
    font-family: Inter;
    font-style: normal;
    font-weight: bold;
    font-size: 24px;
    line-height: 29px;
    text-align: center;
    letter-spacing: -0.02em;
    color: #333333;
    padding: 30px 0;
  }

  .footer {
    display: flex;
    justify-content: flex-end;
    margin-top: 30px;

    .button,
    .button-default {
      padding: 10px 40px;
    }
    .button-default {
      background: #ffffff;
      opacity: 0.5;
      border: 1px solid #d9d9d9;
      box-sizing: border-box;
      border-radius: 5px;
    }
  }
  .logo-wrapper {
    display: flex;
    justify-content: center;
  }

  .file-upload-wrapper {
    width: 65%;
    margin-bottom: 40px;
  }
`;

import React, { useState } from 'react';
import PageContainer from 'components/common/PageContainer';
import {
  HomeWrapper,
  LogoWrapper,
  Content,
  ContentTitle,
  ContentSubTitle,
} from './styles';
import { svgs } from 'assets';
import Button from 'components/common/Button';
import UploadComponent from 'components/UploadComponent';

const AcceptOffer = () => {
  const [success, setSuccess] = useState(false);
  return (
    <PageContainer>
      <LogoWrapper>
        <img src={svgs.LOGO_COLOURED} alt="logo" />
      </LogoWrapper>
      <HomeWrapper>
        <div className="app">
          <Content>
            {!success ? (
              <img src={svgs.EMPTY_FILE} width="60px" height="60px" alt="file icon" />
            ) : (
              <img src={svgs.CHECK_ICON} width="60px" height="60px" alt="file icon" />
            )}
            <br />
            <ContentTitle>
              {!success
                ? 'Upload your Signed offer letter'
                : 'Document Successfully Uploaded'}
            </ContentTitle>
            {!success ? (
              <div>
                Hello,
                <ContentSubTitle>
                  It's nice to have you on-board. Please provide a signed copy of your
                  offer letter to confirm your acceptance.
                </ContentSubTitle>
              </div>
            ) : (
              'You would be contacted for further information.'
            )}
            <br />
            {!success ? (
              <div className="button-container">
                <UploadComponent
                  accept={[
                    'application/pdf',
                    'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
                    'application / msword',
                  ]}
                />
                <br />
                <Button text="Upload Offer Letter" onClick={() => setSuccess(true)} />
              </div>
            ) : (
              ''
            )}
          </Content>
        </div>
      </HomeWrapper>
    </PageContainer>
  );
};

export default AcceptOffer;

import React, { Fragment, useCallback, useState } from 'react';
import { svgs } from 'assets';
import { useDropzone } from 'react-dropzone';
import { Wrapper } from './styles';

const getIconType = (file, DefaultIcon) => {
  const fileObject =
    typeof file === 'string'
      ? {
          type: `image/${file.split('.').pop()}`,
          name: file.split('.').shift().replace('https://', ''),
        }
      : file;

  switch (true) {
    case /application\/pd/.test(fileObject?.type):
      return <svgs.FILE_UPLOAD_ICON />;
    case /image\/(jpg|png|jpeg)/.test(fileObject?.type):
      return <svgs.IMAGE_UPLOAD_ICON />;
    default:
      return <DefaultIcon />;
  }
};

const getFileName = (file) => {
  const fileName =
    typeof file === 'string' ? file.replace('https://', '').split('/').pop() : file?.name;

  return fileName;
};

const FileUpload = ({
  value,
  limit,
  acceptedFiles,
  onChange,
  label,
  name,
  icon,
  className,
  error,
}) => {
  const [errors, setErrors] = useState();

  const onDrop = useCallback(async (files) => {
    /** @type {File} */
    const file = files[0];
    const mb = 1000000;
    const maxFileSize = 25 * mb;
    setErrors('');

    if (file.size > maxFileSize) {
      return setErrors('file size is too large, please select a Video less than 25mb');
    }

    if (file.type !== 'application/pdf') {
      return setErrors('Only Pdf and docx is allowed');
    }

    const reader = new FileReader();

    return reader.readAsArrayBuffer(file);
  }, []);
  const {
    getRootProps,
    getInputProps,
    isDragActive,
    acceptedFiles: draggedFile,
  } = useDropzone({
    onDrop,
  });

  const fileValue = value ?? draggedFile[0];
  const fileError = errors ?? error;
  return (
    <Wrapper className={className}>
      <p className="title">{label}</p>
      <div className={`content-wrapper ${fileError ? 'error' : ''}`}>
        <div
          {...getRootProps()}
          className={`drop-input ${isDragActive ? 'drop-input-active' : ''} ${
            fileError ? 'has-error' : ''
          }`}
        >
          <input
            {...getInputProps({
              accept: acceptedFiles,
            })}
            onChange={onChange}
            name={name}
          />
          <span className="mb-3">{getIconType(value, icon)}</span>
          {fileValue?.name || typeof fileValue === 'string' ? (
            <p style={{maxWidth:"300px",overflowX:'scroll', paddingBottom:"1rem"}}>{getFileName(fileValue)}</p>
          ) : (
            <Fragment>
              <p>
                Drop file here or
                <span> browse </span>
                to select
              </p>
              <p>
                Size limit:
                {limit}
                MB
                {' ('}
                {acceptedFiles
                  .replace('application/', '')
                  .replace('image/*', 'png, jpeg, jpg')}
                {' )'}
              </p>
            </Fragment>
          )}
        </div>
      </div>
      {fileError && <p className="error-text">{error}</p>}
    </Wrapper>
  );
};

export default FileUpload;

import React from "react";
import { ToastContainer } from "react-toastify";
import "./toast.css"
import CustomToast from "components/common/Toast/customToast";

const ToastContext = React.createContext();


/**
 *
 *
 * @param {*} props
 * @return {*} 
 */
function ToastProvider(props) {
  return (
    <ToastContext.Provider value={CustomToast} {...props}>
      <ToastContainer
        autoClose={5000}
        className="test"
        position="top-center"
      />
      {props.children}
    </ToastContext.Provider>
  );
}

/**
 *
 *
 * @export
 */
function useToast() {
  const context = React.useContext(ToastContext)
  return context;
};

export { ToastProvider, useToast };
import React from 'react';
import { InputContainer, InputWithIcon } from './styles';
import { svgs } from '../../../assets';


const SearchBar = ({
  searchParam,
  setSearchParam,
  onKeyPress,
  onBlur,
  handleClick,
  placeHolderText,
  isNested,
  onKeyUp
}) => {
  return (
    <InputContainer>
      <InputWithIcon isNested={isNested}>
        <img
          src={svgs.SEARCH_ICON}
          alt="search icon"
          onClick={handleClick}
          role="button"
        />
        <input
          name="search"
          value={searchParam}
          onChange={(e) => setSearchParam(e.target.value)}
          placeholder={placeHolderText}
          onKeyPress={onKeyPress}
          onKeyUp={(e) => setSearchParam(e.target.value)}
          onBlur={(e) => setSearchParam(e.target.value)}
          style={{ outline: 'none' }}
        />
      </InputWithIcon>
    </InputContainer>
  );
};

export default SearchBar;

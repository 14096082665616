import {
  ADD_OFFER_LETTER_REQUEST,
  ADD_OFFER_LETTER_SUCCESS,
  ADD_OFFER_LETTER_FAILURE,
  EDIT_OFFER_LETTER_REQUEST,
  EDIT_OFFER_LETTER_SUCCESS,
  EDIT_OFFER_LETTER_FAILURE,
  GET_OFFER_LETTER_REQUEST,
  GET_OFFER_LETTER_SUCCESS,
  GET_OFFER_LETTER_FAILURE,
  DELETE_OFFER_LETTER_REQUEST,
  DELETE_OFFER_LETTER_SUCCESS,
  DELETE_OFFER_LETTER_FAILURE,
  GET_SINGLE_OFFER_LETTER_REQUEST,
  GET_SINGLE_OFFER_LETTER_SUCCESS,
  GET_SINGLE_OFFER_LETTER_FAILURE,
  SEND_OFFER_LETTER_REQUEST,
  SEND_OFFER_LETTER_SUCCESS,
  SEND_OFFER_LETTER_FAILURE,
  GET_SEARCH_OFFER_LETTER_REQUEST,
  GET_SEARCH_OFFER_LETTER_SUCCESS
} from './../actionTypes/templates';

const initialState = {
  loading: false,
  data: {},
  addOfferSuccess: false,
  getOfferSuccess: false,
  getSingleOfferSuccess: false,
  editOfferSuccess: false,
  deleteOfferSuccess: false,
  sendOfferLetterSuccess: false,
  errorMessage: '',
  error: false,
  templates: [],
  singleTemplate: {},
  category: '',
  moreTemplatesData:[]
};


const templates = (state = initialState, action) => {
  switch (action.type) {
    case ADD_OFFER_LETTER_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case ADD_OFFER_LETTER_SUCCESS:
      return {
        ...state,
        loading: false,
        addOfferSuccess: true,
      };
    case ADD_OFFER_LETTER_FAILURE:
      return {
        ...state,
        loading: false,
        addOfferSuccess: false,
        error: true,
      };
    case SEND_OFFER_LETTER_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case SEND_OFFER_LETTER_SUCCESS:
      return {
        ...state,
        loading: false,
        sendOfferLetterSuccess: true,
      };
    case SEND_OFFER_LETTER_FAILURE:
      return {
        ...state,
        loading: false,
        sendOfferLetterSuccess: false,
        error: true,
      };
    case GET_OFFER_LETTER_REQUEST:
      return {
        ...state,
        loading: true,
        getOfferSuccess:false,
        moreTemplatesData:[]
      };
    case GET_SEARCH_OFFER_LETTER_REQUEST:
        return {
          ...state,
          loading: true,
          getOfferSuccess:false,
          templates:[],
          moreTemplatesData:[]
        };  
    case GET_SEARCH_OFFER_LETTER_SUCCESS:
      return {
        ...state,
        loading: false,
        getOfferSuccess: true,
        templates: action.payload,
        moreTemplatesData: action.payload
      }; 
    
    case GET_OFFER_LETTER_SUCCESS:
      return {
        ...state,
        loading: false,
        getOfferSuccess: true,
        templates: action.payload,
        moreTemplatesData: action.payload
      };
    case GET_OFFER_LETTER_FAILURE:
      return {
        ...state,
        loading: false,
        getOfferSuccess: false,
        error: true,
      };
    case GET_SINGLE_OFFER_LETTER_REQUEST:
      return {
        ...state,
        loading: true,
        templates:[],
        moreTemplatesData:[]
      };
    case GET_SINGLE_OFFER_LETTER_SUCCESS:
      return {
        ...state,
        loading: false,
        getSingleOfferSuccess: true,
        singleTemplate: action.payload,
      };
    case GET_SINGLE_OFFER_LETTER_FAILURE:
      return {
        ...state,
        loading: false,
        getSingleOfferSuccess: false,
        error: true,
      };
    case EDIT_OFFER_LETTER_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case EDIT_OFFER_LETTER_SUCCESS:
      return {
        ...state,
        loading: false,
        editOfferSuccess: true,
      };
    case EDIT_OFFER_LETTER_FAILURE:
      return {
        ...state,
        loading: false,
        editOfferSuccess: false,
        error: true,
      };
    case DELETE_OFFER_LETTER_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case DELETE_OFFER_LETTER_SUCCESS:
      return {
        ...state,
        loading: false,
        deleteOfferSuccess: true,
      };
    case DELETE_OFFER_LETTER_FAILURE:
      return {
        ...state,
        loading: false,
        deleteOfferSuccess: false,
        error: true,
      };

    case 'OFFER_TEMPLATE_INIT_STATE':
      return {
        ...state,
        addOfferSuccess: false,
        getOfferSuccess: false,
        editOfferSuccess: false,
        deleteOfferSuccess: false,
        getSingleOfferSuccess: false,
        sendOfferLetterSuccess: false,
        errorMessage: '',
        error: false,
      };
    case 'SELECT_CATEGORY':
      return {
        ...state,
        category: action.payload.category,
      };
    default:
      return {
        ...state,
      };
  }
};

export default templates;

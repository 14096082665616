// react libraries
import React from 'react';
import { Route, Switch } from 'react-router-dom';
import NotFound from 'pages/ErrorPages/NotFound';
import AcceptOffer from 'pages/Settings/Letters/AcceptOffer';
import MeetingScheduled from 'pages/Consent/Meeting';
import VerifyToken from 'pages/Auth/VerifyToken';
import CandidateAcceptOffer from 'pages/Candidate/Offer/Accept';
import CandidateAcceptWaiver from 'pages/Candidate/Waiver/Accept';
import CandidateDeclineWaiver from 'pages/Candidate/Waiver/Decline';
import { Decline } from 'pages/Candidate/Offer/Decline';
import { DeclineSuccess } from 'pages/Candidate/Offer/DeclineSuccess';
import { Success } from 'pages/Candidate/Offer/Success';
import CandidateSignup from 'pages/Candidate/Auth/Signup';
import Supervisor from 'pages/Supervisor';
import CreatePassword from 'pages/Auth/CreatePassword';
import PasswordSuccess from 'pages/Auth/PasswordSuccess';
import SetNewPassword from 'pages/Auth/SetNewPassword';
import MailSent from 'pages/Auth/MailSent';
import ForgotPassword from 'pages/Auth/ForgotPassword';
import Signin from 'pages/Auth/Signin';
import ApproveHeadcount from 'pages/Candidate/HeadCount/Approve';
import DeclineHeadcount from 'pages/Candidate/HeadCount/Decline';
import AuthenticatedRoute from 'components/common/AuthenticatedRoute';
import adminRoutes from './admin';
import candidateRoutes from './candidate';
import sharedRoutes from './shared';
import AppraisalReview from 'pages/AppraisalsReview';
import AppraisalsSuccess from 'pages/AppraisalsReview/Success';


const Routes = () => {
  return (
    <Switch>
      {sharedRoutes.map((eachRoute, index) => (
        <AuthenticatedRoute key={eachRoute.path} {...eachRoute} />
      ))}
      {adminRoutes.map((eachRoute, index) => (
        <AuthenticatedRoute key={eachRoute.path} {...eachRoute} />
      ))}
      {candidateRoutes.map((eachRoute, index) => (
        <AuthenticatedRoute key={eachRoute.path} {...eachRoute} />
      ))}
      <Route exact path="/onboarding/accept/offer" component={AcceptOffer} />
      <Route exact path="/error/404" component={NotFound} />
      <Route exact path="/" component={Signin} />
      <Route exact path="/signin" component={Signin} />
      <Route exact path="/verify-user" component={VerifyToken} />
      <Route exact path="/forgot-password" component={ForgotPassword} />
      <Route exact path="/password-success" component={PasswordSuccess} />
      <Route exact path="/mail-sent" component={MailSent} />
      <Route exact path="/new-password/:token" component={SetNewPassword} />
      <Route exact path="/create-password" component={CreatePassword} />
      <Route
        exact
        path="/approve_headcount/:employee_headcount_id/:token/"
        component={ApproveHeadcount}
      />
      <Route
        exact
        path="/decline_headcount/:employee_headcount_id/:token/"
        component={DeclineHeadcount}
      />
      <Route exact path="/supervisor" component={Supervisor} />
      <Route
        exact
        path="/onboarding/accept/offer/:token"
        component={CandidateAcceptOffer}
      />
      <Route
        exact
        path="/onboarding/accept/waiver/:token"
        component={CandidateAcceptWaiver}
      />
      <Route
        exact
        path="/onboarding/decline/waiver/:token"
        component={CandidateDeclineWaiver}
      />
      <Route exact path="/appraisal-success" component={AppraisalsSuccess} />
      <Route exact path="/onboarding/offer-status" component={Success} />
      <Route exact path="/onboarding/offer-decline" component={DeclineSuccess} />
      <Route exact path="/onboarding/decline/offer/:offerId" component={Decline} />
      <Route exact path="/meeting-scheduled" component={MeetingScheduled} />
      <Route exact path="/candidate/complete-signup" component={CandidateSignup} />
      <Route exact path="/:role/appraisals/:appraisal_id/:employee_id/:kpi_id" component={AppraisalReview} />
      <Route path="/404" component={NotFound} />
      <Route path="*" component={NotFound} />
    </Switch>
  );
};

export default Routes;

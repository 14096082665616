import {
    SEND_LEAVE_FAILURE,
    SEND_LEAVE_REQUEST,
    SEND_LEAVE_SUCCESS,
    GET_CANDIDATE_LEAVE_FAILURE,
    GET_CANDIDATE_LEAVE_REQUEST,
    GET_CANDIDATE_LEAVE_SUCCESS,
    RECALL_LEAVE_FAILURE,
    RECALL_LEAVE_REQUEST,
    RECALL_LEAVE_SUCCESS,
    SEND_LEAVE_EXTENSION_FAILURE,
    SEND_LEAVE_EXTENSION_REQUEST,
    SEND_LEAVE_EXTENSION_SUCCESS,
    SEND_LEAVE_RESUMPTION_FAILURE,
    SEND_LEAVE_RESUMPTION_REQUEST,
    SEND_LEAVE_RESUMPTION_SUCCESS,
  } from '../../actionTypes/candidate/leaveManagement';
  
  const initialState = {
    loading: false,
    data: [],
    error: null,
    success: false,
    leave: [],
    createLeaveSuccess: false,
    recallLeaveSuccess: false,
    extendLeaveSuccess:false,
  };
  
  const leaveReducer = (state = initialState, action) => {
    switch (action.type) {
        case SEND_LEAVE_REQUEST:
            return {
              ...state,
              loading: true,
              success: false,
            };
      
          case SEND_LEAVE_SUCCESS:
            return {
              ...state,
              loading: false,
              createLeaveSuccess: true,
              data: action.payload,
            };
      
          case SEND_LEAVE_FAILURE:
            return {
              ...state,
              loading: false,
              error: action.payload.error,
             success: false,   
        };

      case GET_CANDIDATE_LEAVE_REQUEST:
        return {
          ...state,
          loading: true,
          success: false,
          leave:[]
        };

      case GET_CANDIDATE_LEAVE_SUCCESS:
        return {
          ...state,
          loading: false,
          success: false,
          leave: action.payload?.data,
        };

      case GET_CANDIDATE_LEAVE_FAILURE:
        return {
          ...state,
          loading: false,
          error: action.payload?.error,
          recallLeaveSucces: false,
          
        };

    case RECALL_LEAVE_REQUEST:
      return {
        ...state,
        loading: true,
        success: false,
        recallLeaveSuccess:true
      };

    case RECALL_LEAVE_SUCCESS:
      return {
        ...state,
        loading: false,
        recallLeaveSucces: true,
        error:null
      };

    case RECALL_LEAVE_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload?.error,
        recallLeaveSuccess: false,
      };

      case SEND_LEAVE_EXTENSION_REQUEST:
        return {
          ...state,
          loading: true,
          extendLeaveSuccess: false,
          error:null
        };
  
      case SEND_LEAVE_EXTENSION_SUCCESS:
        return {
          ...state,
          loading: false,
          success: false,
          extendLeaveSuccess: true,
          error:null
        };
  
      case SEND_LEAVE_EXTENSION_FAILURE:
        return {
          ...state,
          loading: false,
          error: action.payload?.error,
        success: false,
        extendLeaveSuccess:false
        };
        case SEND_LEAVE_RESUMPTION_REQUEST:
          return {
            ...state,
            loading: true,
            success: false,
          };
    
        case SEND_LEAVE_RESUMPTION_SUCCESS:
          return {
            ...state,
            loading: false,
            success: false,
        
          };
    
        case SEND_LEAVE_RESUMPTION_FAILURE:
          return {
            ...state,
            loading: false,
            error: action.payload?.error,
          success: false,
          };
      
      
      default:
        return {
          ...state,
        };
    }
  };
  
  export default leaveReducer;
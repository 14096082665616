import React from 'react';

export const generateError = (errors) => {
  let listOfErrors = [];
  if (Array.isArray(errors)) {
    listOfErrors = [...errors];
  }

  if (typeof errors === 'object') {
    const arry = [];
    for (const propName in errors?.response?.data) {
      arry.push(errors[propName]);
      listOfErrors = [...arry];
    }
  } else {
    listOfErrors.push(errors);
  }

  return listOfErrors.join(' /n');
};

export const errorHandlerFunc = (errors) => {
  let listOfErrors = [];
  if (Array.isArray(errors)) {
    listOfErrors = [...errors];
  }

  if (typeof errors === 'object') {
    const arry = [];
    for (const propName in errors) {

      if (typeof errors[propName] === 'object' ){
        let propName1=errors[propName]
        for (let propName2 in propName1) {
          arry.push(`${propName2}-${errors[propName][propName2]}`);
          listOfErrors = [...arry];
        } 
      }else{
        arry.push(errors[propName]);
        listOfErrors = [...arry];
      }
     
    }
  } else {
    listOfErrors.push(errors);
  }

  return listOfErrors.join(' /n');
};

export const handleError = (errObject) => {
  const { response } = errObject;
  let errMsg = '';



  if (response && response?.status === 400) {
    const errorValues =
      response?.data?.errors || response?.data?.error
        ? Object.entries(response?.data?.errors || response?.data?.error)
        : Object.entries(response?.data);

    errMsg = errorValues.map((item, key) => {
      let field = `${item[0].charAt(0).toUpperCase()}${item[0].slice(1)}`;

      field = field.replace(/_/g, ' ');
      // item[1][0] can be an object like {approver_groups: []} with a value array of [field, error]
      if (item[1][0] instanceof Object) {
        if (Object.values(item[1][0])[0] instanceof Array) {
          return (
            <span key={key}>
              {`${Object.values(item[1][0])[0][0]}`}
              <br />
            </span>
          );
        }
      }
      return (
        // <span key={key}>{
        `${field}: ${item[1][0]} \n
                    `
        // }<br /></span>
      );
    });
  }

  if (errObject && !response) {

    let err=errObject?.data
    const errorValues = err? Object.entries(err) : Object.entries(err);

        errMsg = errorValues.map((item, key) => {
          let field = `${item[0].charAt(0).toUpperCase()}${item[0].slice(1)}`;
    
          field = field.replace(/_/g, ' ');
          // item[1][0] can be an object like {approver_groups: []} with a value array of [field, error]
          if (item[1][0] instanceof Object) {
            if (Object.values(item[1][0])[0] instanceof Array) {
              return (
                <span key={key}>
                  {`${Object.values(item[1][0])[0][0]}`}
                  <br />
                </span>
              );
            }
          }
          return (
            `${field}: ${item[1][0]} \n`          );
        });
        return errMsg;
  }

  if (response && response?.status === 500) errMsg = 'A server error occured';

  if (!response) errMsg = 'Connection error';
  return errMsg;
};

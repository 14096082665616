import {
  GET_USER_START,
  GET_USER_SUCCESS,
  GET_USER_FAIL,
  UPDATE_USER_START,
  UPDATE_USER_SUCCESS,
  UPDATE_USER_FAIL,
} from '../actionTypes/profile';

const initialState = {
  loading: false,
  data: [],
  error: null,
  success: false,
};

const getUserStart = (state) => ({
  ...state,
  loading: true,
  error: null,
  data:[]
});

const getUserSuccess = (state, action) => ({
  ...state,
  error: null,
  success: false,
  loading: false,
  data: action.payload,
});

const getUserFail = (state, action) => ({
  ...state,
  error: action.error,
  success: false,
  loading: false,
});

const updateUserStart = (state) => ({
  ...state,
  loading: true,
  error: null,
});

const updateUserSuccess = (state) => ({
  ...state,
  error: null,
  success: true,
  loading: false,
});

const updateUserFail = (state, action) => ({
  ...state,
  error: action.error,
  success: false,
  loading: false,
});

const profileReducer = (state = initialState, action) => {
  switch (action.type) {
    case GET_USER_START:
      return getUserStart(state);
    case GET_USER_SUCCESS:
      return getUserSuccess(state, action);
    case GET_USER_FAIL:
      return getUserFail(state, action);
    case UPDATE_USER_START:
      return updateUserStart(state);
    case UPDATE_USER_SUCCESS:
      return updateUserSuccess(state, action);
    case UPDATE_USER_FAIL:
      return updateUserFail(state, action);

    default:
      return {
        ...state,
      };
  }
};
export default profileReducer;

import {
  GET_HEADCOUNT,
  GET_HEADCOUNT_QUESTIONS,
  GET_EMPLOYEE_HEADCOUNT_RESPONSE,
  GET_EMPLOYEE_MONTHLY_HEADCOUNT_REQUEST,
  GET_EMPLOYEE_MONTHLY_HEADCOUNT_SUCCESS,
  GET_EMPLOYEE_RESPONSE_HEADCOUNT_SUCCESS,
} from '../actionTypes/headcount';

const initialState = {
  headCounts: [],
  questions: {},
  employeHeadcounteResponse: {},
  loading: false,
  data: {},
  error: false,
  success: false,
  monthlyHeadCount: [],
  employeeResponse: [],
};

const headCounts = (state = initialState, { type, payload }) => {
  switch (type) {
    case GET_HEADCOUNT:
      return {
        ...state,
        headCounts: payload,
      };
    case GET_HEADCOUNT_QUESTIONS:
      return {
        ...state,
        questions: payload,
      };
    case GET_EMPLOYEE_HEADCOUNT_RESPONSE:
      return {
        ...state,
        employeHeadcounteResponse: payload,
      };

    case GET_EMPLOYEE_MONTHLY_HEADCOUNT_REQUEST:
      return {
        ...state,
        loading: true,
        error: null,
      };
    case GET_EMPLOYEE_MONTHLY_HEADCOUNT_SUCCESS:
      return {
        ...state,
        loading: false,
        monthlyHeadCount: payload,
        error: null,
      };
    case GET_EMPLOYEE_RESPONSE_HEADCOUNT_SUCCESS:
      return {
        ...state,
        loading: false,
        employeeResponse: payload,
        error: null,
      };
    default:
      return state;
  }
};

export default headCounts;

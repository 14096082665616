export const ADD_ACTIVITY_REQUEST = 'ADD_ACTIVITY_REQUEST';
export const ADD_ACTIVITY_SUCCESS = 'ADD_ACTIVITY_SUCCESS';
export const ADD_ACTIVITY_FAILURE = 'ADD_ACTIVITY_FAILURE';

export const GET_ACTIVITIES_REQUEST = 'GET_ACTIVITIES_REQUEST';
export const GET_ACTIVITIES_SUCCESS = 'GET_ACTIVITIES_SUCCESS';
export const GET_ACTIVITIES_FAILURE = 'GET_ACTIVITIES_FAILURE';

export const EDIT_ACTIVITY_REQUEST = 'EDIT_ACTIVITY_REQUEST';
export const EDIT_ACTIVITY_SUCCESS = 'EDIT_ACTIVITY_SUCCESS';
export const EDIT_ACTIVITY_FAILURE = 'EDIT_ACTIVITY_FAILURE';

export const DELETE_ACTIVITY_REQUEST = 'DELETE_ACTIVITY_REQUEST';
export const DELETE_ACTIVITY_SUCCESS = 'DELETE_ACTIVITY_SUCCESS';
export const DELETE_ACTIVITY_FAILURE = 'DELETE_ACTIVITY_FAILURE';
import * as actions from '../actionTypes/companies';
import * as actions2 from '../actionTypes/company';

const initialState = {
  loading: false,
  data: [],
  payroll: null,
  companiesPayroll: null,
  addCompanySuccess: false,
  addCompanyFailure: false,
  bulkAddCompaniesSuccess: false,
  editCompanySuccess: false,
  editCompayFailure: false,
  deleteCompanyFailure: false,
  deleteCompanySuccess: false,
  error: null,
  success: false,
  companyLoading: false,
  departments: [],
  departmentSuccess: false,
  departmentLoading: false,
  jobroleLoading: false,
  jobroles: [],
  branches: [],
  branchLoading: false,
};

const getCompaniesReducer = (state = initialState, action) => {
  switch (action.type) {
    case actions.GET_COMPANIES_REQUEST:
      return {
        ...state,
        loading: true,
        success: false,
        addCompanySuccess: false,
        companyLoading: true,
        data: [],
      };
    case actions.GET_COMPANIES_SUCCESS:
      return {
        ...state,
        loading: false,
        success: true,
        data: action.payload,
        addCompanySuccess: false,
        companyLoading: false,
      };
    case actions.GET_COMPANIES_FAILURE:
      return {
        ...state,
        loading: false,
        success: false,
        error: action.payload,
        companyLoading: false,
      };
    case actions.ADD_COMPANY_REQUEST:
      return {
        ...state,
        loading: true,
        success: null,
        data: [],
      };
    case actions.ADD_COMPANY_SUCCESS:
      return {
        ...state,
        loading: false,
        addCompanySuccess: true,
        // data: { ...state.data, results: [action.payload, ...state.data?.results] },
        data: action.payload,
      };
    case actions.ADD_COMPANY_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload,
        addCompanyFailure: true,
      };
    case actions.BULK_ADD_COMPANIES_REQUEST:
      return {
        ...state,
        loading: true,
        success: null,
        data: [],
      };
    case actions.BULK_ADD_COMPANIES_SUCCESS:
      return {
        ...state,
        loading: false,
        bulkAddCompaniesSuccess: true,
        // data: { ...state.data, results: [...state.data?.results, ...action.payload] },
        data: action.payload,
      };
    case actions.BULK_ADD_COMPANIES_FAILURE:
      return {
        ...state,
        loading: false,
        error: true,
      };

    case actions.UPDATE_COMPANY_SUCCESS:
      return {
        ...state,
        loading: false,
        editCompanySuccess: true,
        data: {
          ...state.data,
          results: state.data?.results.map((item) => {
            if (item.id === action.payload.id) return action.payload;
            return item;
          }),
        },
      };
    case actions.UPDATE_COMPANY_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload,
        editCompanyFailure: true,
      };
    case actions.DELETE_COMPANY_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case actions.DELETE_COMPANY_SUCCESS:
      return {
        ...state,
        loading: false,
        deleteCompanySuccess: true,
        data: {
          ...state.data,
          results: state.data?.results.filter((item) => item.id !== action.payload),
        },
      };
    case actions.DELETE_COMPANY_FAILURE:
      return {
        ...state,
        loading: false,
        deleteCompanyFailure: true,
      };

    case actions2.GET_COMPANY_REQUEST:
      return {
        ...state,
        loading: true,
        success: null,
        data: [],
      };
    case actions2.GET_COMPANY_SUCCESS:
      return {
        ...state,
        loading: false,
        success: null,
        data: action.payload,
      };
    case actions2.GET_COMPANY_FAILURE:
      return {
        ...state,
        loading: false,
        error: true,
      };

    case actions2.GET_PAYROLL_REQUEST:
      return {
        ...state,
        loading: true,
        success: null,
        payroll: [],
      };
    case actions2.GET_PAYROLL_SUCCESS:
      return {
        ...state,
        loading: false,
        success: null,
        payroll: action.payload,
      };
    case actions2.GET_PAYROLL_FAILURE:
      return {
        ...state,
        loading: false,
        error: true,
      };

    case actions2.GET_PAYROLL_COMPANY_REQUEST:
      return {
        ...state,
        loading: true,
        success: null,
        companiesPayroll: [],
      };
    case actions2.GET_PAYROLL_COMPANY_SUCCESS:
      return {
        ...state,
        loading: false,
        success: null,
        companiesPayroll: action.payload,
      };
    case actions2.GET_PAYROLL_COMPANY_FAILURE:
      return {
        ...state,
        loading: false,
        error: true,
      };
    case actions.GET_ALL_COMPANY_DEPARTMENTS_REQUEST:
      return {
        ...state,
        departmentLoading: true,
        error: false,
        departments: [],
      };
    case actions.GET_ALL_COMPANY_DEPARTMENTS_SUCCESS:
      return {
        ...state,
        departmentLoading: false,
        success: true,
        departments: action.payload,
      };
    case actions.GET_ALL_COMPANY_DEPARTMENTS_FAILURE:
      return {
        ...state,
        departmentLoading: false,
        error: true,
      };

    case actions.GET_ALL_COMPANY_JOBROLES_REQUEST:
      return {
        ...state,
        jobroleLoading: true,
        error: false,
      };

    case actions.GET_ALL_COMPANY_JOBROLES_FAILURE:
      return {
        ...state,
        jobroleLoading: false,
        error: true,
        jobroles: [],
      };
    case actions.GET_ALL_COMPANY_JOBROLES_SUCCESS:
      return {
        ...state,
        jobroleLoading: false,
        success: true,
        jobroles: action.payload,
      };

    case actions.GET_ALL_COMPANY_BRANCHES_REQUEST:
      return {
        ...state,
        branchLoading: true,
        error: false,
      };

    case actions.GET_ALL_COMPANY_BRANCHES_FAILURE:
      return {
        ...state,
        branchLoading: false,
        error: true,
        branches: [],
      };
    case actions.GET_ALL_COMPANY_BRANCHES_SUCCESS:
      return {
        ...state,
        branchLoading: false,
        success: true,
        branches: action.payload,
      };

    default:
      return {
        ...state,
      };
  }
};

export default getCompaniesReducer;

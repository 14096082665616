/* eslint-disable no-confusing-arrow */
import { Form } from 'react-bootstrap';
import styled, { css } from 'styled-components';
import { RED_COLOR } from '../../../assets/colors';

export const SpaceBottom = styled.div`
  margin-bottom: ${(props) => (props.by ? props.by : '40px')};
`;

export const FlexWrapper = styled.div`
  display: ${(props) => props?.display || 'flex'};
  flex-direction: ${(props) => props?.direction || 'row'};
  align-items: ${(props) => props?.align_items || 'center'};
  justify-content: ${(props) => props?.justify_content || null};
  width: ${(props) => props?.width || '100%'};
`;

export const Text = styled.p`
  font-size: ${(props) => (props.size ? props.size : '16px')};
  padding: ${(props) => props?.padding};
  margin: ${(props) => props?.margin};
  font-weight: ${(props) => (props.weight ? props.weight : 'normal')};
  color: ${(props) => (props.color ? props.color : '#573636')};
  text-align: ${(props) => (props.align ? props.align : 'left')};
  text-transform: ${(props) => (props.transform ? props.transform : '')};
  word-break: ${(props) => props?.wordBreak};
`;

export const H3 = styled.h3`
  font-size: ${(props) => (props.size ? props.size : '16px')};
  font-weight: ${(props) => (props.weight ? props.weight : 'normal')};
  color: ${(props) => (props.color ? props.color : '#333333')};
  text-align: ${(props) => (props.align ? props.align : 'left')};
  text-transform: ${(props) => (props.transform ? props.transform : '')};
`;

export const H4 = styled.h4`
  font-size: ${(props) => (props.size ? props.size : '16px')};
  font-weight: ${(props) => (props.weight ? props.weight : 'normal')};
  color: ${(props) => (props.color ? props.color : '#333333')};
  text-align: ${(props) => (props.align ? props.align : 'left')};
  text-transform: ${(props) => (props.transform ? props.transform : '')};
`;
export const H2 = styled.h2`
  font-size: ${(props) => (props.size ? props.size : '16px')};
  font-weight: ${(props) => (props.weight ? props.weight : 'normal')};
  color: ${(props) => (props.color ? props.color : '#333333')};
  text-align: ${(props) => (props.align ? props.align : 'left')};
  text-transform: ${(props) => (props.transform ? props.transform : '')};
`;

export const PaginationContainer = styled.div`
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  margin-top: 20px;
  div {
    display: grid;
    align-items: center;
    justify-items: center;
    grid-template-columns: auto 60px;
    &:first-child {
      grid-template-columns: repeat(5, 35px);
    }
    &:last-child {
      justify-items: end;
      span {
        color: #454d59;
        position: relative;
      }
    }
    button {
      display: inline-block;
      width: 32px;
      height: 32px;
      border: 1px solid #b0bac9;
      background: #fff;
      color: #408996;
      font-size: 14px;
    }
    span {
      font-size: 14px;
      color: #b0bac9;
      &:nth-child(2n) {
        color: #333;
      }
    }
  }
`;
export const PageSelector = styled.select`
  border: 1px solid #b0bac9;
  appearance: none;
  padding: 10px 25px 10px 18px;
  position: relative;
`;

export const ChevronDiv = styled.span`
  display: inline-block;
  margin-left: 10px;
  img {
    display: block;
    position: relative;
    top: 3px;
    cursor: pointer;
  }
`;

export const AccordionHeader = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;

  .action {
    padding: 0;
    border: none;
    background: none;
    color: blue;
    z-index: 5;
  }
`;

export const Wrapper = styled.div.attrs((props) => ({
  className: props.className,
}))`
  background: #ffffff;
  box-shadow: 0px 4px 4px rgba(199, 223, 241, 0.25);
  border-radius: 5px;
  width: ${(props) => props?.width || '100%'};
  min-width: ${(props) => props?.minWidth};
  padding: ${(props) => props?.padding};
  border: ${(props) => props?.border};
  margin: ${(props) => props?.margin};
  margin-left: ${(props) => props?.marginLeft};
  height: fit-content;
  &.appraisal-List-employee td {
    width: 30%;
  }
  .inner-div {
    padding: 5px;
    width: 60%;
  }

  @media screen and (max-width: 997px) {
    &.appraisal-List-employee {
      overflow-x: scroll;
      margin-top: 150px;
    }
  }
`;

export const ActionBarArea = styled.div`
  display: grid;
  grid-template-columns: auto 1fr;
  padding: 15px 50px;
  align-items: center;

  div.left-items {
    margin: 0 15px;
  }

  div.right-items {
    display: grid;
    grid-template-columns: auto auto;
    justify-content: flex-end;
  }
`;

export const FormGroup = styled(Form.Group)`
  display: block;
  margin-bottom: ${({ marginbottom }) => marginbottom || '20px'};
  label {
    display: block;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 17px;
    margin-bottom: 8px;
    color: #091e42;
    text-align: left;
    ${({ required }) =>
      required
        ? `
      ::after {
        content: '*';
        color: red;
        padding-left: 5px;
      }
      `
        : ''}
  }
  input {
    width: 100%;
    box-sizing: border-box;
    border: none;
    color: #5e6c84;
    transition: 0.3s;
    background: #f4f5f7;
    &:focus {
      outline: none;
      color: #344563;
    }
  }
  .input-container {
    display: flex;
    flex-direction: row;
    padding: 10px;
    box-sizing: border-box;
    border-radius: 4px;
    border: 1px solid #c1c7d0;
    color: #5e6c84;
    transition: 0.3s;
    background: #f4f5f7;
  }
  .input-container.white {
    background: #fff;
  }
  textarea {
    display: block;
    width: 100%;
    min-height: 111px;
    padding: 10px;
    box-sizing: border-box;
    border-radius: 4px;
    border: 1px solid #c1c7d0;
    color: #5e6c84;
    transition: 0.3s;
    background: #f4f5f7;
    font-size: 16px;
    &:focus {
      outline: none;
      border: 1px solid #0050c8;
      color: #344563;
    }
  }
  .error {
    font-size: 12px;
    color: #eb5757;
  }
  .eye-icon {
    cursor: pointer;
  }
  position: relative;
`;

export const defaultButton = css`
  background: #fff;
  color: #172b4d;
  font-weight: 600;
  font-size: 14px;
`;

export const primaryButton = css`
  color: ${({ company }) => (company ? '#333333' : '#fff')};
  background: ${({ company }) => (company ? '#fff' : '#1a21ba')};
`;

export const StyledButton = styled.button`
  padding: ${({ size }) => (size === 'large' ? '10px' : '5px 10px')};
  margin: ${({ margin = '0 10px' }) => margin};
  border-radius: 4px;
  border: ${({ bordered }) => (bordered ? '1px solid #D9D9D9' : 'none')};
  background: ${(props) => (props?.outlined ? '#fff !important' : '')};
  background: ${(props) => (props?.deleted ? '#FF0000 !important' : '')};
  color: ${(props) => (props?.outlined ? '#333333 !important' : '#fff')};
  width: ${({ block }) => (block ? '100%' : 'auto')};
  box-shadow: ${({ float }) =>
    float ? '0px 4px 4px rgba(199, 223, 241, 0.25)' : 'none'};
  ${({ buttonType }) => (buttonType === 'defaultButton' ? defaultButton : primaryButton)};
  ${({ buttonType }) => (buttonType === 'defaultButton' ? 'opacity: .8' : '')};
  transition: 0.3s;
  &:hover {
    ${({ buttonType }) => (buttonType === 'defaultButton' ? 'opacity: 1' : '')};
  }
`;

export const StyledTextArea = styled.textarea`
  min-height: ${({ minHeight = '100px' }) => minHeight};
  background-color: #f4f7fa !important;
  font-size: ${({ fontSize = '10px' }) => fontSize};
  font-weight: ${({ fontWeight = '400' }) => fontWeight};
  border: ${({ border }) => border};
  color: ${({ color }) => color ?? '#000'};
`;

export const Select = styled.select`
  display: block;
  width: 100%;
  padding: 10px;
  box-sizing: border-box;
  border-radius: 4px;
  border: 1px solid #c1c7d0;
  color: #5e6c84;
  transition: 0.3s;
  background: #f4f5f7;
  position: relative;
  -webkit-appearance: none;
  -moz-appearance: none;
  & + img {
    position: absolute;
    top: 46%;
    right: 14px;
    color: #344563;
  }
`;

export const ErrorLabel = styled.div`
  color: ${RED_COLOR};
  text-align: ${(props) => (props.position ? props.position : 'center')};
  position: relative;
  top: -12px;
  margin-bottom: 10px;
`;

export const Required = styled.span`
  color: #e32;
`;

export const Nav = styled.nav`
  padding: 20px 50px;
  display: flex;
  justify-content: space-between;
  background-color: #fbfbfb; ;
`;

export const PageWrapper = styled.div`
  overflow-x: hidden;
`;

export const ArrrowDown = styled.img`
  position: absolute;
  right: 10px;
  top: 15px;
`;

export const LinkNav = styled.nav`
  margin-top: 70px;
  height: 71px;
  background: #fff;
  display: flex;
  align-items: flex-end;
  justify-content: center;
  a {
    margin-right: 70px;
    color: black;
    text-decoration: none;
    &:hover {
      font-weight: 500;
      color: #1a21ba;
    }
    .indicator {
      display: none;
    }
  }
  .active {
    .indicator {
      display: block;
      height: 3px;

      background: #1a21ba;
      border-top-left-radius: 6px;
      border-top-right-radius: 6px;
    }
    font-weight: 500;
    color: #1a21ba;
  }
  .approval-link-length {
    width: 24px;
    height: 22px;
    background: #deebff;
    border-radius: 100px;
    font-size: 12px;
    padding: 4px 8px;
  }
`;

export const ActionBar = styled.div`
  display: flex;
  flex-direction: column;
  box-shadow: 0px 1px 2px rgba(199, 223, 241, 0.25);
  background: #ffffff;

  div.action-buttons {
    display: flex;
    align-self: flex-end;
    padding: 20px 50px;
  }

  ::before {
    content: '';
    height: 1px;
    width: 100%;
    background: #f4f7fa;
  }
`;

export const Table = styled.table`
    display: table;
    width:100%;
    border-collapse: collapse;
    background: #FFFFFF;
    border-radius: 6px;
    table-layout: fixed;
    .table__header{
        background: #fff;
        padding: 22px 30px;
        width: 100%;
        border-bottom: 1px solid #F2F2F2;
        th{
            padding: 20px 30px;
            font-style: normal;
            font-weight: 500;
            font-size: 16px;
            line-height: 19px;
            color: #666666;
            &:first-child {
                padding-left:90px;
                width:23%;
              }
         
            &:nth-of-type(2) {
            padding:10px 0px
            
            }
           
        }
    }
    .w-200{
        width: 20%;
    }
    .w-250{
        width: 25%;
    }
    .w-10{
        width: 10%;
    }

    .w-12{
        width: 12%;
    }
  
  
    .table-item {
        width: 100%;
        td {
          padding: 22px 30px;
          background: #ffffff;
          box-shadow: inset 0px -1px 0px rgba(183, 183, 183, 0.25);
        }
        td:nth-last-child(1) {
            padding:22px 10px;
           }
    }
}
`;

export const QuestionsWrapper = styled.div.attrs((props) => ({
  className: props.className,
}))`
  min-height: 300px;
  padding: 1rem;
  background: #ffffff;
  border: 1px solid #f0f0f0;
  box-sizing: border-box;
  border-radius: 5px;
  margin-bottom: 1rem;
  overflow-x: scroll;
  &.candidate-kpi-view-questions-wrapper {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    &.responsive {
      display: none;
    }
  }

  @media screen and (max-width: 800px) {
    &.candidate-kpi-view-questions-wrapper {
      min-height: auto;
      th,
      td {
        white-space: nowrap;
        padding-bottom: 20px;
        padding-left: 20px;
        padding-right: 20px;
      }
      &.responsive {
        display: block;
      }
      &.no-responsive {
        display: none;
      }
    }
  }
`;

export const QuestionItem = styled.div.attrs((props) => ({
  className: props.className,
}))`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;

  color: #091e42;
  p {
    margin: 0;
    display: flex;
    align-items: center;
  }

  span {
    display: flex;
    align-items: center;
    margin: 10px;
    width: 9.333px;
    color: rgba(132, 145, 167, 1);
  }

  .form-group:nth-of-type(2) {
    margin-left: 20px;
  }
  .form-group input {
    height: 60px;
    margin-left: 10px;
  }
  .t-table-wrapper {
    width: 100%;
  }
  .t-table {
    font-family: arial, sans-serif;
    border-collapse: collapse;
    width: 100%;
  }
  .t-table td,
  .t-table th {
    border: 0px solid #dddddd;
    text-align: left;
    padding: 8px;
  }
  .t-table th {
    color: #666666;
    font-size: 13px;
  }
  .th-obj {
    width: 30%;
  }
  &.candidate-kpi-view-questions-item {
    display: flex;
    align-items: center;
    justify-content: space-between;

    .objectives-item {
      width: 700px;
      color: #666666;
      display: flex;
      align-items: center;
    }
    .rating-item {
      display: flex;
      align-items: center;
    }
    .supervisor-item {
      display: flex;
      align-items: center;
    }
    .supervisor-item-empty {
      display: flex;
      align-items: center;
    }
  }

  @media screen and (max-width: 400px) {
    &.candidate-kpi-view-questions-item .objectives-item {
      width: auto;
    }
  }
`;

export const ActionContainer = styled.div`
  display: flex;
`;

export const GET_CONFIRMATION_LETTER_START = 'GET_CONFIRMATION_LETTER_START';
export const GET_CONFIRMATION_LETTER_SUCCESS = 'GET_CONFIRMATION_LETTER_SUCCESS';
export const GET_CONFIRMATION_LETTER_FAIL = 'GET_CONFIRMATION_LETTER_FAIL';

export const SEND_CONFIRMATION_LETTER_START = 'SEND_CONFIRMATION_LETTER_START';
export const SEND_CONFIRMATION_LETTER_SUCCESS = 'SEND_CONFIRMATION_LETTER_SUCCESS';
export const SEND_CONFIRMATION_LETTER_FAIL = 'SEND_CONFIRMATION_LETTER_FAIL';

export const GET_REDEPLOYMENT_LETTER_START = 'GET_REDEPLOYMENT_LETTER_START';
export const GET_REDEPLOYMENT_LETTER_SUCCESS = 'GET_REDEPLOYMENT_LETTER_SUCCESS';
export const GET_REDEPLOYMENT_LETTER_FAIL = 'GET_REDEPLOYMENT_LETTER_FAIL';

export const GET_REWARD_LETTER_START = 'GET_REWARD_LETTER_START';
export const GET_REWARD_LETTER_SUCCESS = 'GET_REWARD_LETTER_SUCCESS';
export const GET_REWARD_LETTER_FAIL = 'GET_REWARD_LETTER_FAIL';

export const REDEPLOY_EMPLOYEE_START = 'REDEPLOY_EMPLOYEE_START';
export const REDEPLOY_EMPLOYEE_SUCCESS = 'REDEPLOY_EMPLOYEE_SUCCESS';
export const REDEPLOY_EMPLOYEE_FAIL = 'REDEPLOY_EMPLOYEE_FAIL';



export const  SEND_LEAVE_REQUEST = 'SEND_LEAVE_REQUEST';
export const  SEND_LEAVE_FAILURE = 'SEND_LEAVE_FAILURE';
export const  SEND_LEAVE_SUCCESS = 'SEND_LEAVE_SUCCESS';

export const  GET_CANDIDATE_LEAVE_REQUEST = 'GET_CANDIDATE_LEAVE_REQUEST';
export const  GET_CANDIDATE_LEAVE_FAILURE = 'GET_CANDIDATE_LEAVE_FAILURE';
export const  GET_CANDIDATE_LEAVE_SUCCESS = 'GET_CANDIDATE_LEAVE_SUCCESS';

export const  RECALL_LEAVE_REQUEST = 'RECALL_LEAVE_REQUEST';
export const  RECALL_LEAVE_FAILURE = 'RECALL_LEAVE_FAILURE';
export const  RECALL_LEAVE_SUCCESS = 'RECALL_LEAVE_SUCCESS';

export const  SEND_LEAVE_EXTENSION_REQUEST = 'SEND_LEAVE_EXTENSION_REQUEST';
export const  SEND_LEAVE_EXTENSION_FAILURE = 'SEND_LEAVE_EXTENSION_FAILURE';
export const  SEND_LEAVE_EXTENSION_SUCCESS = 'SEND_LEAVE_EXTENSION_SUCCESS';

export const  SEND_LEAVE_RESUMPTION_REQUEST = 'SEND_LEAVE_RESUMPTION_REQUEST';
export const  SEND_LEAVE_RESUMPTION_FAILURE = 'SEND_LEAVE_RESUMPTION_FAILURE';
export const  SEND_LEAVE_RESUMPTION_SUCCESS = 'SEND_LEAVE_RESUMPTION_SUCCESS';


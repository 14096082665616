import {
  GET_EXIT_TYPE_REQUEST,
  GET_EXIT_TYPE_SUCCESS,
  GET_EXIT_TYPE_FAILURE,
  GET_EXIT_POLICY_REQUEST,
  GET_EXIT_POLICY_SUCCESS,
  GET_EXIT_POLICY_FAILURE,
  GET_ALL_EXIT_REQUEST,
  GET_ALL_EXIT_SUCCESS,
  GET_ALL_EXIT_FAILURE,
  EXIT_APPROVAL_REQUEST,
  EXIT_APPROVAL_SUCCESS,
  EXIT_APPROVAL_FAILURE,
  DELETE_EXIT_REQUEST,
  DELETE_EXIT_SUCCESS,
  DELETE_EXIT_FAILURE,
  DELETE_EXIT_TYPE_REQUEST,
  DELETE_EXIT_TYPE_SUCCESS,
  DELETE_EXIT_TYPE_FAILURE,
  ADD_EXIT_TYPE_REQUEST,
  ADD_EXIT_TYPE_SUCCESS,
  ADD_EXIT_TYPE_FAILURE,
  UPDATE_EXIT_TYPE_REQUEST,
  UPDATE_EXIT_TYPE_SUCCESS,
  UPDATE_EXIT_TYPE_FAILURE,
  ADD_EXIT_POLICY_REQUEST,
  ADD_EXIT_POLICY_SUCCESS,
  ADD_EXIT_POLICY_FAILURE,
  DELETE_EXIT_POLICY_REQUEST,
  DELETE_EXIT_POLICY_SUCCESS,
  DELETE_EXIT_POLICY_FAILURE,
  UPDATE_EXIT_POLICY_REQUEST,
  UPDATE_EXIT_POLICY_SUCCESS,
  UPDATE_EXIT_POLICY_FAILURE,
  ADD_EXIT_REQUEST,
  ADD_EXIT_SUCCESS,
  ADD_EXIT_FAILURE,
  GET_SINGLE_EXIT_REQUEST,
  GET_SINGLE_EXIT_SUCCESS,
  GET_SINGLE_EXIT_FAILURE,
  GET_EXIT_TOTAL_REQUEST,
  GET_EXIT_TOTAL_SUCCESS,
  GET_EXIT_TOTAL_FAILURE,
  GET_ALL_EMPLOYEE_EXIST_REQUEST,
  GET_ALL_EMPLOYEE_EXIST_SUCCESS,
  GET_ALL_EMPLOYEE_EXIST_FAILURE,
} from 'redux/actionTypes/exitManagement';

const initialState = {
  loading: false,
  exitTypes: [],
  exitPolicy: [],
  exitRequests: [],
  approveRequest: false,
  deleteRequest: false,
  deleteExitType: false,
  addExitType: false,
  updateExitType: false,
  addExitPolicy: false,
  deleteExitPolicy: false,
  updateExitPolicy: false,
  addExitRequest: false,
  singleExitRequest: [],
  singleExitSuccess: false,
  requestsTotal: 0,
  historyTotal: 0,
  employeeExistList: []
};

/**
 *exit Reducer
 *
 * @export
 */
export default function (state = initialState, { type, payload }) {
  switch (type) {
    case GET_EXIT_TYPE_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case GET_EXIT_TYPE_SUCCESS:
      return {
        ...state,
        loading: false,
        exitTypes: payload?.requests.results,
      };
    case GET_EXIT_TYPE_FAILURE:
      return {
        ...state,
        loading: false,
      };
    case GET_EXIT_POLICY_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case GET_EXIT_POLICY_SUCCESS:
      return {
        ...state,
        loading: false,
        exitPolicy: payload?.requests.results,
      };
    case GET_EXIT_POLICY_FAILURE:
      return {
        ...state,
        loading: false,
      };
    case GET_ALL_EXIT_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case GET_ALL_EXIT_SUCCESS:
      return {
        ...state,
        loading: false,
        exitRequests: payload?.requests,
      };
    case GET_ALL_EXIT_FAILURE:
      return {
        ...state,
        loading: false,
      };
    case EXIT_APPROVAL_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case EXIT_APPROVAL_SUCCESS:
      return {
        ...state,
        loading: false,
        approveRequest: true,
      };
    case EXIT_APPROVAL_FAILURE:
      return {
        ...state,
        loading: false,
        approveRequest: false,
      };
    case DELETE_EXIT_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case DELETE_EXIT_SUCCESS:
      return {
        ...state,
        loading: false,
        deleteRequest: true,
      };
    case DELETE_EXIT_FAILURE:
      return {
        ...state,
        loading: false,
        deleteRequest: false,
      };
    case DELETE_EXIT_TYPE_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case DELETE_EXIT_TYPE_SUCCESS:
      return {
        ...state,
        loading: false,
        deleteExitType: true,
      };
    case DELETE_EXIT_TYPE_FAILURE:
      return {
        ...state,
        loading: false,
        deleteExitType: false,
      };
    case ADD_EXIT_TYPE_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case ADD_EXIT_TYPE_SUCCESS:
      return {
        ...state,
        loading: false,
        addExitType: true,
      };
    case ADD_EXIT_TYPE_FAILURE:
      return {
        ...state,
        loading: false,
        addExitType: false,
      };
    case UPDATE_EXIT_TYPE_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case UPDATE_EXIT_TYPE_SUCCESS:
      return {
        ...state,
        loading: false,
        updateExitType: true,
      };
    case UPDATE_EXIT_TYPE_FAILURE:
      return {
        ...state,
        loading: false,
        updateExitType: false,
      };
    case ADD_EXIT_POLICY_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case ADD_EXIT_POLICY_SUCCESS:
      return {
        ...state,
        loading: false,
        addExitPolicy: true,
      };
    case ADD_EXIT_POLICY_FAILURE:
      return {
        ...state,
        loading: false,
        addExitPolicy: false,
      };
    case DELETE_EXIT_POLICY_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case DELETE_EXIT_POLICY_SUCCESS:
      return {
        ...state,
        loading: false,
        deleteExitPolicy: true,
      };
    case DELETE_EXIT_POLICY_FAILURE:
      return {
        ...state,
        loading: false,
        deleteExitPolicy: false,
      };
    case UPDATE_EXIT_POLICY_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case UPDATE_EXIT_POLICY_SUCCESS:
      return {
        ...state,
        loading: false,
        updateExitPolicy: true,
      };
    case UPDATE_EXIT_POLICY_FAILURE:
      return {
        ...state,
        loading: false,
        updateExitPolicy: false,
      };
    case ADD_EXIT_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case ADD_EXIT_SUCCESS:
      return {
        ...state,
        loading: false,
        addExitRequest: true,
      };
    case ADD_EXIT_FAILURE:
      return {
        ...state,
        loading: false,
        addExitRequest: false,
      };
    case GET_SINGLE_EXIT_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case GET_SINGLE_EXIT_SUCCESS:
      return {
        ...state,
        loading: false,
        singleExitRequest: payload?.requests,
        singleExitSuccess: true,
      };
    case GET_SINGLE_EXIT_FAILURE:
      return {
        ...state,
        loading: false,
        singleExitSuccess: false,
      };
    case GET_EXIT_TOTAL_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case GET_EXIT_TOTAL_SUCCESS:
      return {
        ...state,
        loading: false,
        requestsTotal: payload?.requestsTotal,
        historyTotal: payload?.historyTotal,
      };
    case GET_EXIT_TOTAL_FAILURE:
      return {
        ...state,
        loading: false,
      };
    case 'EXIT_MGT_INIT_STATE':
      return {
        ...state,
        loading: false,
        approveRequest: false,
        deleteRequest: false,
        deleteExitType: false,
        addExitType: false,
        updateExitType: false,
        addExitPolicy: false,
        deleteExitPolicy: false,
        updateExitPolicy: false,
        addExitRequest: false,
      };
    case  GET_ALL_EMPLOYEE_EXIST_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case GET_ALL_EMPLOYEE_EXIST_SUCCESS:
      return {
        ...state,
        loading: false,
        employeeExistList: payload
      };
    case GET_ALL_EMPLOYEE_EXIST_FAILURE:
      return {
        ...state,
        loading: false,
      };
    default:
      return state;
  }
}

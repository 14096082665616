import styled from 'styled-components';
import { svgs } from '../../../assets';

export const PageWrapper = styled.div`
  background: #ffffff;
  box-shadow: 0px 1px 2px rgba(199, 223, 241, 0.25);
  position: ${(props) => (props.position ? props.position : 'fixed')};
  top: 0;
  left: 250px;
  right: 0;
  height: 70px;
  z-index: 14;
  @media screen and (max-width: 1024px) {
    left: 0px;
  }
`;

export const MenuWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  padding: 20px 50px;
  background: #ffffff;
  box-shadow: 0px 1px 2px rgba(199, 223, 241, 0.25);

  .user-menu {
    display: grid;
    grid-template-columns: auto 1fr auto;
    column-gap: 12.92px;
    align-items: center;
  }
`;

export const PageName = styled.p`
  font-style: normal;
  font-weight: 500;
  font-size: 21px;
  line-height: 30px;
  color: #333333;
`;

export const PageNameWrapper = styled.div`
  display: grid;
  grid-template-columns: auto 1fr;
  column-gap: 10px;
  align-items: center;
`;

export const Back = styled(svgs.BACK)`
  cursor: pointer;
`;

export const Logout = styled.p`
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 30px;
  color: #333333;
  cursor: pointer;
`;

export const NotifWrapper = styled.div`
  span{
    display: inline-block;
    width: 18px;
    height: 18px;
    background-color: #CD310A;
    color: white;
    font-size: 10px;
    border-radius: 100%;
    padding: 1px;
    font-weight: 500;
    position: relative;
    top: -10px;
    left: 28px;
    border: 1px solid #FFFFFF;
    text-align: center;
  }
  .notification-dropdown {
    display: block;
    width: auto;
    max-height: 500px;
    font-size: 12px;
    position: absolute;
    top: 50px;
    overflow: scroll;
    z-index: 1;
    outline: none;
    background: #fff;
    box-shadow: 0px 4px 4px rgba(199, 223, 241, 0.25);
    border-radius: 5px;
    padding: 10px 15px; 
    text-align: left;
    
    li{
      padding: 10px 20px;
      list-style:none;
      border-bottom: 1px solid #f2f2f2
    }
    .notification-list{
      display: flex;
      align-items:center;
      justify-content: space-between;
    }
    .mark-read{
      position:relative;
      z-index: 50;
    }
    .mark-read p{
      width: 100px;
      color: #333333;
      box-shadow: 0px 4px 4px rgba(199, 223, 241, 0.25);
      border-radius: 5px;
      position: absolute;
      background: #f8f8f8;      
      top: -18px;
      left: 200px;
      padding: 10px;
      font-size: 12px;
      cursor: pointer;
    }
  }

  .no-dropdown{
    display: none;
  }
`;

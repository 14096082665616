import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import {
  Container,
  Logo,
  LogoRed,
  SidebarContentResponsive,
  MenuResponsive,
  TopMenuResponsive,
  MainContentResponsive,
} from './candidateStyles';
import { SidebarContent, Menu, TopMenu, MainContent } from './styles';
import { svgs } from '../../assets';
import CustomLink from '../common/CustomLink/CandidateCustomLink';
import { useSelector, useDispatch } from 'react-redux';
import { useLocation } from 'react-router-dom';
import {mobileViewerState } from 'redux/actions/user';

/**
 * Sidebar component
 *
 * @param {Object} props Component props
 * @param {JSX.Element} children children node props
 * @returns {JSX.Element} component
 */
const Sidebar = ({ children, ...props }) => {
  const { pathname } = useLocation();
  const initialState = {
    home: false,
    onboarding: pathname.startsWith('/onboarding/') ?? false,
    employees: pathname.startsWith('/employees/') ?? false,
    companies: false,
    tasks: false,
    approval: false,
    leave: pathname.startsWith('/leave-management/') ?? false,
    settings: pathname.startsWith('/settings/') ?? false,
    selfService: pathname.startsWith('/selfservice/') ?? false,
    exitManagement: pathname.startsWith('/exit/') ?? false,
  };
  const [menuDisplay, setMenuDisplay] = useState(initialState);
  const { mobileMenu } = useSelector((state) => state.user);
  const [ toggleCaret, setToggleCaret] = useState(false);

  const dispatch = useDispatch();

  const updateMenuDisplay = (e) => {
    e.persist();
    setMenuDisplay((prev) => ({
      ...prev,
      ...initialState,
    }));
    setMenuDisplay((prev) => ({
      ...prev,
      [e.target.id]: !menuDisplay[e.target.id],
    }));
    setToggleCaret(!toggleCaret)
  };

  let query = '(max-width: 997px)';
  const [matches, setMatches] = useState('');

  // Activity normally for componentDidMount + componentDidUpdate
  useEffect(() => {
    const media = window.matchMedia(query);
    if (media.matches !== matches) {
      setMatches(media.matches);
    }

    const listener = () => setMatches(media.matches);
    media.addListener(listener);

    return () => media.removeListener(listener);
  }, [matches, query]);

  const togglerMobileViewer = () => {
    dispatch(mobileViewerState(false));
  };



  const renderCandidate = () => (
    <>
      <CustomLink label="Home" leftIcon={svgs.HOME} path="/home" navClick={()=> {togglerMobileViewer()}}/>
      <CustomLink label="My Profile" leftIcon={svgs.USER} path="/candidate/profile/summary"    navClick={()=> {togglerMobileViewer()}} />
      {/* <CustomLink label="Onboarding" leftIcon={svgs.ONBOARDING_ICON} path="/onboarding" /> */}
      <CustomLink
        label="My Welfare"
        leftIcon={svgs.WELFARE_ICON}
        path="/candidate/welfare"
        navClick={()=> {togglerMobileViewer()}}
      />
      {/* <CustomLink
        label="Settings"
        leftIcon={svgs.SETTINGS}
        path="/settings"
        subMenus={[
          { path: '/manage-admins', label: 'Manage admins' },
          { path: '/roles', label: 'Roles' },
        ]}
        more
        updateMenuDisplay={updateMenuDisplay}
        showMenu={menuDisplay.settings}
        id="settings"
      /> */}
      <CustomLink
        label="Self-Service"
        leftIcon={svgs.SETTINGS}
        path="/selfservice"
        subMenus={[
          { path: '/disciplinary', label: 'Disciplinary' },
          { path: '/leave', label: 'Leave' },
          { path: '/exit', label: 'Exit' },
          { path: '/complaints', label: 'Complaints' },
          { path: '/appraisals-list', label: 'Appraisals' },
          { path: '/headcount', label: 'Headcount' },
          { path: '/hmo-surveys', label: 'Hmo Surveys' },
          // { path: '/activities', label: 'Activities' },


        ]}
        more={true}
        updateMenuDisplay={updateMenuDisplay}
        showMenu={menuDisplay.selfService}
        id="selfService"
        toggleCaret={toggleCaret}
        navClick={()=> {togglerMobileViewer()}}
      />
    </>
  );

  return (
    <>
    <Container>
      {!matches && (
        <>
          <SidebarContent {...props}>
            <TopMenu>
              {props.candidate ? <LogoRed /> : <Logo />}
              <Menu {...props}>{renderCandidate()}</Menu>
            </TopMenu>
          </SidebarContent>
          <MainContent>{children}</MainContent>
        </>
      )}

    </Container>


    {matches && (
    <Container>
  
        <>
          <MainContentResponsive>{children}</MainContentResponsive>
          {mobileMenu  &&
          <SidebarContentResponsive {...props}>
            <div className="responsive-sidebar-layout">
               <img
                src={svgs.MOBILE_VIEWER}
                alt="mobile"
                onClick={() => {
                  togglerMobileViewer();
                }}
                style={{cursor:'pointer', float:'right',margin: '10px 20px 0 0px'}}

              />
              </div>
            <MenuResponsive {...props}>
              <div className="" style={{marginTop:"30px", paddingLeft:"0px"}}>
           
            {props.candidate ? <LogoRed /> : <Logo />}
            </div>
              {renderCandidate()}
              </MenuResponsive>
          </SidebarContentResponsive>}
          <TopMenuResponsive></TopMenuResponsive>
        </>
   
    </Container>
       )}
    </>
  );
};

Sidebar.propTypes = {
  children: PropTypes.element.isRequired,
};

export default Sidebar;


import * as types from '../actionTypes/onboarding';

const initialState = {
  loading: false,
  employees: { },
  employee: { },
  settings: null,
};

const onboardingReducer = (state = initialState, action) => {
  switch (action.type) {
      case types.INITIATE_ONBOARDING_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case types.INITIATE_ONBOARDING_SUCCESS:
      return {
        ...state,
        error: false,
        loading: false,
        success: true,
      };
    case types.INITIATE_ONBOARDING_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.error,
        success: false,
      };
    case types.GET_EMPLOYEE_ONBOARDING_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case types.GET_EMPLOYEE_ONBOARDING_RESPONSE:
      return {
        ...state,
        loading: false,
        employees: action?.payload?.response,
      };
    case types.GET_EMPLOYEE_ONBOARDING_ERROR:
      return {
        ...state,
        loading: false,
        error: true,
      };
    case types.GET_ONBOARDING_SETTINGS_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case types.GET_ONBOARDING_SETTINGS_SUCCESS:
      return {
        ...state,
        loading: false,
        settings: action?.payload?.response,
      };
    case types.GET_ONBOARDING_SETTINGS_FAILURE:
      return {
        ...state,
        loading: false,
        settings: [],
      };
    default:
      return {
        ...state,
      };
  }
};

export default onboardingReducer;

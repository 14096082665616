import * as actions from '../actionTypes/welfare';

const initialState = {
  loading: false,
  data: [],
  employee: [],
  response: [],
  events: [],
  settings: false,
  letter: [],
  welfare: [],
  welfareHistory: [],
  welfareLoading: false,
  welfareListType: [],
  dashboard: []
};
const getWelfareStart = (state) => ({
  ...state,
  loading: false,
  error: null,
});

const getWelfareSuccess = (state, action) => ({
  ...state,
  error: null,
  success: false,
  loading: false,
  employee: action.payload,
});

const getWelfareFail = (state, action) => ({
  ...state,
  error: action.error,
  success: false,
  loading: false,
});

const getWelfareEventsSuccess = (state, action) => ({
  ...state,
  error: null,
  success: false,
  loading: false,
  events: action.payload,
});

const sendWelfareMsgStart = (state) => ({
  ...state,
  loading: true,
  error: null,
});

const sendWelfareMsgSuccess = (state) => ({
  ...state,
  error: null,
  success: true,
  loading: false,
});
const sendWelfareMsgFail = (state, action) => ({
  ...state,
  error: action.error,
  success: false,
  loading: false,
});

const addWelfareLetterStart = (state) => ({
  ...state,
  loading: true,
  error: null,
});

const addWelfareLetterSuccess = (state) => ({
  ...state,
  error: null,
  success: true,
  loading: false,
});
const addWelfareLetterFail = (state, action) => ({
  ...state,
  error: action.error,
  success: false,
  loading: false,
});

const editWelfareLetterStart = (state) => ({
  ...state,
  loading: true,
  error: null,
});

const editWelfareLetterSuccess = (state, action) => ({
  ...state,
  error: null,
  success: true,
  loading: false,
});
const editWelfareLetterFail = (state, action) => ({
  ...state,
  error: action.error,
  success: false,
  loading: false,
});

const getLetterStart = (state) => ({
  ...state,
  loading: true,
  error: null,
});

const getLetterSuccess = (state, action) => ({
  ...state,
  error: null,
  success: false,
  loading: false,
  letter: action.payload,
});
const getLetterFail = (state, action) => ({
  ...state,
  error: action.error,
  success: false,
  loading: false,
});

const deleteWelfareLetterStart = (state) => ({
  ...state,
  loading: true,
  error: null,
});

const deleteWelfareLetterSuccess = (state, action) => ({
  ...state,
  error: null,
  success: true,
  loading: false,
});
const deleteWelfareLetterFail = (state, action) => ({
  ...state,
  error: action.error,
  success: false,
  loading: false,
});

const getWelfareTypeStart = (state) => ({
  ...state,
  loading: true,
  error: null,
});

const getWelfareTypeSuccess = (state, action) => ({
  ...state,
  error: null,
  success: false,
  loading: false,
  welfare: action.payload,
});

const getWelfareTypeFail = (state, action) => ({
  ...state,
  error: action.error,
  success: false,
  loading: false,
});

const getEmployeeWelfareHistoryStart = (state) => ({
  ...state,
  welfareLoading: true,
  error: null,
});

const getEmployeeWelfareHistorySuccess = (state, action) => ({
  ...state,
  error: null,
  success: false,
  welfareLoading: false,
  welfareHistory: action.payload,
});

const deleteEmployeeWelfareFail = (state, action) => ({
  ...state,
  error: action.error,
  success: false,
  welfareLoading: false,
});

const deleteEmployeeWelfareStart = (state) => ({
  ...state,
  loading: true,
  error: null,
});

const deleteEmployeeWelfareSuccess = (state, action) => ({
  ...state,
  error: null,
  success: false,
  loading: false,
  welfareHistory: action.payload,
});

const getEmployeeWelfareHistoryFail = (state, action) => ({
  ...state,
  error: action.error,
  success: false,
  loading: false,
});

const welfareReducer = (state = initialState, action) => {
  switch (action.type) {
    case actions.GET_WELFARE_START:
      return getWelfareStart(state);
    case actions.GET_WELFARE_SUCCESS:
      return getWelfareSuccess(state, action);
    case actions.GET_WELFARE_FAIL:
      return getWelfareFail(state, action);
    case actions.GET_WELFARE_EVENTS_SUCCESS:
      return getWelfareEventsSuccess(state, action);
    case actions.SEND_WELFARE_MSG_START:
      return sendWelfareMsgStart(state);
    case actions.SEND_WELFARE_MSG_SUCCESS:
      return sendWelfareMsgSuccess(state);
    case actions.SEND_WELFARE_MSG_FAIL:
      return sendWelfareMsgFail(state, action);
    case actions.ADD_WELFARE_LETTER_REQUEST:
      return addWelfareLetterStart(state);
    case actions.ADD_WELFARE_LETTER_SUCCESS:
      return addWelfareLetterSuccess(state);
    case actions.ADD_WELFARE_LETTER_FAIL:
      return addWelfareLetterFail(state, action);
    case actions.EDIT_WELFARE_LETTER_REQUEST:
      return editWelfareLetterStart(state);
    case actions.EDIT_WELFARE_LETTER_SUCCESS:
      return editWelfareLetterSuccess(state, action);
    case actions.EDIT_WELFARE_LETTER_FAIL:
      return editWelfareLetterFail(state, action);
    case actions.GET_LETTER_START:
      return getLetterStart(state);
    case actions.GET_LETTER_SUCCESS:
      return getLetterSuccess(state, action);
    case actions.GET_LETTER_FAIL:
      return getLetterFail(state, action);
    case actions.DELETE_WELFARE_LETTER_START:
      return deleteWelfareLetterStart(state);
    case actions.DELETE_WELFARE_LETTER_SUCCESS:
      return deleteWelfareLetterSuccess(state, action);
    case actions.DELETE_WELFARE_LETTER_FAIL:
      return deleteWelfareLetterFail(state, action);
    case actions.GET_WELFARE_TYPE_START:
      return getWelfareTypeStart(state);
    case actions.GET_WELFARE_TYPE_SUCCESS:
      return getWelfareTypeSuccess(state, action);
    case actions.GET_WELFARE_TYPE_FAIL:
      return getWelfareTypeFail(state, action);
    case actions.GET_EMPLOYEE_WELFARE_HISTORY_START:
      return getEmployeeWelfareHistoryStart(state);
    case actions.GET_EMPLOYEE_WELFARE_HISTORY_SUCCESS:
      return getEmployeeWelfareHistorySuccess(state, action);
    case actions.GET_EMPLOYEE_WELFARE_HISTORY_FAIL:
      return getEmployeeWelfareHistoryFail(state, action);
    case actions.DELETE_EMPLOYEE_WELFARE_START:
      return deleteEmployeeWelfareStart(state);
    case actions.DELETE_EMPLOYEE_WELFARE_SUCCESS:
      return deleteEmployeeWelfareSuccess(state, action);
    case actions.DELETE_EMPLOYEE_WELFARE_FAIL:
      return deleteEmployeeWelfareFail(state, action);
    case actions.SEND_EMPLOYEE_WELFARE_MSG_START:
      return {
        ...state,
        loading: true,
        error: null,
      };
    case actions.SEND_EMPLOYEE_WELFARE_MSG_SUCCESS:
      return {
        ...state,
        error: null,
        success: false,
        loading: false,
      }; 
    case actions.SEND_EMPLOYEE_WELFARE_MSG_FAIL:
      return {
        ...state,
        error: action.error,
        success: false,
        loading: false,
      };     
      case actions.GET_WELFARE_TYPES_LIST_SUCCESS:
        return {
          ...state,
          error: null,
          success: false,
          loading: false,
          welfareListType: action.payload
        };

      case actions.WELFARE_DASHBOARD_DATA_SUCCESS:
          return {
            ...state,
            error: null,
            success: false,
            loading: false,
            dashboard: action.payload
          };  
    default:
      return {
        ...state,
      };
  }
};
export default welfareReducer;

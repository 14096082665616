import styled, { css } from 'styled-components';
// import { svgs } from 'assets';

export const ProfileImageSection = styled.div`
  display: grid;
  grid-template-columns: auto 1fr auto;
  grid-column-gap: 30px;
  padding: 30px;
  border-radius: 4px;
  background: #ffffff;
  border: 1px solid #f0f0f0;

  .close-img{
    position:absolute;
  height: 16px
  }
`;

export const OuterContainer = css`
  display: grid;
  margin:130px auto 30px;
  justify-content: center;
  grid-template-columns: minmax(400px, 540px);
  grid-template-rows: repeat(auto, 2);
  grid-row-gap: 30px;
  ${'' /* padding: 20px; */}
`;

export const PersonalInformation = styled.div`
  ${OuterContainer}
`;

export const Image = styled.div`
  border-radius: 100%;
  overflow: hidden;
`;

export const ProfileText = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding-top: 10px;
  padding-bottom: 10px;

  span:first-child {
    font-weight: 500;
    font-size: 16px;
  }

  span:not(:first-child) {
    font-size: 14px;
    color: #999;
  }
`;

export const FileInput = styled.div`
  display: grid;
  align-items: center;
  position:relative;
  input {
    position:absolute;
  top:10px;
opacity: 0
  }
  
`;

export const FileSelector = styled.div`
  padding: 15px;
  color: #fff;
  background: #1a21ba;
  border-radius: 4px;
  width: auto;
  font-size: 16px;
  font-weight: 500;
  cursor: pointer;
`;

export const FlexItem = styled.div`
  display: flex;
  div {
    margin-right: 20px;
    flex: 1;
  }

  div:last-child {
    margin-right: auto;
  }
`;

export const FlexItemRight = styled.div`
  display: flex;
  flex: 1 1 auto;
  justify-content: flex-end;
`;


export const HomeWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
`;
export const Text = styled.p``;


   /*****card*******************************

***************************************************/

export const CardWrapper = styled.div.attrs((props) => ({
  className: props.className,
}))`
 
  background: #FFFFFF;
  box-shadow: 0px 4px 4px rgba(199, 223, 241, 0.25);
  border-radius: 5px;
  width:350px;
  height:133px;
  padding: 20px 15px;
  .card-flex{
      display:flex;
      justify-content:space-between
  }

  .title p{
      font-family: Inter;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 16px;
    color: #333333;
    text-transform: capitalize
    }
    
    .title h2{
      padding-top: 20px;
      font-family: Inter;
    font-style: normal;
    font-weight: 600;
    font-size: 36px;
    line-height: 44px;
    text-transform: capitalize;
    }
    .title-right-side svg{
      position:relative;
      top:-5px
    }
    &.documents{
    padding:20px 0px 10px 10px;
     width:auto;
     height:auto;
    }
    &.documents.candidate{
      padding:20px 0px 10px 10px;
       width:auto;
       height:auto;
       .dropdown-menu.show {
        width: -webkit-fit-content;
        width: -moz-fit-content;
        width: fit-content;
        padding-left: 10px;
        font-size: 15px;
        -webkit-transform: translate(-0px,25px) !important;
        -ms-transform: translate(-0px,25px) !important;
        transform: translate(-0px,25px) !important;
        height: auto !important;
        overflow: scroll;
    }
      }
      @media screen and (max-width: 997px){
        &.documents.candidate{
           width:100%;
          }
      }
        
`;



 /*****card*******************************

***************************************************/
import {
  ADD_TO_DRAFT_REQUEST,
  ADD_TO_DRAFT_SUCCESS,
  ADD_TO_DRAFT_FAILURE,
  PUBLISH_REQUEST,
  PUBLISH_SUCCESS,
  PUBLISH_FAILURE,
  GET_ALL_REQUEST,
  GET_ALL_SUCCESS,
  GET_ALL_FAILURE,
  SEARCH_AND_FILTER_REQUEST,
  SEARCH_AND_FILTER_SUCCESS,
  SEARCH_AND_FILTER_FAILURE,
  DELETE_REQUEST,
  DELETE_SUCCESS,
  DELETE_FAILURE,
  GET_SINGLE_REQUEST,
  GET_SINGLE_SUCCESS,
  GET_SINGLE_FAILURE,
  UPDATE_DRAFT_REQUEST,
  UPDATE_DRAFT_SUCCESS,
  UPDATE_DRAFT_FAILURE,
} from './../actionTypes/announcements';

const initialState = {
  loading: false,
  addToDraftSuccess: false,
  publishSuccess: false,
  deleteSuccess: false,
  getAllSuccess: false,
  getSingleSuccess: false,
  updateDraftSuccess: false,
  errorMessage: '',
  error: false,
  allAnnouncements: [],
  searchAndFilterSuccess: false,
  searchAndFilterResult: [],
  singleAnnouncement: {}
};

const announcements = (state = initialState, action) => {
  switch (action.type) {
    case ADD_TO_DRAFT_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case ADD_TO_DRAFT_SUCCESS:
      return {
        ...state,
        loading: false,
        addToDraftSuccess: true,
      };
    case ADD_TO_DRAFT_FAILURE:
      return {
        ...state,
        loading: false,
        addToDraftSuccess: false,
        error: true,
      };
    case PUBLISH_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case PUBLISH_SUCCESS:
      return {
        ...state,
        loading: false,
        publishSuccess: true,
      };
    case PUBLISH_FAILURE:
      return {
        ...state,
        loading: false,
        publishSuccess: false,
        error: true,
      };
    case GET_ALL_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case GET_ALL_SUCCESS:
      return {
        ...state,
        loading: false,
        getAllSuccess: true,
        allAnnouncements: action.payload,
      };
    case GET_ALL_FAILURE:
      return {
        ...state,
        loading: false,
        getAllSuccess: false,
        error: true,
      };
    case SEARCH_AND_FILTER_REQUEST:
      return {
        ...state,
        loading: true,
        searchAndFilterSuccess: false,
      };
    case SEARCH_AND_FILTER_SUCCESS:
      return {
        ...state,
        loading: false,
        searchAndFilterSuccess: true,
        searchAndFilterResult: action.payload,
      };
    case SEARCH_AND_FILTER_FAILURE:
      return {
        ...state,
        loading: false,
        searchAndFilterSuccess: false,
        error: true,
      };
    case DELETE_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case DELETE_SUCCESS:
      return {
        ...state,
        loading: false,
        deleteSuccess: true,
      };
    case DELETE_FAILURE:
      return {
        ...state,
        loading: false,
        deleteSuccess: false,
        error: true,
      };
    case GET_SINGLE_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case GET_SINGLE_SUCCESS:
      return {
        ...state,
        loading: false,
        getSingleSuccess: true,
        singleAnnouncement: action.payload,
      };
    case GET_SINGLE_FAILURE:
      return {
        ...state,
        loading: false,
        getSingleSuccess: false,
        error: true,
      };
    case UPDATE_DRAFT_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case UPDATE_DRAFT_SUCCESS:
      return {
        ...state,
        loading: false,
        updateDraftSuccess: true,
      };
    case UPDATE_DRAFT_FAILURE:
      return {
        ...state,
        loading: false,
        updateDraftSuccess: false,
        error: true,
      };
    case 'ANNOUNCEMENTS_INIT_STATE':
      return {
        ...state,
        publishSuccess: false,
        addToDraftSuccess: false,
        getAllSuccess: false,
        errorMessage: '',
        error: false,
        searchAndFilterSuccess: false,
        deleteSuccess: false,
        getSingleSuccess: false,
        updateDraftSuccess: false,
      };
    default:
      return {
        ...state,
      };
  }
};

export default announcements;

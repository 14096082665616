import styled from 'styled-components';
export const CheckboxContainer = styled.div`
display: flex;
cursor:pointer;
  input {
    display: none;
  }

  .check-label {
    margin-left: 10px;
  }

  .bold-label {
    font-weight: 500;
  }
`;

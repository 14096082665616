import * as types from '../actionTypes/location';

const initialState = {
  loading: false,
  data: [],
};

const getLocationReducer = (state = initialState, action) => {
  switch (action.type) {
    case types.ADD_LOCATION_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case types.ADD_LOCATION_SUCCESS:
      return {
        ...state,
        error: false,
        loading: false,
        data: [...state?.data, action.payload?.data?.data],
        success: true,
      };
    case types.ADD_LOCATION_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.error,
        success: false,
      };

    case types.GET_LOCATIONS_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case types.GET_LOCATIONS_SUCCESS:
      return {
        ...state,
        error: false,
        loading: false,
        data: action.payload?.data,
        success: true,
      };
    case types.GET_LOCATIONS_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.error,
        success: false,
      };

    case types.EDIT_LOCATION_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case types.EDIT_LOCATION_SUCCESS:
      // eslint-disable-next-line no-case-declarations
      const result = state?.data?.filter((i) => i?.id !== action.payload?.data?.data?.id);
      return {
        ...state,
        error: false,
        loading: false,
        data: [...result, action.payload?.data?.data],
        success: true,
      };
    case types.EDIT_LOCATION_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.error,
        success: false,
      };

    case types.DELETE_LOCATION_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case types.DELETE_LOCATION_SUCCESS:
      return {
        ...state,
        error: false,
        loading: false,
        data: state?.data?.filter((i) => i?.id !== action.payload),
        success: true,
      };
    case types.DELETE_LOCATION_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.error,
        success: false,
      };
    default:
      return {
        ...state,
      };
  }
};

export default getLocationReducer;


import * as actions from '../actionTypes/employeeDocuments';


const initialState = {
  loading: false,
  data: [],
  documents: [],
  requests: [],
  error: null,
  success: false,
  documenttype: [],
  documentsuccess: false
};


const getEmployeeDocumentStart = (state) => ({
  ...state,
  loading: false,
  error: null,
});

const getEmployeeDocumentSuccess = (state, action) => ({
  ...state,
  error: null,
  success: false,
  loading: false,
  documents: action.payload,
});
const getEmployeeDocumentFail = (state, action) => ({
  ...state,
  error: action.error,
  success: false,
  employee: null,
  loading: false,
});


const uploadEmployeeDocumentStart = (state) => ({
  ...state,
  loading: true,
  error: null,
  documentsuccess: false,
});

const uploadEmployeeDocumentSuccess = (state, action) => ({
  ...state,
  error: null,
  documentsuccess:true,
  loading: false,
  documents: action.payload,
});
const uploadEmployeeDocumentFail = (state, action) => ({
  ...state,
  error: action.error,
  documentsuccess: false,
  employee: null,
  loading: false,
});


const verifyEmployeeDocumentStart = (state) => ({
  ...state,
  loading: true,
  error: null,
});

const verifyEmployeeDocumentSuccess = (state, action) => ({
  ...state,
  error: null,
  success: false,
  loading: false,
  documents: action.payload,
});
const verifyEmployeeDocumentFail = (state, action) => ({
  ...state,
  error: action.error,
  success: false,
  employee: null,
  loading: false,
});


const DeleteEmployeeDocumentStart = (state) => ({
  ...state,
  loading: true,
  error: null,
});

const DeleteEmployeeDocumentSuccess = (state, action) => ({
  ...state,
  error: null,
  success: false,
  loading: false,
  documents: action.payload,
});
const DeleteEmployeeDocumentFail = (state, action) => ({
  ...state,
  error: action.error,
  success: false,
  employee: null,
  loading: false,
});

const changeRequestStart = (state) => ({
  ...state,
  loading: true,
  error: null,
});

const changeRequestSuccess = (state, action) => ({
  ...state,
  error: null,
  success: true,
  loading: false,
  requests: action.payload,
});
const changeRequestFail = (state, action) => ({
  ...state,
  error: action.error,
  success: false,
  loading: false,
});

const getChangeRequestStart = (state) => ({
  ...state,
  loading: true,
  error: null,
});

const getChangeRequestSuccess = (state, action) => ({
  ...state,
  error: null,
  success: false,
  loading: false,
  requests: action.payload,
});
const getChangeRequestFail = (state, action) => ({
  ...state,
  error: action.error,
  success: false,
  loading: false,
});



const employeeDocumentsReducer = (state = initialState, action) => {
  switch (action.type) {
    case actions.GET_EMPLOYEE_DOCUMENT_START:
      return getEmployeeDocumentStart(state);
    case actions.GET_EMPLOYEE_DOCUMENT_SUCCESS:
      return getEmployeeDocumentSuccess(state, action);
    case actions.GET_EMPLOYEE_DOCUMENT_FAIL:
      return getEmployeeDocumentFail(state, action);
    case actions.UPLOAD_EMPLOYEE_DOCUMENT_START:
      return uploadEmployeeDocumentStart(state);
    case actions.UPLOAD_EMPLOYEE_DOCUMENT_SUCCESS:
      return uploadEmployeeDocumentSuccess(state, action);
    case actions.UPLOAD_EMPLOYEE_DOCUMENT_FAIL:
      return uploadEmployeeDocumentFail(state, action);
    case actions.VERIFY_EMPLOYEE_DOCUMENT_START:
      return verifyEmployeeDocumentStart(state);
    case actions.VERIFY_EMPLOYEE_DOCUMENT_SUCCESS:
      return verifyEmployeeDocumentSuccess(state, action);
    case actions.VERIFY_EMPLOYEE_DOCUMENT_FAIL:
      return verifyEmployeeDocumentFail(state, action);
    case actions.DELETE_EMPLOYEE_DOCUMENT_START:
      return DeleteEmployeeDocumentStart(state);
    case actions.DELETE_EMPLOYEE_DOCUMENT_SUCCESS:
      return DeleteEmployeeDocumentSuccess(state, action);
    case actions.DELETE_EMPLOYEE_DOCUMENT_FAIL:
      return DeleteEmployeeDocumentFail(state, action);
    case actions.CHANGE_REQUEST_START:
      return changeRequestStart(state);
    case actions.CHANGE_REQUEST_SUCCESS:
      return changeRequestSuccess(state, action);
    case actions.CHANGE_REQUEST_FAIL:
      return changeRequestFail(state, action);
    case actions.GET_CHANGE_REQUEST_START:
      return getChangeRequestStart(state);
    case actions.GET_CHANGE_REQUEST_SUCCESS:
      return getChangeRequestSuccess(state, action);
    case actions.GET_CHANGE_REQUEST_FAIL:
      return getChangeRequestFail(state, action);
    case actions.GET_EMPLOYEE_DOCUMENT_TYPE_LIST_REQUEST:
      return {
        ...state,
        loading: false,
        eror:null
      };
    case actions.GET_EMPLOYEE_DOCUMENT_TYPE_LIST_SUCCESS:
      return {
        ...state,
        error: null,
        loading: false,
        documenttype: action.payload.data,
     
      };

    case actions.GET_EMPLOYEE_DOCUMENT_TYPE_LIST_FAILURE:
      return {
        ...state,
        error: action.error,
        loading: false,
      };
 
  
    default:
      return {
        ...state,
      };
  }
};
export default employeeDocumentsReducer;
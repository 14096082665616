import {
  GET_CANDIDATES_SUCCESS,
  GET_CANDIDATES_REQUEST,
  GET_CANDIDATES_FAILURE,
  ADD_CANDIDATES_REQUEST,
  ADD_CANDIDATES_SUCCESS,
  ADD_CANDIDATES_FAILURE,
  ADD_BULK_CANDIDATES_REQUEST,
  ADD_BULK_CANDIDATES_FAILURE,
  ADD_BULK_CANDIDATES_SUCCESS,
  GET_SINGLE_CANDIDATE_FAILURE,
  GET_SINGLE_CANDIDATE_REQUEST,
  GET_SINGLE_CANDIDATE_SUCCESS,
  UPDATE_CANDIDATES_REQUEST,
  UPDATE_CANDIDATES_SUCCESS,
  UPDATE_CANDIDATES_FAILURE,
  CREATE_APPOINTMENT_REQUEST,
  CREATE_APPOINTMENT_FAILURE,
  CREATE_APPOINTMENT_SUCCESS,
  SEARCH_AND_FILTER_CANDIDATE_REQUEST,
  SEARCH_AND_FILTER_CANDIDATE_FAILURE,
  SEARCH_AND_FILTER_CANDIDATE_SUCCESS,
  GET_DASHBOARD_DATA_REQUEST,
  GET_DASHBOARD_DATA_SUCCESS,
  GET_DASHBOARD_DATA_FAILURE,
  GET_CANDIDATE_DOC_REQUEST,
  GET_CANDIDATE_DOC_RESPONSE,
  GET_CANDIDATE_DOC_ERROR,
  BULK_CANDIDATE_CSV_HEADERS_SUCCESS,
  BULK_CANDIDATE_CSV_HEADERS_FAIL,
} from '../actionTypes/candidates';

import * as actionTypes from '../actionTypes/candidates';

const initialState = {
  loading: false,
  data: [],
  documents: null,
  dashboardData: {},
  candidate: null,
  error: null,
  add_error: null,
  bulk_error: null,
  success: false,
  bulk_success: false,
  createAppointmentError: null,
  searchAndFilterSuccess: false,
  institutions: [],
  institutionLoading: false,
  bulkCVSData: [],
};

const candidatesReducer = (state = initialState, { type, payload }) => {
  switch (type) {
    case GET_CANDIDATES_REQUEST:
      return {
        ...state,
        loading: true,
        success: false,
      };
    case GET_CANDIDATE_DOC_REQUEST:
      return {
        ...state,
        loading: true,
        success: false,
      };
    case GET_CANDIDATE_DOC_RESPONSE:
      return {
        ...state,
        loading: false,
        success: false,
        documents: payload,
      };
    case GET_CANDIDATE_DOC_ERROR:
      return {
        ...state,
        loading: false,
        success: false,
        documents: null,
      };
    case GET_CANDIDATES_FAILURE:
      return {
        ...state,
        loading: false,
        success: false,
      };

    case GET_CANDIDATES_SUCCESS:
      return {
        ...state,
        loading: false,
        success: false,
        data: payload,
      };

    case GET_SINGLE_CANDIDATE_REQUEST:
      return {
        ...state,
        success: false,
      };

    case GET_SINGLE_CANDIDATE_FAILURE:
      return {
        ...state,
        loading: false,
        success: false,
        error: payload,
      };

    case GET_SINGLE_CANDIDATE_SUCCESS:
      return {
        ...state,
        loading: false,
        success: false,
        candidate: payload,
      };

    case ADD_CANDIDATES_REQUEST:
      return {
        ...state,
        success: false,
        error: null,
      };

    case ADD_CANDIDATES_FAILURE:
      return {
        ...state,
        loading: false,
        success: false,
        add_error: payload,
      };
    case ADD_CANDIDATES_SUCCESS:
      return {
        ...state,
        loading: false,
        success: true,
        data: payload,
      };
    case ADD_BULK_CANDIDATES_REQUEST:
      return {
        ...state,
        bulk_success: false,
        bulk_error: null,
      };

    case ADD_BULK_CANDIDATES_FAILURE:
      return {
        ...state,
        loading: false,
        bulk_success: false,
        bulk_error: payload,
      };
    case ADD_BULK_CANDIDATES_SUCCESS:
      return {
        ...state,
        loading: false,
        bulk_success: true,
        data: payload,
      };
    case CREATE_APPOINTMENT_REQUEST:
      return {
        ...state,
        loading: true,
        success: false,
      };
    case CREATE_APPOINTMENT_FAILURE:
      return {
        ...state,
        loading: false,
        success: false,
        createAppointmentError: payload,
      };
    case CREATE_APPOINTMENT_SUCCESS:
      return {
        ...state,
        loading: false,
        createAppointmentError: null,
      };
    case SEARCH_AND_FILTER_CANDIDATE_REQUEST:
      return {
        ...state,
        loading: true,
        searchAndFilterSuccess: false,
      };

    case SEARCH_AND_FILTER_CANDIDATE_FAILURE:
      return {
        ...state,
        loading: false,
        searchAndFilterSuccess: false,
        error: payload,
      };

    case SEARCH_AND_FILTER_CANDIDATE_SUCCESS:
      return {
        ...state,
        loading: false,
        searchAndFilterSuccess: false,
        data: payload,
      };
    case GET_DASHBOARD_DATA_REQUEST:
      return {
        ...state,
        loading: true,
        success: false,
      };

    case GET_DASHBOARD_DATA_FAILURE:
      return {
        ...state,
        loading: false,
        success: false,
        error: payload,
      };

    case GET_DASHBOARD_DATA_SUCCESS:
      return {
        ...state,
        loading: false,
        success: false,
        dashboardData: payload,
      };

    case UPDATE_CANDIDATES_REQUEST:
      return {
        ...state,
        loading: true,
      };

    case UPDATE_CANDIDATES_SUCCESS:
      return {
        ...state,
        loading: false,
        success: true,
      };

    case UPDATE_CANDIDATES_FAILURE:
      return {
        ...state,
        loading: false,
        error: true,
      };

    case actionTypes.GET_INSTITUTIONS_REQUEST:
      return {
        ...state,
        institutionloading: true,
      };

    case actionTypes.GET_INSTITUTIONS_SUCCESS:
      return {
        ...state,
        institutionloading: false,
        success: true,
        institutions: payload,
      };

    case actionTypes.GET_INSTITUTIONS_FAILURE:
      return {
        ...state,
        institutionloading: false,
        error: true,
      };
    case BULK_CANDIDATE_CSV_HEADERS_SUCCESS:
      return {
        ...state,
        loading: false,
        error: null,
        bulkCVSData: payload,
      };

    case BULK_CANDIDATE_CSV_HEADERS_FAIL:
      return {
        ...state,
        loading: false,
        error: true,
      };

    default:
      return {
        ...state,
      };
  }
};

export default candidatesReducer;

import React, { useState } from 'react';
import PropTypes from 'prop-types';
import {
  Container,
  Logo,
  LogoRed,
  SidebarContent,
  Menu,
  TopMenu,
  MainContent,
} from './styles';
import { svgs } from '../../assets';
import CustomLink from '../common/CustomLink';
import { useLocation } from 'react-router-dom';
import { decodeToken, getToken } from 'utils/auth';




const onboardingPaths = [
  { path: '/dashboard', label: 'Dashboard' },
  { path: '/candidates/onboarding', label: 'Candidates' },
  // { path: '/employees/onboarding', label: 'Employees' },
  { path: '/workflow', label: 'Workflow' },
  // { path: '/settings', label: 'Setting' },
];

/**
 * Sidebar component
 *
 * @param {Object} props Component props
 * @param {JSX.Element} children children node props
 * @returns {JSX.Element} component
 */
const Sidebar = ({ children, ...props }) => {
  const { pathname } = useLocation();
  const token = getToken();
  const user = decodeToken(token);

  let submenus =[
    { path: '/manage-admins', label: 'Admin settings' },
    { path: '/manage-pension', label: 'Pension Providers' },
    { path: '/manage-signature', label: 'Signature' },
    { path: '/manage-banks', label: 'Banks' },
    { path: '/confirmation-settings', label: 'Confirmation Settings' },
    { path: '/letters', label: 'Letter Management' },
  ]

  if (['SUPERADMIN'].some((val) => user?.roles?.includes(val)) ){
    submenus.push({ path: '/impersonate', label: 'Impersonate' },)
  }
  


  const initialState = {
    home: false,
    onboarding: pathname.startsWith('/onboarding/') ?? false,
    employees: pathname.startsWith('/employees/') ?? false,
    companies: false,
    tasks: false,
    approval: false,
    leave: pathname.startsWith('/leave-management/') ?? false,
    settings: pathname.startsWith('/settings/') ?? false,
    selfService: false,
    exitManagement: pathname.startsWith('/exit/') ?? false,
    headcount: false,
    hmo: pathname.startsWith('/hmo/') ?? false,
    complaint: pathname.startsWith('/complaints/') ?? false,
    appraisal:  pathname.startsWith('/appraisal/') ?? false,
  };
  const [menuDisplay, setMenuDisplay] = useState(initialState);
  const updateMenuDisplay = (e) => {
    e.persist();
    setMenuDisplay((prev) => ({
      ...prev,
      ...initialState,
    }));
    setMenuDisplay((prev) => ({
      ...prev,
      [e.target.id]: !menuDisplay[e.target.id],
    }));
  };
  const renderAdmin = () => (
    <>
      <CustomLink label="Home" leftIcon={svgs.HOME} path="/home" />
      <CustomLink
        label="Onboarding"
        leftIcon={svgs.ONBOARDING_ICON}
        path="/onboarding"
        subMenus={onboardingPaths}
        more
        updateMenuDisplay={updateMenuDisplay}
        showMenu={menuDisplay.onboarding}
        id="onboarding"
      />
      <CustomLink
        label="Employees"
        leftIcon={svgs.USER}
        path="/employees"
        subMenus={[
          { path: '/manage-employees', label: 'Manage Employees' },
          { path: '/announcement', label: 'Announcement' },
          { path: '/request-change', label: 'Change Request' },
          { path: '/welfare', label: 'Welfare' },
        ]}
        more
        updateMenuDisplay={updateMenuDisplay}
        showMenu={menuDisplay.employees}
        id="employees"
      />
      <CustomLink label="Companies" leftIcon={svgs.COMPANY} path="/companies" />
      {/* <CustomLink label="Tasks" leftIcon={svgs.CLIPBOARD} path="/tasks" /> */}
      <CustomLink label="Approvals" leftIcon={svgs.CLIPBOARD} path="/approval" />
      <CustomLink
        label="Disciplinary"
        leftIcon={svgs.CLIPBOARD}
        path="/disciplinary/management"
      />
      <CustomLink
        label="Exit Management"
        leftIcon={svgs.CLIPBOARD}
        path="/exit"
        subMenus={[
          { path: '/overview', label: 'Exit Request & History' },
          { path: '/settings', label: 'Exit Settings' },
        ]}
        more
        updateMenuDisplay={updateMenuDisplay}
        showMenu={menuDisplay.exitManagement}
        id="exitManagement"
      />
      <CustomLink
        label="Leave and Holi..."
        leftIcon={svgs.CLIPBOARD}
        path="/leave-management"
        subMenus={[
          { path: '/requests', label: 'Leave Management' },
          { path: '/settings', label: 'Leave Settings' },
        ]}
        more
        updateMenuDisplay={updateMenuDisplay}
        showMenu={menuDisplay.leave}
        id="leave"
      />
      <CustomLink
        label="Settings"
        leftIcon={svgs.SETTINGS}
        path="/settings"
        subMenus={submenus}
        more
        updateMenuDisplay={updateMenuDisplay}
        showMenu={menuDisplay.settings}
        id="settings"
      />


        <CustomLink
        label="Headcount"
        leftIcon={svgs.CLIPBOARD}
        path="/headcount"
        subMenus={[
          { path: '/dashboard', label: 'Dashboard' },

          { path: '/questions', label: 'Question' },
        ]}
        more
        updateMenuDisplay={updateMenuDisplay}
        showMenu={menuDisplay.headcount}
        id="headcount"
      />

      <CustomLink
        label="Complaints"
        leftIcon={svgs.SETTINGS}
        path="/complaints"
        subMenus={[
          { path: '/dashboard', label: 'Dashboard' },
          { path: '/category', label: 'Categories' },
        ]}
        more
        updateMenuDisplay={updateMenuDisplay}
        showMenu={menuDisplay.complaint}
        id="complaint"
      />


      <CustomLink
        label="HMO"
        leftIcon={svgs.SETTINGS}
        path="/hmo"
        subMenus={[
          { path: '/dashboard', label: 'Dashboard' },
          { path: '/hmos', label: 'Hmos' },
          { path: '/complaints', label: 'Hmo Complaints' },
          { path: '/surveys', label: 'Hmo Surveys' },
          { path: '/change-request-list', label: 'Hmo Change Request' },
          { path: '/announcements', label: 'Hmo Announcements' },
         
        ]}
        more
        updateMenuDisplay={updateMenuDisplay}
        showMenu={menuDisplay.hmo}
        id="hmo"
      />

     <CustomLink
        label="Appraisal"
        leftIcon={svgs.SETTINGS}
        path="/appraisal"
        subMenus={[
          { path: '/dashboard', label: 'Dashboard' },
         
        ]}
        more
        updateMenuDisplay={updateMenuDisplay}
        showMenu={menuDisplay.appraisal}
        id="appraisal"
      />
    </>
  );

  const renderCandidate = () => (
    <>
      <CustomLink label="Home" leftIcon={svgs.HOME} path="/home" />
      <CustomLink label="My Profile" leftIcon={svgs.USER} path="/candidate/profile/summary" />
      {/* <CustomLink label="Onboarding" leftIcon={svgs.ONBOARDING_ICON} path="/onboarding" /> */}
      <CustomLink
        label="My Welfare"
        leftIcon={svgs.WELFARE_ICON}
        path="/candidate/welfare"
      />
      {/* <CustomLink
        label="Settings"
        leftIcon={svgs.SETTINGS}
        path="/settings"
        subMenus={[
          { path: '/manage-admins', label: 'Manage admins' },
          { path: '/roles', label: 'Roles' },
        ]}
        more
        updateMenuDisplay={updateMenuDisplay}
        showMenu={menuDisplay.settings}
        id="settings"
      /> */}
      <CustomLink
        label="Self-Service"
        leftIcon={svgs.SETTINGS}
        path="/selfservice"
        subMenus={[
          { path: '/disciplinary', label: 'Disciplinary' },
          { path: '/leave', label: 'Leave' },
          { path: '/exit', label: 'Exit' },
          { path: '/complaints', label: 'Complaints' },
          { path: '/activities', label: 'Activities' },
          { path: '/headcount', label: 'Headcount' },
        ]}
        more
        updateMenuDisplay={updateMenuDisplay}
        showMenu={menuDisplay.selfService}
        id="selfService"
      />
    </>
  );

  return (
    <Container>
      <SidebarContent {...props}>
        <TopMenu>
          {props.candidate ? <LogoRed /> : <Logo />}
          <Menu {...props}>{props.candidate ? renderCandidate() : renderAdmin()}</Menu>
        </TopMenu>
      </SidebarContent>
      <MainContent>{children}</MainContent>
    </Container>
  );
};

Sidebar.propTypes = {
  children: PropTypes.element.isRequired,
};

export default React.memo(Sidebar);

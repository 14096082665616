import React, { useState } from 'react';
import Button from 'components/common/Button';
import { EMPTY_FILE, Wrapper } from 'pages/Candidate/Offer/styles';
import FileUpload from 'components/common/FileUpload';
import { svgs } from 'assets';
import { useToast } from 'context/toastContext';


export const Step3 = (activeStep, setActiveStep, setData, data) => {
  const [fileValue, setFileValue] = useState();
  const toast = useToast();

  const handleOnchange = (e) => {
    e.persist();
    setFileValue(e.target.files[0]);
    setData((prev) => ({
      ...prev,
      handbook: e.target.files[0],
    }));
  };

  const handleNextStepwithValidation =()=>{
    if (data?.handbook){
    setActiveStep(activeStep + 1)
    return
    }
    return toast.error('Attachment is required');

  }
  return (
    <Wrapper>
      <div className="content">
        <div className="logo-wrapper">
          <EMPTY_FILE />
        </div>
        <p className="header">Upload your Signed Handbook </p>
        <p className="note">
          Hello,
          {' '}
          <span>{data?.firstname}</span>
          .
          <br />
          {`It's nice to have you on-board. Please provide a signed copy of
        your Handbook to confirm your acceptance.`}
        </p>

        <div className="file-upload-wrapper">
          <FileUpload
            className="upload"
            limit={5}
            value={fileValue}
            acceptedFiles="application/pdf,  docx"
            name="file"
            icon={svgs.UPLOAD_ICON_CLOUD}
            onChange={handleOnchange}
          />
        </div>
      </div>
      <div className="footer">
        <Button
          text="Previous"
          bordered
          className="button-default"
          company
          onClick={() => setActiveStep(activeStep - 1)}
        />
        <Button
          text="Next"
          className="button"
          onClick={() => handleNextStepwithValidation()}
        />
      </div>
    </Wrapper>
  );
};

import {
  GET_TEMPLATE_REQUEST,
  GET_TEMPLATE_SUCCESS,
  GET_TEMPLATE_FAILURE,
  GET_LETTER_CATEGORY_REQUEST,
  GET_LETTER_CATEGORY_SUCCESS,
  GET_LETTER_CATEGORY_FAIL,
} from '../actionTypes/templates';

const initialState = {
  loading: false,
  getTemplates: false,
  templates: [],
  category: [],
  error: false,
};

const templatesReducer = (state = initialState, action) => {
  switch (action.type) {
    case GET_TEMPLATE_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case GET_TEMPLATE_SUCCESS:
      return {
        ...state,
        loading: false,
        getTemplates: true,
        templates: action.payload,
      };
    case GET_TEMPLATE_FAILURE:
      return {
        ...state,
        loading: false,
        getTemplates: false,
        error: true,
      };

    case 'GET_TEMPLATE_INIT_STATE':
      return {
        ...state,
        getTemplates: false,
        errorMessage: '',
        error: false,
      };
      case GET_LETTER_CATEGORY_REQUEST:
        return {
          ...state,
          loading: false,
        };
      case GET_LETTER_CATEGORY_SUCCESS:
        return {
          ...state,
          loading: false,
          category: action.payload,
        };
      case GET_LETTER_CATEGORY_FAIL:
        return {
          ...state,
          loading: false,
          error: action.payload.error,
        };
    default:
      return {
        ...state,
      };
  }
};

export default templatesReducer;

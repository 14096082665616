import { GET_APPROVALS_REQUEST, GET_APPROVALS_SUCCESS, GET_APPROVALS_FAILURE, VIEW_REQUEST } from 'redux/actionTypes/approvalRequests'


const initialState = {
    approvalRequests : [],
    requestInView: {},
    loading: false,
}

const approvalRequestsReducer = (state=initialState, {type, payload}) => {
    switch (type) {
        case GET_APPROVALS_REQUEST:
            return {
                ...state,
                loading: true,
            }
        case GET_APPROVALS_SUCCESS:
            return {
                ...state,
                loading: false,
                approvalRequests: payload.approvalRequests
            }
        case GET_APPROVALS_FAILURE:
            return {
                ...state,
                loading: false,
            }
        case VIEW_REQUEST:
            return {
                ...state,
                requestInView: payload.requestInView,
            }
        default:
            return state;
    }
}

export default approvalRequestsReducer;
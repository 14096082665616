import {
  GET_DISCIPLINARY_FAILURE,
  GET_DISCIPLINARY_REQUEST,
  GET_DISCIPLINARY_SUCCESS,
  GET_DISCIPLINARY_RESPONSE_FAILURE,
  GET_DISCIPLINARY_RESPONSE_REQUEST,
  GET_DISCIPLINARY_RESPONSE_SUCCESS,
} from '../actionTypes/candidate';

const initialState = {
  loading: false,
  data: [],
  error: null,
  disciplinarySuccess: false,
  disciplinaryloading: false,
  disciplinaryerror: null,
  disciplinarysuccess: false
  
};

const candidateReducer = (state = initialState, action) => {
  switch (action.type) {
    case GET_DISCIPLINARY_REQUEST:
      return {
        ...state,
        loading: true,
        disciplinarySuccess: false,
      };

    case GET_DISCIPLINARY_SUCCESS:
      return {
        ...state,
        loading: false,
        disciplinarySuccess: true,
        data: action.payload,
      };

    case GET_DISCIPLINARY_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.error,
        disciplinarySuccess: false,
      };

      case GET_DISCIPLINARY_RESPONSE_SUCCESS:
        return {
          ...state,
          disciplinaryloading: false,
          disciplinarysuccess: true,
        };
  
      case GET_DISCIPLINARY_RESPONSE_REQUEST:
        return {
          ...state,
          disciplinaryloading: false,
          disciplinarysuccess: false,
         data: action.payload,
        };
  
      case  GET_DISCIPLINARY_RESPONSE_FAILURE:
        return {
          ...state,
          disciplinaryloading: false,
          disciplinaryerror: action.error,
          disciplinarysuccess: false,
        };
    default:
      return {
        ...state,
      };
  }
};

export default candidateReducer;

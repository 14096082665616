import styled from 'styled-components';

export const Container = styled.div`
  width: 100%;
  height: 100vh;
  background: #f4f7fa;
  padding: 34px 30px;
  margin-top: ${({ marginTop }) => marginTop + 'px'};

  @media screen and (max-width:997px){
    padding: 34px 10px;
  }
`;

import PageContainer from 'components/common/PageContainer';
import React, { useCallback, useEffect, useState } from 'react';
import Button from 'components/common/Button';
import { svgs } from 'assets';
import { useHistory } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { verifyUser } from 'redux/actions/user';
import { ContentWrapper } from './styles';

const VerifyToken = () => {
  const [loading, setLoading] = useState();
  const [message, setMessage] = useState(null);
  const [status, setStatus] = useState(null);
  const history = useHistory();
  const dispatch = useDispatch();
  const token = history.location.search.split('?token=')[1];

  const verifyToken = useCallback(async () => {
    setLoading(true);
    try {
      const result = await dispatch(verifyUser({ token }));
      setLoading(false);
      if (result?.payload?.status === 401) {
        setMessage(result?.payload?.data?.detail);
        setStatus('error');
        return;
      }
      if (result?.payload?.status === 200 || result?.payload?.success) {
        setMessage(result?.payload?.data?.detail);
        setStatus('success');
        return;
      }
    } catch (error) {
      setLoading(false);
    }
  }, [dispatch, token]);

  const navigate = () => {
    history.push('/signin');
  };
  useEffect(() => {
    verifyToken();
  }, [verifyToken]);

  if (loading) {
    return (
      <PageContainer>
        <ContentWrapper>
          <img src={svgs.LOADER} alt="loader" />
        </ContentWrapper>
      </PageContainer>
    );
  }

  return (
    <PageContainer>
      <ContentWrapper>
        {status === 'success' ? (
          <>
            <svgs.SUCCESS_CHECK2 />
            <p className="message-title">Verification Successful</p>
            <p className="message">
              You have been successfully verified, you can now click the button below to
              sign in to the platform
            </p>
            <Button className="button" text="Sign in" onClick={navigate} />
          </>
        ) : (
          <>
            <svgs.ERROR />
            <p className="message-title">Verification Failed</p>
            <p className="message">{message}</p>
            <Button
              className="button"
              text={loading ? 'Loading...' : 'Retry'}
              onClick={verifyToken}
            />
          </>
        )}
      </ContentWrapper>
    </PageContainer>
  );
};

export default VerifyToken;

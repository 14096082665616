import React from 'react';
import Button from '../../components/common/Button';
import Form from '../../components/OnboardingForm';
import Input from '../../components/common/Input';
import { FlexItem, FlexItemRight } from './styles';
import { Formik } from 'formik';
import * as Yup from 'yup';
import { Error } from 'pages/Employee/Welfare/style';

const profileSchema = Yup.object().shape({
  fname: Yup.string().required('First Name is required'),
  lname: Yup.string().required('Last Name is required'),
  email: Yup.string()
    .required('Email is required')
    .email('Please enter a valid email address'),
  phone: Yup.string().required('Phone is required'),
});

const PersonalInfoForm = ({ user, sendProfileDetails }) => {
  const handleSubmit = async (values, { resetForm }) => {
    sendProfileDetails(values);
  };

  
  return (
    <Form title="Personal Information">
      <Formik
        enableReinitialize
        initialValues={{
          fname: user?.firstname || '',
          lname: user?.lastname || '',
          email: user?.email || '',
          phone: user?.phone || '',
        }}
        validationSchema={profileSchema}
        onSubmit={handleSubmit}
      >
        {({
          values,
          handleChange,
          handleSubmit,
          touched,
          handleBlur,
          errors,
          resetForm,
        }) => (
          <>
            <FlexItem>
              <Input
                label="First Name"
                type="text"
                name="fname"
                placeholder="Olamide"
                required={false}
                onBlur={handleBlur}
                value={values.fname}
                onChange={handleChange}
              />
              {touched.fname && errors.fname ? <Error>{errors.fname}</Error> : null}

              <Input
                label="Last Name"
                type="text"
                name="lname"
                placeholder="Jegede"
                required={false}
                onBlur={handleBlur}
                value={values.lname}
                onChange={handleChange}
              />
              {touched.lname && errors.lname ? <Error>{errors.lname}</Error> : null}
            </FlexItem>

            <Input
              label="Phone Number"
              type="number"
              name="phone"
              placeholder="012345679"
              required={false}
              onBlur={handleBlur}
              value={values.phone}
              onChange={handleChange}
            />
            {touched.phone && errors.phone ? <Error>{errors.phone}</Error> : null}
            <Input
              label="Email"
              type="text"
              name="email"
              placeholder="f.olamide@gmail.com"
              required={false}
              onBlur={handleBlur}
              value={values.email}
              onChange={handleChange}
            />
            {touched.email && errors.email ? <Error>{errors.email}</Error> : null}

            <FlexItemRight>
              <Button onClick={handleSubmit} type="button" text="Save Changes" />
            </FlexItemRight>
          </>
        )}
      </Formik>
    </Form>
  );
};

export default PersonalInfoForm;

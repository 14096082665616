import styled from 'styled-components';

export const TableContainer = styled.div.attrs((props) => ({
  className: props.className,
}))`
  margin-top: ${(props) => (props.isDisciplinary ? '20px' : null)};
  display: flex;
  flex-direction: column;
  font-family: Inter;
  border-radius: 10px;
  box-shadow: 0px 4px 4px rgba(199, 223, 241, 0.25);
  background: #ffffff;
  padding: 10px;
  min-width: ${(props) => (props.min ? '80vw' : null)};
  table {
    font-family: arial, sans-serif;
    border-collapse: collapse;
    width: 100%;
  }

  tr {
    border: 0px solid #dddddd;
    border-bottom: 1px solid #ddd;
  }

  td {
    text-align: left;
    padding: 20px 8px;
  }

  tr:last-of-type {
    border: 0px solid #dddddd;
    border-bottom: 0px solid #ddd;
  }

  th {
    border: 0px solid #dddddd;
    border-bottom: 1px solid #ddd;
    text-align: left;
    padding: 20px 8px;
  }

  .header.border-bottom {
    border-bottom: 1px solid #f2f2f2;
    padding: 12px 8px;
  }

  .dropdown-menu {
    pointer-events: auto !important;
    border-radius: 5px;
    min-width: 140px !important;
  }
  &.category-table .dropdown-menu.show {
    transform: translate(-150px, 25px) !important;
    height: auto !important;
  }

  &.complaint-table .dropdown-menu.show {
    transform: translate(-150px, 25px) !important;
    height: auto !important;
  }
`;
export const TablerowImgDiv = styled.span`
  display: inline-block;
  img {
    height: 36px;
    width: 36px;
    border-radius: 50%;
  }
`;

export const AddToTopDiv = styled.div`
  margin-top: 150px;
  position: relative;
  z-index: 4;
`;

export const AddToTopDivII = styled.div`
  margin-top: 100px;
  position: relative;
  z-index: 4;
`;
